import { DataStoreConnectorInterface } from '@/services/connectors/interfaces';
import { FormDataToken } from '@/models/BaseTypes';
import { orderAPI } from '@/services/http-service';
import { AxiosResponse } from 'axios';
import { logRequestErrors } from '@/composables/data-handling';

export class DataStoreConnector implements DataStoreConnectorInterface {
  private static DATA_STORE_API_BASE_PATH = '/storedData';
  retrieveData(formDataToken: FormDataToken | null): Promise<string> {
    return orderAPI
      .get(DataStoreConnector.DATA_STORE_API_BASE_PATH, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response: void | AxiosResponse) => {
        if (response) {
          return response.data;
        }
      })
      .catch((error) => {
        logRequestErrors(error);
        throw error;
      });
  }

  storeData(data: string, formDataToken: FormDataToken | null): Promise<void> {
    return orderAPI
      .post(DataStoreConnector.DATA_STORE_API_BASE_PATH, data, {
        headers: {
          'token': JSON.stringify(formDataToken),
          'Content-Type': 'text/plain',
        },
      })
      .then((response) => response.data)
      .catch((error) => {
        logRequestErrors(error);
        throw error;
      });
  }
}

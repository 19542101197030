import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "personal-business-data-form" }
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { class: "form-row" }
const _hoisted_4 = { class: "form-row" }
const _hoisted_5 = {
  key: 0,
  class: "form-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementSelectField = _resolveComponent("FormElementSelectField")!
  const _component_DisplayElementUserSalutation = _resolveComponent("DisplayElementUserSalutation")!
  const _component_FormElementInputField = _resolveComponent("FormElementInputField")!
  const _component_FormElementCheckBox = _resolveComponent("FormElementCheckBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.nameEditable)
        ? (_openBlock(), _createBlock(_component_FormElementSelectField, {
            key: 0,
            class: "col-md-6 mb-4",
            label: _ctx.commonTexts.userInterface.formFieldLabels.salutation,
            selectOptions: _ctx.salutationOptionsCompany,
            value: _ctx.value.salutation,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.SALUTATION, $event))),
            "field-has-error": _ctx.fieldErrors.salutation,
            onSelectionChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetFieldError('salutation')))
          }, null, 8, ["label", "selectOptions", "value", "field-has-error"]))
        : (_openBlock(), _createBlock(_component_DisplayElementUserSalutation, {
            key: 1,
            "show-company-name": "",
            address: _ctx.value,
            class: "col-md-12 mb-4"
          }, null, 8, ["address"]))
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.nameEditable)
        ? (_openBlock(), _createBlock(_component_FormElementInputField, {
            key: 0,
            label: _ctx.commonTexts.userInterface.formFieldLabels.companyName,
            value: _ctx.value.companyName,
            onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.COMPANY_NAME, $event))),
            "field-has-error": _ctx.fieldErrors.companyName,
            onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetFieldError('companyName'))),
            class: "col-md-12 mb-4",
            maxlength: "35"
          }, null, 8, ["label", "value", "field-has-error"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.nameEditable)
        ? (_openBlock(), _createBlock(_component_FormElementCheckBox, {
            key: 0,
            value: _ctx.showCompanySuffixes,
            onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCompanySuffixes = $event)),
            name: "showCompanySuffixes"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.commonTexts.userInterface.formFieldLabels
            .showAdditionalFieldsPersonalBusinessData), 1)
            ]),
            _: 1
          }, 8, ["value"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.showCompanySuffixes && _ctx.nameEditable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_FormElementInputField, {
            label: _ctx.commonTexts.userInterface.formFieldLabels.companySuffix1,
            value: _ctx.value.companySuffix1,
            onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.COMPANY_SUFFIX_1, $event))),
            readonly: !_ctx.nameEditable,
            class: "col-md-6 mb-4"
          }, null, 8, ["label", "value", "readonly"]),
          _createVNode(_component_FormElementInputField, {
            label: _ctx.commonTexts.userInterface.formFieldLabels.companySuffix2,
            value: _ctx.value.companySuffix2,
            onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.COMPANY_SUFFIX_2, $event))),
            readonly: !_ctx.nameEditable,
            class: "col-md-6 mb-4"
          }, null, 8, ["label", "value", "readonly"]),
          _createVNode(_component_FormElementInputField, {
            label: _ctx.commonTexts.userInterface.formFieldLabels.companySuffix3,
            value: _ctx.value.companySuffix3,
            onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.COMPANY_SUFFIX_3, $event))),
            readonly: !_ctx.nameEditable,
            class: "col-md-6"
          }, null, 8, ["label", "value", "readonly"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
import { ViolationFields } from '@/validators/validatorTypes';

export class RegionSelectValidator {
  // Extendable array of values that must not be selected on submit:
  invalidValues = ['-'];

  validate(target: string): ViolationFields[] {
    const violations: ViolationFields[] = [];
    if (target.length === 0 || this.invalidValues.includes(target)) {
      violations.push(ViolationFields.PVA);
    }
    return violations;
  }
}

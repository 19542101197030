<template>
  <ContentArea>
    <div>
      <div
        id="main-order-form"
        ref="mainOrderForm"
        class="address-data-container active"
      >
        <header>
          <h2 class="main-content-title">
            {{ mainTitle }}
          </h2>
        </header>
        <AddressError
          :addressChanged="addressChanged"
          :addressInvalid="addressInvalid || orderFormOptionalFieldWrong"
          :addressIgnore="addressIgnore"
          :addressSuggested="addressSuggested"
          :addressComplete="addressComplete"
          :addressAlreadyChecked="formSubmitted"
          :personal-data-incomplete="orderFormPersonalDataIsInvalid"
        />
        <LoginInterface
          v-if="enableLoginInterface"
          :login-url="appConfiguration.urls.loginUrl"
        />
        <TheOverlay :show="submittingFormData" :isFullPage="false">
          <OrderForm
            :address-check-result="addressValidationResult"
            :order-form-data="orderFormData"
            :form-data-currently-validating="isValidating"
            :country-options="countryOptions"
            :name-is-editable="isNameEditable || !userExists"
            :request-email-phone-number="true"
            :request-region-select="false"
            :full-address-required="true"
            :product-type="formData.salesCampaign.printProductType"
            :email-is-mandatory="emailIsMandatory"
            :request-miles-and-more-id="requestMilesAndMoreId"
            :miles-and-more-id="formData.mmNumber"
            :gp-selection-is-enabled="
              gpTypeSelectionPossible && !isGiftSubscription
            "
            :business-address="businessAddress"
            :delivery-address="deliveryAddress"
            :delivery-address-enabled="deliveryAddressPossible"
            :invoice-address="invoiceAddress"
            :invoice-address-enabled="invoiceAddressPossible"
            @formValidated="submitOrderForm($event)"
            @updateMilesAndMoreId="$emit('updateMilesAndMoreId', $event)"
            @updateBusinessData="updateBusinessData($event)"
            @updatePrivateData="updatePrivateData($event)"
            @updateDeliveryAddress="updateDeliveryAddress($event)"
            @deleteDeliveryAddress="deleteDeliveryAddress"
            @updateInvoiceAddress="updateInvoiceAddress($event)"
            @deleteInvoiceAddress="deleteInvoiceAddress"
          />
        </TheOverlay>
        <SubTitleModal
          :open-modal="openSubTitleModal"
          :sub-title-out-link="subTitleOutLink"
          :sub-title-copy="subTitleCopy"
          @modalClosed="closeModal"
        />
      </div>
    </div>
  </ContentArea>
</template>
<script lang="ts">
  import AddressError from '@/components/AddressError.vue';
  import OrderForm from '@/components/Forms/OrderForm.vue';
  import {
    deleteAddress,
    getBusinessAddressFunctionality,
    getOrderFormLogic,
    prefillBusinessData,
  } from '@/composables/order-form';
  import { isInputFilled } from '@/composables/form-functions';
  import { computed, defineComponent, nextTick, Ref, ref, watch } from 'vue';
  import { ConfigGetterTypes } from '@/store/modules/config/types';
  import { StoreModules } from '@/store/types';
  import { OrderFormData } from '@/types/OrderFormTypes';
  import {
    FormBusinessAddress,
    FormDeviatingAddress,
  } from '@/models/OrderData';
  import LoginInterface from '@/components/GeneralInterface/LoginInterface.vue';
  import { SubscriptionType } from '@/types/GeneralInterfaceTypes';
  import commonTexts from '@/data/commonTexts';
  import ContentArea from '@/components/PageLayout/ContentArea.vue';
  import SubTitleModal from '@/components/Modals/SubTitleModal.vue';
  import TheOverlay from '@/components/Overlay/TheOverlay.vue';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'OrderPrint',
    components: {
      SubTitleModal,
      LoginInterface,
      OrderForm,
      AddressError,
      ContentArea,
      TheOverlay,
    },
    props: {
      prefillFormData: {
        type: Boolean,
        required: true,
      },
      requestMilesAndMoreId: {
        type: Boolean,
        required: false,
        default: false,
      },
      enableLoginInterface: {
        type: Boolean,
        required: false,
        default: true,
      },
      subscriptionType: {
        type: Number,
        required: true,
      },
      isFollowUpForm: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['formPrefilled', 'updateMilesAndMoreId'],
    setup(props, { emit }) {
      const store = useStore();
      const mainOrderForm: Ref<HTMLDivElement | null> = ref(null);

      const {
        businessAddress,
        deliveryAddress,
        invoiceAddress,
        gpTypeSelectionPossible,
        invoiceAddressPossible,
        deliveryAddressPossible,
      } = getBusinessAddressFunctionality();

      const {
        orderFormData,
        streetError,
        streetNumberError,
        cityError,
        streetCorrected,
        zipCodeCorrected,
        cityCorrected,
        addressIgnore,
        citySuggestion,
        addressOptions,
        cityOptions,
        streetOptions,
        addressSuggested,
        fullAddressNeeded,
        isValidating,
        formSubmitted,
        addressFormSubmitted,
        orderFormAddressIsInvalid,
        orderFormAddressHasEmptyFields,
        orderFormPersonalDataIsInvalid,
        orderFormOptionalFieldWrong,
        content,
        formData,
        address,
        userExists,
        isNameEditable,
        addressInvalid,
        addressComplete,
        addressChanged,
        goToCheckData,
        countryOptions,
        prefillForm,
        showRegionSelect,
        submitOrderForm,
        isAddressRequired,
        postOfficeBoxSelected,
        addressValidationResult,
        submittingFormData,
        openSubTitleModal,
        subTitleOutLink,
        subTitleCopy,
        closeModal,
      } = getOrderFormLogic(businessAddress, deliveryAddress, invoiceAddress);

      const emailIsMandatory = computed(() => {
        // Email is mandatory, if so specified in salesCampaign AND for Print / Magazines only – in digital campaigns
        //    the user already has registered with email and email input field is shown never
        //  Additionally: In gift subscriptions email only is mandatory for recruiter,
        //    in LWL for ordering party
        return (
          store.getters.formData.salesCampaign.emailMandatory &&
          !store.getters.formData.salesCampaign.giftSubscription
        );
      });

      const isLoggedIn = computed(() => {
        return store.state.isLoggedIn;
      });
      const loginChecked = computed(() => {
        return store.state.loginChecked;
      });
      const appConfiguration = computed(
        () =>
          store.getters[
            StoreModules.CONFIG_MODULE + ConfigGetterTypes.GET_CONFIG
          ],
      );

      const updatePrivateData = function (updatedData: OrderFormData) {
        orderFormData.value = updatedData;
      };
      const updateBusinessData = function (updatedData: FormBusinessAddress) {
        businessAddress.value = updatedData;
      };
      const updateDeliveryAddress = function (
        updatedData: FormDeviatingAddress,
      ) {
        deliveryAddress.value = updatedData;
      };
      const deleteDeliveryAddress = function () {
        deleteAddress(deliveryAddress.value);
      };
      const updateInvoiceAddress = function (
        updatedData: FormDeviatingAddress,
      ) {
        invoiceAddress.value = updatedData;
      };
      const deleteInvoiceAddress = function () {
        deleteAddress(invoiceAddress.value);
      };

      watch(props, () => {
        if (props.prefillFormData) {
          prefillForm();

          const addresses = formData.value.addresses;

          if (gpTypeSelectionPossible.value) {
            businessAddress.value = prefillBusinessData(
              businessAddress.value,
              addresses.businessAddress,
              store.state.userIsReceiver,
            ) as FormBusinessAddress;
          }
          if (deliveryAddressPossible.value) {
            deliveryAddress.value = prefillBusinessData(
              deliveryAddress.value,
              addresses.deliveryAddress,
              store.state.userIsReceiver,
            ) as FormDeviatingAddress;
          }
          if (invoiceAddressPossible.value) {
            invoiceAddress.value = prefillBusinessData(
              invoiceAddress.value,
              addresses.invoiceAddress,
              store.state.userIsReceiver,
            ) as FormDeviatingAddress;
          }
          if (props.isFollowUpForm) {
            nextTick(() => {
              if (mainOrderForm.value) {
                // caveat: in firefox scroll won't work, if argument is given
                mainOrderForm.value.scrollIntoView();
              }
            });
          }
          emit('formPrefilled');
        }
      });

      const mainTitle = computed(() => {
        if (
          props.subscriptionType ===
          (SubscriptionType.GIFT_SUBSCRIPTION as unknown as number)
        ) {
          return commonTexts.userFrontend.orderForm.mainTitleGift;
        } else if (
          props.subscriptionType ===
          (SubscriptionType.LWL_SUBSCRIPTION as unknown as number)
        ) {
          return commonTexts.userFrontend.orderForm.mainTitleLwL;
        }
        return content.value.start.headline2;
      });

      const isGiftSubscription = computed(() => {
        return formData.value.salesCampaign.giftSubscription;
      });

      return {
        orderFormData,
        streetError,
        streetNumberError,
        cityError,
        streetCorrected,
        zipCodeCorrected,
        cityCorrected,
        addressIgnore,
        citySuggestion,
        addressOptions,
        cityOptions,
        streetOptions,
        addressSuggested,
        fullAddressNeeded,
        isValidating,
        formSubmitted,
        addressFormSubmitted,
        orderFormAddressIsInvalid,
        orderFormAddressHasEmptyFields,
        orderFormPersonalDataIsInvalid,
        orderFormOptionalFieldWrong,
        content,
        formData,
        address,
        userExists,
        isNameEditable,
        addressInvalid,
        addressComplete,
        addressChanged,
        goToCheckData,
        countryOptions,
        showRegionSelect,
        submitOrderForm,
        isAddressRequired,
        isInputFilled,
        emailIsMandatory,
        postOfficeBoxSelected,
        isLoggedIn,
        loginChecked,
        appConfiguration,
        updatePrivateData,
        addressValidationResult,
        submittingFormData,
        // business address related logic:
        businessAddress,
        deliveryAddress,
        invoiceAddress,
        gpTypeSelectionPossible,
        invoiceAddressPossible,
        deliveryAddressPossible,
        updateBusinessData,
        deleteDeliveryAddress,
        updateDeliveryAddress,
        updateInvoiceAddress,
        deleteInvoiceAddress,
        mainTitle,
        isGiftSubscription,
        openSubTitleModal,
        subTitleOutLink,
        subTitleCopy,
        closeModal,
        commonTexts,
        mainOrderForm,
      };
    },
  });
</script>

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "miles-and-more-form" }
const _hoisted_2 = { class: "miles-and-more-form__content" }
const _hoisted_3 = { class: "miles-and-more-form__content-headline" }
const _hoisted_4 = { class: "miles-and-more-form__content-text" }
const _hoisted_5 = { class: "miles-and-more-form__content-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementInputField = _resolveComponent("FormElementInputField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.commonTexts.userFrontend.milesAndMore.form.headline), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.commonTexts.userFrontend.milesAndMore.form.text), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_FormElementInputField, {
        value: _ctx.milesAndMoreId,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.milesAndMoreId = $event)),
        label: _ctx.commonTexts.userFrontend.milesAndMore.form.label,
        "field-has-error": _ctx.hasError,
        onBlur: _ctx.updateMilesAndMoreId
      }, null, 8, ["value", "label", "field-has-error", "onBlur"])
    ])
  ]))
}
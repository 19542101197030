const currentYear = new Date().getFullYear();

export const sz = {
  config: {
    region: true,
    advertisingConsent: false,
    earliestStartDateOffsetForPrintInDays: 10,
    orderWithoutAddressCustomizable: true,
    dayPassPrefixes: ['DSZ1TAG'],
  },
  meta: {
    title:
      'Meine Bestellung - Süddeutsche Zeitung und SZ Plus - sueddeutsche.de',
  },
  contact: {
    link: 'service/kontaktformular',
    phoneNumber: '089/2183-8080',
    myDataLink: 'https://abo.sueddeutsche.de/service/meine-daten',
  },
  start: {
    headline1: 'Ihre Bestellung',
    headline2: 'Ihre Daten',
  },
  modals: {
    browserBack: {
      copy: '<p>Mit der Bestellung eines digitalen Abonnements können Sie über den Button “Zur SZ-Startseite” direkt auf der SZ-Homepage lesen.</p><p>Möchten Sie Änderungen an Ihrer Bestellung oder Ihrem bestehenden Abonnement vornehmen, dann klicken Sie bitte auf “Zu Konto & Abo”.</p>',
      link1: {
        label: 'Zur SZ-Startseite',
        href: 'https://produkte.sueddeutsche.de/',
      },
      link2: {
        href: 'https://abo.sueddeutsche.de/service/meine-abos',
      },
    },
  },
  gpNr: {
    headlineWithGpNr: 'Ich habe bereits eine Kundennummer',
    headlineWithoutGpNr: 'Ich habe noch keine Kundennummer',
    gpNrInputHint:
      '10-stellig, beginnt mit 3. Sie finden die Kundennummer in der Bestellbestätigung',
    error: {
      gpNrNotValid: 'Keine gültigen Daten',
      formValidationMessage:
        'Bitte überprüfen Sie die gekennzeichneten Felder und füllen Sie die Pflichtfelder aus.',
      agb: 'Bitte Akzeptieren Sie die AGB',
      gpLocked:
        'Der Onlinezugriff auf Ihre Daten ist zu unserem Bedauern nicht möglich, bitte wenden Sie sich an unseren Kundenservice: Telefon 089/2183-8080. Sie erreichen uns von Montag bis Freitag von 07:00 Uhr bis 18:00 Uhr und samstags von 07:30 Uhr bis 12:30 Uhr oder Sie nutzen das <I>Kontaktformular</I>.  <BR />' +
        'Vielen Dank für Ihr Verständnis.',
      gpUsed:
        '<strong>Verknüpfung fehlgeschlagen.</strong><br />' +
        'Ihr Abo/Ihre Kundennummer ist bereits einem anderen Benutzernamen zugeordnet.<br />' +
        'Bitte verwenden Sie diesen bereits vergebenen Benutzernamen. Haben Sie diesen vergessen, dann folgen Sie bitte diesem Link: <a href="https://www.sueddeutsche.de/?show-my-account" target="_blank" rel="noopener noreferrer">Benutzername oder Passwort vergessen?</a>',
    },
  },
  payment: {
    headline2: 'Bitte wählen Sie eine Zahlmethode',
    messageError: 'Bitte prüfen Sie die eingegebenen Daten.',
  },
  verification: {
    headline1: 'Bestellung abschließen',
    agbAdditional:
      'Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages begonnen wird. Mir ist bekannt, dass mein Widerrufsrecht durch diese Zustimmung mit der Beginn der Ausführung erlischt.',
  },
  success: {
    backLinkSrc: 'https://produkte.sueddeutsche.de/',
    contactSrc: 'https://service-test.sueddeutsche.de/kontakt.html',
    phone: '089 / 2183 - 8080',
    phoneSrc: 'tel:+498921838080',
    registrationPossible:
      '<b>Wichtig!</b> Scheinbar haben Sie in der Vergangenheit schon bei uns bestellt oder sind Kunde/Kundin. In Kürze erhalten Sie eine E-Mail, über die Sie Ihre Kundendaten anhand Ihrer Kundennummer zusammenführen können.',
    additionalCopy:
      '<p>Die digitale Welt der SZ bietet Ihnen vielfältige Inhalte. Unter <a href="https://produkte.sueddeutsche.de/willkommen/" target="_blank">sz.de/auf-einen-blick</a> können Sie sich einen ersten Überblick verschaffen.</p>',
  },
  orderExists: {
    info: 'Dieses Produkt wurde bereits für Sie aktiviert.<br /> Sie haben aktuell Zugriff auf alle SZ-Plus-Artikel.',
    buttonText: 'Zurück zur Startseite',
    buttonTextBackToArticle: 'SZ Plus Artikel lesen',
    link: 'https://produkte.sueddeutsche.de/',
  },
  followUpOffer: {
    infoText:
      '<p> Sie können mit Ihren aktuellen Zugangsdaten SZ Plus bereits nutzen.</p><p class="mb-0">Endet Ihr Angebot demnächst? Bestellen Sie hier Ihr Folgeangebot.</p>',
    homePageLink: 'https://produkte.sueddeutsche.de/',
  },
  error: {
    headline1: 'Es tut uns leid . . .',
    server: '. . . es gab ein Serverproblem.',
    startPageLink: 'https://produkte.sueddeutsche.de/',
    startPageLinkLabel: 'Erneut versuchen',
  },
  productunavailable: {
    headline2: '. . . dieses Produkt ist nicht verfügbar.',
  },
  trialExpired: {
    headline1: 'Es tut uns leid . . .',
    headline2: '. . . dieses Produkt können Sie im Moment nicht bestellen.',
    textDigital:
      'Es ist uns wichtig, dass Sie die digitalen Produkte der Süddeutschen Zeitung kostenlos kennenlernen und ausprobieren. Sie hatten bereits die Möglichkeit, SZ Plus zu testen. Um weiterhin alle Texte lesen zu können, wählen Sie bitte eines der folgenden Angebote',
    textPrint:
      'Es ist uns wichtig, dass Sie die gedruckte Ausgabe der Süddeutschen Zeitung zu einem vergünstigten Preis kennenlernen und ausprobieren können. Sie hatten bereits die Möglichkeit, die Süddeutsche Zeitung zu testen. Um weiterhin bestens informiert zu bleiben, wählen Sie bitte eines der folgenden Angebote.',
    linkLabelDigital: 'SZ Plus Artikel lesen',
    linkSrcDigital: 'https://angebote.sueddeutsche.de/?offersId=offersMonthly',
    linkLabelPrint: 'Die Süddeutsche Zeitung lesen',
    linkSrcPrint: 'https://sz.de/weitereangebote',
  },
  legal: {
    imprintSrc: 'https://www.sueddeutsche.de/impressum',
    privacySrc: 'https://www.swmh-datenschutz.de/sz',
    copyright:
      'Copyright © Süddeutsche Zeitung Digitale Medien GmbH / Süddeutsche Zeitung GmbH',
    agbSrc: 'https://service.sueddeutsche.de/lesermarkt/service/agb.html',
    dataPrivacyLink: 'https://www.swmh-datenschutz.de/sz',
    revocationLink:
      'https://service.sueddeutsche.de/lesermarkt/service/faq-widerruf.html',
    agbSrcDigital:
      'https://service.sueddeutsche.de/lesermarkt/service/agb.html',
    agbSrcPrint: 'https://service.sueddeutsche.de/lesermarkt/service/agb.html',
    agbSrcMagazine:
      'https://service.sueddeutsche.de/lesermarkt/service/zeitschriften-agb.html',
    cancellationSrc:
      'https://service.sueddeutsche.de/lesermarkt/service/faq-widerruf.html',
    partner:
      'Ihr Vertragspartner ist die Süddeutsche Zeitung GmbH, Hultschiner Str. 8, 81677 München. Geschäftsführer: Dr. Christian Wegner (Vors.), Johannes Hauner, Dr. Karl Ulrich, Amtsgericht München HRB 73515. ',
    dataPrivacy:
      'Die Süddeutsche Zeitung GmbH verarbeitet Ihre Daten zur Vertragserfüllung, Kundenbetreuung und, sofern Sie eingewilligt haben oder wir gesetzlich dazu berechtigt sind, für werbliche Zwecke. Bis zu Ihrem Widerruf werden wir Ihre bei der Registrierung angegebenen E-Mail-Adresse für Information über eigene ähnliche Waren und Dienstleistungen nutzen (§ 7 III UWG). Sie können der werblichen Nutzung Ihrer Daten jederzeit schriftlich oder per E-Mail an <a href="mailto:werbewiderspruch@sz.de">werbewiderspruch@sz.de</a> widersprechen. Ihre Daten können zu den genannten Zwecken auch an Dienstleister weitergegeben werden.',
    dataPrivacyAddition:
      'Ausführliches zum Datenschutz und zu den Informationspflichten finden Sie in der <a href="https://www.swmh-datenschutz.de/sz" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
  },
  advertisingConsent: {
    disclaimer:
      'Ja, ich bin damit einverstanden, dass über Medienangebote der Süddeutsche Zeitung GmbH bis auf Widerruf informiert werden darf:',
    privacy: 'sz.de/datenschutz',
  },
};
export const stz = {
  config: {
    region: false,
    advertisingConsent: true,
    earliestStartDateOffsetForPrintInDays: 10,
    orderWithoutAddressCustomizable: true,
    dayPassPrefixes: [],
  },
  meta: {
    title: 'Meine Bestellung - Stuttgarter Zeitung',
  },
  contact: {
    link: 'service/kontaktformular',
    phoneNumber: '0711 7205 6161',
    myDataLink: 'https://abo.stuttgarter-zeitung.de/Mein-Abo/',
  },
  start: {
    headline1: 'Ihre Bestellung',
    headline2: 'Ihre Daten',
  },
  modals: {
    browserBack: {
      copy: '<p>Mit der Bestellung eines digitalen Abonnements können Sie über den Button “Zur StZ-Startseite” direkt auf der StZ-Homepage lesen.</p><p>Möchten Sie Änderungen an Ihrer Bestellung oder Ihrem bestehenden Abonnement vornehmen, dann klicken Sie bitte auf “Zu Konto & Abo”.</p>',
      link1: {
        label: 'Zur StZ-Startseite',
        href: 'https://www.stuttgarter-zeitung.de/',
      },
      link2: {
        href: 'https://service.stuttgarter-zeitung.de/service/meine-abos',
      },
    },
  },
  gpNr: {
    headlineWithGpNr: 'Ich habe bereits eine Kundennummer',
    headlineWithoutGpNr: 'Ich habe noch keine Kundennummer',
    gpNrInputHint:
      '10-stellig, beginnt mit 2. Sie finden die Kundennummer in der Bestellbestätigung',
    error: {
      gpNrNotValid: 'Keine gültigen Daten',
      formValidationMessage:
        'Bitte überprüfen Sie die gekennzeichneten Felder und füllen Sie die Pflichtfelder aus.',
      agb: 'Bitte Akzeptieren Sie die AGB',
      gpLocked:
        'Der Onlinezugriff auf Ihre Daten ist zu unserem Bedauern nicht möglich, bitte wenden Sie sich an unseren Kundenservice: Telefon 0711 7205 6161. Sie erreichen uns von Montag bis Freitag von 07:00 Uhr bis 17:00 Uhr und samstags von 07:30 Uhr bis 12:00 Uhr oder Sie nutzen das <I>Kontaktformular</I>.  <BR />' +
        'Vielen Dank für Ihr Verständnis.',
      gpUsed:
        '<strong>Verknüpfung fehlgeschlagen.</strong><br />' +
        'Ihr Abo/Ihre Kundennummer ist bereits einem anderen Benutzernamen zugeordnet.<br />' +
        'Bitte verwenden Sie diesen bereits vergebenen Benutzernamen. Haben Sie diesen vergessen, dann folgen Sie bitte diesem Link: <a href="https://www.stuttgarter-zeitung.de/passwort-vergessen" target="_blank" rel="noopener noreferrer">Benutzername oder Passwort vergessen?</a>',
    },
  },
  payment: {
    headline2: 'Bitte wählen Sie eine Zahlmethode',
    messageError: 'Bitte prüfen Sie die eingegebenen Daten.',
  },
  verification: {
    headline1: 'Bestellung abschließen',
    agbAdditional:
      'Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages begonnen wird. Mir ist bekannt, dass mein Widerrufsrecht durch diese Zustimmung mit der Beginn der Ausführung erlischt.',
  },
  success: {
    backLinkSrc: 'https://www.stuttgarter-zeitung.de/',
    contactSrc: 'mailto:digital@stz.zgs.de',
    phone: '0711 7205 6161',
    phoneSrc: 'tel:+4971172056161',
    registrationPossible:
      '<b>Wichtig!</b> Scheinbar haben Sie in der Vergangenheit schon bei uns bestellt oder sind Kunde/Kundin. In Kürze erhalten Sie eine E-Mail, über die Sie Ihre Kundendaten anhand Ihrer Kundennummer zusammenführen können.',
    additionalCopy: '',
  },
  orderExists: {
    info: 'Dieses Produkt wurde bereits für Sie aktiviert.<br /> Sie haben aktuell Zugriff auf alle StZ Plus Artikel.',
    buttonText: 'Zurück zur Startseite',
    buttonTextBackToArticle: 'StZ Plus Artikel lesen',
    link: 'https://www.stuttgarter-zeitung.de',
  },
  followUpOffer: {
    infoText:
      '<p> Sie können mit Ihren aktuellen Zugangsdaten StZ Plus bereits nutzen.</p><p class="mb-0">Endet Ihr Angebot demnächst? Bestellen Sie hier Ihr Folgeangebot.</p>',
    homePageLink: 'https://www.stuttgarter-zeitung.de',
  },
  error: {
    headline1: 'Es tut uns leid . . .',
    server: '. . . es gab ein Serverproblem.',
    startPageLink: 'https://www.stuttgarter-zeitung.de',
    startPageLinkLabel: 'Erneut versuchen',
  },
  productunavailable: {
    headline2: '. . . dieses Produkt ist nicht verfügbar.',
  },
  trialExpired: {
    headline1: 'Es tut uns leid . . .',
    headline2: '. . . dieses Produkt können Sie im Moment nicht bestellen.',
    textDigital:
      'Es ist uns wichtig, dass Sie die digitalen Produkte der Stuttgarter Zeitung kostenlos kennenlernen und ausprobieren. Sie hatten bereits die Möglichkeit, StZ Plus Komplett zu testen. Um weiterhin alle Artikel und das StZ ePaper lesen zu können, wählen Sie bitte eines der folgenden Angebote.',
    textPrint:
      'Es ist uns wichtig, dass Sie die gedruckte Ausgabe der Stuttgarter Zeitung zu einem vergünstigten Preis kennenlernen und ausprobieren können. Sie hatten bereits die Möglichkeit, die Stuttgarter Zeitung zu testen. Um weiterhin bestens informiert zu bleiben, wählen Sie bitte eines der folgenden Angebote.',
    linkLabelDigital: 'Das komplette digitale Angebot der StZ',
    linkSrcDigital: 'https://produkte.stuttgarter-zeitung.de/digital/digital/',
    linkLabelPrint: 'Die Stuttgarter Zeitung lesen',
    linkSrcPrint:
      'https://produkte.stuttgarter-zeitung.de/digital/print-abonnements/',
  },
  legal: {
    imprintSrc: 'https://www.stuttgarter-zeitung.de/impressum',
    privacySrc: 'https://www.swmh-datenschutz.de/stz',
    copyright: `© ${currentYear} Stuttgarter Zeitung Verlagsgesellschaft mbH`,
    agbSrc: 'https://abo.stuttgarter-zeitung.de/agb/',
    dataPrivacyLink: 'https://www.swmh-datenschutz.de/stz',
    revocationLink:
      'https://produkte.stuttgarter-zeitung.de/digital/widerrufsrecht/',
    agbSrcDigital: 'https://produkte.stuttgarter-zeitung.de/digital/agb/',
    agbSrcPrint: 'https://produkte.stuttgarter-zeitung.de/digital/agb/',
    agbSrcMagazine: 'https://produkte.stuttgarter-zeitung.de/digital/agb/',
    cancellationSrc: 'https://abo.stuttgarter-zeitung.de/Widerrufsrecht/',
    partner:
      'Ihr Vertragspartner ist die Stuttgarter Zeitung Verlagsgesellschaft mbH, Plieninger Str. 150, 70567 Stuttgart. Geschäftsführer: Herbert Dachs, Amtsgericht Stuttgart HRB 725255.',
    dataPrivacy:
      'Die Stuttgarter Zeitung Verlagsgesellschaft mbH verarbeitet Ihre Daten zur Vertragserfüllung, Kundenbetreuung und, sofern Sie eingewilligt haben oder wir gesetzlich dazu berechtigt sind, für werbliche Zwecke. Bis zu Ihrem Widerruf werden wir Ihre bei der Registrierung angegebenen E-Mail-Adresse für Information über eigene ähnliche Waren und Dienstleistungen nutzen (§ 7 III UWG). Sie können der werblichen Nutzung Ihrer Daten jederzeit schriftlich oder per E-Mail an <a href="mailto:datenschutz@stz.zgs.de">datenschutz@stz.zgs.de</a> widersprechen. Ihre Daten können zu den genannten Zwecken auch an Dienstleister weitergegeben werden.',
    dataPrivacyAddition:
      'Ausführliches zum Datenschutz und zu den Informationspflichten finden Sie in der <a href="https://www.swmh-datenschutz.de/stz" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
  },
  advertisingConsent: {
    disclaimer:
      'Ja, ich bin damit einverstanden, dass ich über Medienangebote der Stuttgarter Zeitung Verlagsgesellschaft mbh bis auf Widerruf informiert werden darf:',
    privacy: 'stuttgarter-zeitung.de/datenschutzerklaerung',
  },
};

export const stn = {
  config: {
    region: false,
    advertisingConsent: true,
    earliestStartDateOffsetForPrintInDays: 10,
    orderWithoutAddressCustomizable: true,
    dayPassPrefixes: [],
  },
  meta: {
    title: 'Meine Bestellung - Stuttgarter Nachrichten',
  },
  contact: {
    link: 'service/kontaktformular',
    phoneNumber: '0711 7205 6161',
    myDataLink: 'https://abo.stuttgarter-nachrichten.de/Mein-Abo/',
  },
  start: {
    headline1: 'Ihre Bestellung',
    headline2: 'Ihre Daten',
  },
  modals: {
    browserBack: {
      copy: '<p>Mit der Bestellung eines digitalen Abonnements können Sie über den Button “Zur StN-Startseite” direkt auf der StN-Homepage lesen.</p><p>Möchten Sie Änderungen an Ihrer Bestellung oder Ihrem bestehenden Abonnement vornehmen, dann klicken Sie bitte auf “Zu Konto & Abo”.</p>',
      link1: {
        label: 'Zur StN-Startseite',
        href: 'https://www.stuttgarter-nachrichten.de/',
      },
      link2: {
        href: 'https://service.stuttgarter-nachrichten.de/service/meine-abos',
      },
    },
  },
  gpNr: {
    headlineWithGpNr: 'Ich habe bereits eine Kundennummer',
    headlineWithoutGpNr: 'Ich habe noch keine Kundennummer',
    gpNrInputHint:
      '10-stellig, beginnt mit 2. Sie finden die Kundennummer in der Bestellbestätigung',
    error: {
      gpNrNotValid: 'Keine gültigen Daten',
      formValidationMessage:
        'Bitte überprüfen Sie die gekennzeichneten Felder und füllen Sie die Pflichtfelder aus.',
      agb: 'Bitte Akzeptieren Sie die AGB',
      gpLocked:
        'Der Onlinezugriff auf Ihre Daten ist zu unserem Bedauern nicht möglich, bitte wenden Sie sich an unseren Kundenservice: Telefon 0711 7205 6161. Sie erreichen uns von Montag bis Freitag von 07:00 Uhr bis 17:00 Uhr und samstags von 07:30 Uhr bis 12:00 Uhr oder Sie nutzen das <I>Kontaktformular</I>. <BR />' +
        'Vielen Dank für Ihr Verständnis.',
      gpUsed:
        '<strong>Verknüpfung fehlgeschlagen.</strong><br />' +
        'Ihr Abo/Ihre Kundennummer ist bereits einem anderen Benutzernamen zugeordnet.<br />' +
        'Bitte verwenden Sie diesen bereits vergebenen Benutzernamen. Haben Sie diesen vergessen, dann folgen Sie bitte diesem Link: <a href="https://www.stuttgarter-nachrichten.de/passwort-vergessen" target="_blank" rel="noopener noreferrer">Benutzername oder Passwort vergessen?</a>',
    },
  },
  payment: {
    headline2: 'Bitte wählen Sie eine Zahlmethode',
    messageError: 'Bitte prüfen Sie die eingegebenen Daten.',
  },
  verification: {
    headline1: 'Bestellung abschließen',
    agbAdditional:
      'Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages begonnen wird. Mir ist bekannt, dass mein Widerrufsrecht durch diese Zustimmung mit der Beginn der Ausführung erlischt.',
  },
  success: {
    backLinkSrc: 'https://www.stuttgarter-nachrichten.de/',
    contactSrc: 'mailto:digital@stn.zgs.de',
    phone: '0711 7205 6161',
    phoneSrc: 'tel:+4971172056161',
    registrationPossible:
      '<b>Wichtig!</b> Scheinbar haben Sie in der Vergangenheit schon bei uns bestellt oder sind Kunde/Kundin. In Kürze erhalten Sie eine E-Mail, über die Sie Ihre Kundendaten anhand Ihrer Kundennummer zusammenführen können.',
    additionalCopy: '',
  },
  orderExists: {
    info: 'Dieses Produkt wurde bereits für Sie aktiviert.<br /> Sie haben aktuell Zugriff auf alle StN+ Artikel.',
    buttonText: 'Zurück zur Startseite',
    buttonTextBackToArticle: 'StN+ Artikel lesen',
    link: 'https://www.stuttgarter-nachrichten.de',
  },
  followUpOffer: {
    infoText:
      '<p> Sie können mit Ihren aktuellen Zugangsdaten StN+ bereits nutzen.</p><p class="mb-0">Endet Ihr Angebot demnächst? Bestellen Sie hier Ihr Folgeangebot.</p>',
    homePageLink: 'https://www.stuttgarter-nachrichten.de/',
  },
  error: {
    headline1: 'Es tut uns leid . . .',
    server: '. . . es gab ein Serverproblem.',
    startPageLink: 'https://www.stuttgarter-nachrichten.de/',
    startPageLinkLabel: 'Erneut versuchen',
  },
  productunavailable: {
    headline2: '. . . dieses Produkt ist nicht verfügbar.',
  },
  trialExpired: {
    headline1: 'Es tut uns leid . . .',
    headline2: '. . . dieses Produkt können Sie im Moment nicht bestellen.',
    textDigital:
      'Es ist uns wichtig, dass Sie die digitalen Produkte der Stuttgarter Nachrichten kostenlos kennenlernen und ausprobieren. Sie hatten bereits die Möglichkeit, StN+ inkl. StN epaper zu testen. Um weiterhin alle Artikel und das StN ePaper lesen zu können, wählen Sie bitte eines der folgenden Angebote.',
    textPrint:
      'Es ist uns wichtig, dass Sie die gedruckte Ausgabe der Stuttgarter Nachrichten zu einem vergünstigten Preis kennenlernen und ausprobieren können. Sie hatten bereits die Möglichkeit, die Stuttgarter Nachrichten zu testen. Um weiterhin bestens informiert zu bleiben, wählen Sie bitte eines der folgenden Angebote.',
    linkLabelDigital: 'Das komplette digitale Angebot der StN',
    linkSrcDigital:
      'https://produkte.stuttgarter-nachrichten.de/digital/digital/',
    linkLabelPrint: 'Die Stuttgarter Nachrichten lesen',
    linkSrcPrint:
      'https://produkte.stuttgarter-nachrichten.de/digital/print-abonnements/',
  },
  legal: {
    imprintSrc: 'https://www.stuttgarter-nachrichten.de/impressum',
    privacySrc: 'https://www.swmh-datenschutz.de/stn',
    copyright: `© ${currentYear} Stuttgarter Nachrichten Verlagsgesellschaft mbH`,
    agbSrc: 'https://abo.stuttgarter-nachrichten.de/agb/',
    cancellationSrc: 'https://abo.stuttgarter-nachrichten.de/Widerrufsrecht/',
    dataPrivacyLink: 'https://www.swmh-datenschutz.de/stn',
    revocationLink:
      'https://produkte.stuttgarter-nachrichten.de/digital/widerrufsrecht/',
    agbSrcDigital: 'https://produkte.stuttgarter-nachrichten.de/digital/agb/',
    agbSrcPrint: 'https://produkte.stuttgarter-nachrichten.de/digital/agb/',
    agbSrcMagazine: 'https://produkte.stuttgarter-nachrichten.de/digital/agb/',
    partner:
      'Ihr Vertragspartner ist die Stuttgarter Nachrichten Verlagsgesellschaft mbH, Plieninger Str. 150, 70567 Stuttgart. Geschäftsführer: Herbert Dachs, Amtsgericht Stuttgart HRB 798.',
    dataPrivacy:
      'Die Stuttgarter Nachrichten Verlagsgesellschaft mbH verarbeitet Ihre Daten zur Vertragserfüllung, Kundenbetreuung und, sofern Sie eingewilligt haben oder wir gesetzlich dazu berechtigt sind, für werbliche Zwecke. Bis zu Ihrem Widerruf werden wir Ihre bei der Registrierung angegebenen E-Mail-Adresse für Information über eigene ähnliche Waren und Dienstleistungen nutzen (§ 7 III UWG). Sie können der werblichen Nutzung Ihrer Daten jederzeit schriftlich oder per E-Mail an <a href="mailto:datenschutz@stn.zgs.de">datenschutz@stn.zgs.de</a> widersprechen. Ihre Daten können zu den genannten Zwecken auch an Dienstleister weitergegeben werden.',
    dataPrivacyAddition:
      'Ausführliches zum Datenschutz und zu den Informationspflichten finden Sie in der <a href="https://www.swmh-datenschutz.de/stn" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
  },
  advertisingConsent: {
    disclaimer:
      'Ja, ich bin damit einverstanden, dass ich über Medienangebote der Stuttgarter Nachrichten Verlagsgesellschaft mbH bis auf Widerruf informiert werden darf:',
    privacy: 'stuttgarter-nachrichten.de/datenschutzerklaerung',
  },
};

export const kwz = {
  config: {
    region: false,
    advertisingConsent: true,
    earliestStartDateOffsetForPrintInDays: 10,
    orderWithoutAddressCustomizable: true,
    dayPassPrefixes: [],
  },
  meta: {
    title: 'Meine Bestellung - Kornwestheimer Zeitung',
  },
  contact: {
    link: 'service/kontaktformular',
    phoneNumber: '0711 7205-6142',
    myDataLink: 'https://service.kornwestheimer-zeitung.de/service/meine-daten',
  },
  start: {
    headline1: 'Ihre Bestellung',
    headline2: 'Ihre Daten',
  },
  modals: {
    browserBack: {
      copy: '<p>Mit der Bestellung eines digitalen Abonnements können Sie über den Button “Zur KWZ-Startseite” direkt auf der KWZ-Homepage lesen.</p><p>Möchten Sie Änderungen an Ihrer Bestellung oder Ihrem bestehenden Abonnement vornehmen, dann klicken Sie bitte auf “Zu Konto & Abo”.</p>',
      link1: {
        label: 'Zur KWZ-Startseite',
        href: 'https://www.stuttgarter-nachrichten.de/kornwestheimer-zeitung',
      },
      link2: {
        href: 'https://service.kornwestheimer-zeitung.de/service/meine-abos',
      },
    },
  },
  gpNr: {
    headlineWithGpNr: 'Ich habe bereits eine Kundennummer',
    headlineWithoutGpNr: 'Ich habe noch keine Kundennummer',
    gpNrInputHint:
      '10-stellig, beginnt mit 2. Sie finden die Kundennummer in der Bestellbestätigung',
    error: {
      gpNrNotValid: 'Keine gültigen Daten',
      formValidationMessage:
        'Bitte überprüfen Sie die gekennzeichneten Felder und füllen Sie die Pflichtfelder aus.',
      agb: 'Bitte Akzeptieren Sie die AGB',
      gpLocked:
        'Der Onlinezugriff auf Ihre Daten ist zu unserem Bedauern nicht möglich, bitte wenden Sie sich an unseren Kundenservice: Telefon 0711 7205-6142. Sie erreichen uns von Montag bis Freitag von 07:00 Uhr bis 17:00 Uhr und samstags von 07:30 Uhr bis 12:00 Uhr oder Sie nutzen das Kontaktformular. <BR />' +
        'Vielen Dank für Ihr Verständnis.',
      gpUsed:
        '<strong>Verknüpfung fehlgeschlagen.</strong><br />' +
        'Ihr Abo/Ihre Kundennummer ist bereits einem anderen Benutzernamen zugeordnet.<br />' +
        'Bitte verwenden Sie diesen bereits vergebenen Benutzernamen. Haben Sie diesen vergessen, dann folgen Sie bitte diesem Link: <a href="https://www.stuttgarter-nachrichten.de/passwort-vergessen" target="_blank" rel="noopener noreferrer">Benutzername oder Passwort vergessen?</a>',
    },
  },
  payment: {
    headline2: 'Bitte wählen Sie eine Zahlmethode',
    messageError: 'Bitte prüfen Sie die eingegebenen Daten.',
  },
  verification: {
    headline1: 'Bestellung abschließen',
    agbAdditional:
      'Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages begonnen wird. Mir ist bekannt, dass mein Widerrufsrecht durch diese Zustimmung mit der Beginn der Ausführung erlischt.',
  },
  success: {
    backLinkSrc: 'https://www.kornwestheimer-zeitung.de/',
    contactSrc: 'mailto:vertrieb@kornwestheimer-zeitung.zgs.de',
    phone: '0711 7205-6142',
    phoneSrc: 'tel:+4971172056142',
    registrationPossible:
      '<b>Wichtig!</b> Scheinbar haben Sie in der Vergangenheit schon bei uns bestellt oder sind Kunde/Kundin. In Kürze erhalten Sie eine E-Mail, über die Sie Ihre Kundendaten anhand Ihrer Kundennummer zusammenführen können.',
    additionalCopy: '',
  },
  orderExists: {
    info: 'Dieses Produkt wurde bereits für Sie aktiviert.<br /> Sie haben aktuell Zugriff auf alle StN+ Artikel.',
    buttonText: 'Zurück zur Startseite',
    buttonTextBackToArticle: 'StN+ Artikel lesen',
    link: 'https://www.kornwestheimer-zeitung.de/',
  },
  followUpOffer: {
    infoText:
      '<p> Sie können mit Ihren aktuellen Zugangsdaten StN+ bereits nutzen.</p><p class="mb-0">Endet Ihr Angebot demnächst? Bestellen Sie hier Ihr Folgeangebot.</p>',
    homePageLink: 'https://www.kornwestheimer-zeitung.de/',
  },
  error: {
    headline1: 'Es tut uns leid . . .',
    server: '. . . es gab ein Serverproblem.',
    startPageLink: 'https://www.kornwestheimer-zeitung.de/',
    startPageLinkLabel: 'Erneut versuchen',
  },
  productunavailable: {
    headline2: '. . . dieses Produkt ist nicht verfügbar.',
  },
  trialExpired: {
    headline1: 'Es tut uns leid . . .',
    headline2: '. . . dieses Produkt können Sie im Moment nicht bestellen.',
    textDigital:
      'Es ist uns wichtig, dass Sie die digitalen Produkte der Kornwestheimer Zeitung kostenlos kennenlernen und ausprobieren. Sie hatten bereits die Möglichkeit das KWZ ePaper zu testen. Um weiterhin das KWZ ePaper lesen zu können, wählen Sie bitte eines der folgenden Angebote.',
    textPrint:
      'Es ist uns wichtig, dass Sie die gedruckte Ausgabe der Kornwestheimer Zeitung zu einem vergünstigten Preis kennenlernen und ausprobieren können. Sie hatten bereits die Möglichkeit, die Kornwestheimer Zeitung zu testen. Um weiterhin bestens informiert zu bleiben, wählen Sie bitte eines der folgenden Angebote.',
    linkLabelDigital: 'Das komplette digitale Angebot der KWZ',
    linkSrcDigital: 'https://produkte.kornwestheimer-zeitung.de/',
    linkLabelPrint: 'Die Kornwestheimer Zeitung lesen',
    linkSrcPrint: 'https://produkte.kornwestheimer-zeitung.de/angebote-print/',
  },
  legal: {
    imprintSrc: 'https://www.kornwestheimer-zeitung.de/impressum',
    privacySrc: 'https://www.swmh-datenschutz.de/stn',
    copyright: `© ${currentYear} Kornwestheimer Zeitung`,
    agbSrc: 'https://produkte.stuttgarter-nachrichten.de/agb/',
    dataPrivacyLink: 'https://www.swmh-datenschutz.de/stn',
    revocationLink:
      'https://produkte.stuttgarter-nachrichten.de/digital/widerrufsrecht/',
    agbSrcDigital: 'https://produkte.stuttgarter-nachrichten.de/digital/agb/',
    agbSrcPrint: 'https://produkte.stuttgarter-nachrichten.de/digital/agb/',
    agbSrcMagazine: 'https://produkte.stuttgarter-nachrichten.de/digital/agb/',
    cancellationSrc:
      'https://produkte.stuttgarter-nachrichten.de/digital/widerrufsrecht/',
    partner:
      'Ihr Vertragspartner ist die Stuttgarter Nachrichten Verlagsgesellschaft mbH, Plieninger Str. 150, 70567 Stuttgart. Geschäftsführer: Herbert Dachs, Amtsgericht Stuttgart HRB 798.',
    dataPrivacy:
      'Die Stuttgarter Nachrichten Verlagsgesellschaft mbH verarbeitet Ihre Daten zur Vertragserfüllung, Kundenbetreuung und, sofern Sie eingewilligt haben oder wir gesetzlich dazu berechtigt sind, für werbliche Zwecke. Bis zu Ihrem Widerruf werden wir Ihre bei der Registrierung angegebenen E-Mail-Adresse für Information über eigene ähnliche Waren und Dienstleistungen nutzen (§ 7 III UWG). Sie können der werblichen Nutzung Ihrer Daten jederzeit schriftlich oder per E-Mail an <a href="mailto:datenschutz@stn.zgs.de">datenschutz@stn.zgs.de</a> widersprechen. Ihre Daten können zu den genannten Zwecken auch an Dienstleister weitergegeben werden.',
    dataPrivacyAddition:
      'Ausführliches zum Datenschutz und zu den Informationspflichten finden Sie in der <a href="https://www.swmh-datenschutz.de/stn" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
  },
  advertisingConsent: {
    disclaimer:
      'Ja, ich bin damit einverstanden, dass ich über Medienangebote der Stuttgarter Nachrichten  Verlagsgesellschaft mbH bis auf Widerruf informiert werde:',
    privacy: 'https://www.swmh-datenschutz.de/kwz',
  },
};

export const maz = {
  config: {
    region: false,
    advertisingConsent: true,
    earliestStartDateOffsetForPrintInDays: 10,
    orderWithoutAddressCustomizable: true,
    dayPassPrefixes: [],
  },
  meta: {
    title: 'Meine Bestellung - Marbacher Zeitung',
  },
  contact: {
    link: 'service/kontaktformular',
    phoneNumber: '07144 850022',
    myDataLink: 'https://service.marbacher-zeitung.de/service/meine-daten',
  },
  start: {
    headline1: 'Ihre Bestellung',
    headline2: 'Ihre Daten',
  },
  modals: {
    browserBack: {
      copy: '<p>Mit der Bestellung eines digitalen Abonnements können Sie über den Button “Zur MZ-Startseite” direkt auf der MZ-Homepage lesen.</p><p>Möchten Sie Änderungen an Ihrer Bestellung oder Ihrem bestehenden Abonnement vornehmen, dann klicken Sie bitte auf “Zu Konto & Abo”.</p>',
      link1: {
        label: 'Zur MZ-Startseite',
        href: 'https://www.stuttgarter-nachrichten.de/marbacher-zeitung',
      },
      link2: {
        href: 'https://service.marbacher-zeitung.de/service/meine-abos',
      },
    },
  },
  gpNr: {
    headlineWithGpNr: 'Ich habe bereits eine Kundennummer',
    headlineWithoutGpNr: 'Ich habe noch keine Kundennummer',
    gpNrInputHint:
      '10-stellig, beginnt mit 2. Sie finden die Kundennummer in der Bestellbestätigung',
    error: {
      gpNrNotValid: 'Keine gültigen Daten',
      formValidationMessage:
        'Bitte überprüfen Sie die gekennzeichneten Felder und füllen Sie die Pflichtfelder aus.',
      agb: 'Bitte Akzeptieren Sie die AGB',
      gpLocked:
        'Der Onlinezugriff auf Ihre Daten ist zu unserem Bedauern nicht möglich, bitte wenden Sie sich an unseren Kundenservice: Telefon 07144 850022. Sie erreichen uns von Montag bis Freitag von 07:00 Uhr bis 17:00 Uhr und samstags von 07:30 Uhr bis 12:00 Uhr oder Sie nutzen das Kontaktformular. <BR />' +
        'Vielen Dank für Ihr Verständnis.',
      gpUsed:
        '<strong>Verknüpfung fehlgeschlagen.</strong><br />' +
        'Ihr Abo/Ihre Kundennummer ist bereits einem anderen Benutzernamen zugeordnet.<br />' +
        'Bitte verwenden Sie diesen bereits vergebenen Benutzernamen. Haben Sie diesen vergessen, dann folgen Sie bitte diesem Link: <a href="https://www.stuttgarter-nachrichten.de/passwort-vergessen" target="_blank" rel="noopener noreferrer">Benutzername oder Passwort vergessen?</a>',
    },
  },
  payment: {
    headline2: 'Bitte wählen Sie eine Zahlmethode',
    messageError: 'Bitte prüfen Sie die eingegebenen Daten.',
  },
  verification: {
    headline1: 'Bestellung abschließen',
    agbAdditional:
      'Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages begonnen wird. Mir ist bekannt, dass mein Widerrufsrecht durch diese Zustimmung mit der Beginn der Ausführung erlischt.',
  },
  success: {
    backLinkSrc: 'https://www.marbacher-zeitung.de/',
    contactSrc: 'mailto:service@marbacher-zeitung.de',
    phone: '07144 850022',
    phoneSrc: 'tel:+497144850022',
    registrationPossible:
      '<b>Wichtig!</b> Scheinbar haben Sie in der Vergangenheit schon bei uns bestellt oder sind Kunde/Kundin. In Kürze erhalten Sie eine E-Mail, über die Sie Ihre Kundendaten anhand Ihrer Kundennummer zusammenführen können.',
    additionalCopy: '',
  },
  orderExists: {
    info: 'Dieses Produkt wurde bereits für Sie aktiviert.<br /> Sie haben aktuell Zugriff auf alle StN+ Artikel.',
    buttonText: 'Zurück zur Startseite',
    buttonTextBackToArticle: 'StN+ Artikel lesen',
    link: 'https://www.marbacher-zeitung.de/',
  },
  followUpOffer: {
    infoText:
      '<p> Sie können mit Ihren aktuellen Zugangsdaten StN+ bereits nutzen.</p><p class="mb-0">Endet Ihr Angebot demnächst? Bestellen Sie hier Ihr Folgeangebot.</p>',
    homePageLink: 'https://www.marbacher-zeitung.de/',
  },
  error: {
    headline1: 'Es tut uns leid . . .',
    server: '. . . es gab ein Serverproblem.',
    startPageLink: 'https://www.marbacher-zeitung.de/',
    startPageLinkLabel: 'Erneut versuchen',
  },
  productunavailable: {
    headline2: '. . . dieses Produkt ist nicht verfügbar.',
  },
  trialExpired: {
    headline1: 'Es tut uns leid . . .',
    headline2: '. . . dieses Produkt können Sie im Moment nicht bestellen.',
    textDigital:
      'Es ist uns wichtig, dass Sie die digitalen Produkte der Marbacher Zeitung kostenlos kennenlernen und ausprobieren. Sie hatten bereits die Möglichkeit das MZ ePaper zu testen. Um weiterhin das MZ ePaper lesen zu können, wählen Sie bitte eines der folgenden Angebote.',
    textPrint:
      'Es ist uns wichtig, dass Sie die gedruckte Ausgabe der Marbacher Zeitung zu einem vergünstigten Preis kennenlernen und ausprobieren können. Sie hatten bereits die Möglichkeit, die Marbacher Zeitung zu testen. Um weiterhin bestens informiert zu bleiben, wählen Sie bitte eines der folgenden Angebote.',
    linkLabelDigital: 'Das komplette digitale Angebot der MZ',
    linkSrcDigital: 'https://produkte.marbacher-zeitung.de/',
    linkLabelPrint: 'Die Marbacher Zeitung lesen',
    linkSrcPrint: 'https://produkte.marbacher-zeitung.de/angebote-print/',
  },
  legal: {
    imprintSrc: 'https://www.marbacher-zeitung.de/impressum',
    privacySrc: 'https://www.swmh-datenschutz.de/stn',
    copyright: `© ${currentYear} Marbacher Zeitung`,
    agbSrc: 'https://produkte.stuttgarter-nachrichten.de/agb/',
    revocationLink:
      'https://produkte.stuttgarter-nachrichten.de/widerrufsrecht/',
    dataPrivacyLink: 'https://www.swmh-datenschutz.de/stn',
    agbSrcDigital: 'https://produkte.stuttgarter-nachrichten.de/agb/',
    agbSrcPrint: 'https://produkte.stuttgarter-nachrichten.de/agb/',
    agbSrcMagazine: 'https://produkte.stuttgarter-nachrichten.de/agb/',
    cancellationSrc:
      'https://produkte.stuttgarter-nachrichten.de/widerrufsrecht/',
    partner:
      'Ihr Vertragspartner ist die Stuttgarter Nachrichten Verlagsgesellschaft mbH, Plieninger Str. 150, 70567 Stuttgart. Geschäftsführer: Herbert Dachs, Amtsgericht Stuttgart HRB 798.',
    dataPrivacy:
      'Die Stuttgarter Nachrichten Verlagsgesellschaft mbH verarbeitet Ihre Daten zur Vertragserfüllung, Kundenbetreuung und, sofern Sie eingewilligt haben oder wir gesetzlich dazu berechtigt sind, für werbliche Zwecke. Bis zu Ihrem Widerruf werden wir Ihre bei der Registrierung angegebenen E-Mail-Adresse für Information über eigene ähnliche Waren und Dienstleistungen nutzen (§ 7 III UWG). Sie können der werblichen Nutzung Ihrer Daten jederzeit schriftlich oder per E-Mail an <a href="mailto:datenschutz@stn.zgs.de">datenschutz@stn.zgs.de</a> widersprechen. Ihre Daten können zu den genannten Zwecken auch an Dienstleister weitergegeben werden.',
    dataPrivacyAddition:
      'Ausführliches zum Datenschutz und zu den Informationspflichten finden Sie in der <a href="https://www.swmh-datenschutz.de/stn" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
  },
  advertisingConsent: {
    disclaimer:
      'Ja, ich bin damit einverstanden, dass ich über Medienangebote der Stuttgarter Nachrichten  Verlagsgesellschaft mbH bis auf Widerruf informiert werde:',
    privacy: 'https://www.swmh-datenschutz.de/mz',
  },
};

export const lkz = {
  config: {
    region: false,
    advertisingConsent: true,
    earliestStartDateOffsetForPrintInDays: 10,
    orderWithoutAddressCustomizable: true,
    dayPassPrefixes: [],
  },
  meta: {
    title: 'Meine Bestellung - Leonberger Kreiszeitung',
  },
  contact: {
    link: 'service/kontaktformular',
    phoneNumber: '07152 937-2808',
    myDataLink:
      'https://service.leonberger-kreiszeitung.de/service/meine-daten',
  },
  start: {
    headline1: 'Ihre Bestellung',
    headline2: 'Ihre Daten',
  },
  modals: {
    browserBack: {
      copy: '<p>Mit der Bestellung eines digitalen Abonnements können Sie über den Button “Zur LKZ-Startseite” direkt auf der LKZ-Homepage lesen.</p><p>Möchten Sie Änderungen an Ihrer Bestellung oder Ihrem bestehenden Abonnement vornehmen, dann klicken Sie bitte auf “Zu Konto & Abo”.</p>',
      link1: {
        label: 'Zur LKZ-Startseite',
        href: 'https://www.stuttgarter-zeitung.de/leonberger-kreiszeitung',
      },
      link2: {
        href: 'https://service.leonberger-kreiszeitung.de/service/meine-abos',
      },
    },
  },
  gpNr: {
    headlineWithGpNr: 'Ich habe bereits eine Kundennummer',
    headlineWithoutGpNr: 'Ich habe noch keine Kundennummer',
    gpNrInputHint:
      '10-stellig, beginnt mit 2. Sie finden die Kundennummer in der Bestellbestätigung',
    error: {
      gpNrNotValid: 'Keine gültigen Daten',
      formValidationMessage:
        'Bitte überprüfen Sie die gekennzeichneten Felder und füllen Sie die Pflichtfelder aus.',
      agb: 'Bitte Akzeptieren Sie die AGB',
      gpLocked:
        'Der Onlinezugriff auf Ihre Daten ist zu unserem Bedauern nicht möglich, bitte wenden Sie sich an unseren Kundenservice: Telefon 07152 937-2808. Sie erreichen uns von Montag bis Freitag von 07:00 Uhr bis 17:00 Uhr und samstags von 07:30 Uhr bis 12:00 Uhr oder Sie nutzen das Kontaktformular. <BR />' +
        'Vielen Dank für Ihr Verständnis.',
      gpUsed:
        '<strong>Verknüpfung fehlgeschlagen.</strong><br />' +
        'Ihr Abo/Ihre Kundennummer ist bereits einem anderen Benutzernamen zugeordnet.<br />' +
        'Bitte verwenden Sie diesen bereits vergebenen Benutzernamen. Haben Sie diesen vergessen, dann folgen Sie bitte diesem Link: <a href="https://www.stuttgarter-zeitung.de/passwort-vergessen" target="_blank" rel="noopener noreferrer">Benutzername oder Passwort vergessen?</a>',
    },
  },
  payment: {
    headline2: 'Bitte wählen Sie eine Zahlmethode',
    messageError: 'Bitte prüfen Sie die eingegebenen Daten.',
  },
  verification: {
    headline1: 'Bestellung abschließen',
    agbAdditional:
      'Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages begonnen wird. Mir ist bekannt, dass mein Widerrufsrecht durch diese Zustimmung mit der Beginn der Ausführung erlischt.',
  },
  success: {
    backLinkSrc: 'https://www.leonberger-kreiszeitung.de/',
    contactSrc: 'mailto:vertrieb@leonberger-kreiszeitung.zgs.de',
    phone: '07152 937-2808',
    phoneSrc: 'tel:+4971529372808',
    registrationPossible:
      '<b>Wichtig!</b> Scheinbar haben Sie in der Vergangenheit schon bei uns bestellt oder sind Kunde/Kundin. In Kürze erhalten Sie eine E-Mail, über die Sie Ihre Kundendaten anhand Ihrer Kundennummer zusammenführen können.',
    additionalCopy: '',
  },
  orderExists: {
    info: 'Dieses Produkt wurde bereits für Sie aktiviert.<br /> Sie haben aktuell Zugriff auf alle StN Plus Artikel.',
    buttonText: 'Zurück zur Startseite',
    buttonTextBackToArticle: 'StN Plus Artikel lesen',
    link: 'https://www.leonberger-kreiszeitung.de/',
  },
  followUpOffer: {
    infoText:
      '<p> Sie können mit Ihren aktuellen Zugangsdaten StZ Plus bereits nutzen.</p><p class="mb-0">Endet Ihr Angebot demnächst? Bestellen Sie hier Ihr Folgeangebot.</p>',
    homePageLink: '	https://www.leonberger-kreiszeitung.de/',
  },
  error: {
    headline1: 'Es tut uns leid . . .',
    server: '. . . es gab ein Serverproblem.',
    startPageLink: 'https://www.leonberger-kreiszeitung.de/',
    startPageLinkLabel: 'Erneut versuchen',
  },
  productunavailable: {
    headline2: '. . . dieses Produkt ist nicht verfügbar.',
  },
  trialExpired: {
    headline1: 'Es tut uns leid . . .',
    headline2: '. . . dieses Produkt können Sie im Moment nicht bestellen.',
    textDigital:
      'Es ist uns wichtig, dass Sie die digitalen Produkte der Leonberger Kreiszeitung kostenlos kennenlernen und ausprobieren. Sie hatten bereits die Möglichkeit das LKZ ePaper zu testen. Um weiterhin das LKZ ePaper lesen zu können, wählen Sie bitte eines der folgenden Angebote.',
    textPrint:
      'Es ist uns wichtig, dass Sie die gedruckte Ausgabe der Leonberger Kreiszeitung zu einem vergünstigten Preis kennenlernen und ausprobieren können. Sie hatten bereits die Möglichkeit, die Leonberger Kreiszeitung zu testen. Um weiterhin bestens informiert zu bleiben, wählen Sie bitte eines der folgenden Angebote.',
    linkLabelDigital: 'Das komplette digitale Angebot der LKZ',
    linkSrcDigital: 'https://produkte.leonberger-kreiszeitung.de/',
    linkLabelPrint: 'Die Leonberger Kreiszeitung lesen',
    linkSrcPrint: 'https://produkte.leonberger-kreiszeitung.de/angebote-print/',
  },
  legal: {
    imprintSrc: 'https://www.leonberger-kreiszeitung.de/impressum',
    privacySrc: 'https://www.swmh-datenschutz.de/stn',
    copyright: `© ${currentYear} Leonberger Kreiszeitung`,
    agbSrc: 'https://produkte.stuttgarter-zeitung.de/agb/',
    revocationLink:
      'https://produkte.leonberger-kreiszeitung.de/widerrufsrecht/',
    dataPrivacyLink: 'https://www.swmh-datenschutz.de/stn',
    agbSrcDigital: 'https://produkte.stuttgarter-zeitung.de/agb/',
    agbSrcPrint: 'https://produkte.stuttgarter-zeitung.de/agb/',
    agbSrcMagazine: 'https://produkte.stuttgarter-zeitung.de/agb/',
    cancellationSrc:
      'https://produkte.leonberger-kreiszeitung.de/widerrufsrecht/',
    partner:
      'Ihr Vertragspartner ist die Stuttgarter Nachrichten Verlagsgesellschaft mbH, Plieninger Str. 150, 70567 Stuttgart. Geschäftsführer: Herbert Dachs, Amtsgericht Stuttgart HRB 798.',
    dataPrivacy:
      'Die Stuttgarter Nachrichten Verlagsgesellschaft mbH verarbeitet Ihre Daten zur Vertragserfüllung, Kundenbetreuung und, sofern Sie eingewilligt haben oder wir gesetzlich dazu berechtigt sind, für werbliche Zwecke. Bis zu Ihrem Widerruf werden wir Ihre bei der Registrierung angegebenen E-Mail-Adresse für Information über eigene ähnliche Waren und Dienstleistungen nutzen (§ 7 III UWG). Sie können der werblichen Nutzung Ihrer Daten jederzeit schriftlich oder per E-Mail an <a href="datenschutz@stn.zgs.de">datenschutz@stn.zgs.de</a> widersprechen. Ihre Daten können zu den genannten Zwecken auch an Dienstleister weitergegeben werden.',
    dataPrivacyAddition:
      'Ausführliches zum Datenschutz und zu den Informationspflichten finden Sie in der <a href="https://www.swmh-datenschutz.de/stn" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
  },
  advertisingConsent: {
    disclaimer:
      'Ja, ich bin damit einverstanden, dass ich über Medienangebote der Stuttgarter Nachrichten Verlagsgesellschaft mbH  bis auf Widerruf informiert werde:',
    privacy: 'https://www.swmh-datenschutz.de/stn',
  },
};

export const ez = {
  config: {
    region: false,
    advertisingConsent: true,
    earliestStartDateOffsetForPrintInDays: 10,
    orderWithoutAddressCustomizable: true,
    dayPassPrefixes: [],
  },
  meta: {
    title: 'Meine Bestellung - Eßlinger Zeitung',
  },
  contact: {
    link: 'service/kontaktformular',
    phoneNumber: '0711 9310 242',
    myDataLink: 'https://service.esslinger-zeitung.de/service/meine-daten',
  },
  start: {
    headline1: 'Ihre Bestellung',
    headline2: 'Ihre Daten',
  },
  modals: {
    browserBack: {
      copy: '<p>Mit der Bestellung eines digitalen Abonnements können Sie über den Button “Zur EZ-Startseite” direkt auf der EZ-Homepage lesen.</p><p>Möchten Sie Änderungen an Ihrer Bestellung oder Ihrem bestehenden Abonnement vornehmen, dann klicken Sie bitte auf “Zu Konto & Abo”.</p>',
      link1: {
        label: 'Zur EZ-Startseite',
        href: 'https://www.esslinger-zeitung.de/',
      },
      link2: {
        href: 'https://service.esslinger-zeitung.de/service/meine-abos',
      },
    },
  },
  gpNr: {
    headlineWithGpNr: 'Ich habe bereits eine Kundennummer',
    headlineWithoutGpNr: 'Ich habe noch keine Kundennummer',
    gpNrInputHint:
      '10-stellig, beginnt mit 2. Sie finden die Kundennummer in der Bestellbestätigung',
    error: {
      gpNrNotValid: 'Keine gültigen Daten',
      formValidationMessage:
        'Bitte überprüfen Sie die gekennzeichneten Felder und füllen Sie die Pflichtfelder aus.',
      agb: 'Bitte Akzeptieren Sie die AGB',
      gpLocked:
        'Der Onlinezugriff auf Ihre Daten ist zu unserem Bedauern nicht möglich, bitte wenden Sie sich an unseren Kundenservice: Telefon 0711 9310 242. Sie erreichen uns von Montag bis Freitag von 07:00 Uhr bis 17:00 Uhr und samstags von 07:30 Uhr bis 12:00 Uhr oder Sie nutzen das Kontaktformular. <BR />' +
        'Vielen Dank für Ihr Verständnis.',
      gpUsed:
        '<strong>Verknüpfung fehlgeschlagen.</strong><br />' +
        'Ihr Abo/Ihre Kundennummer ist bereits einem anderen Benutzernamen zugeordnet.<br />' +
        'Bitte verwenden Sie diesen bereits vergebenen Benutzernamen. Haben Sie diesen vergessen, dann folgen Sie bitte diesem Link: <a href="https://id.esslinger-zeitung.de/auth/realms/ez/login-actions/reset-credentials" target="_blank" rel="noopener noreferrer">Benutzername oder Passwort vergessen?</a>',
    },
  },
  payment: {
    headline2: 'Bitte wählen Sie eine Zahlmethode',
    messageError: 'Bitte prüfen Sie die eingegebenen Daten.',
  },
  verification: {
    headline1: 'Bestellung abschließen',
    agbAdditional:
      'Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages begonnen wird. Mir ist bekannt, dass mein Widerrufsrecht durch diese Zustimmung mit der Beginn der Ausführung erlischt.',
  },
  success: {
    backLinkSrc: 'https://www.esslinger-zeitung.de/',
    contactSrc: 'mailto:abo@ez-online.de',
    phone: '0711 9310 242',
    phoneSrc: 'tel:+497119310242',
    registrationPossible:
      '<b>Wichtig!</b> Scheinbar haben Sie in der Vergangenheit schon bei uns bestellt oder sind Kunde/Kundin. In Kürze erhalten Sie eine E-Mail, über die Sie Ihre Kundendaten anhand Ihrer Kundennummer zusammenführen können.',
    additionalCopy: '',
  },
  orderExists: {
    info: 'Dieses Produkt wurde bereits für Sie aktiviert.<br /> Sie haben aktuell Zugriff auf alle EZ Plus Artikel.',
    buttonText: 'Zurück zur Startseite',
    buttonTextBackToArticle: 'EZ Plus Artikel lesen',
    link: 'https://www.esslinger-zeitung.de/',
  },
  followUpOffer: {
    infoText:
      '<p> Sie können mit Ihren aktuellen Zugangsdaten EZ Plus bereits nutzen.</p><p class="mb-0">Endet Ihr Angebot demnächst? Bestellen Sie hier Ihr Folgeangebot.</p>',
    homePageLink: 'https://www.esslinger-zeitung.de/',
  },
  error: {
    headline1: 'Es tut uns leid . . .',
    server: '. . . es gab ein Serverproblem.',
    startPageLink: 'https://www.esslinger-zeitung.de/',
    startPageLinkLabel: 'Erneut versuchen',
  },
  productunavailable: {
    headline2: '. . . dieses Produkt ist nicht verfügbar.',
  },
  trialExpired: {
    headline1: 'Es tut uns leid . . .',
    headline2: '. . . dieses Produkt können Sie im Moment nicht bestellen.',
    textDigital:
      'Es ist uns wichtig, dass Sie die digitalen Produkte der Eßlinger Zeitung kostenlos kennenlernen und ausprobieren. Sie hatten bereits die Möglichkeit das EZ Plus zu testen. Um weiterhin das EZ ePaper lesen zu können, wählen Sie bitte eines der folgenden Angebote.',
    textPrint:
      'Es ist uns wichtig, dass Sie die gedruckte Ausgabe der Eßlinger Zeitung zu einem vergünstigten Preis kennenlernen und ausprobieren können. Sie hatten bereits die Möglichkeit, die Eßlinger Zeitung zu testen. Um weiterhin bestens informiert zu bleiben, wählen Sie bitte eines der folgenden Angebote.',
    linkLabelDigital: 'Das komplette digitale Angebot der EZ',
    linkSrcDigital: 'https://produkte.esslinger-zeitung.de/',
    linkLabelPrint: 'Die Eßlinger Zeitung lesen',
    linkSrcPrint: 'https://produkte.esslinger-zeitung.de/angebote-print/',
  },
  legal: {
    imprintSrc: 'https://www.esslinger-zeitung.de/impressum',
    privacySrc: 'https://www.swmh-datenschutz.de/ez',
    copyright: `© ${currentYear} Bechtle Verlag und Esslinger Zeitung GmbH & Co. KG`,
    agbSrc: 'https://produkte.esslinger-zeitung.de/agb/',
    revocationLink: 'https://produkte.esslinger-zeitung.de/widerrufsrecht/',
    dataPrivacyLink: 'https://www.swmh-datenschutz.de/ez',
    agbSrcDigital: 'https://produkte.esslinger-zeitung.de/agb/',
    agbSrcPrint: 'https://produkte.esslinger-zeitung.de/agb/',
    agbSrcMagazine: 'https://produkte.esslinger-zeitung.de/agb/',
    cancellationSrc: 'https://produkte.esslinger-zeitung.de/widerrufsrecht/',
    partner:
      'Ihr Vertragspartner ist die Bechtle, Graphische Betriebe und Verlagsgesellschaft (Bechtle Verlag und Esslinger Zeitung) GmbH & Co. KG, Zeppelinstr. 116, 73730 Esslingen. Geschäftsführer: Herbert Dachs, Amtsgericht Stuttgart HRA 210160.',
    dataPrivacy:
      'Die Bechtle, Graphische Betriebe und Verlagsgesellschaft (Bechtle Verlag und Esslinger Zeitung) GmbH & Co. KG verarbeitet Ihre Daten zur Vertragserfüllung, Kundenbetreuung und, sofern Sie eingewilligt haben oder wir gesetzlich dazu berechtigt sind, für werbliche Zwecke. Bis zu Ihrem Widerruf werden wir Ihre bei der Registrierung angegebenen E-Mail-Adresse für Information über eigene ähnliche Waren und Dienstleistungen nutzen (§ 7 III UWG). Sie können der werblichen Nutzung Ihrer Daten jederzeit schriftlich oder per E-Mail an <a href="mailto:datenschutz@ez-online.de">datenschutz@ez-online.de</a> widersprechen. Ihre Daten können zu den genannten Zwecken auch an Dienstleister weitergegeben werden.',
    dataPrivacyAddition:
      'Ausführliches zum Datenschutz und zu den Informationspflichten finden Sie in der <a href="https://www.swmh-datenschutz.de/ez" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
  },
  advertisingConsent: {
    disclaimer:
      'Ja, ich bin damit einverstanden, dass ich über Medienangebote der Bechtle, Graphische Betriebe und Verlagsgesellschaft (Bechtle Verlag und Esslinger Zeitung) GmbH & Co. KG bis auf Widerruf informiert werde:',
    privacy: 'https://www.esslinger-zeitung.de/datenschutz',
  },
};

export const cz = {
  config: {
    region: false,
    advertisingConsent: true,
    earliestStartDateOffsetForPrintInDays: 10,
    orderWithoutAddressCustomizable: true,
    dayPassPrefixes: [],
  },
  meta: {
    title: 'Meine Bestellung - Cannstatter Zeitung / Untertürkheimer Zeitung',
  },
  contact: {
    link: 'service/kontaktformular',
    phoneNumber: '0711 9310 242',
    myDataLink: 'https://service.canstatter-zeitung.de/service/meine-daten',
  },
  start: {
    headline1: 'Ihre Bestellung',
    headline2: 'Ihre Daten',
  },
  modals: {
    browserBack: {
      copy: '<p>Mit der Bestellung eines digitalen Abonnements können Sie über den Button “Zur CZ-Startseite” direkt auf der CZ-Homepage lesen.</p><p>Möchten Sie Änderungen an Ihrer Bestellung oder Ihrem bestehenden Abonnement vornehmen, dann klicken Sie bitte auf “Zu Konto & Abo”.</p>',
      link1: {
        label: 'Zur CZ-Startseite',
        href: 'https://www.cannstatter-zeitung.de/',
      },
      link2: {
        href: 'https://service.cannstatter-zeitung.de/service/meine-abos',
      },
    },
  },
  gpNr: {
    headlineWithGpNr: 'Ich habe bereits eine Kundennummer',
    headlineWithoutGpNr: 'Ich habe noch keine Kundennummer',
    gpNrInputHint:
      '10-stellig, beginnt mit 2. Sie finden die Kundennummer in der Bestellbestätigung',
    error: {
      gpNrNotValid: 'Keine gültigen Daten',
      formValidationMessage:
        'Bitte überprüfen Sie die gekennzeichneten Felder und füllen Sie die Pflichtfelder aus.',
      agb: 'Bitte Akzeptieren Sie die AGB',
      gpLocked:
        'Der Onlinezugriff auf Ihre Daten ist zu unserem Bedauern nicht möglich, bitte wenden Sie sich an unseren Kundenservice: Telefon 0711 9310 242. Sie erreichen uns von Montag bis Freitag von 07:00 Uhr bis 17:00 Uhr und samstags von 07:30 Uhr bis 12:00 Uhr oder Sie nutzen das Kontaktformular. <BR />' +
        'Vielen Dank für Ihr Verständnis.',
      gpUsed:
        '<strong>Verknüpfung fehlgeschlagen.</strong><br />' +
        'Ihr Abo/Ihre Kundennummer ist bereits einem anderen Benutzernamen zugeordnet.<br />' +
        'Bitte verwenden Sie diesen bereits vergebenen Benutzernamen. Haben Sie diesen vergessen, dann folgen Sie bitte diesem Link: <a href="https://id.cannstatter-zeitung.de/auth/realms/cz/login-actions/reset-credentials" target="_blank" rel="noopener noreferrer">Benutzername oder Passwort vergessen?</a>',
    },
  },
  payment: {
    headline2: 'Bitte wählen Sie eine Zahlmethode',
    messageError: 'Bitte prüfen Sie die eingegebenen Daten.',
  },
  verification: {
    headline1: 'Bestellung abschließen',
    agbAdditional:
      'Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages begonnen wird. Mir ist bekannt, dass mein Widerrufsrecht durch diese Zustimmung mit der Beginn der Ausführung erlischt.',
  },
  success: {
    backLinkSrc: 'https://www.cannstatter-zeitung.de/',
    contactSrc: 'mailto:abo@ez-online.de',
    phone: '0711 9310 242',
    phoneSrc: 'tel:+497119310242',
    registrationPossible:
      '<b>Wichtig!</b> Scheinbar haben Sie in der Vergangenheit schon bei uns bestellt oder sind Kunde/Kundin. In Kürze erhalten Sie eine E-Mail, über die Sie Ihre Kundendaten anhand Ihrer Kundennummer zusammenführen können.',
    additionalCopy: '',
  },
  orderExists: {
    info: 'Dieses Produkt wurde bereits für Sie aktiviert.<br /> Sie haben aktuell Zugriff auf alle CZ Plus Artikel.',
    buttonText: 'Zurück zur Startseite',
    buttonTextBackToArticle: 'CZ Plus Artikel lesen',
    link: 'https://www.cannstatter-zeitung.de/',
  },
  followUpOffer: {
    infoText:
      '<p> Sie können mit Ihren aktuellen Zugangsdaten CZ Plus bereits nutzen.</p><p class="mb-0">Endet Ihr Angebot demnächst? Bestellen Sie hier Ihr Folgeangebot.</p>',
    homePageLink: 'https://www.cannstatter-zeitung.de/',
  },
  error: {
    headline1: 'Es tut uns leid . . .',
    server: '. . . es gab ein Serverproblem.',
    startPageLink: 'https://www.cannstatter-zeitung.de/',
    startPageLinkLabel: 'Erneut versuchen',
  },
  productunavailable: {
    headline2: '. . . dieses Produkt ist nicht verfügbar.',
  },
  trialExpired: {
    headline1: 'Es tut uns leid . . .',
    headline2: '. . . dieses Produkt können Sie im Moment nicht bestellen.',
    textDigital:
      'Es ist uns wichtig, dass Sie die digitalen Produkte der Cannstatter Zeitung / Untertürkheimer Zeitung kostenlos kennenlernen und ausprobieren. Sie hatten bereits die Möglichkeit CZ Plus zu testen. Um weiterhin alle Artikel und das CZ ePaper lesen zu können, wählen Sie bitte eines der folgenden Angebote.',
    textPrint:
      'Es ist uns wichtig, dass Sie die gedruckte Ausgabe der Cannstatter Zeitung / Untertürkheimer Zeitung zu einem vergünstigten Preis kennenlernen und ausprobieren können. Sie hatten bereits die Möglichkeit, die Cannstatter Zeitung / Untertürkheimer Zeitung zu testen. Um weiterhin bestens informiert zu bleiben, wählen Sie bitte eines der folgenden Angebote',
    linkLabelDigital: 'Das komplette digitale Angebot der CZ',
    linkSrcDigital: '	https://produkte.cannstatter-zeitung.de/',
    linkLabelPrint: 'Die Cannstatter Zeitung / Untertürkheimer Zeitung lesen',
    linkSrcPrint: 'https://produkte.cannstatter-zeitung.de/angebote-print/',
  },
  legal: {
    imprintSrc: '	https://www.cannstatter-zeitung.de/impressum',
    privacySrc: 'https://www.swmh-datenschutz.de/cz',
    copyright: `© ${currentYear} Bechtle Verlag und Esslinger Zeitung GmbH & Co. KG`,
    agbSrc: 'https://produkte.cannstatter-zeitung.de/agb/',
    revocationLink: 'https://produkte.cannstatter-zeitung.de/widerrufsrecht/',
    dataPrivacyLink: 'https://www.swmh-datenschutz.de/cz',
    agbSrcDigital: 'https://produkte.cannstatter-zeitung.de/agb/',
    agbSrcPrint: 'https://produkte.cannstatter-zeitung.de/agb/',
    agbSrcMagazine: 'https://produkte.cannstatter-zeitung.de/agb/',
    cancellationSrc: 'https://produkte.cannstatter-zeitung.de/widerrufsrecht/',
    partner:
      'Ihr Vertragspartner ist die Bechtle, Graphische Betriebe und Verlagsgesellschaft (Bechtle Verlag und Esslinger Zeitung) GmbH & Co. KG, Zeppelinstr. 116, 73730 Esslingen. Geschäftsführer: Herbert Dachs, Amtsgericht Stuttgart HRA 210160.',
    dataPrivacy:
      'Die Bechtle, Graphische Betriebe und Verlagsgesellschaft (Bechtle Verlag und Esslinger Zeitung) GmbH & Co. KG verarbeitet Ihre Daten zur Vertragserfüllung, Kundenbetreuung und, sofern Sie eingewilligt haben oder wir gesetzlich dazu berechtigt sind, für werbliche Zwecke. Bis zu Ihrem Widerruf werden wir Ihre bei der Registrierung angegebenen E-Mail-Adresse für Information über eigene ähnliche Waren und Dienstleistungen nutzen (§ 7 III UWG). Sie können der werblichen Nutzung Ihrer Daten jederzeit schriftlich oder per E-Mail an <a href="mailto:datenschutz@ez-online.de">datenschutz@ez-online.de</a> widersprechen. Ihre Daten können zu den genannten Zwecken auch an Dienstleister weitergegeben werden.',
    dataPrivacyAddition:
      'Ausführliches zum Datenschutz und zu den Informationspflichten finden Sie in der <a href="https://www.swmh-datenschutz.de/cz" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
  },
  advertisingConsent: {
    disclaimer:
      'Ja, ich bin damit einverstanden, dass ich über Medienangebote der Bechtle, Graphische Betriebe und Verlagsgesellschaft (Bechtle Verlag und Esslinger Zeitung) GmbH & Co. KG bis auf Widerruf informiert werde:',
    privacy: 'https://www.swmh-datenschutz.de/cz',
  },
};

export const kbb = {
  config: {
    region: false,
    advertisingConsent: true,
    earliestStartDateOffsetForPrintInDays: 10,
    orderWithoutAddressCustomizable: true,
    dayPassPrefixes: [],
  },
  meta: {
    title: 'Meine Bestellung - KREISZEITUNG Böblinger Bote',
  },
  contact: {
    link: 'service/kontaktformular',
    phoneNumber: '07031 6200 51',
    myDataLink: 'https://service.krzbb.de/service/meine-daten',
  },
  start: {
    headline1: 'Ihre Bestellung',
    headline2: 'Ihre Daten',
  },
  modals: {
    browserBack: {
      copy: '<p>Mit der Bestellung eines digitalen Abonnements können Sie über den Button “Zur KRZBB-Startseite” direkt auf der KRZBB-Homepage lesen.</p><p>Möchten Sie Änderungen an Ihrer Bestellung oder Ihrem bestehenden Abonnement vornehmen, dann klicken Sie bitte auf “Zu Konto & Abo”.</p>',
      link1: {
        label: 'Zur KRZBB-Startseite',
        href: 'https://www.krzbb.de/',
      },
      link2: {
        href: 'https://service.krzbb.de/service/meine-abos',
      },
    },
  },
  gpNr: {
    headlineWithGpNr: 'Ich habe bereits eine Kundennummer',
    headlineWithoutGpNr: 'Ich habe noch keine Kundennummer',
    gpNrInputHint:
      '10-stellig, beginnt mit 2. Sie finden die Kundennummer in der Bestellbestätigung',
    error: {
      gpNrNotValid: 'Keine gültigen Daten',
      formValidationMessage:
        'Bitte überprüfen Sie die gekennzeichneten Felder und füllen Sie die Pflichtfelder aus.',
      agb: 'Bitte Akzeptieren Sie die AGB',
      gpLocked:
        'Der Onlinezugriff auf Ihre Daten ist zu unserem Bedauern nicht möglich, bitte wenden Sie sich an unseren Kundenservice: Telefon 07031 6200 51. Sie erreichen uns von Montag bis Freitag von 07:00 Uhr bis 17:00 Uhr und samstags von 07:30 Uhr bis 12:00 Uhr oder Sie nutzen das Kontaktformular. <BR />' +
        'Vielen Dank für Ihr Verständnis.',
      gpUsed:
        '<strong>Verknüpfung fehlgeschlagen.</strong><br />' +
        'Ihr Abo/Ihre Kundennummer ist bereits einem anderen Benutzernamen zugeordnet.<br />' +
        'Bitte verwenden Sie diesen bereits vergebenen Benutzernamen. Haben Sie diesen vergessen, dann folgen Sie bitte diesem Link: <a href="https://id.krzbb.de/auth/realms/krzbb/login-actions/reset-credentials" target="_blank" rel="noopener noreferrer">Benutzername oder Passwort vergessen?</a>',
    },
  },
  payment: {
    headline2: 'Bitte wählen Sie eine Zahlmethode',
    messageError: 'Bitte prüfen Sie die eingegebenen Daten.',
  },
  verification: {
    headline1: 'Bestellung abschließen',
    agbAdditional:
      'Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages begonnen wird. Mir ist bekannt, dass mein Widerrufsrecht durch diese Zustimmung mit der Beginn der Ausführung erlischt.',
  },
  success: {
    backLinkSrc: 'https://www.krzbb.de/',
    contactSrc: 'mailto:leserservice@krzbb.de',
    phone: '07031 6200 51',
    phoneSrc: 'tel:+497031620051',
    registrationPossible:
      '<b>Wichtig!</b> Scheinbar haben Sie in der Vergangenheit schon bei uns bestellt oder sind Kunde/Kundin. In Kürze erhalten Sie eine E-Mail, über die Sie Ihre Kundendaten anhand Ihrer Kundennummer zusammenführen können.',
    additionalCopy: '',
  },
  orderExists: {
    info: 'Dieses Produkt wurde bereits für Sie aktiviert.<br /> Sie haben aktuell Zugriff auf alle KRZ Plus Artikel.',
    buttonText: 'Zurück zur Startseite',
    buttonTextBackToArticle: 'KRZ Plus Artikel lesen',
    link: 'https://www.krzbb.de/',
  },
  followUpOffer: {
    infoText:
      '<p> Sie können mit Ihren aktuellen Zugangsdaten KRZ Plus bereits nutzen.</p><p class="mb-0">Endet Ihr Angebot demnächst? Bestellen Sie hier Ihr Folgeangebot.</p>',
    homePageLink: 'https://www.krzbb.de/',
  },
  error: {
    headline1: 'Es tut uns leid . . .',
    server: '. . . es gab ein Serverproblem.',
    startPageLink: 'https://www.krzbb.de/',
    startPageLinkLabel: 'Erneut versuchen',
  },
  productunavailable: {
    headline2: '. . . dieses Produkt ist nicht verfügbar.',
  },
  trialExpired: {
    headline1: 'Es tut uns leid . . .',
    headline2: '. . . dieses Produkt können Sie im Moment nicht bestellen.',
    textDigital:
      'Es ist uns wichtig, dass Sie die digitalen Produkte der KREISZEITUNG Böblinger Bot kostenlos kennenlernen und ausprobieren. Sie hatten bereits die Möglichkeit KRZ Plus zu testen. Um weiterhin alle Artikel und das KRZ ePaper lesen zu können, wählen Sie bitte eines der folgenden Angebote.',
    textPrint:
      'Es ist uns wichtig, dass Sie die gedruckte Ausgabe der KREISZEITUNG Böblinger Bot zu einem vergünstigten Preis kennenlernen und ausprobieren können. Sie hatten bereits die Möglichkeit, die Cannstatter Zeitung / Untertürkheimer Zeitung zu testen. Um weiterhin bestens informiert zu bleiben, wählen Sie bitte eines der folgenden Angebote',
    linkLabelDigital: 'Das komplette digitale Angebot der KRZ',
    linkSrcDigital: 'https://produkte.krzbb.de/',
    linkLabelPrint: 'Die KREISZEITUNG Böblinger Bote lesen',
    linkSrcPrint: 'https://produkte.krzbb.de/angebote-print/',
  },
  legal: {
    imprintSrc: 'https://www.krzbb.de/impressum',
    privacySrc: 'https://www.krzbb.de/datenschutzerklaerung',
    copyright: `© ${currentYear} KREISZEITUNG Böblinger Bote Wilhelm Schlecht GmbH & Co. KG`,
    agbSrc: 'https://www.krzbb.de/abo-agb',
    revocationLink: 'https://produkte.krzbb.de/widerrufsrecht/',
    dataPrivacyLink: 'https://www.swmh-datenschutz.de/krzbb',
    agbSrcDigital: 'https://www.krzbb.de/abo-agb',
    agbSrcPrint: 'https://www.krzbb.de/abo-agb',
    agbSrcMagazine: 'https://www.krzbb.de/abo-agb',
    cancellationSrc: 'https://produkte.krzbb.de/widerrufsrecht/',
    partner:
      'Ihr Vertragspartner ist die KREISZEITUNG Böblinger Bote Wilhelm Schlecht GmbH & Co. KG, Wilhelmstr. 34, 71034 Böblingen. Geschäftsführer: Herbert Dachs, Amtsgericht Stuttgart HRA 240665.',
    dataPrivacy:
      'Die KREISZEITUNG Böblinger Bote Wilhelm Schlecht GmbH & Co. KG verarbeitet Ihre Daten zur Vertragserfüllung, Kundenbetreuung und, sofern Sie eingewilligt haben oder wir gesetzlich dazu berechtigt sind, für werbliche Zwecke. Bis zu Ihrem Widerruf werden wir Ihre bei der Registrierung angegebenen E-Mail-Adresse für Information über eigene ähnliche Waren und Dienstleistungen nutzen (§ 7 III UWG). Sie können der werblichen Nutzung Ihrer Daten jederzeit schriftlich oder per E-Mail an <a href="mailto:leserservice@krzbb.de">leserservice@krzbb.de</a> widersprechen. Ihre Daten können zu den genannten Zwecken auch an Dienstleister weitergegeben werden.',
    dataPrivacyAddition:
      'Ausführliches zum Datenschutz und zu den Informationspflichten finden Sie in der <a href="https://www.swmh-datenschutz.de/krzbb" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
  },
  advertisingConsent: {
    disclaimer:
      'Ja, ich bin damit einverstanden, dass ich über Medienangebote der KREISZEITUNG Böblinger Bote Wilhelm Schlecht GmbH & Co. KG bis auf Widerruf informiert werde:',
    privacy: 'https://www.krzbb.de/datenschutzerklaerung',
  },
};

export const swb = {
  config: {
    region: true,
    advertisingConsent: true,
    earliestStartDateOffsetForPrintInDays: 10,
    orderWithoutAddressCustomizable: true,
    dayPassPrefixes: [],
  },
  meta: {
    title: 'Meine Bestellung - Schwarzwälder Bote',
  },
  contact: {
    link: 'service/kontaktformular',
    phoneNumber: '07423 780',
    myDataLink:
      'https://onlineservice.schwarzwaelder-bote.de/service/meine-daten',
  },
  start: {
    headline1: 'Ihre Bestellung',
    headline2: 'Ihre Daten',
  },
  modals: {
    browserBack: {
      copy: '<p>Mit der Bestellung eines digitalen Abonnements können Sie über den Button “Zur SB-Startseite” direkt auf der SB-Homepage lesen.</p><p>Möchten Sie Änderungen an Ihrer Bestellung oder Ihrem bestehenden Abonnement vornehmen, dann klicken Sie bitte auf “Zu Konto & Abo”.</p>',
      link1: {
        label: 'Zur SB-Startseite',
        href: 'https://www.schwarzwaelder-bote.de/',
      },
      link2: {
        href: 'https://onlineservice.schwarzwaelder-bote.de/service/meine-abos',
      },
    },
  },
  gpNr: {
    headlineWithGpNr: 'Ich habe bereits eine Kundennummer',
    headlineWithoutGpNr: 'Ich habe noch keine Kundennummer',
    gpNrInputHint:
      '10-stellig, beginnt mit 2. Sie finden die Kundennummer in der Bestellbestätigung',
    error: {
      gpNrNotValid: 'Keine gültigen Daten',
      formValidationMessage:
        'Bitte überprüfen Sie die gekennzeichneten Felder und füllen Sie die Pflichtfelder aus.',
      agb: 'Bitte Akzeptieren Sie die AGB',
      gpLocked:
        'Der Onlinezugriff auf Ihre Daten ist zu unserem Bedauern nicht möglich, bitte wenden Sie sich an unseren Kundenservice: Telefon 07423 78-0. Sie erreichen uns von Montag bis Freitag von 07:00 Uhr bis 17:00 Uhr und samstags von 07:30 Uhr bis 12:00 Uhr oder Sie nutzen das Kontaktformular. <BR />' +
        'Vielen Dank für Ihr Verständnis.',
      gpUsed:
        '<strong>Verknüpfung fehlgeschlagen.</strong><br />' +
        'Ihr Abo/Ihre Kundennummer ist bereits einem anderen Benutzernamen zugeordnet.<br />' +
        'Bitte verwenden Sie diesen bereits vergebenen Benutzernamen. Haben Sie diesen vergessen, dann folgen Sie bitte diesem Link: <a href="https://www.schwarzwaelder-bote.de/passwort-vergessen" target="_blank" rel="noopener noreferrer">Benutzername oder Passwort vergessen?</a>',
    },
  },
  payment: {
    headline2: 'Bitte wählen Sie eine Zahlmethode',
    messageError: 'Bitte prüfen Sie die eingegebenen Daten.',
  },
  verification: {
    headline1: 'Bestellung abschließen',
    agbAdditional:
      'Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages begonnen wird. Mir ist bekannt, dass mein Widerrufsrecht durch diese Zustimmung mit der Beginn der Ausführung erlischt.',
  },
  success: {
    backLinkSrc: 'https://www.schwarzwaelder-bote.de/',
    contactSrc: 'mailto:service@schwarzwaelder-bote.de',
    phone: '07423 780',
    phoneSrc: 'tel:+497423780',
    registrationPossible:
      '<b>Wichtig!</b> Scheinbar haben Sie in der Vergangenheit schon bei uns bestellt oder sind Kunde/Kundin. In Kürze erhalten Sie eine E-Mail, über die Sie Ihre Kundendaten anhand Ihrer Kundennummer zusammenführen können.',
    additionalCopy: '',
  },
  orderExists: {
    info: 'Für die Bestellung einer weiteren Lokalausgabe wenden Sie sich bitte an unseren Service unter <a href="mailto:service@schwarzwaelder-bote.de">service@schwarzwaelder-bote.de</a>.',
    buttonText: 'Zurück zur Startseite',
    buttonTextBackToArticle: 'SB Plus Artikel lesen',
    link: 'https://www.schwarzwaelder-bote.de/',
  },
  followUpOffer: {
    infoText:
      '<p> Sie können mit Ihren aktuellen Zugangsdaten SB Plus bereits nutzen.</p><p class="mb-0">Endet Ihr Angebot demnächst? Bestellen Sie hier Ihr Folgeangebot.</p>',
    homePageLink: 'https://www.schwarzwaelder-bote.de/',
  },
  error: {
    headline1: 'Es tut uns leid . . .',
    server: '. . . es gab ein Serverproblem.',
    startPageLink: 'https://www.schwarzwaelder-bote.de/',
    startPageLinkLabel: 'Erneut versuchen',
  },
  productunavailable: {
    headline2: '. . . dieses Produkt ist nicht verfügbar.',
  },
  trialExpired: {
    headline1: 'Es tut uns leid . . .',
    headline2: '. . . dieses Produkt können Sie im Moment nicht bestellen.',
    textDigital:
      'Es ist uns wichtig, dass Sie die digitalen Produkte des Schwarzwälder Boten kostenlos kennenlernen und ausprobieren. Sie hatten bereits die Möglichkeit SB Plus zu testen. Um weiterhin alle Artikel und das SB ePaper lesen zu können, wählen Sie bitte eines der folgenden Angebote.',
    textPrint:
      'Es ist uns wichtig, dass Sie die gedruckte Ausgabe des Schwarzwälder Boten zu einem vergünstigten Preis kennenlernen und ausprobieren können. Sie hatten bereits die Möglichkeit, den Schwarzwälder Boten zu testen. Um weiterhin bestens informiert zu bleiben, wählen Sie bitte eines der folgenden Angebote',
    linkLabelDigital: 'Das komplette digitale Angebot der SB',
    linkSrcDigital: 'https://produkte.schwarzwaelder-bote.de/',
    linkLabelPrint: 'Den Schwarzwälder Boten lesen',
    linkSrcPrint: 'https://produkte.schwarzwaelder-bote.de/angebote-print/',
  },
  legal: {
    imprintSrc: 'https://www.schwarzwaelder-bote.de/impressum',
    privacySrc: 'https://www.swmh-datenschutz.de/sbm',
    copyright: `© ${currentYear} Schwarzwälder Bote Mediengesellschaft mbH`,
    agbSrc: 'https://produkte.schwarzwaelder-bote.de/agb/',
    revocationLink: 'https://produkte.schwarzwaelder-bote.de/widerrufsrecht/',
    dataPrivacyLink: 'https://www.swmh-datenschutz.de/sbm',
    agbSrcDigital: 'https://produkte.schwarzwaelder-bote.de/agb/',
    agbSrcPrint: 'https://produkte.schwarzwaelder-bote.de/agb/',
    agbSrcMagazine: 'https://produkte.schwarzwaelder-bote.de/agb/',
    cancellationSrc: 'https://produkte.schwarzwaelder-bote.de/widerrufsrecht/',
    partner:
      'Ihr Vertragspartner ist die Schwarzwälder Bote Mediengesellschaft mbH, Kirchtorstr. 14, 78727 Oberndorf am Neckar. Geschäftsführer: Carsten Huber, Amtsgericht Stuttgart HRB 480886.',
    dataPrivacy:
      'Die Schwarzwälder Bote Mediengesellschaft mbH verarbeitet Ihre Daten zur Vertragserfüllung, Kundenbetreuung und, sofern Sie eingewilligt haben oder wir gesetzlich dazu berechtigt sind, für werbliche Zwecke. Bis zu Ihrem Widerruf werden wir Ihre bei der Registrierung angegebenen E-Mail-Adresse für Information über eigene ähnliche Waren und Dienstleistungen nutzen (§ 7 III UWG). Sie können der werblichen Nutzung Ihrer Daten jederzeit schriftlich oder per E-Mail an <a href="mailto:werbewiderspruch@schwarzwaelder-bote.de">werbewiderspruch@schwarzwaelder-bote.de</a> widersprechen. Ihre Daten können zu den genannten Zwecken auch an Dienstleister weitergegeben werden.',
    dataPrivacyAddition:
      'Ausführliches zum Datenschutz und zu den Informationspflichten finden Sie in der <a href="https://www.swmh-datenschutz.de/sbm" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
  },
  advertisingConsent: {
    disclaimer:
      'Ja, ich bin damit einverstanden, dass ich über Medienangebote der Schwarzwälder Bote Mediengesellschaft mbH bis auf Widerruf informiert werde',
    privacy: 'https://www.swmh-datenschutz.de/sbm',
  },
};

export const lz = {
  config: {
    region: false,
    advertisingConsent: true,
    earliestStartDateOffsetForPrintInDays: 10,
    orderWithoutAddressCustomizable: true,
    dayPassPrefixes: [],
  },
  meta: {
    title: 'Meine Bestellung - Lahrer Zeitung',
  },
  contact: {
    link: 'service/kontaktformular',
    phoneNumber: '0800 2783 000',
    myDataLink: 'service/meine-daten',
  },
  start: {
    headline1: 'Ihre Bestellung',
    headline2: 'Ihre Daten',
  },
  modals: {
    browserBack: {
      copy: '<p>Mit der Bestellung eines digitalen Abonnements können Sie über den Button “Zur LZ-Startseite” direkt auf der LZ-Homepage lesen.</p><p>Möchten Sie Änderungen an Ihrer Bestellung oder Ihrem bestehenden Abonnement vornehmen, dann klicken Sie bitte auf “Zu Konto & Abo”.</p>',
      link1: {
        label: 'Zur LZ-Startseite',
        href: 'https://www.schwarzwaelder-bote.de/ortenau',
      },
      link2: {
        href: 'https://service.lahrer-zeitung.de/service/meine-abos',
      },
    },
  },
  gpNr: {
    headlineWithGpNr: 'Ich habe bereits eine Kundennummer',
    headlineWithoutGpNr: 'Ich habe noch keine Kundennummer',
    gpNrInputHint:
      '10-stellig, beginnt mit 2. Sie finden die Kundennummer in der Bestellbestätigung',
    error: {
      gpNrNotValid: 'Keine gültigen Daten',
      formValidationMessage:
        'Bitte überprüfen Sie die gekennzeichneten Felder und füllen Sie die Pflichtfelder aus.',
      agb: 'Bitte Akzeptieren Sie die AGB',
      gpLocked:
        'Der Onlinezugriff auf Ihre Daten ist zu unserem Bedauern nicht möglich, bitte wenden Sie sich an unseren Kundenservice: Telefon 0800 2783 000. Sie erreichen uns von Montag bis Freitag von 07:00 Uhr bis 17:00 Uhr und samstags von 07:30 Uhr bis 12:00 Uhr oder Sie nutzen das Kontaktformular.  <BR />' +
        'Vielen Dank für Ihr Verständnis.',
      gpUsed:
        '<strong>Verknüpfung fehlgeschlagen.</strong><br />' +
        'Ihr Abo/Ihre Kundennummer ist bereits einem anderen Benutzernamen zugeordnet.<br />' +
        'Bitte verwenden Sie diesen bereits vergebenen Benutzernamen. Haben Sie diesen vergessen, dann folgen Sie bitte diesem Link: <a href="https://www.schwarzwaelder-bote.de/passwort-vergessen" target="_blank" rel="noopener noreferrer">Benutzername oder Passwort vergessen?</a>',
    },
  },
  payment: {
    headline2: 'Bitte wählen Sie eine Zahlmethode',
    messageError: 'Bitte prüfen Sie die eingegebenen Daten.',
  },
  verification: {
    headline1: 'Bestellung abschließen',
    agbAdditional:
      'Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrages begonnen wird. Mir ist bekannt, dass mein Widerrufsrecht durch diese Zustimmung mit der Beginn der Ausführung erlischt.',
  },
  success: {
    backLinkSrc: 'https://www.lahrer-zeitung.de/',
    contactSrc: 'mailto:aboservice@lahrer-zeitung.de',
    phone: '0800 2783 000',
    phoneSrc: 'tel:+498002783000',
    registrationPossible:
      '<b>Wichtig!</b> Scheinbar haben Sie in der Vergangenheit schon bei uns bestellt oder sind Kunde/Kundin. In Kürze erhalten Sie eine E-Mail, über die Sie Ihre Kundendaten anhand Ihrer Kundennummer zusammenführen können.',
    additionalCopy: '',
  },
  orderExists: {
    info: 'Dieses Produkt wurde bereits für Sie aktiviert.<br /> Sie haben aktuell Zugriff auf alle LZ Plus Artikel.',
    buttonText: 'Zurück zur Startseite',
    buttonTextBackToArticle: 'LZ Plus Artikel lesen',
    link: 'https://www.lahrer-zeitung.de/',
  },
  followUpOffer: {
    infoText:
      '<p> Sie können mit Ihren aktuellen Zugangsdaten die digitale Ausgabe bereits nutzen.</p><p class="mb-0">Endet Ihr Angebot demnächst? Bestellen Sie hier Ihr Folgeangebot.</p>',
    homePageLink: 'https://www.lahrer-zeitung.de/',
  },
  error: {
    headline1: 'Es tut uns leid . . .',
    server: '. . . es gab ein Serverproblem.',
    startPageLink: 'https://www.lahrer-zeitung.de/',
    startPageLinkLabel: 'Erneut versuchen',
  },
  productunavailable: {
    headline2: '. . . dieses Produkt ist nicht verfügbar.',
  },
  trialExpired: {
    headline1: 'Es tut uns leid . . .',
    headline2: '. . . dieses Produkt können Sie im Moment nicht bestellen.',
    textDigital:
      'Es ist uns wichtig, dass Sie die digitalen Produkte der Lahrer Zeitung kostenlos kennenlernen und ausprobieren. Sie hatten bereits die Möglichkeit das LZ ePaper zu testen. Um weiterhin das LZ ePaper lesen zu können, wählen Sie bitte eines der folgenden Angebote.',
    textPrint:
      'Es ist uns wichtig, dass Sie die gedruckte Ausgabe der Lahrer Zeitung zu einem vergünstigten Preis kennenlernen und ausprobieren können. Sie hatten bereits die Möglichkeit, die Lahrer Zeitung zu testen. Um weiterhin bestens informiert zu bleiben, wählen Sie bitte eines der folgenden Angebote.',
    linkLabelDigital: 'Das komplette digitale Angebot der LZ',
    linkSrcDigital: 'https://produkte.lahrer-zeitung.de/',
    linkLabelPrint: 'Die Lahrer Zeitung lesen',
    linkSrcPrint: 'https://produkte.lahrer-zeitung.de/angebote-print/',
  },
  legal: {
    imprintSrc: 'https://www.schwarzwaelder-bote.de/impressum',
    privacySrc: 'https://swmh-datenschutz.de/lz',
    copyright: `© ${currentYear} Lahrer Zeitung GmbH`,
    agbSrc: 'https://www.lahrer-zeitung.de/agb',
    revocationLink: 'https://produkte.lahrer-zeitung.de/widerrufsrecht/',
    dataPrivacyLink: 'https://www.swmh-datenschutz.de/lz',
    agbSrcDigital:
      'https://www.schwarzwaelder-bote.de/media.media.983e66e4-271c-4540-b691-985ebd983e85.original.pdf',
    agbSrcPrint:
      'https://www.schwarzwaelder-bote.de/media.media.983e66e4-271c-4540-b691-985ebd983e85.original.pdf',
    agbSrcMagazine:
      'https://www.schwarzwaelder-bote.de/media.media.983e66e4-271c-4540-b691-985ebd983e85.original.pdf',
    cancellationSrc: 'https://produkte.lahrer-zeitung.de/widerrufsrecht/',
    partner:
      'Ihr Vertragspartner ist die Lahrer Zeitung GmbH, Kreuzstr. 9, 77933 Lahr. Geschäftsführerin: Kirsten Wolf, Amtsgericht Freiburg HRB 390908.',
    dataPrivacy:
      'Die Lahrer Zeitung GmbH verarbeitet Ihre Daten zur Vertragserfüllung, Kundenbetreuung und, sofern Sie eingewilligt haben oder wir gesetzlich dazu berechtigt sind, für werbliche Zwecke. Bis zu Ihrem Widerruf werden wir Ihre bei der Registrierung angegebenen E-Mail-Adresse für Information über eigene ähnliche Waren und Dienstleistungen nutzen (§ 7 III UWG). Sie können der werblichen Nutzung Ihrer Daten jederzeit schriftlich oder per E-Mail an <a href="mailto:werbewiderspruch@lahrer-zeitung.de">werbewiderspruch@lahrer-zeitung.de</a> widersprechen. Ihre Daten können zu den genannten Zwecken auch an Dienstleister weitergegeben werden.',
    dataPrivacyAddition:
      'Ausführliches zum Datenschutz und zu den Informationspflichten finden Sie in der <a href="https://www.swmh-datenschutz.de/lz" target="_blank" rel="noopener noreferrer">Datenschutzerklärung</a>.',
  },
  advertisingConsent: {
    disclaimer:
      'Ja, ich bin damit einverstanden, dass ich über Medienangebote der Lahrer Zeitung GmbH bis auf Widerruf informiert werde',
    privacy: 'https://www.swmh-datenschutz.de/lz',
  },
};

<template>
  <div v-if="showComponent">
    <span class="mb-4 d-block">{{
      commonTexts.userInterface.loginInterface.title
    }}</span>
    <ButtonAuth :type="AuthButtonType.LOGIN" transparent :link="loginUrl">
      {{ commonTexts.userInterface.loginInterface.buttonLabel }}
    </ButtonAuth>
  </div>
</template>

<script lang="ts">
  import commonTexts from '@/data/commonTexts';
  import { AuthButtonType } from '@/types/GeneralInterfaceTypes';
  import { computed, defineComponent } from 'vue';
  import ButtonAuth from '@/components/Buttons/ButtonAuth.vue';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'LoginInterface',
    components: { ButtonAuth },
    props: {
      loginUrl: {
        type: String,
        required: true,
      },
    },
    setup() {
      const store = useStore();
      const showComponent = computed(() => {
        return !store.state.isLoggedIn && store.state.loginChecked;
      });

      return { commonTexts, AuthButtonType, showComponent };
    },
  });
</script>

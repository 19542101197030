<template>
  <div class="osc-street-address-form-simple">
    <div class="form-row osc-street-address-form-simple__street-input">
      <FormElementSelectField
        v-if="streetSuggestion && !suggestedAddressWasSelected"
        class="col-md-9 mb-1 pt-2"
        :selectOptions="
          addressCheckResult ? addressCheckResult.streetCorrectionOptions : []
        "
        :value="selectedSuggestion"
        @input="selectedSuggestion = $event"
        embedded
        :field-value-corrected="streetCorrected"
      />
      <FormElementInputField
        v-else
        :label="commonTexts.userInterface.formFieldLabels.street"
        :field-id="
          commonTexts.userInterface.formFieldLabels.street + fieldIdSlug
        "
        class="col-md-9 mb-4"
        :value="addressData.street"
        @input="update(formFields.STREET, $event)"
        :field-value-corrected="streetCorrected"
        :field-has-error="fieldErrorStreet"
        @focus="resetFieldError('street')"
        maxlength="35"
      />
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.streetNumber"
        :field-id="
          commonTexts.userInterface.formFieldLabels.streetNumber + fieldIdSlug
        "
        class="col-md-3 mb-4"
        :value="addressData.streetNumber"
        @input="update(formFields.STREET_NUMBER, $event)"
        :field-has-error="fieldErrorStreetNumber"
        @focus="resetFieldError('streetNumber')"
      />
    </div>
    <div class="form-row">
      <FormElementInputField
        :label="
          commonTexts.userInterface.formFieldLabels.additionalInfoPrivateSimple
        "
        :field-id="
          commonTexts.userInterface.formFieldLabels
            .additionalInfoPrivateSimple + fieldIdSlug
        "
        :value="addressData.additionalInfo"
        @input="update(formFields.ADDITIONAL_INFO, $event)"
        :field-has-error="fieldErrors ? fieldErrors.additionalInfo : false"
        class="col-md-12 mb-4"
      />
    </div>
    <div class="form-row">
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.zipCode"
        :field-id="
          commonTexts.userInterface.formFieldLabels.zipCode + fieldIdSlug
        "
        class="col-md-3 mb-4"
        :value="addressData.zipCode"
        @input="update(formFields.ZIP_CODE, $event)"
        :field-value-corrected="zipCodeCorrected"
        :field-has-error="fieldErrorZipCode"
        @focus="resetFieldError('zipCode')"
        maxlength="10"
      />
      <FormElementSelectField
        v-if="citySuggestion && !suggestedAddressWasSelected"
        class="col-md-9 pt-2"
        :value="selectedSuggestion"
        @input="selectedSuggestion = $event"
        :selectOptions="
          addressCheckResult ? addressCheckResult.cityCorrectionOptions : []
        "
        :field-value-corrected="cityCorrected"
        embedded
      />
      <FormElementInputField
        v-else
        :label="commonTexts.userInterface.formFieldLabels.city"
        :field-id="commonTexts.userInterface.formFieldLabels.city + fieldIdSlug"
        class="col-md-9"
        :value="addressData.city"
        @input="update(formFields.CITY, $event)"
        :field-value-corrected="cityCorrected"
        :field-has-error="fieldErrorCity"
        @focus="resetFieldError('city')"
        maxlength="35"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onMounted,
    ref,
    watch,
    PropType,
  } from 'vue';
  import {
    FieldErrorsAddressData,
    ViolationFields,
  } from '@/validators/validatorTypes';
  import FormElementSelectField from '@/components/FormElements/FormElementSelectField.vue';
  import FormElementInputField from '@/components/FormElements/FormElementInputField.vue';
  import commonTexts from '@/data/commonTexts';
  import { OrderFormStreetAddress } from '@/types/OrderFormTypes';
  import { InternalSingleAddressValidationResult } from '@/models/OrderData';

  export default defineComponent({
    name: 'StreetAddressFormSimple',
    components: {
      FormElementSelectField,
      FormElementInputField,
    },
    props: {
      addressData: {
        type: Object as PropType<OrderFormStreetAddress>, // OrderFormStreetAddress to enable multiple implementations later on
        required: true,
      },
      fieldErrors: {
        type: Object as PropType<FieldErrorsAddressData>,
        required: false,
      },
      addressCheckResult: {
        type: Object as PropType<InternalSingleAddressValidationResult>,
        required: false,
      },
      fieldIdSlug: {
        type: String,
        required: false,
        default: '',
      },
    },
    emits: ['addressDataInput', 'resetFieldError'],
    setup(props, { emit }) {
      const selectedSuggestion = ref(0);
      const suggestedAddressWasSelected = ref(false);
      const citySuggestion = ref(false);
      const streetSuggestion = ref(false);
      const streetCorrected = ref(false);
      const zipCodeCorrected = ref(false);
      const cityCorrected = ref(false);

      enum formFields {
        STREET = 'street',
        STREET_NUMBER = 'streetNumber',
        ZIP_CODE = 'zipCode',
        CITY = 'city',
        ADDITIONAL_INFO = 'additionalInfo',
      }

      const update = function (field: formFields, value: string) {
        const data = Object.assign({}, props.addressData);
        switch (field) {
          case formFields.STREET:
            data.street = value;
            break;
          case formFields.STREET_NUMBER:
            data.streetNumber = value;
            break;
          case formFields.ZIP_CODE:
            data.zipCode = value;
            break;
          case formFields.CITY:
            data.city = value;
            break;
          case formFields.ADDITIONAL_INFO:
            data.additionalInfo = value;
            break;
        }

        emit('addressDataInput', data);
      };

      const setFieldCorrections = function () {
        citySuggestion.value =
          props.addressCheckResult !== undefined &&
          props.addressCheckResult.cityCorrectionOptions &&
          props.addressCheckResult.cityCorrectionOptions.length > 0;
        streetSuggestion.value =
          props.addressCheckResult !== undefined &&
          props.addressCheckResult.streetCorrectionOptions &&
          props.addressCheckResult.streetCorrectionOptions.length > 0;
        streetCorrected.value =
          props.addressCheckResult !== undefined &&
          props.addressCheckResult.streetCorrected;
        zipCodeCorrected.value =
          props.addressCheckResult !== undefined &&
          props.addressCheckResult.zipCodeCorrected;
        cityCorrected.value =
          props.addressCheckResult !== undefined &&
          props.addressCheckResult.cityCorrected;
      };

      onMounted(() => {
        setFieldCorrections();
      });

      watch(props, () => {
        setFieldCorrections();
      });

      const fieldErrorStreet = computed(
        () =>
          (props.fieldErrors !== undefined && props.fieldErrors.street) ||
          (props.addressCheckResult !== undefined &&
            props.addressCheckResult.streetError),
      );
      const fieldErrorStreetNumber = computed(
        () =>
          (props.fieldErrors !== undefined && props.fieldErrors.streetNumber) ||
          (props.addressCheckResult !== undefined &&
            props.addressCheckResult.streetNumberError),
      );
      const fieldErrorCity = computed(
        () =>
          (props.fieldErrors !== undefined && props.fieldErrors.city) ||
          (props.addressCheckResult !== undefined &&
            props.addressCheckResult.cityError),
      );
      const fieldErrorZipCode = computed(
        () =>
          (props.fieldErrors !== undefined && props.fieldErrors.zipCode) ||
          (props.addressCheckResult !== undefined &&
            props.addressCheckResult.zipCodeError),
      );

      const resetSuggestionMode = function () {
        citySuggestion.value = false;
        streetSuggestion.value = false;
        streetCorrected.value = false;
        zipCodeCorrected.value = false;
        cityCorrected.value = false;
        selectedSuggestion.value = 0;
      };

      watch(selectedSuggestion, () => {
        if (selectedSuggestion.value == -1) {
          suggestedAddressWasSelected.value = false;
          resetSuggestionMode();
        } else if (selectedSuggestion.value > 0) {
          const suggestedAddress:
            | { city: string; street: string; zipCode: string; id: number }
            | undefined =
            props.addressCheckResult?.addressCorrectionOptions?.find(
              (address: {
                city: string;
                street: string;
                zipCode: string;
                id: number;
              }) => {
                if (address.id == selectedSuggestion.value) {
                  return address;
                }
              },
            );
          if (suggestedAddress) {
            const { city, street, zipCode } = suggestedAddress;
            const data = Object.assign({}, props.addressData);

            data.city = city;
            data.street = street;
            data.zipCode = zipCode;
            suggestedAddressWasSelected.value = true;

            emit('addressDataInput', data);
          }
          resetSuggestionMode();
        }
      });

      const resetFieldError = function (field: string) {
        switch (field) {
          case 'street':
            emit('resetFieldError', ViolationFields.STREET);
            break;
          case 'streetNumber':
            emit('resetFieldError', ViolationFields.STREET_NUMBER);
            break;
          case 'zipCode':
            emit('resetFieldError', ViolationFields.ZIP_CODE);
            break;
          case 'city':
            emit('resetFieldError', ViolationFields.CITY);
            break;
        }
      };

      return {
        fieldErrorStreet,
        fieldErrorStreetNumber,
        fieldErrorCity,
        fieldErrorZipCode,
        selectedSuggestion,
        streetSuggestion,
        citySuggestion,
        streetCorrected,
        zipCodeCorrected,
        cityCorrected,
        resetFieldError,
        commonTexts,
        formFields,
        update,
        suggestedAddressWasSelected,
      };
    },
  });
</script>

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "business-address-addition-form mb-4" }
const _hoisted_2 = {
  key: 0,
  class: "form-row business-address-addition-form__row mb-4 mt-4"
}
const _hoisted_3 = {
  key: 1,
  class: "form-row business-address-addition-form__row mb-4"
}
const _hoisted_4 = {
  key: 2,
  class: "form-row business-address-addition-form__row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementCheckBox = _resolveComponent("FormElementCheckBox")!
  const _component_FormElementInputField = _resolveComponent("FormElementInputField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormElementCheckBox, {
      value: _ctx.additionalInfoSelected,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.additionalInfoSelected = $event)),
      name: _ctx.fieldIdSlug + '-businessAddressAddition'
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.commonTexts.userInterface.formFieldLabels
          .showAddressAdditionFormBusiness), 1)
      ]),
      _: 1
    }, 8, ["value", "name"]),
    (_ctx.additionalInfoSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_FormElementInputField, {
            label: _ctx.commonTexts.userInterface.formFieldLabels.floor,
            "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels.floor + _ctx.fieldIdSlug
        ,
            value: _ctx.value.floor,
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update(_ctx.formFields.FLOOR, $event))),
            "field-has-error": _ctx.fieldErrors.floor,
            class: "col-md-6",
            maxlength: "6"
          }, null, 8, ["label", "field-id", "value", "field-has-error"]),
          _createVNode(_component_FormElementCheckBox, {
            value: _ctx.value.mailDeliverySelected,
            onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.update(_ctx.formFields.MAIL_DELIVERY_SELECTED, $event))),
            class: "col-md-6 mt-3 mt-md-4",
            name: _ctx.fieldIdSlug + 'businessAddressAdditionMailDelivery',
            maxlength: "6"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.commonTexts.userInterface.formFieldLabels.selectMailDelivery), 1)
            ]),
            _: 1
          }, 8, ["value", "name"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.additionalInfoSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_FormElementInputField, {
            label: 
          _ctx.commonTexts.userInterface.formFieldLabels.additionalInfoBusiness
        ,
            "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels.additionalInfoBusiness +
          _ctx.fieldIdSlug
        ,
            value: _ctx.value.additionalInfo,
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.update(_ctx.formFields.ADDITIONAL_INFO, $event))),
            "field-has-error": _ctx.fieldErrors.additionalInfo,
            class: "col-md-12",
            maxlength: "50"
          }, null, 8, ["label", "field-id", "value", "field-has-error"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.additionalInfoSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_FormElementInputField, {
            label: _ctx.commonTexts.userInterface.formFieldLabels.majorCustomerZipCode,
            "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels.majorCustomerZipCode +
          _ctx.fieldIdSlug
        ,
            class: "col-md-4 mb-4 mb-md-0",
            value: _ctx.value.majorCustomerZipCode,
            onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.update(_ctx.formFields.MAJOR_CUSTOMER_ZIP_CODE, $event))),
            maxlength: "10"
          }, null, 8, ["label", "field-id", "value"]),
          _createVNode(_component_FormElementInputField, {
            label: _ctx.commonTexts.userInterface.formFieldLabels.majorCustomerCity,
            "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels.majorCustomerCity +
          _ctx.fieldIdSlug
        ,
            class: "col-md-8",
            value: _ctx.value.majorCustomerCity,
            onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.update(_ctx.formFields.MAJOR_CUSTOMER_CITY, $event))),
            maxlength: "35"
          }, null, 8, ["label", "field-id", "value"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
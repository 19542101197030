import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "order-recruiter",
  class: "order-recruiter"
}
const _hoisted_2 = { class: "main-content-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddressError = _resolveComponent("AddressError")!
  const _component_LoginInterface = _resolveComponent("LoginInterface")!
  const _component_OrderForm = _resolveComponent("OrderForm")!
  const _component_TheOverlay = _resolveComponent("TheOverlay")!
  const _component_ContentArea = _resolveComponent("ContentArea")!

  return (_openBlock(), _createBlock(_component_ContentArea, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("header", null, [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.headline), 1)
          ]),
          _createVNode(_component_AddressError, {
            addressChanged: _ctx.addressChanged,
            addressInvalid: _ctx.addressInvalid || _ctx.orderFormOptionalFieldWrong,
            addressIgnore: _ctx.addressIgnore,
            addressSuggested: _ctx.addressSuggested,
            addressComplete: _ctx.addressComplete,
            addressAlreadyChecked: _ctx.formSubmitted,
            "personal-data-incomplete": _ctx.orderFormPersonalDataIsInvalid
          }, null, 8, ["addressChanged", "addressInvalid", "addressIgnore", "addressSuggested", "addressComplete", "addressAlreadyChecked", "personal-data-incomplete"]),
          _createVNode(_component_LoginInterface, {
            "login-url": _ctx.appConfiguration.urls.loginUrl
          }, null, 8, ["login-url"]),
          _createVNode(_component_TheOverlay, {
            show: _ctx.submittingFormData,
            isFullPage: false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_OrderForm, {
                "address-check-result": _ctx.addressValidationResult,
                "order-form-data": _ctx.addresses.privateAddress,
                "form-data-currently-validating": _ctx.isValidating,
                "country-options": _ctx.countryOptions,
                "name-is-editable": _ctx.isNameEditable,
                "request-email-phone-number": true,
                "request-region-select": false,
                "full-address-required": true,
                "product-type": _ctx.formData.salesCampaign.printProductType,
                "email-is-mandatory": _ctx.emailIsMandatory,
                "request-miles-and-more-id": _ctx.requestMilesAndMoreId,
                "miles-and-more-id": _ctx.formData.mmNumber,
                "gp-selection-is-enabled": _ctx.gpTypeSelectionPossible,
                "business-address": _ctx.addresses.businessAddress,
                "delivery-address": _ctx.addresses.deliveryAddress,
                "delivery-address-enabled": _ctx.deliveryAddressPossible,
                "invoice-address": _ctx.addresses.invoiceAddress,
                "invoice-address-enabled": _ctx.invoiceAddressPossible,
                "show-gift-subscription-limit-selection": 
              _ctx.formData.salesCampaign.timeLimitSelectable
            ,
                "selected-gift-subscription-limit": _ctx.selectedGiftSubscriptionLimit,
                "request-iban": _ctx.requestIban,
                "iban-data": _ctx.ibanData,
                onFormValidated: _cache[0] || (_cache[0] = ($event: any) => (_ctx.submitOrderForm($event))),
                onUpdatePrivateData: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updatePrivateData($event))),
                onUpdateBusinessData: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateBusinessData($event))),
                onUpdateDeliveryAddress: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateDeliveryAddress($event))),
                onDeleteDeliveryAddress: _ctx.deleteDeliveryAddress,
                onUpdateInvoiceAddress: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateInvoiceAddress($event))),
                onDeleteInvoiceAddress: _ctx.deleteInvoiceAddress,
                onUpdateMilesAndMoreId: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('updateMilesAndMoreId', $event))),
                onChangeRecruiterGiftSubscriptionLimit: _cache[6] || (_cache[6] = ($event: any) => (
              _ctx.updateGiftSubscriptionLimit($event)
            )),
                onUpdateIban: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('updateIban', $event)))
              }, null, 8, ["address-check-result", "order-form-data", "form-data-currently-validating", "country-options", "name-is-editable", "product-type", "email-is-mandatory", "request-miles-and-more-id", "miles-and-more-id", "gp-selection-is-enabled", "business-address", "delivery-address", "delivery-address-enabled", "invoice-address", "invoice-address-enabled", "show-gift-subscription-limit-selection", "selected-gift-subscription-limit", "request-iban", "iban-data", "onDeleteDeliveryAddress", "onDeleteInvoiceAddress"])
            ]),
            _: 1
          }, 8, ["show"])
        ])
      ])
    ]),
    _: 1
  }))
}
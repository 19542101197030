<template>
  <div class="page-row" :class="additionalClasses">
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'PageRow',
    props: {
      additionalClasses: {
        type: String,
        default: '',
      },
    },
    components: {},
    setup() {
      return {};
    },
  });
</script>

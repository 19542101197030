import state from '@/store/modules/config/state';
import getters from '@/store/modules/config/getters';
import mutations from '@/store/modules/config/mutations';
import actions from '@/store/modules/config/actions';
import { Module } from 'vuex';
import { ConfigState } from '@/store/modules/config/types';
import { AppRootState } from '@/store/interfaces';

const configModule: Module<ConfigState, AppRootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default configModule;

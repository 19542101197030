import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["id", "type", "value", "maxlength", "readonly"]
const _hoisted_2 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-element-input-field", {
      'form-element-input-field--not-empty': _ctx.fieldValueNotEmpty || _ctx.withoutLabel,
      'form-element-input-field--disabled': _ctx.readonly,
      'form-element-input-field--error': _ctx.fieldHasError,
      'form-element-input-field--corrected': _ctx.fieldValueCorrected,
    }])
  }, [
    _createElementVNode("input", {
      id: _ctx.fieldId ? _ctx.fieldId : _ctx.label,
      class: "form-element form-element-input-field__input",
      type: _ctx.inputType,
      value: _ctx.value,
      maxlength: _ctx.maxlength,
      readonly: _ctx.readonly,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('input', $event.target.value))),
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
      onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
      onFocusout: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.handleFocusOut && _ctx.handleFocusOut(...args))),
      onKeydown: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isValidCharacter($event)))
    }, null, 40, _hoisted_1),
    (_ctx.label.length > 0)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.fieldId ? _ctx.fieldId : _ctx.label,
          class: "form-element-input-field__label"
        }, _toDisplayString(_ctx.label), 9, _hoisted_2))
      : _createCommentVNode("", true)
  ], 2))
}
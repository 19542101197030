<template>
  <ContentArea>
    <div>
      <div class="order-students-view">
        <header>
          <h2 class="main-content-title">
            {{ commonTexts.userFrontend.studentEnrollmentForm.headline }}
          </h2>
        </header>
        <div class="order-students-view__copy">
          {{ commonTexts.userFrontend.studentEnrollmentForm.copy }}
        </div>
        <div class="order-students-view__form">
          <StudentEnrollmentUploadForm :file-name="imageUpload.fileName" />
        </div>
        <div class="order-students-view__submit">
          <BasicButton
            variant="primary"
            type="submit"
            @buttonClicked="proceedToPayment"
          >
            {{
              commonTexts.userFrontend.studentEnrollmentForm.submitButtonLabel
            }}</BasicButton
          >
        </div>
      </div>
    </div>
  </ContentArea>
</template>

<script lang="ts">
  import commonTexts from '@/data/commonTexts';
  import StudentEnrollmentUploadForm from '@/components/Forms/StudentEnrollmentUploadForm.vue';
  import { computed, defineComponent, onMounted, watch } from 'vue';
  import GtmService from '@/services/gtm-service';
  import ContentArea from '@/components/PageLayout/ContentArea.vue';
  import { useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import BasicButton from '@/components/Buttons/BasicButton.vue';

  export default defineComponent({
    name: 'OrderStudents',
    components: { StudentEnrollmentUploadForm, ContentArea, BasicButton },
    props: {
      prefillFormData: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['formPrefilled'],
    setup(props, { emit }) {
      const store = useStore();
      const router = useRouter();
      watch(props, () => {
        if (props.prefillFormData) {
          emit('formPrefilled');
        }
      });

      const proceedToPayment = function () {
        router.push({ name: 'PaymentPage' });
      };

      const imageUpload = computed(() => {
        return store.state.studentEnrollmentUpload;
      });

      onMounted(() => {
        GtmService.pushGenericEvent({
          category: 'Checkout',
          action: 'Immatrikulationsupload',
          label: store.getters.formData.salesCampaign.salesCampaignId,
        });
      });

      return { commonTexts, proceedToPayment, imageUpload };
    },
  });
</script>

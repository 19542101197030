export enum PaymentMethods {
  PAYPAL = 'paypal',
  SEPA = 'sepa',
  INVOICE = 'invoice',
  CREDIT_CARD = 'card',
}

export enum PaymentMethodLiteral {
  CREDIT_CARD = 'Q',
  PAYPAL = 'P',
  SEPA = '7',
  INVOICE = 'Y',
}

export type CreditCardData = {
  firstName: string;
  lastName: string;
  creditCardTimeInvalid: boolean;
  creditCardDeclined: boolean;
  creditCardPaymentFrameUrl: string;
  truncatedCardPan?: string;
  validUntil?: string;
};

export type BillingFrequencyOption = {
  code: string;
  name: string;
  discountDomestic: number;
  discountAbroad: number;
};

export type IbanCoreData = {
  iban: string;
  swift: string;
  bankName: string;
  bankCountryCode: string;
};

export type BasicPaymentData = {
  paymentMethod: PaymentMethodLiteral | '';
  truncatedCardPan: string;
  ccIns: string;
  ccName: string;
  ccId: string;
  validUntil: null | string;
  pseudoCardPan: string;
  displayCCName: string;
  iban: string;
  swift: string;
  bankDataId: string;
  bankName: string;
  bankAccountOwner: string;
  bankCountryCode: string;
  creditCardPayment: boolean;
  billingFrequency: string;
  payPalCode: string;
};

export type BasicCheckRequestData = {
  user: {
    customerId?: string;
    firstname: string;
    lastname: string;
  };
  shopInfo: {
    shopDomain: string;
  };
};

export type PaymentUrlBaseResponse = {
  absUrl: string;
};

export type PaymentUrlProcessResponse = PaymentUrlBaseResponse & {
  processId: string;
};

import { InternalAdminConnectorInterface } from '@/services/connectors/interfaces';
import { AdminData, AdminUserData } from '@/types/AdminUserRole';
import {
  AdminSalesCampaignApprovalListData,
  BasicSalesCampaignData,
} from '@/types/AdminSalesCampaignData';
import { stubDataAllSalesCampaigns } from '@/services/connectors/stub-data-all-sales-campaigns';
import {
  SalesCampaignDetailsData,
  SingleSalesCampaignData,
  SingleSalesCampaignResponseData,
} from '@/models/SalesCampaigns';
import { adminSingleSalesCampaigns } from '@/services/connectors/stub-data';

export class InternalAdminConnectorStub
  implements InternalAdminConnectorInterface
{
  getAdminData(): Promise<AdminData> {
    return new Promise<AdminData>((resolve) => {
      setTimeout(() => {
        resolve({
          lastName: 'Russo',
          firstName: 'Giulia',
          email: 'osc-test-tenant-admin-sz@sztest.de',
          homePageInfos: {
            documentationLink:
              'https://mywiki.swmh.de/display/OSC/06+RMH+OSC+3.0+Weiterentwicklung',
            version: 'version-1.79.5-14-ge132139',
          },
          _links: {
            // Cave: ADMIN_ADMINS no longer is used and will have precedence over all other admin roles
            // ADMIN_ADMINS: {
            //   href: 'https://abo-ci.sueddeutsche.de/api/customizing-service/v1/swmh/tenant/admin/admin',
            //   title: 'König:in der Admins',
            // },
            ADMIN_TENANT_ADMINS: {
              href: 'https://abo-ci.sueddeutsche.de/api/customizing-service/v1/swmh/tenant/admin/tenantAdmin',
              title: 'Keyuser verwalten',
            },
            ADMIN_USERS: {
              href: 'https://abo-ci.sueddeutsche.de/api/customizing-service/v1/swmh/tenant/admin/user',
              title: 'Benutzer verwalten',
            },
            CONFIGURE_SALES_CAMPAIGNS: {
              href: 'https://abo-ci.sueddeutsche.de/api/customizing-service/v1/swmh/tenant',
              title: 'Werbeaktionen pflegen',
            },
          },
          logoutUrl: 'https://www.google.de',
        });
      }, 500);
    });
  }

  getAdminUserData(url: string): Promise<AdminUserData> {
    console.log('Getting Admin User Data from url ' + url);
    return new Promise<AdminUserData>((resolve) => {
      setTimeout(() => {
        resolve({
          _embedded: {
            users: [
              {
                login: 'linda_luebbich@sztest.de',
                _links: {
                  DELETE: {
                    href: 'https://abo-ci.sueddeutsche.de/api/customizing-service/v1/swmh/tenant/admin/user',
                    name: 'delete tenant user',
                  },
                },
              },
              {
                login: 'osc-test-user-sz@sztest.de',
                _links: {
                  DELETE: {
                    href: 'https://abo-ci.sueddeutsche.de/api/customizing-service/v1/swmh/tenant/admin/user',
                    name: 'delete tenant user',
                  },
                },
              },
            ],
          },
          _links: {
            POST_NEW: {
              href: 'https://abo-ci.sueddeutsche.de/api/customizing-service/v1/swmh/tenant/admin/user',
              name: 'create new tenant user',
            },
          },
        });
      });
    });
  }

  addNewUser(url: string, newUserMail: string): Promise<string> {
    console.log('Adding new user with mail ' + newUserMail + ' to url ' + url);
    return new Promise<string>((resolve) => {
      setTimeout(() => {
        resolve(newUserMail);
      });
    });
  }

  deleteUser(url: string, userMail: string): Promise<void> {
    console.log('Deleting user with mail ' + userMail + ' from url ' + url);
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      });
    });
  }

  getSalesCampaignsForTenant(): Promise<BasicSalesCampaignData[]> {
    console.log('Getting sales campaigns for tenant');
    return new Promise<BasicSalesCampaignData[]>((resolve) => {
      setTimeout(() => {
        resolve(stubDataAllSalesCampaigns);
      });
    });
  }

  getSalesCampaignById(
    salesCampaignId: string,
  ): Promise<SingleSalesCampaignResponseData> {
    console.log('Getting sales campaign for tenant');
    console.log('Sales campaign id: ' + salesCampaignId);

    let salesCampaign: SingleSalesCampaignResponseData;

    switch (salesCampaignId.toLowerCase()) {
      case 'lwl':
        salesCampaign = adminSingleSalesCampaigns.lwl;
        break;
      case 'dszbk':
        salesCampaign = adminSingleSalesCampaigns.dszbk;
        break;
      case 'lan4hp':
        salesCampaign = adminSingleSalesCampaigns.lan4hp;
        break;
      default:
        salesCampaign = adminSingleSalesCampaigns.fes;
    }

    return new Promise<SingleSalesCampaignResponseData>((resolve) => {
      setTimeout(() => {
        resolve(salesCampaign);
      });
    });
  }

  saveSingleSalesCampaign(
    salesCampaignData: SingleSalesCampaignData | SalesCampaignDetailsData,
  ): Promise<void> {
    console.log('Saving sales campaign for tenant');
    console.log('Sales campaign data: ', salesCampaignData);

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      });
    });
  }

  saveApprovedSalesCampaigns(
    selectedTenant: string,
    selectedSalesCampaigns: string[],
  ): Promise<void> {
    console.log('Saving approved sales campaigns for tenant');
    console.log('Selected tenant: ' + selectedTenant);
    console.log('Selected sales campaigns: ', selectedSalesCampaigns);

    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      });
    });
  }
  getSalesCampaignsApprovalList(
    selectedTenant: string,
  ): Promise<AdminSalesCampaignApprovalListData> {
    console.log('Getting sales campaigns approval list for tenant');
    console.log('Selected tenant: ' + selectedTenant);

    return new Promise<AdminSalesCampaignApprovalListData>((resolve) => {
      setTimeout(() => {
        resolve({
          _embedded: {
            availableSalesCampaigns: [
              {
                active: true,
                approved: false,
                name: 'LWL',
                validFrom: '2020-01-01',
                validUntil: '2020-12-31',
                id: 'lwl',
              },
              {
                active: false,
                approved: false,
                name: 'LWL32',
                validFrom: '2020-01-01',
                validUntil: '2020-12-31',
                id: 'lwl32',
              },
              {
                active: true,
                approved: true,
                name: 'FES',
                validFrom: '2020-01-01',
                validUntil: '2020-12-31',
                id: 'fes',
              },
            ],
          },
        });
      }, 500);
    });
  }
}

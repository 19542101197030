import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "mb-4 d-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonAuth = _resolveComponent("ButtonAuth")!

  return (_ctx.showComponent)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.commonTexts.userInterface.loginInterface.title), 1),
        _createVNode(_component_ButtonAuth, {
          type: _ctx.AuthButtonType.LOGIN,
          transparent: "",
          link: _ctx.loginUrl
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.commonTexts.userInterface.loginInterface.buttonLabel), 1)
          ]),
          _: 1
        }, 8, ["type", "link"])
      ]))
    : _createCommentVNode("", true)
}
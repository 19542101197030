import { ConfigGetterTypes, ConfigState } from '@/store/modules/config/types';
import { GetterTree } from 'vuex';
import { AppRootState } from '@/store/interfaces';

const getters: GetterTree<ConfigState, AppRootState> = {
  [ConfigGetterTypes.GET_CONFIG](state: ConfigState) {
    return state.config;
  },
};

export default getters;

<template>
  <div class="icon-tilde" :class="{ 'icon-tilde--active': active }">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="7"
      viewBox="0 0 14 7"
    >
      <g fill="none" fill-rule="evenodd" opacity="1">
        <g class="icon-tilde__graph">
          <path
            class="icon-tilde__path"
            d="M1.243 7L0 5.936 6.998.003 6.999 0 7 .001 7.001 0v.003L14 5.936 12.757 7 7 2.096 1.243 7z"
            transform="translate(-884.000000, -781.000000) translate(835.000000, 569.000000) translate(20.000000, 20.000000) translate(29.000000, 182.000000) translate(7.000000, 13.500000) rotate(-180.000000) translate(-7.000000, -13.500000) translate(0.000000, 10.000000)"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'IconTilde',
    props: {
      active: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  });
</script>

<template>
  <div class="icon-image-placeholder">
    <div class="icon-image-placeholder__inner">
      <div class="icon-image-placeholder__label">
        <span>{{ label }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'IconImagePlaceholder',
    props: {
      label: {
        type: String,
        required: false,
        default: 'Bild',
      },
    },
  });
</script>

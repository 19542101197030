import {
  BasicOrderBonus,
  ComplexOrderBonusProperty,
  ComplexOrderBonusVariant,
  InternalComplexOrderBonus,
  InternalComplexOrderBonusProperty,
  OrderBonus,
} from '@/types/OrderBonusesTypes';
import store from '@/store';
import { StoreModules } from '@/store/types';
import { AdminOrderBonusesGetterTypes } from '@/store/modules/adminOrderBonuses/types';

export function isComplexOrderBonus(orderBonus: OrderBonus | BasicOrderBonus) {
  return orderBonus.materialNumber.startsWith('K');
}

export function parseInternalComplexOrderBonusPropertiesToComplexOrderBonusProperties(
  inputProperties: InternalComplexOrderBonusProperty[],
): ComplexOrderBonusProperty[] {
  return inputProperties.map((property) => {
    const values = property.values.map((value) => value.value);
    return {
      name: property.name,
      values: values,
    };
  });
}

export function chosenConfigurationExistsForComplexOrderBonus(
  configuration: string[],
  orderBonus: OrderBonus,
) {
  // If configuration is incomplete: return false
  if (configuration.some((value) => value === '')) return false;

  if (orderBonus.variants) {
    return orderBonus.variants.some((variant) => {
      switch (configuration.length) {
        case 1:
          return variant.propertyValues[0].value === configuration[0];
        case 2:
          return (
            variant.propertyValues[0].value === configuration[0] &&
            variant.propertyValues[1].value === configuration[1]
          );
        default:
          return (
            variant.propertyValues[0].value === configuration[0] &&
            variant.propertyValues[1].value === configuration[1] &&
            variant.propertyValues[2].value === configuration[2]
          );
      }
    });
  } else {
    return false;
  }
}

export function getVariantForChosenConfiguration(
  configuration: string[],
  orderBonus: OrderBonus,
) {
  // If configuration is incomplete: return false
  if (configuration.some((value) => value === '')) return undefined;

  if (orderBonus.variants) {
    return orderBonus.variants.find((variant) => {
      switch (configuration.length) {
        case 1:
          return variant.propertyValues[0].value === configuration[0];
        case 2:
          return (
            variant.propertyValues[0].value === configuration[0] &&
            variant.propertyValues[1].value === configuration[1]
          );
        default:
          return (
            variant.propertyValues[0].value === configuration[0] &&
            variant.propertyValues[1].value === configuration[1] &&
            variant.propertyValues[2].value === configuration[2]
          );
      }
    });
  } else {
    return undefined;
  }
}

export function createOrderBonusFromVariantConfiguration(
  complexOrderBonus: OrderBonus,
  configuration: string[],
): OrderBonus | undefined {
  const variant = getVariantForChosenConfiguration(
    configuration,
    complexOrderBonus,
  );

  if (variant) {
    return {
      active: true,
      additionalCharges: variant.additionalCharges
        ? variant.additionalCharges
        : [],
      cashBonus: complexOrderBonus.cashBonus,
      classification: complexOrderBonus.classification,
      imagePath: complexOrderBonus.imagePath,
      infoPoints: complexOrderBonus.infoPoints,
      label: variant.title,
      link: complexOrderBonus.link,
      linkLabel: complexOrderBonus.linkLabel,
      materialNumber: variant.materialNumber,
      text: complexOrderBonus.text,
      textLabel: complexOrderBonus.textLabel,
      title: variant.title,
      validFrom: complexOrderBonus.validFrom,
    };
  } else {
    return undefined;
  }
}

export function getAvailableActiveOrderBonusesFromStore(
  orderBonus: OrderBonus | InternalComplexOrderBonus,
) {
  const variants = orderBonus.variants ? orderBonus.variants : [];
  return store.getters[
    StoreModules.ADMIN_ORDER_BONUSES_MODULE +
      AdminOrderBonusesGetterTypes.GET_BONUSES_COLLECTION
  ].filter((basicOrderBonus: BasicOrderBonus) => {
    return (
      // orderBonus must be marked as active
      basicOrderBonus.active &&
      // orderBonus must not be a complex order bonus
      !isComplexOrderBonus(basicOrderBonus) &&
      !variants.some(
        (variant: ComplexOrderBonusVariant) =>
          variant.materialNumber === basicOrderBonus.materialNumber,
      )
    );
  });
}

export function addVariantsByMaterialNumberToOrderBonus(
  selectedVariants: string[],
  orderBonus: OrderBonus | InternalComplexOrderBonus,
  availableOrderBonuses: BasicOrderBonus[],
) {
  if (!orderBonus.variants) {
    orderBonus.variants = [];
  }

  for (const materialNumber of selectedVariants) {
    const availableOrderBonus = availableOrderBonuses.find(
      (availableOrderBonus: BasicOrderBonus) =>
        availableOrderBonus.materialNumber === materialNumber,
    );

    if (
      availableOrderBonus &&
      !orderBonus.variants.some(
        (orderBonusVariant: ComplexOrderBonusVariant) =>
          orderBonusVariant.materialNumber === materialNumber,
      )
    ) {
      const variant = {
        materialNumber: availableOrderBonus.materialNumber,
        title: availableOrderBonus.label ? availableOrderBonus.label : '',
        propertyValues: [],
      };
      orderBonus.variants.push(variant);
    }
  }
}

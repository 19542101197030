import { ConfigState } from '@/store/modules/config/types';

const state: ConfigState = {
  config: {
    urls: {
      loginUrl: '',
      logoutUrl: '',
      registerUrl: '',
    },
  },
};

export default state;

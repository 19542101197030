import {
  ImageUploadInterface,
  ImageUploadOptions,
} from '@/services/connectors/interfaces';
import {
  ImageUploadResponse,
  ImageUploadResponseMessage,
} from '@/types/ImageUploadTypes';

export class StudentEnrollmentUploadConnectorStub
  implements ImageUploadInterface
{
  uploadImage(
    image: File,
    options: ImageUploadOptions,
  ): Promise<ImageUploadResponse> {
    console.log('Stubbed image upload (StudentEnrollmentUploadConnectorStub)');
    console.log('image: ', image);
    console.log('options: ', options);
    return new Promise<ImageUploadResponse>((resolve) => {
      setTimeout(() => {
        resolve({
          message: ImageUploadResponseMessage.SUCCESS,
          path: 'https://www.google.com',
          status: 200,
          timestamp: '2021-06-22T12:00:00.000Z',
          id: '1234567890',
        });
      }, 1000);
    });
  }
}

import { PaymentConnectorInterface } from '@/services/connectors/interfaces';
import { AxiosResponse } from 'axios';
import {
  BasicCheckRequestData,
  PaymentUrlProcessResponse,
} from '@/types/PaymentTypes';
import PaymentMethodLiteral from '@/utils/paymentMethodLiteral';
import { FormDataToken } from '@/models/BaseTypes';
import {
  CreditCardData,
  CreditCardIFrameData,
  CreditCardPaymentInitRequestData,
  PayPalData,
  PayPalPaymentInitRequestData,
} from '@/models/PaymentServiceRequest';

export class PaymentConnectorStub implements PaymentConnectorInterface {
  checkIbanSwift(iban: string, swift: string): Promise<AxiosResponse> {
    console.log('Checking IBAN Data:');
    console.dir({ iban, swift });

    const result = {
      checkFailed: false,
      knownBySAP: false,
      bankName: 'Musterbank im Park',
      ibanError: false,
    };

    if (iban.toLowerCase().includes('fail')) {
      result.checkFailed = true;
    }
    if (iban.toLowerCase().includes('error')) {
      result.ibanError = true;
    }
    return new Promise<AxiosResponse>((resolve) => {
      setTimeout(() => {
        resolve({ data: result } as AxiosResponse);
      }, 500);
    });
  }

  getBillingFrequencies(
    salesCampaignId: string,
    paymentMethod: PaymentMethodLiteral,
  ): Promise<AxiosResponse> {
    console.log('Fetching stubbed billing frequencies:');
    console.dir({ salesCampaignId, paymentMethod });
    return new Promise<AxiosResponse>((resolve) => {
      setTimeout(() => {
        resolve({
          data: [
            {
              code: '01',
              name: 'Jährlich',
              discountDomestic: -4,
              discountAbroad: -4,
            },
            {
              code: '02',
              name: 'Halbjährlich',
              discountDomestic: -2,
              discountAbroad: -2,
            },
            {
              code: '04',
              name: 'Vierteljährlich',
              discountDomestic: 0,
              discountAbroad: 0,
            },
            {
              code: '12',
              name: 'Monatlich',
              discountDomestic: 0,
              discountAbroad: 0,
            },
          ],
        } as AxiosResponse);
      }, 500);
    });
  }

  getCreditCardIFrameUrl(
    data: BasicCheckRequestData,
    formDataToken: FormDataToken | null,
  ): Promise<PaymentUrlProcessResponse> {
    console.log('Fetching stubbed iFrame URL:');
    console.dir(formDataToken);
    console.dir(data);

    return new Promise<PaymentUrlProcessResponse>((resolve) => {
      setTimeout(() => {
        resolve({
          absUrl:
            // 'https://securepay-ci.swmh.de/view/v1/oscszp1/cc/check/3eabc09a-3a23-4edb-8775-1c7d538013b5/AQAAAQb6-vJ8YQ5F8dt1uwOLUh7ffO5lIPYNROoimlnSBpsc-4D_x0IyRcTMql3xTM0Xv1ga4zs5Fx5TOLO-ETdZmVfZwvRBSzwBpKVXvYRSbvBQ9aXUj2Xx-KrniwhN0x6zTvtjBlC0rD1yeWc=',
            'https://stubbed-iframe-url.com',
          // processId: '3eabc09a-3a23-4edb-8775-1c7d538013b5',
          processId: 'stubbed-process-id',
        });
      }, 500);
    });
  }

  getPayPalAuthorizationUrl(
    data: PayPalPaymentInitRequestData,
    formDataToken: FormDataToken | null,
  ): Promise<PaymentUrlProcessResponse> {
    console.log('Get stubbed PayPal Authorization Request URL for data:');
    console.dir(data);
    console.dir(formDataToken);

    return new Promise<PaymentUrlProcessResponse>((resolve) => {
      setTimeout(() => {
        resolve({
          absUrl: `https://www.google.de?successUrl=${data.returnUrls.successUrl}&st=testst&backUrl=${data.returnUrls.backUrl}&errorUrl=${data.returnUrls.errorUrl}`,
          processId: '1234567',
        });
      }, 500);
    });
  }
  getPaymentResult(
    processId: string,
    serviceTicket: string,
    uri: string,
    formDataToken: FormDataToken | null,
  ): Promise<CreditCardData | PayPalData> {
    console.log('Get stubbed payment result for data:');
    console.dir({ processId, serviceTicket, uri, formDataToken });

    let response: CreditCardData | PayPalData;
    switch (uri) {
      case 'paypal/billingagreement/result':
        response = {
          billingData: {
            userId: 'testUserId',
          },
        };
        break;
      case 'creditcard/oneoffpayment/result':
        response = {
          pseudocardpan: '1234567890',
          truncatedcardpan: '1234*********7890',
          cardtype: 'VISA',
          cardexpiredate: '12/2020',
          paymentData: {
            txId: 'testTxId',
          },
        };
        break;
      default:
        response = {
          pseudocardpan: '1234567890',
          truncatedcardpan: '1234*********7890',
          cardtype: 'VISA',
          cardexpiredate: '12/2020',
        };
    }

    return new Promise<CreditCardData | PayPalData>((resolve) => {
      setTimeout(() => {
        resolve(response);
      }, 500);
    });
  }

  getCreditCardAuthorizationUrl(
    data: CreditCardPaymentInitRequestData,
    formDataToken: FormDataToken | null,
  ): Promise<PaymentUrlProcessResponse> {
    console.log('Get stubbed credit card authorization URL for data:');
    console.dir(data);
    console.dir(formDataToken);

    return new Promise<PaymentUrlProcessResponse>((resolve) => {
      setTimeout(() => {
        resolve({
          absUrl: `https://www.google.de?successUrl=${data.returnUrls.successUrl}&st=testst&backUrl=${data.returnUrls.backUrl}&errorUrl=${data.returnUrls.errorUrl}`,
          processId: '1234567',
        });
      }, 500);
    });
  }
  getCreditCardIframeData(
    processId: string,
    serviceTicket: string,
    formDataToken: FormDataToken | null,
  ): Promise<CreditCardIFrameData> {
    console.log('Get stubbed credit card iFrame data for data:');
    console.dir({ processId, serviceTicket, formDataToken });

    return new Promise<CreditCardIFrameData>((resolve) => {
      setTimeout(() => {
        resolve({
          cardData: {
            pseudocardpan: '1234567890',
            truncatedcardpan: '1234XXXXXXXXX7890',
            cardtype: 'V',
            cardexpiredate: '3012',
          },
          processId: '2d68f688-6a13-4782-bee0-52e000c91d3e',
        });
      }, 500);
    });
  }

  getCreditCardOneOffPaymentAuthorizationUrl(
    data: CreditCardPaymentInitRequestData,
    formDataToken: FormDataToken | null,
  ): Promise<PaymentUrlProcessResponse> {
    console.log(
      'Get stubbed credit card one-off payment authorization URL for data:',
    );
    console.dir(data);
    console.dir(formDataToken);

    return new Promise<PaymentUrlProcessResponse>((resolve) => {
      setTimeout(() => {
        resolve({
          absUrl: `https://www.google.de?successUrl=${data.returnUrls.successUrl}&st=testst&backUrl=${data.returnUrls.backUrl}&errorUrl=${data.returnUrls.errorUrl}`,
          processId: '1234567',
        });
      }, 500);
    });
  }
}

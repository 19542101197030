import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "complex-order-bonus-configuration-selection-form" }
const _hoisted_2 = { class: "complex-order-bonus-configuration-selection-form__headline" }
const _hoisted_3 = { class: "row complex-order-bonus-configuration-selection-form__configuration-fields" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementSelectField = _resolveComponent("FormElementSelectField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.headline), 1),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.configurationSelectionOptions, (configurationOption, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "col-md-6 complex-order-bonus-configuration-selection-form__configuration-field"
        }, [
          _createVNode(_component_FormElementSelectField, {
            "select-options": configurationOption.values,
            label: configurationOption.name,
            value: _ctx.chosenOptions[index],
            disabled: _ctx.disabled,
            onInput: ($event: any) => (_ctx.updateChosenOptions(index, $event))
          }, null, 8, ["select-options", "label", "value", "disabled", "onInput"])
        ]))
      }), 128))
    ])
  ]))
}
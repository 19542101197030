import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "order-start" }
const _hoisted_2 = { class: "forms-container" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }
const _hoisted_8 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderBonus = _resolveComponent("OrderBonus")!
  const _component_OrderUserRoleSelection = _resolveComponent("OrderUserRoleSelection")!
  const _component_OrderRecruiter = _resolveComponent("OrderRecruiter")!
  const _component_OrderDigital = _resolveComponent("OrderDigital")!
  const _component_OrderPrint = _resolveComponent("OrderPrint")!
  const _component_OrderStudents = _resolveComponent("OrderStudents")!
  const _component_TheOverlay = _resolveComponent("TheOverlay")!
  const _component_OrderDetails = _resolveComponent("OrderDetails")!
  const _component_SideArea = _resolveComponent("SideArea")!
  const _component_OrderBonusShoppingCartForm = _resolveComponent("OrderBonusShoppingCartForm")!
  const _component_ThePageContent = _resolveComponent("ThePageContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ThePageContent, {
      title: _ctx.content.start.headline1,
      isFoldable: _ctx.isDigitalSalesCampaign && !_ctx.showStudentOrderView
    }, {
      "main-content-area": _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TheOverlay, {
            isFullPage: false,
            show: !_ctx.formDataLoaded
          }, {
            default: _withCtx(() => [
              (_ctx.showOrderBonusView)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_OrderBonus, {
                      "form-data": _ctx.formData,
                      "bonuses-loaded": _ctx.preloadFormData,
                      "order-bonus-cart": _ctx.orderBonusCart,
                      "order-bonus-cart-is-full": _ctx.shoppingCartIsFilledCorrectly,
                      onFormPrefilled: _ctx.setFormPrefilled,
                      onConfirmOrderBonusSelection: _ctx.confirmOrderBonusSelection,
                      onRemoveItemFromCart: _cache[0] || (_cache[0] = ($event: any) => (_ctx.removeSelectedBonusByBonus($event)))
                    }, null, 8, ["form-data", "bonuses-loaded", "order-bonus-cart", "order-bonus-cart-is-full", "onFormPrefilled", "onConfirmOrderBonusSelection"])
                  ]))
                : (_ctx.requestUserRoleSelection)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_OrderUserRoleSelection, {
                        "prefill-form-data": _ctx.preloadFormData,
                        "subscription-type": _ctx.subscriptionType,
                        onFormPrefilled: _ctx.setFormPrefilled
                      }, null, 8, ["prefill-form-data", "subscription-type", "onFormPrefilled"])
                    ]))
                  : (
                _ctx.requestRecruiterAddress && !_ctx.recruiterFormDependencySolved
              )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_OrderRecruiter, {
                          "prefill-form-data": _ctx.preloadFormData,
                          "subscription-type": _ctx.subscriptionType,
                          onFormPrefilled: _ctx.setFormPrefilled,
                          "request-miles-and-more-id": 
                  _ctx.requestMilesAndMoreId && _ctx.hasLwlOrGift
                ,
                          "request-iban": _ctx.requestIbanForCashBonus,
                          "iban-data": _ctx.ibanDataCashBonus,
                          onRecruiterAddressesSaved: _ctx.showOrderForm,
                          onUpdateMilesAndMoreId: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateMilesAndMoreId($event))),
                          onUpdateIban: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateIban($event)))
                        }, null, 8, ["prefill-form-data", "subscription-type", "onFormPrefilled", "request-miles-and-more-id", "request-iban", "iban-data", "onRecruiterAddressesSaved"])
                      ]))
                    : (_ctx.isDigitalSalesCampaign && !_ctx.showStudentOrderView)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_OrderDigital, {
                            onReloadPage: _ctx.reloadPage,
                            onFormPrefilled: _ctx.setFormPrefilled,
                            "prefill-form-data": _ctx.preloadFormData,
                            "request-miles-and-more-id": 
                  _ctx.requestMilesAndMoreId && !_ctx.hasLwlOrGift
                ,
                            "is-follow-up-form": _ctx.isMultipleAddressesSubscription,
                            onUpdateMilesAndMoreId: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateMilesAndMoreId($event)))
                          }, null, 8, ["onReloadPage", "onFormPrefilled", "prefill-form-data", "request-miles-and-more-id", "is-follow-up-form"])
                        ]))
                      : (!_ctx.isDigitalSalesCampaign && !_ctx.showStudentOrderView)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_OrderPrint, {
                              onFormPrefilled: _ctx.setFormPrefilled,
                              "prefill-form-data": _ctx.preloadFormData,
                              "request-miles-and-more-id": 
                  _ctx.requestMilesAndMoreId && !_ctx.hasLwlOrGift
                ,
                              "enable-login-interface": !_ctx.isMultipleAddressesSubscription,
                              "subscription-type": _ctx.subscriptionType,
                              "is-follow-up-form": _ctx.isMultipleAddressesSubscription,
                              onUpdateMilesAndMoreId: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateMilesAndMoreId($event)))
                            }, null, 8, ["onFormPrefilled", "prefill-form-data", "request-miles-and-more-id", "enable-login-interface", "subscription-type", "is-follow-up-form"])
                          ]))
                        : (_ctx.showStudentOrderView)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                              _createVNode(_component_OrderStudents, {
                                "prefill-form-data": _ctx.preloadFormData,
                                onFormPrefilled: _ctx.setFormPrefilled
                              }, null, 8, ["prefill-form-data", "onFormPrefilled"])
                            ]))
                          : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["show"])
        ])
      ]),
      "side-content-area": _withCtx(() => [
        _createVNode(_component_SideArea, null, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_OrderDetails, {
                "hide-order-bonus-cart": !_ctx.orderBonusSelected
              }, null, 8, ["hide-order-bonus-cart"])
            ])
          ]),
          _: 1
        }),
        (_ctx.showOrderBonusView)
          ? (_openBlock(), _createBlock(_component_SideArea, { key: 0 }, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _createVNode(_component_OrderBonusShoppingCartForm, {
                    "order-bonus-selected": _ctx.orderBonusSelected,
                    "selected-order-bonuses": _ctx.orderBonusCart,
                    "order-bonus-cart-is-full": _ctx.shoppingCartIsFilledCorrectly,
                    onRemoveItemFromCart: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removeSelectedBonus($event))),
                    onConfirmOrderBonusSelection: _ctx.confirmOrderBonusSelection
                  }, null, 8, ["order-bonus-selected", "selected-order-bonuses", "order-bonus-cart-is-full", "onConfirmOrderBonusSelection"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["title", "isFoldable"])
  ]))
}
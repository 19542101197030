import { AuthConnectorInterface } from '@/services/connectors/interfaces';
import { authAPI } from '@/services/http-service';
import router from '@/router';
import {
  AuthScreen,
  GpConnectionResult,
  LoginStateResponseData,
  UserConnectRequestData,
} from '@/types/AuthTypes';

export default class AuthConnector implements AuthConnectorInterface {
  getLoginState(
    redirectPath: string,
    loginRequired: boolean,
    authScreen?: AuthScreen,
  ): Promise<LoginStateResponseData> {
    return (
      authAPI
        .get('isLoggedIn/' + redirectPath, {
          params: { loginRequired, authScreen },
        })
        // } + '?loginRequired=' + loginRequired)
        .then((response) => response.data)
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            router.push({
              name: 'ErrorPage',
              params: { type: 'server' },
            });
          } else {
            console.log(error);
          }
          Promise.reject();
        })
    );
  }

  connectUser(
    connectUserObj: UserConnectRequestData,
  ): Promise<GpConnectionResult> {
    return authAPI
      .post('gp-service/v1/connectUser', JSON.stringify(connectUserObj), {
        headers: { 'Content-Type': 'application/json' },
      })
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data);
          router.push({
            name: 'ErrorPage',
            params: { type: 'server' },
          });
        } else {
          console.log(error);
        }
        Promise.reject();
      });
  }
  async getLogoutUrl(): Promise<string> {
    return await authAPI
      .get('logout')
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

<template>
  <div class="form-element-file-upload">
    <div
      class="form-element-file-upload__input"
      @dragover="onDragOver"
      @dragleave="onDragLeave"
      @drop="onDrop"
    >
      <input
        type="file"
        :multiple="multiple"
        :id="id"
        ref="fileUpload"
        class="form-element-file-upload__input-field"
        :accept="validFileFormats"
        @change="onChange"
      />
      <label
        v-if="!uploadSuccess"
        :for="id"
        class="form-element-file-upload__input-label"
        :class="{
          'form-element-file-upload__input-label--active': dropAreaActive,
          'form-element-file-upload__input-label--error': fieldError,
        }"
      >
        <span class="form-element-file-upload__input-label-drop"
          ><IconDropFile class="form-element-file-upload__input-icon" />Drag &
          Drop oder</span
        >
        <span class="form-element-file-upload__input-label-finder"
          >Datei auswählen</span
        >
      </label>
      <label
        v-else
        :for="id"
        class="form-element-file-upload__input-label form-element-file-upload__input-label--success"
        :class="{
          'form-element-file-upload__input-label--active': dropAreaActive,
        }"
      >
        <span class="form-element-file-upload__input-label-success-icon">
          <IconSuccessCheck />
        </span>
        <span class="form-element-file-upload__input-label-success">{{
          message
        }}</span>
      </label>
    </div>
    <div class="form-element-file-upload__error" v-if="hasError && message">
      <MessageBox :content="message" />
    </div>
    <div class="form-element-file-upload__caption" v-html="caption"></div>
    <div
      class="form-element-file-upload__success"
      v-if="uploadSuccess || value"
    >
      <div
        v-if="showPreviewImageAfterUpload"
        class="form-element-file-upload__success-image"
      >
        <img :src="value" alt />
      </div>
      <div class="form-element-file-upload__success-name">
        {{ fileName }}
      </div>
      <div class="form-element-file-upload__success-delete">
        <span @click="removeImage"
          ><IconTrashCan class="form-element-file-upload__success-delete-icon"
        /></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, Ref, ref, watch } from 'vue';
  import MessageBox from '@/components/Alerts/MessageBox.vue';
  import IconSuccessCheck from '@/components/Icons/IconSuccessCheck.vue';
  import IconDropFile from '@/components/Icons/IconDropFile.vue';
  import IconTrashCan from '@/components/Icons/IconTrashCan.vue';

  export default defineComponent({
    name: 'FormElementFileUpload',
    components: { IconTrashCan, IconDropFile, IconSuccessCheck, MessageBox },
    props: {
      value: {
        type: String,
        required: true,
      },
      caption: {
        type: String,
        required: true,
      },
      id: {
        type: String,
        required: true,
      },
      multiple: {
        type: Boolean,
        required: false,
        default: false,
      },
      validFileFormats: {
        type: String,
        required: false,
        default: '.pdf,.jpg,.jpeg,.jpg,.png,.gif',
      },
      hasError: {
        type: Boolean,
        required: false,
        default: false,
      },
      message: {
        type: String,
        required: false,
      },
      uploadSuccess: {
        type: Boolean,
        required: false,
        default: false,
      },
      showPreviewImageAfterUpload: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
    emits: ['fileSelected', 'unsupportedFileTypeError', 'imageDeleted'],
    setup(props, { emit }) {
      const fileUpload: Ref<HTMLInputElement | null> = ref(null);

      const file: Ref<FileList | null> = ref(null);
      const filePath: Ref<string> = ref('');
      const dropAreaActive = ref(false);
      const fieldError = computed(() => {
        return props.hasError;
      });

      const fileName = computed(() => {
        const fileName = filePath.value.split('/');
        let result = '';
        if (fileName && fileName.length > 0) {
          const lastFileNameElement = fileName.pop();
          if (lastFileNameElement) {
            result = lastFileNameElement.split('?')[0];
          } else if (props.value) {
            result = props.value;
          }
        }
        return result;
      });

      const uploadFile = function (selectedFile: FileList) {
        emit('fileSelected', selectedFile);
      };

      watch(props, () => {
        filePath.value = props.value;
      });

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const onDragOver = function (event: any) {
        event.preventDefault();
        dropAreaActive.value = true;
      };

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const onDragLeave = function (event: any) {
        event.preventDefault();
        dropAreaActive.value = false;
      };

      const validateFileExtension = function (): boolean {
        let result = file.value != null;

        if (file.value) {
          const validExtensions = props.validFileFormats
            .replace(/\./g, '')
            .split(',');
          let index = 0;

          while (result && index < file.value.length) {
            const fileName = file.value[index]?.name;
            if (fileName) {
              const fileExtension = fileName.split('.').pop()?.toLowerCase();
              result = fileExtension
                ? validExtensions.includes(fileExtension)
                : false;
            } else {
              result = false;
            }
            // result = validExtensions.includes(
            //   file.value[index]?.name
            //     ?.split('.')
            //     ?.pop()
            //     ?.toLowerCase(),
            // );
            index++;
          }
        }

        return result;
      };

      const onChange = function () {
        file.value = fileUpload.value ? fileUpload.value.files : null;
        if (file.value && validateFileExtension()) {
          uploadFile(file.value);
        } else {
          file.value = null;
          if (fileUpload.value) {
            fileUpload.value.files = null;
          }
          emit('unsupportedFileTypeError');
        }
      };

      const onDrop = function (event: DragEvent) {
        event.preventDefault();
        dropAreaActive.value = false;
        if (fileUpload.value) {
          fileUpload.value.files = event.dataTransfer
            ? event.dataTransfer.files
            : null;
          onChange();
        }
      };

      const removeImage = function () {
        file.value = null;
        if (fileUpload.value) {
          fileUpload.value.files = null;
        }
        emit('imageDeleted');
      };

      return {
        file,
        dropAreaActive,
        onDragOver,
        onDragLeave,
        onDrop,
        onChange,
        fieldError,
        fileName,
        removeImage,
        fileUpload,
      };
    },
  });
</script>

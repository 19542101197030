import {
  ValidatorInterface,
  ViolationFields,
} from '@/validators/validatorTypes';
import { OrderFormData } from '@/types/OrderFormTypes';
import { FormBusinessAddress, FormDeviatingAddress } from '@/models/OrderData';

export class StreetAddressDataValidator implements ValidatorInterface {
  validate(
    target: OrderFormData | FormBusinessAddress | FormDeviatingAddress,
  ): ViolationFields[] {
    const violations: ViolationFields[] = [];

    if (!target.street || target.street.length <= 2) {
      violations.push(ViolationFields.STREET);
    }
    if (!target.streetNumber || target.streetNumber.length < 1) {
      violations.push(ViolationFields.STREET_NUMBER);
    }
    if (!target.zipCode || target.zipCode.length > 10) {
      violations.push(ViolationFields.ZIP_CODE);
    }
    if (!target.city || target.city.length <= 2) {
      violations.push(ViolationFields.CITY);
    }

    return violations;
  }
}

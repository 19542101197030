import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "name", "value", "checked", "onChange"]
const _hoisted_3 = {
  key: 0,
  class: "form-element-radio-button-group__label"
}
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["form-element-radio-button-group", {
      'form-element-radio-button-group--disabled': _ctx.disabled,
      'form-element-radio-button-group--stacked': _ctx.stacked,
    }])
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("label", {
        key: index,
        for: _ctx.getOptionIdForIndex(index),
        class: _normalizeClass(["form-element-radio-button-group__radio-button", {
        'form-element-radio-button-group__radio-button--checked':
          option.value.toString() === _ctx.value.toString(),
        'form-element-radio-button-group__radio-button--full-width': _ctx.fullWidth,
      }])
      }, [
        _createElementVNode("input", {
          id: _ctx.getOptionIdForIndex(index),
          class: "form-element-radio-button-group__input",
          type: "radio",
          name: _ctx.getOptionIdForIndex(index),
          value: option.value,
          checked: option.value.toString() === _ctx.value.toString(),
          onChange: ($event: any) => (_ctx.handleChange(index, $event))
        }, null, 40, _hoisted_2),
        (option.text && option.text.length > 0)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(option.text), 1))
          : _createCommentVNode("", true),
        (option.html && option.html.length > 0)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: "form-element-radio-button-group__label",
              innerHTML: option.html
            }, null, 8, _hoisted_4))
          : _createCommentVNode("", true)
      ], 10, _hoisted_1))
    }), 128))
  ], 2))
}
import store from '@/store';
import { computed } from 'vue';
import PrintProductTypeLiteral from '@/utils/printProductTypeLiteral';
import commonConfig from '@/data/commonConfig';

export function getPriceListFlags() {
  const showPriceList = computed(() => {
    // PriceList is to be displayed for digital campaigns always,
    //    for non-digital only with sap connection intact
    return (
      (store.state.formData.salesCampaign.printProductType !==
        PrintProductTypeLiteral.DIGITAL &&
        !store.state.formData.sapConnectionFailed) ||
      store.state.formData.salesCampaign.printProductType ===
        PrintProductTypeLiteral.DIGITAL
    );
  });

  const showTotal = computed(() => {
    // Total only is relevant for non-digital products
    //    (due to billingFrequency relation, that only exists for non-digital)
    // and is only to be displayed, if annualPrice (total) was sent
    return (
      store.state.formData.salesCampaign.printProductType !==
        PrintProductTypeLiteral.DIGITAL &&
      store.state.priceResult.annualPrice !== undefined &&
      store.state.priceResult.annualPrice !== null
    );
  });

  return { showPriceList, showTotal };
}

export function getGeneralStudentEnrollmentFunctionality() {
  const showUploadConfirmation = computed(() => {
    return (
      store.state.studentEnrollmentUpload.fileName !== '' &&
      store.state.studentEnrollmentUpload.fileId !== ''
    );
  });

  return { showUploadConfirmation };
}

export function getUserLocationFlags() {
  const userIsAbroad = computed(() => {
    const addresses = store.state.formData.addresses;
    if (addresses.deliveryAddress !== null) {
      return addresses.deliveryAddress.country !== commonConfig.domesticCountry;
    } else if (addresses.businessAddress !== null) {
      return addresses.businessAddress.country !== commonConfig.domesticCountry;
    } else if (addresses.privateAddress !== null) {
      return addresses.privateAddress.country !== commonConfig.domesticCountry;
    }
    return false;
  });

  return { userIsAbroad };
}

export function getUserIsPurchaser() {
  // user is purchaser if:
  // 1. GiftSubscription && user is NOT receiver
  // 2. LwL && user IS receiver
  // 3. 1 address subscription: always
  return (
    (store.state.formData.salesCampaign.giftSubscription &&
      !store.state.userIsReceiver) ||
    (store.state.formData.salesCampaign.lwl && store.state.userIsReceiver) ||
    (!store.state.formData.salesCampaign.giftSubscription &&
      !store.state.formData.salesCampaign.lwl)
  );
}

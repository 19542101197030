import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "region-select-label-1" }
const _hoisted_2 = { class: "region-select" }
const _hoisted_3 = { class: "region-select-label-note" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementSelectField = _resolveComponent("FormElementSelectField")!
  const _component_FormSection = _resolveComponent("FormSection")!
  const _component_FormRow = _resolveComponent("FormRow")!

  return (_openBlock(), _createBlock(_component_FormRow, { "additional-class": "mx-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_FormSection, { "additional-class": "mb-0" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.commonTexts.userInterface.formFieldLabels.regionSelect.label), 1),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_FormElementSelectField, {
              value: _ctx.district,
              selectOptions: _ctx.options,
              "field-has-error": _ctx.hasError,
              "field-id": "region",
              onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('input', $event)))
            }, null, 8, ["value", "selectOptions", "field-has-error"])
          ]),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.commonTexts.userInterface.formFieldLabels.regionSelect.note), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
import { ImageUploadInterface } from '@/services/connectors/interfaces';
import { ImageUploadResponse } from '@/types/ImageUploadTypes';
import { orderAPI } from '@/services/http-service';
import { AxiosResponse } from 'axios';
import store from '@/store';

export class StudentEnrollmentUploadConnector implements ImageUploadInterface {
  uploadImage(
    image: File,
    // options: ImageUploadOptions,
  ): Promise<ImageUploadResponse> {
    const formData = new FormData();
    formData.append('imageFile', image);

    return orderAPI
      .post('studentEnrollmentCertificate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'token': JSON.stringify(store.state.formDataToken),
        },
      })
      .then((response: AxiosResponse) => response.data);
  }
}

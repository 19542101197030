import { createApp } from 'vue';
import VueCookies from 'vue3-cookies';
import VueGtm from '@gtm-support/vue-gtm';
import SetupService from '@/services/setup-service';
import App from './App.vue';
import AppInternal from './AppInternal.vue';
import router from './router';
//import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import LoadScript from 'vue-plugin-load-script';
import './themes/app.scss';

// import VueCompositionAPI from 'vue';
import VueScreen from 'vue-screen';
import store from '@/store';

const app = window.location.href.includes('/internal')
  ? createApp(AppInternal)
  : createApp(App);

app.use(router);
app.use(store);

//app.use(BootstrapVue);
//app.use(IconsPlugin);
app.use(VueCookies);
app.use(LoadScript);

app.use(VueGtm, {
  id: SetupService.getGtmId(),
  enabled: true,
  loadScript: true,
  vueRouter: router,
  debug: false,
});

app.use(VueScreen, 'bootstrap');

app.mount('#app');

// Was removed in Vue 3:
// app.config.productionTip = false;

// if (window.location.href.includes('/internal')) {
// new Vue({
//   router,
//   store,
//   render: h => h(AppInternal),
// }).$mount('#app');
// } else {
// new Vue({
//   router,
//   store,
//   render: h => h(App),
// }).$mount('#app');
// }

import { ViolationFields } from '@/validators/validatorTypes';

export type BasicOrderBonus = {
  // [index: string]: string | boolean;
  // not editable
  materialNumber: string;
  label: string | null;
  // editable
  active: boolean;
  title: string;
  validFrom: string | null;
};

export type OrderBonus = BasicOrderBonus & {
  [index: string]:
    | string
    | boolean
    | string[]
    | OrderBonusAdditionsCharge[]
    | ComplexOrderBonusProperty[]
    | ComplexOrderBonusVariant[]
    | undefined
    | null;
  // not editable
  cashBonus: boolean;
  additionalCharges: OrderBonusAdditionsCharge[] | null;
  // editable
  textLabel: string;
  text: string;
  linkLabel: string;
  link: string;
  infoPoints: string[];
  imagePath: string;
  classification: OrderBonusClassification;
  properties?: ComplexOrderBonusProperty[] | null;
  variants?: ComplexOrderBonusVariant[] | null;
  invalid?: boolean;
  // additional
};

export type InternalComplexOrderBonus = BasicOrderBonus & {
  validFrom: string;
  cashBonus: boolean;
  additionalCharges: OrderBonusAdditionsCharge[];
  textLabel: string;
  text: string;
  linkLabel: string;
  link: string;
  infoPoints: string[];
  imagePath: string;
  classification: OrderBonusClassification;
  properties: InternalComplexOrderBonusProperty[];
  variants: ComplexOrderBonusVariant[];
};

export type SingleUserOrderBonus = {
  bonus: OrderBonus;
  fixedBonus: boolean;
  validUntil: string | null;
};

export type OrderBonusAdditionsCharge = {
  salesCampaignId: string;
  value: number;
};

export type SalesCampaignOrderBonus = {
  materialNumber: string;
  label: string | null;
  validFrom: string | null;
  title: string;
  active: boolean;
};

export enum OrderBonusClassification {
  CASH = 'CASH',
  MILES_AND_MORE = 'MILES_AND_MORE',
  NON_MONETARY = 'NON_MONETARY',
}

export type ComplexOrderBonusProperty = {
  name: string;
  values: string[];
};

export type ComplexOrderBonusVariant = {
  materialNumber: string;
  title: string;
  additionalCharges?: OrderBonusAdditionsCharge[] | null;
  propertyValues: SingleComplexOrderBonusProperty[];
  invalid?: boolean;
};

export type SingleComplexOrderBonusProperty = {
  name: string;
  value: string;
};

export type InternalComplexOrderBonusProperty = {
  name: string;
  values: { value: string }[];
};

export type ComplexOrderBonusValidationResult = {
  violations: ViolationFields[];
  violatingVariants: string[];
  violatingProperties: string[];
};

export enum ComplexOrderBonusParsingError {
  UNPROCESSABLE_VARIANT_CONFIGURATION = 'Unprocessable variant',
}

<template>
  <div class="navbar-progress">
    <span>
      {{ commonText.userFrontend.orderBonusSelectionView.orderProgress }}
    </span>

    <div class="dots" :data-step="step">
      <span v-for="i in steps" :key="i" class="dot"></span>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue';
  import commonTexts from '@/data/commonTexts';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'ProgressBar',
    props: {
      steps: {
        type: Number,
        required: true,
      },
    },
    setup() {
      const store = useStore();
      const commonText = commonTexts;
      const step = computed(() => store.state.orderProgress);

      return { commonText, step };
    },
  });
</script>

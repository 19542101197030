<template>
  <div class="icon-drop-file">
    <svg
      version="1.1"
      id="Ebene_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 20 24"
      style="enable-background: new 0 0 19 24"
      xml:space="preserve"
      width="20"
      height="24"
    >
      <path
        id="Shape"
        class="st0"
        d="M11.1,0H2.3l0,0C1,0,0,1.1,0,2.4l0,19.2C0,22.9,1,24,2.3,24h13.8c1.3,0,2.3-1.1,2.3-2.4v-14
    c0-0.3-0.1-0.5-0.3-0.7l-6.3-6.6C11.6,0.1,11.3,0,11.1,0z M15.8,22.4H2.6c-0.6,0-1-0.4-1-1V2.6c0-0.6,0.4-1,1-1h8.6l0,0V7
    c0,0.6,0.4,1,1,1h4.6l0,0v13.4C16.8,22,16.4,22.4,15.8,22.4z M6.2,14.8l1.1,1.1l1.2-1.3v4.9H10v-4.9l1.2,1.3l1.1-1.1l-3.1-3.2
    L6.2,14.8z"
      />
    </svg>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'IconDropFile',
  });
</script>

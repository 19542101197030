import OrderExists from '@/views/neubestellung/abo/OrderExists.vue';

const commonTexts = {
  userInterface: {
    buttons: {
      delete: 'Löschen',
      save: 'Speichern',
      submitSelectionLabel: 'Auswahl übernehmen',
      changeData: 'ändern',
      checkData: 'Daten prüfen',
      proceed: 'Weiter',
      deleteEntry: 'Eintrag löschen',
    },
    textlabels: {
      postOfficeBox: 'Postfach',
      floor: 'Stockwerk',
      room: 'Appartment',
      mailDelivery: 'Postversand',
    },
    formFieldLabels: {
      salutation: 'Anrede',
      title: 'Titel',
      firstName: 'Vorname',
      lastName: 'Nachname',
      country: 'Land',
      street: 'Straße',
      streetNumber: 'Nr.',
      zipCode: 'PLZ',
      city: 'Ort',
      postOfficeBox: 'Postfach',
      companyName: 'Firmenbezeichnung (laut HRG)',
      companySuffix1: 'Firmenzusatz 1',
      companySuffix2: 'Firmenzusatz 2',
      companySuffix3: 'Firmenzusatz 3',
      showAdditionalFieldsPersonalBusinessData: 'Firmenzusätze angeben',
      majorCustomerZipCode: 'Großkunden PLZ',
      majorCustomerCity: 'Großkunden Ort',
      addressAddition: 'Adressergänzung (z.B. c/o)',
      additionalInfoPrivateSimple: 'Adresszusatz',
      additionalInfoPrivate: 'Zustellbesonderheiten (z.B. Hintereingang)',
      additionalInfoBusiness: 'Zustellbesonderheiten (z.B. Hintereingang)',
      floor: 'Stockwerk',
      room: 'Zimmer, Apartment',
      showAddressAdditionFormPrivate:
        'Adressergänzungen (z.B. c/o), Stock, Zimmer, Apartment oder andere Zustellbesonderheiten (optional)',
      showAddressAdditionFormBusiness:
        'Großkunden PLZ/ Ort, Stock oder andere Zustellbesonderheiten',
      selectMailDelivery: 'Zustellung per Post',
      emailPhoneFormLabel:
        'Bitte geben Sie Ihre Telefonnummer und E-Mail an, unter der wir Sie bei Rückfragen gut erreichen können.',
      email: 'E-Mail',
      phone: 'Telefonnummer privat',
      businessPhone: 'Telefonnummer geschäftlich',
      mobilePhone: 'Telefonnummer mobil',
      deliveryAddressFormLabel: 'Abweichende Lieferadresse angeben',
      invoiceAddressFormLabel: 'Abweichende Rechnungsadresse angeben',
      phoneNumber: {
        formLabel: 'Telefonnummer',
        countryCode: 'Ländervorwahl',
        areaCode: 'Vorwahl',
        subscriberNumber: 'Telefonnummer',
      },
      iban: 'iban',
      swift: 'BIC/SWIFT (8-stellig + optional 3 für Filiale)',
      regionSelect: {
        label: 'Ihr Regionalteil:',
        note: 'Sie können Ihre Auswahl jederzeit ändern.',
      },
    },
    loginInterface: {
      title: 'Sind Sie schon Kunde?',
      buttonLabel: 'Login',
    },
    modals: {
      browserBackModal: {
        title: 'Vielen Dank! Ihre Bestellung ist erfolgreich abgeschlossen.',
        link2: {
          label: 'Zu Konto & Abo',
        },
      },
    },
    calendar: {
      nextMonth: 'nächster Monat',
      prevMonth: 'letzter Monat',
      // weekDays: {
      //   mon: 'Mo',
      //   tue: 'Di',
      //   wed: 'Mi',
      //   thu: 'Do',
      //   fri: 'Fr',
      //   sat: 'Sa',
      //   sun: 'So',
      // },
      // months: {
      //   jan: 'Januar',
      //   feb: 'Februar',
      //   mar: 'März',
      //   apr: 'April',
      //   may: 'Mai',
      //   jun: 'Juni',
      //   jul: 'Juli',
      //   aug: 'August',
      //   sep: 'September',
      //   oct: 'Oktober',
      //   nov: 'November',
      //   dec: 'Dezember',
      // }
    },
  },
  userFrontend: {
    orderUserSelectionView: {
      mainTitleGift:
        'Verschenken Sie ein Abo oder möchten Sie es selber nutzen?',
      mainTitleLwL:
        'Werben Sie einen neuen Abonnenten oder sind Sie der neue Bezieher? ',
      labelUserIsRecruitingGift: 'Ich verschenke ein Abo.',
      labelUserIsRecruitingLwL: 'Ich werbe einen neuen Abonnenten.',
      labelUserIsReceiverGift: 'Ich bin Empfänger eines Geschenk-Abos.',
      labelUserIsReceiverLwL: 'Ich bin der neue Bezieher.',
    },
    orderBonusSelectionView: {
      mainTitle: 'Prämienauswahl',
      mainText: 'Bitte wählen Sie eine Prämie aus.',
      confirmationText:
        'Fügen Sie eine Prämie zu Ihrer Bestellung hinzu um fortzufahren!',
      cartHeadline: 'Meine Prämien',
      detailPreviewPriceInfoLabel: 'Einmalzahlung',
      buttonLabelCartFull: 'Warenkorb ist voll',
      buttonLabelEnabled: 'Zum Warenkorb hinzufügen',
      buttonLabelRemoveItem: 'Aus Warenkorb entfernen',
      complexOrderBonusConfigurationSelectionTitle: 'Bitte auswählen:',
      orderProgress: 'Bestellfortschritt',
    },
    orderRecruiterView: {
      mainTitleGift: 'Schenkender',
      mainTitleLwL: 'Daten des Vermittlers',
      giftSubscriptionLimitSubHeader:
        'Bitte wählen Sie hier, für welchen Zeitraum Sie die Zeitung verschenken möchten. Das Abo endet nach dem Ablauf automatisch.',
    },
    orderForm: {
      mainTitleGift: 'Geschenkempfänger',
      mainTitleLwL: 'Daten des Abonnenten',
      subTitleModalHeadline:
        'Leider können wir Ihnen das gewählte Angebot nicht per Träger zustellen',
      subTitleChangeTenantButtonLabel: 'Angebot mit Trägerzustellung auswählen',
      subTitleCancelButtonLabel:
        'Ich möchte das Angebot per Postzustellung erhalten',
    },
    milesAndMore: {
      form: {
        headline: 'Miles & More Servicekartennummer',
        text: 'Bitte geben Sie Ihre Miles & More Servicekartennummer ein. Nur mit dieser Angabe ist eine Meilengutschrift möglich.',
        label: 'Servicekartennummer',
      },
      displayElement: {
        headline: 'Miles & More Servicekartennummer:',
      },
    },
    cashBonus: {
      displayElement: {
        headline: 'Bankverbindung für die Gutschrift der Barprämie:',
      },
      ibanDigits: 'IBAN endet auf:',
      swiftDigits: 'BIC endet auf:',
    },
    displayElements: {
      deliveryAddressHeadline: 'Abweichende Lieferadresse',
      invoiceAddressHeadline: 'Abweichende Rechnungsadresse',
      studentEnrollmentUpload: 'Upload der Studienbescheinigung ist erfolgt.',
      months: 'Monate',
      unlimited: 'unbefristet',
      deliveryPeriod: 'Lieferzeitraum:',
    },
    priceList: {
      totalLabel: 'Gesamtpreis',
      monthlyLabel: 'Preis monatl.',
      monthlyLabelMagazine: 'Preis pro Ausgabe',
      monthlyLabelFallback: 'Preis',
    },
    payment: {
      paymentSelectionLabelPaypal: 'Paypal',
      paymentSelectionLabelCreditCard: 'Kreditkarte',
      paymentSelectionLabelSepa: 'Lastschrift',
      paymentSelectionLabelInvoice: 'Rechnung',
      billingFrequencySelectionFormHeadline: 'Zahlungsrhythmus',
      billingFrequencySelectionFormAdditionalTextPrefix: ', <strong>',
      billingFrequencySelectionFormAdditionalTextSlug: '% sparen**</strong>',
      billingFrequencySelectionFormAdditionalTextCaption:
        '**gegenüber der monatlichen Zahlungsweise',
      creditCardTimeInvalid:
        'Die Gültigkeitsdauer Ihrer Kreditkarte reicht nicht für das von Ihnen gewählte Produkt.',
      creditCardDeclined:
        'Die Transaktion wurde von Ihrem Kartenaussteller verweigert. Überprüfen Sie ggf. Ihre eingegebenen Daten.',
    },
    studentEnrollmentForm: {
      headline: 'Studienbescheinigung',
      copy: 'Für das vergünstigte Studentenabo benötigen wir Ihre aktuelle Immatrikulationsbescheinigung.',
      uploadCaption:
        'Format: jpg, gif, png oder pdf<br />' +
        'Größe: max. DIN A4, Dateigröße 500 KB<br />' +
        'Auflösung: 72 dpi (max. 595 x 842 Pixel)',
      submitButtonLabel: 'Weiter zur Zahlmethode',
    },
    verificationView: {
      paymentSectionTitle: 'Zahlmethode:',
      addressDataDefault: 'Ihre Daten:',
      addressDataReceiverGift: 'Empfänger des Geschenkabos:',
      addressDataReceiverLwL: 'Abonnent:',
      addressDataRecruiterGift: 'Schenkender:',
      addressDataRecruiterLwL: 'Vermittler:',
      buttonCreateOrder: 'Zahlungspflichtig bestellen',
      emailDisclaimerText:
        'Sie erhalten automatisch eine Bestätigung Ihrer Bestellung an Ihre E-Mail-Adresse',
      orderDisclaimerText:
        'Es gelten die <a href="${TOS_LINK}" target="_blank" rel="noopener noreferrer">AGB</a>. Die Hinweise zum <a href="${REVOCATION_LINK}" target="_blank" rel="noopener noreferrer">Widerrufsrecht</a> und zum <a href="${PRIVACY_LINK}" target="_blank" rel="noopener noreferrer">Datenschutz</a> habe ich gelesen.',
    },
    successView: {
      paymentSectionTitle: 'Zahlmethode:',
      addressDataDefault: 'Ihre Daten',
      addressDataReceiverGift: 'Empfänger des Geschenkabos:',
      addressDataReceiverLwL: 'Abonnent:',
      addressDataRecruiterGift: 'Schenkender:',
      addressDataRecruiterLwL: 'Vermittler:',
      startDateTitle: 'Starttermin:',
      registrationIncentiveTitle: 'Jetzt registrieren und profitieren:',
      registrationIncentiveListItem1: 'Ihre Daten und Abos im Überblick',
      registrationIncentiveListItem2: 'Schnell und unkompliziert verwalten',
      registrationIncentiveListItem3: 'Urlaubs- und Nachsendeservice',
      registrationIncentiveListItem4: 'Exklusive Vorteile',
      registrationIncentiveButtonLabel: 'Jetzt registrieren',
      buttonToArticle: 'Jetzt Artikel lesen',
      buttonToStartPage: 'Zur Startseite',
      userLoggedInDisclaimer: 'Sie sind nun angemeldet als',
      contactsLabel: 'Haben Sie Fragen?',
      endRequest: {
        thanks: 'Vielen Dank!',
        order: 'Ihre Bestellung',
        is: 'ist',
        checkOrder: 'wird geprüft.',
        completed: 'abgeschlossen',
      },
    },
    paymentView: {
      invoiceFrameMessage:
        'Sie bekommen die Überweisungsinformation per Mail oder Brief mitgeteilt.',
      sepa: {
        isValid: 'Bitte prüfen Sie die eingegebenen Daten.',
        iban: 'IBAN',
        swift: 'BIC/SWIFT (8-stellig + optional 3 für Filiale)',
      },
      paypal: {
        note: 'Um die Bestellung abzuschließen, klicken Sie bitte auf die Schaltfläche Weiter mit Paypal und loggen Sie sich mit Ihrer E-Mail Adresse und Ihrem Passwort bei Paypal ein.',
        button: 'Weiter mit PayPal',
      },
    },
    subscriptionStartDateSelection: {
      startDateTitle: 'Starttermin:',
      deliveryStartTitle: 'Versandtermin:',
    },
    address: {
      connectError:
        'Wir konnten Sie online nicht mit dieser Kundennummer verknüpfen. Bitte bestellen Sie neu.',
      connectSuccess: 'Ihre Kundennummer wurde erfolgreich verknüpft.',
      addressChanged:
        'Ihre Eingabe wurde korrigiert, bitte prüfen Sie die Angaben.',
      addressInvalid: 'Ungültige Adresse, bitte prüfen Sie Ihre Angaben.',
      addressIgnore:
        'Sind Sie sicher, dass Ihre Adresseingaben korrekt sind - dann klicken Sie auf "Weiter".',
      formIncomplete: 'Bitte füllen Sie alle Pflichtfelder aus',
    },
    tracking: {
      categories: {
        checkout: 'Checkout',
      },
      subTitleChange: {
        showModal: 'Ausgabenwechsel overlay',
        abort: 'Ausgabenwechsel Abbruch',
        mailDelivery: 'Ausgabenwechsel weiter mit Postversand',
      },
    },
  },
  errorView: {
    OrderExists: {
      mainHeadline: 'Ihre Bestellung',
      generalMessage: 'Sie besitzen dieses Produkt bereits!',
    },
  },
  admin: {
    orderBonusesOverview: {
      createNewComplexBonusLabel: '+ Neue komplexe Zugabe hinzufügen',
    },
    orderBonusCustomizing: {
      imageUploadCaption:
        'Format: gif, png (Bitte einen transparenten Hintergrund wählen)<br />' +
        'Dateigröße: max. 500 KB  <br />' +
        'Auflösung: 72 dpi / H: 164 px / B: max. 200 px',
      imageUploadSuccess: 'Datei wurde akzeptiert',
      complexOrderBonuses: {
        creationPage: {
          headline: 'Neue komplexe Prämie erstellen',
        },
        orderBonusSelectionForm: {
          headline: 'Zugaben:',
          addMoreLabel: '+ Zugaben hinzufügen',
        },
        propertyForm: {
          headline: 'Anlage komplexe Prämien:',
          materialNumberFieldLabel: 'Codierung der komplexen Zugabe',
          materialNumberFieldCaption:
            'Bitte füge ein „K“ an den Anfang der Codierung!',
          subHeadlineProperties: 'Mögliche Eigenschaften',
          labelPropertyName: 'Eigenschaft',
          labelPropertyValues: 'Ausprägungen',
          labelAddMoreValues: '+ Ausprägung hinzufügen',
          labelAddMoreProperties: '+ Eigenschaft hinzufügen',
        },
      },
    },
    salesCampaignCustomizing: {
      salesCampaignSettings: {
        headline: 'Formular-Pflege',
        errorFormIncomplete: 'Bitte füllen Sie alle Pflichtfelder aus',
        labelValidFrom: 'Gültig von',
        labelValidUntil: 'Gültig bis',
        labelCountrySelection: 'Land',
        subTitlePaymentOptions: 'Zahlwege:',
        labelPaymentOptionPaypal: 'Paypal',
        labelPaymentOptionCreditCard: 'Kreditkarte',
        labelPaymentOptionSepa: 'SEPA',
        labelPaymentOptionInvoice: 'Rechnung',
        labelBillingFrequencySelection: 'Fakturaperiodizitätgruppe',
        captionBillingFrequencySelection:
          'Achtung! Fakturaperiodizität muss bei Normal-Positon gepflegt werden! Bei KPNO oder PPNO nicht.',
        defaultValueBillingFrequencySelection: {
          value: '00',
          text: 'Auswahl der Gruppe',
        },
        labelEmailMandatory: 'Email-Adressenpflicht',
        labelOrderCsv: 'Anlage per CSV',
        labelShowStartDateDefault: 'Startzeitpunkt anzeigen',
        labelShowStartDateMagazine: 'Versandtermin einblenden',
        subTitleAdvertOptIns: 'Werbeerlaubnis:',
        labelMailAdvertEnabled: 'E-mail',
        labelTelAdvertEnabled: 'Telefon',
        labelAllowOrderWithExistingSubscription:
          'Trotz vorhandenem Auftrag bestellen',
        labelAllowSchnorren: 'Schnorrer darf trotzdem bestellen',
        subTitleAddressSettings: 'Adressen',
        labelShowGPTypeSelection: 'Firmenkunde: Adresse anzeigen',
        labelOrderWithoutAddress: 'GP ohne Adresse möglich',
        labelShowDeliveryAddressCompany: 'Firmenkunde: WE-ADR zulässig',
        labelShowInvoiceAddressCompany: 'Firmenkunde: RE-ADR zulässig',
        subTitleStartDates: 'Fester Starttermin des Auftrags',
        labelDelayedCharging: 'Verzögerter Berechnungstermin',
        subscriptionTypeSelectionHeadline: 'Abo Art',
        subscriptionTypeStudents: 'Studentenabo',
        subscriptionTypeRecruit: 'LWL-Abo',
        subscriptionTypeGift: 'Geschenkabo',
        subscriptionTypeGiftTimeLimitSelectable: 'Auftragsbefristung',
        labelFixedStartDate: 'Gültig von',
        labelFixedEndDate: 'Gültig bis',
        labelFixedStartIssue: 'Heftnummer ab',
        labelFixedEndIssue: 'Heftnummer bis',
        labelPreventOrderWithExistingSubscription:
          'Bei vorhandenem Auftrag nicht bestellen',
      },
      orderBonusSelection: {
        formTitle: 'Zugaben:',
        addedBonusesTitle: 'Hinzugefügte Zugabe(n):',
        fieldLabelNumberOfBonuses: 'Anzahl der auszuwählenden Zugaben',
        noBonusesAvailablePlaceholder: 'Es stehen keine Zugaben zur Verfügung!',
        modalLinkLabel: '+ Zugaben hinzufügen',
        modalTitle: 'Zugaben auswählen:',
        modalAcceptButtonText: 'Auswahl übernehmen',
        errorBonusInvalid: 'Prämie(&N) XXX nicht mehr vorhanden',
      },
      salesCampaignTextEdit: {
        headline: 'Textpflege',
        description: 'Bezeichnung',
        infoPoint: 'Info Point',
        priceDescription: 'Preisbeschreibung',
        price: 'Preis',
        orderInfo: 'Angebotsinformation',
      },
    },
    userDetails: {
      userData: 'Benutzerdaten',
      documentation: {
        prefix: 'Zur',
        title: 'Dokumentation',
      },
      roles: {
        admin: 'Admins',
        Keyuser: 'Keyuser',
        user: 'Benutzer',
      },
      logout: 'Abmelden',
      management: 'Verwaltung',
      version: 'Aktuelle Releaseversion: Vs',
    },
    userManagement: {
      pageTitlePrefix: 'Benutzerpflege - ',
      subTitleExistingUsers: 'Berechtigte Benutzer',
      tenantSelectionLabel: '-- Mandanten auswählen --',
      subTitleNewUser: 'Neuer Benutzer',
      labelNewUserField: 'Registrierter Benutzer',
    },
  },
};

export default commonTexts;

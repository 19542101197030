<template>
  <ContentArea>
    <div>
      <div class="order-user-role-selection">
        <header>
          <h2 class="main-content-title">
            {{ mainTitle }}
          </h2>
        </header>
        <div class="order-user-role-selection__form">
          <FormElementRadioButtons
            :value="selectedUserRole"
            :radio-options="selectionOptions"
            @input="updateUserRole($event)"
            stacked
          />
        </div>
        <div class="order-user-role-selection__submit">
          <BasicButton
            variant="primary"
            type="submit"
            @buttonClicked="submitUserRole"
            class="main-content-button"
          >
            {{ commonTexts.userInterface.buttons.proceed }}</BasicButton
          >
        </div>
      </div>
    </div>
  </ContentArea>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, watch } from 'vue';
  import commonTexts from '@/data/commonTexts';
  import FormElementRadioButtons from '@/components/FormElements/FormElementRadioButtons.vue';
  import { UserRole } from '@/types/UserTypes';
  import { SubscriptionType } from '@/types/GeneralInterfaceTypes';
  import ContentArea from '@/components/PageLayout/ContentArea.vue';
  import { useStore } from 'vuex';
  import BasicButton from '@/components/Buttons/BasicButton.vue';

  export default defineComponent({
    name: 'OrderUserRoleSelection',
    components: { FormElementRadioButtons, ContentArea, BasicButton },
    props: {
      prefillFormData: {
        type: Boolean,
        required: true,
      },
      subscriptionType: {
        type: Number,
        required: true,
      },
    },
    emits: ['submittedForm', 'formPrefilled'],
    setup(props, { emit }) {
      const store = useStore();
      const selectedUserRole = ref(UserRole.USER_IS_RECRUITING);

      const selectionOptions = computed(() => {
        const textUserIsRecruiting =
          props.subscriptionType === SubscriptionType.GIFT_SUBSCRIPTION
            ? commonTexts.userFrontend.orderUserSelectionView
                .labelUserIsRecruitingGift
            : commonTexts.userFrontend.orderUserSelectionView
                .labelUserIsRecruitingLwL;
        const textUserIsReceiver =
          props.subscriptionType === SubscriptionType.GIFT_SUBSCRIPTION
            ? commonTexts.userFrontend.orderUserSelectionView
                .labelUserIsReceiverGift
            : commonTexts.userFrontend.orderUserSelectionView
                .labelUserIsReceiverLwL;
        return [
          {
            value: UserRole.USER_IS_RECRUITING,
            text: textUserIsRecruiting,
          },
          {
            value: UserRole.USER_IS_RECEIVER,
            text: textUserIsReceiver,
          },
        ];
      });

      const updateUserRole = function (newUserRole: UserRole) {
        selectedUserRole.value = newUserRole;
      };

      const submitUserRole = function () {
        store.dispatch('activateUserRoleSelected', selectedUserRole.value);
        emit('submittedForm');
      };

      const mainTitle = computed(() => {
        if (
          props.subscriptionType ===
          (SubscriptionType.GIFT_SUBSCRIPTION as unknown as number)
        ) {
          return commonTexts.userFrontend.orderUserSelectionView.mainTitleGift;
        } else if (
          props.subscriptionType ===
          (SubscriptionType.LWL_SUBSCRIPTION as unknown as number)
        ) {
          return commonTexts.userFrontend.orderUserSelectionView.mainTitleLwL;
        }
        return '';
      });

      watch(props, () => {
        if (props.prefillFormData) {
          // For gift subscriptions: Businesses only can bei recruiters, never receivers
          if (
            store.state.formData.salesCampaign.giftSubscription &&
            store.state.formData.addresses.privateAddress === null &&
            store.state.formData.addresses.businessAddress !== null
          ) {
            selectedUserRole.value = UserRole.USER_IS_RECRUITING;
            submitUserRole();
          }

          emit('formPrefilled');
        }
      });

      return {
        commonTexts,
        selectionOptions,
        selectedUserRole,
        updateUserRole,
        submitUserRole,
        mainTitle,
      };
    },
  });
</script>

import router from '@/router';
import { ConnectorFactory } from '@/services/connectors/connectorFactory';

export default class ConfigService {
  public static getConfig() {
    const customizingConnector = ConnectorFactory.getCustomizingConnector();

    return customizingConnector.getConfiguration().catch((error) => {
      console.log(error);
      router.push({
        name: 'ErrorPage',
        params: { type: 'server' },
      });
    });
  }
}

import { AuthScreen } from '@/types/AuthTypes';

export enum AuthButtonType {
  LOGIN = 'login',
  REGISTRATION = 'register',
}

export enum SubscriptionType {
  SINGLE_ADDRESS,
  GIFT_SUBSCRIPTION,
  LWL_SUBSCRIPTION,
}

export const DATE_FORMAT = {
  baseDateShort: 'yyyy-MM-dd',
  localDateShort: 'DD.MM.YYYY',
  localShowDate: 'dd.MM.yyyy',
};

export type AppQueryParameters = {
  wa: string;
  offerId: string;
  advertisingMedium: string;
  campaign: string;
  ktnr: string;
  sourceUrl: string;
  pianoTrackingId: string;
  authScreen: AuthScreen;
};

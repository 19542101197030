import { ConnectorFactory } from '@/services/connectors/connectorFactory';
import { ImageUploadConnectorName } from '@/services/connectors/types';
import { ImageUploadResponse } from '@/types/ImageUploadTypes';

export class StudentOrderService {
  public static uploadStudentEnrollmentCertificate(
    file: File,
  ): Promise<ImageUploadResponse> {
    const studentEnrollmentUploadConnector =
      ConnectorFactory.getImageUploadConnector(
        ImageUploadConnectorName.STUDENT_ENROLLMENT,
      );
    const options = {};
    return studentEnrollmentUploadConnector.uploadImage(file, options);
  }
}

<template>
  <!--  <div>-->
  <!--    <b-form-radio-group-->
  <!--      :checked="value"-->
  <!--      :options="radioOptions"-->
  <!--      @change="$emit('input', $event)"-->
  <!--      :stacked="stacked"-->
  <!--      class="form-element-radio-buttons"-->
  <!--      :class="{ 'form-element-radio-buttons&#45;&#45;stacked': stacked }"-->
  <!--    >-->
  <!--    </b-form-radio-group>-->
  <!--  </div>-->
  <div
    class="form-element-radio-button-group"
    :class="{
      'form-element-radio-button-group--disabled': disabled,
      'form-element-radio-button-group--stacked': stacked,
    }"
  >
    <label
      v-for="(option, index) in options"
      :key="index"
      :for="getOptionIdForIndex(index)"
      class="form-element-radio-button-group__radio-button"
      :class="{
        'form-element-radio-button-group__radio-button--checked':
          option.value.toString() === value.toString(),
        'form-element-radio-button-group__radio-button--full-width': fullWidth,
      }"
    >
      <input
        :id="getOptionIdForIndex(index)"
        class="form-element-radio-button-group__input"
        type="radio"
        :name="getOptionIdForIndex(index)"
        :value="option.value"
        :checked="option.value.toString() === value.toString()"
        @change="handleChange(index, $event)"
      />
      <span
        v-if="option.text && option.text.length > 0"
        class="form-element-radio-button-group__label"
      >
        {{ option.text }}
      </span>
      <span
        v-if="option.html && option.html.length > 0"
        class="form-element-radio-button-group__label"
        v-html="option.html"
      />
    </label>
  </div>
</template>

<script lang="ts">
  import {
    computed,
    ComputedRef,
    defineComponent,
    PropType,
    Ref,
    ref,
  } from 'vue';
  import { RadioButtonOption } from '@/types/GeneralFormTypes';

  export default defineComponent({
    name: 'FormElementRadioButtons',
    props: {
      radioOptions: {
        type: Array as PropType<string[] | RadioButtonOption[]>,
        required: true,
      },
      value: {
        type: [String, Number],
        required: true,
      },
      stacked: {
        type: Boolean,
        required: false,
        default: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      fullWidth: {
        type: Boolean,
        required: false,
        default: false,
      },
      name: {
        type: String,
        required: false,
        default: '',
      },
    },
    emits: ['input'],
    setup(props, { emit }) {
      const optionValueTypes: Ref<string[]> = ref([]);
      const options: ComputedRef<RadioButtonOption[]> = computed(() => {
        const result: RadioButtonOption[] = [];
        if (props.radioOptions.length > 0) {
          if (typeof props.radioOptions[0] === 'string') {
            (props.radioOptions as string[]).forEach((option) => {
              result.push({ value: option, text: option });
              optionValueTypes.value.push(typeof option);
            });
          } else {
            (props.radioOptions as RadioButtonOption[]).forEach((option) => {
              result.push(option);
              optionValueTypes.value.push(typeof option.value);
            });
          }
        }

        return result;
      });

      const getOptionIdForIndex = (index: number) => {
        const option = options.value[index];
        const fallback = `option-${index}`;
        if (props.name) {
          return `${props.name}-${index}`;
        } else if (option) {
          let id: string;
          if (option.text) {
            id = option.text.replace(/\s/g, '');
          } else {
            id = option.value.toString().concat(index.toString());
          }
          return id;
        }
        return fallback;
      };

      const handleChange = function (
        index: number,
        event: Event & { target: HTMLInputElement },
      ) {
        if (!props.disabled) {
          if (optionValueTypes.value[index] === 'number') {
            const value = parseInt(event.target.value, 10);
            emit('input', value);
            return;
          }
          emit('input', event.target.value);
        }
      };

      return { handleChange, getOptionIdForIndex, options };
    },
  });
</script>

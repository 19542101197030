import { OrderBonusClassification } from '@/types/OrderBonusesTypes';
import { GpType } from '@/types/OrderFormTypes';

export const salutationOptions = [
  { value: '00', text: 'Bitte auswählen', disabled: true },
  { value: '05', text: 'Neutral' },
  { value: '02', text: 'Herr' },
  { value: '03', text: 'Frau' },
];

export const salutationOptionsCompany = [
  { value: '00', text: 'Bitte auswählen', disabled: true },
  { value: '01', text: 'Neutral' },
  { value: '04', text: 'Firma' },
];

export const completeSalutationOptions = [
  { value: '00', text: 'Bitte auswählen', disabled: true },
  { value: '01', text: 'Neutral' },
  { value: '02', text: 'Herr' },
  { value: '03', text: 'Frau' },
  { value: '04', text: 'Firma' },
  { value: '05', text: 'Neutral' },
];

export const regionOptions = [
  { value: 'EG', text: 'Automatische Auswahl' },
  { value: 'MDDA', text: 'Dachau' },
  { value: 'MDEB', text: 'Ebersberg' },
  { value: 'MDER', text: 'Erding' },
  { value: 'MDFF', text: 'Fürstenfeldbruck' },
  { value: 'MDFR', text: 'Freising' },
  { value: 'MDLN', text: 'München Landkreis Nord' },
  { value: 'MDLS', text: 'München Landkreis Süd' },
  { value: 'MDMC', text: 'München Stadt' },
  { value: 'MDMO', text: 'München Ost' },
  { value: 'MDMS', text: 'München Süd' },
  { value: 'MDMW', text: 'München West' },
  { value: 'MDST', text: 'Starnberg' },
  { value: 'MDWO', text: 'Bad Tölz/Wolfratshausen' },
  { value: 'FDOE', text: 'Österreich' },
];

export const countryOptions = [
  { value: 'DE', text: 'DE' },
  { value: 'AT', text: 'AT' },
  { value: 'BE', text: 'BE' },
  { value: 'CY', text: 'CY' },
  { value: 'EE', text: 'EE' },
  { value: 'ES', text: 'ES' },
  { value: 'FI', text: 'FI' },
  { value: 'FR', text: 'FR' },
  { value: 'GR', text: 'GR' },
  { value: 'IE', text: 'IE' },
  { value: 'IT', text: 'IT' },
  { value: 'LT', text: 'LT' },
  { value: 'LU', text: 'LU' },
  { value: 'LV', text: 'LV' },
  { value: 'MC', text: 'MC' },
  { value: 'MT', text: 'MT' },
  { value: 'NL', text: 'NL' },
  { value: 'PT', text: 'PT' },
  { value: 'SI', text: 'SI' },
  { value: 'SK', text: 'SK' },
  { value: 'SM', text: 'SM' },
];

export const addressTypeOptions = ['Straßenadresse', 'Postfachadresse'];
export const customerTypeOptions = [GpType.PRIVATE, GpType.BUSINESS];

export const titleOptions = [
  { value: '00', text: 'Keinen' },
  { value: 'B.A.', text: 'B.A.' },
  { value: 'B.Ed.', text: 'B.Ed.' },
  { value: 'B.Eng.', text: 'B.Eng.' },
  { value: 'B.F.A.', text: 'B.F.A.' },
  { value: 'B.Mus.', text: 'B.Mus.' },
  { value: 'B.Sc.', text: 'B.Sc.' },
  { value: 'BBA', text: 'BBA' },
  { value: 'BBA&E', text: 'BBA&E' },
  { value: 'BBI', text: 'BBI' },
  { value: 'Dipl.Agrar-Ing.', text: 'Dipl.Agrar-Ing.' },
  { value: 'Dipl.-Architekt', text: 'Dipl.-Architekt' },
  { value: 'Dipl-Betriebsw.', text: 'Dipl-Betriebsw.' },
  { value: 'Dipl.-Bibl.', text: 'Dipl.-Bibl.' },
  { value: 'Dipl.-Bw.', text: 'Dipl.-Bw.' },
  { value: 'Dipl.-Chem.', text: 'Dipl.-Chem.' },
  { value: 'Dipl.-Finanzw.', text: 'Dipl.-Finanzw.' },
  { value: 'Dipl.-Geogr.', text: 'Dipl.-Geogr.' },
  { value: 'Dipl.-Inf.', text: 'Dipl.-Inf.' },
  { value: 'Dipl.Ing. Arch.', text: 'Dipl.Ing. Arch.' },
  { value: 'Dipl.-Ing.', text: 'Dipl.-Ing.' },
  { value: 'Dipl.-Ing. (FH)', text: 'Dipl.-Ing. (FH)' },
  { value: 'Dipl.Ing. Kffr.', text: 'Dipl.Ing. Kffr.' },
  { value: 'Dipl.-Ing.-Kfm.', text: 'Dipl.-Ing.-Kfm.' },
  { value: 'Dipl.-Ing.oek.', text: 'Dipl.-Ing.oek.' },
  { value: 'Dipl.-Jur.', text: 'Dipl.-Jur.' },
  { value: 'Dipl.-Kffr.', text: 'Dipl.-Kffr.' },
  { value: 'Dipl.-Kfm.', text: 'Dipl.-Kfm.' },
  { value: 'Dipl.-Ldw.', text: 'Dipl.-Ldw.' },
  { value: 'Dipl.-Math.', text: 'Dipl.-Math.' },
  { value: 'Dipl.-Oec.', text: 'Dipl.-Oec.' },
  { value: 'Dipl.-Ök.', text: 'Dipl.-Ök.' },
  { value: 'Dipl.-Päd.', text: 'Dipl.-Päd.' },
  { value: 'Dipl.-phil.', text: 'Dipl.-phil.' },
  { value: 'Dipl.-Phys.', text: 'Dipl.-Phys.' },
  { value: 'Dipl.-Psych.', text: 'Dipl.-Psych.' },
  { value: 'Dipl.-Red.', text: 'Dipl.-Red.' },
  { value: 'Dipl.-rer.pol.', text: 'Dipl.-rer.pol.' },
  { value: 'Dipl.-Soz.', text: 'Dipl.-Soz.' },
  { value: 'Dipl.-Soz.-Päd.', text: 'Dipl.-Soz.-Päd.' },
  { value: 'Dipl.-Sportwiss', text: 'Dipl.-Sportwiss' },
  { value: 'Dipl.-Staatsw.', text: 'Dipl.-Staatsw.' },
  { value: 'Dipl.-Übersetz.', text: 'Dipl.-Übersetz.' },
  { value: 'Dipl.-Umw.wiss.', text: 'Dipl.-Umw.wiss.' },
  { value: 'Dipl. Verw.', text: 'Dipl. Verw.' },
  { value: 'Dipl.-Verw.Wirt', text: 'Dipl.-Verw.Wirt' },
  { value: 'Dipl.Verw.Wiss.', text: 'Dipl.Verw.Wiss.' },
  { value: 'Dipl.-Vw.', text: 'Dipl.-Vw.' },
  { value: 'Dipl.Wirt.-Ing.', text: 'Dipl.Wirt.-Ing.' },
  { value: 'Direktor', text: 'Direktor' },
  { value: 'Dr.', text: 'Dr.' },
  { value: 'Dr. Dipl.-Bibl.', text: 'Dr. Dipl.-Bibl.' },
  { value: 'Dr. Dipl.-Ing.', text: 'Dr. Dipl.-Ing.' },
  { value: 'Dr. Dipl.-Kffr.', text: 'Dr. Dipl.-Kffr.' },
  { value: 'Dr. Dipl.-Kfm.', text: 'Dr. Dipl.-Kfm.' },
  { value: 'Dr. Dipl.-Phys.', text: 'Dr. Dipl.-Phys.' },
  { value: 'Dr. Dr.', text: 'Dr. Dr.' },
  { value: 'Dr. h.c.', text: 'Dr. h.c.' },
  { value: 'Dr. Ing.', text: 'Dr. Ing.' },
  { value: 'Dr. jur.', text: 'Dr. jur.' },
  { value: 'Dr. MA', text: 'Dr. MA' },
  { value: 'Dr. mag.', text: 'Dr. mag.' },
  { value: 'Dr.Math.', text: 'Dr.Math.' },
  { value: 'Dr.Med.', text: 'Dr.Med.' },
  { value: 'Dr. Med. Dent.', text: 'Dr. Med. Dent.' },
  { value: 'Dr.med Dipl-Ing', text: 'Dr.med Dipl-Ing' },
  { value: 'Dr. med. Dr.', text: 'Dr. med. Dr.' },
  { value: 'Dr. med. vet.', text: 'Dr. med. vet.' },
  { value: 'Dr. oec.', text: 'Dr. oec.' },
  { value: 'Dr. Phil.', text: 'Dr. Phil.' },
  { value: 'Dr. Prof.', text: 'Dr. Prof.' },
  { value: 'Dr.rer.nat.', text: 'Dr.rer.nat.' },
  { value: 'Dr. rer.pol', text: 'Dr. rer.pol' },
  { value: 'Drs.', text: 'Drs.' },
  { value: 'Dr. sc.nat.', text: 'Dr. sc.nat.' },
  { value: 'Dr. sc.phil.', text: 'Dr. sc.phil.' },
  { value: 'Ing.', text: 'Ing.' },
  { value: 'Ing. grad.', text: 'Ing. grad.' },
  { value: 'Lic.', text: 'Lic.' },
  { value: 'Lic.bec.', text: 'Lic.bec.' },
  { value: 'Lic.oec.', text: 'Lic.oec.' },
  { value: 'LL.B.', text: 'LL.B.' },
  { value: 'LL.M.', text: 'LL.M.' },
  { value: 'M.Arch.', text: 'M.Arch.' },
  { value: 'M.B.L.', text: 'M.B.L.' },
  { value: 'M.Ed.', text: 'M.Ed.' },
  { value: 'M.F.A.', text: 'M.F.A.' },
  { value: 'M.I.B.', text: 'M.I.B.' },
  { value: 'MA', text: 'MA' },
  { value: 'Mag.', text: 'Mag.' },
  { value: 'Mag.Dr.MA', text: 'Mag.Dr.MA' },
  { value: 'Mag.oec.', text: 'Mag.oec.' },
  { value: 'MBA', text: 'MBA' },
  { value: 'MBC', text: 'MBC' },
  { value: 'MBE', text: 'MBE' },
  { value: 'MHA', text: 'MHA' },
  { value: 'MHBA', text: 'MHBA' },
  { value: 'MLB', text: 'MLB' },
  { value: 'MPA', text: 'MPA' },
  { value: 'MSc', text: 'MSc' },
  { value: 'MScBL', text: 'MScBL' },
  { value: 'Pr.Doz.Dr.', text: 'Pr.Doz.Dr.' },
  { value: 'Prof.', text: 'Prof.' },
  { value: 'Prof. Dipl-Ing.', text: 'Prof. Dipl-Ing.' },
  { value: 'Prof. Dipl.-Mat', text: 'Prof. Dipl.-Mat' },
  { value: 'Prof.Dipl-Phys.', text: 'Prof.Dipl-Phys.' },
  { value: 'Prof. Dr.', text: 'Prof. Dr.' },
  { value: 'Prof. Dr. Dr.', text: 'Prof. Dr. Dr.' },
  { value: 'Prof. Dr.habil.', text: 'Prof. Dr.habil.' },
  { value: 'Prof. Dr. hc.', text: 'Prof. Dr. hc.' },
  { value: 'Prof. Dr. Ing.', text: 'Prof. Dr. Ing.' },
  { value: 'Prof.Dr-Ing.Eh.', text: 'Prof.Dr-Ing.Eh.' },
  { value: 'Prof. Dr. jur.', text: 'Prof. Dr. jur.' },
  { value: 'Prof. Dr.med', text: 'Prof. Dr.med' },
  { value: 'Prof. Dr. Med.', text: 'Prof. Dr. Med.' },
  { value: 'Prof.Dr.med.hab', text: 'Prof.Dr.med.hab' },
  { value: 'Prof. Dr. mult.', text: 'Prof. Dr. mult.' },
  { value: 'Prof. Dr. Oec.', text: 'Prof. Dr. Oec.' },
  { value: 'Prof. Dr. phil.', text: 'Prof. Dr. phil.' },
  { value: 'Prof.Dr.rer.nat', text: 'Prof.Dr.rer.nat' },
  { value: 'Prof.Dr.rer.pol', text: 'Prof.Dr.rer.pol' },
  { value: 'SM.Eng.', text: 'SM.Eng.' },
  { value: 'Univ.-Prof.Dr', text: 'Univ.-Prof.Dr' },
];

export const orderBonusCategoryOptionsEditable = [
  { value: OrderBonusClassification.NON_MONETARY, text: 'Sachprämie' },
  { value: OrderBonusClassification.MILES_AND_MORE, text: 'Miles&More Prämie' },
];

export const orderBonusCategoryOptionsIneditable = [
  { value: OrderBonusClassification.CASH, text: 'Barprämie' },
];

export const showDeviatingAddressSelectionOptions = [
  { value: '00', text: 'keine', disabled: true },
  { value: '01', text: 'Privat' },
  { value: '02', text: 'Organisation' },
  { value: '03', text: 'beide' },
];

export const giftSubscriptionLimitOptions = [
  { value: 0, text: 'unbefristet' },
  { value: 3, text: '3 Monate' },
  { value: 6, text: ' 6 Monate' },
  { value: 12, text: '12 Monate' },
];

export const tenantOptions = [
  { value: 'sz', text: 'SZ' },
  { value: 'stz', text: 'STZ' },
  { value: 'stn', text: 'STN' },
  { value: 'kwz', text: 'KWZ' },
  { value: 'maz', text: 'MAZ' },
  { value: 'lkz', text: 'LKZ' },
  { value: 'ez', text: 'EZ' },
  { value: 'cz', text: 'CZ' },
  { value: 'kbb', text: 'KBB' },
  { value: 'swb', text: 'SWB' },
  { value: 'lz', text: 'LZ' },
];

import { PersonalCoreDataValidator } from '@/validators/PersonalCoreDataValidator';
import { PersonalEmailDataValidator } from '@/validators/PersonalEmailDataValidator';
import { PersonalPhoneDataValidator } from '@/validators/PersonalPhoneDataValidator';
import { PostOfficeBoxAddressDataValidator } from '@/validators/PostOfficeBoxAddressDataValidator';
import { StreetAddressDataValidator } from '@/validators/StreetAddressDataValidator';
import {
  AddressViolations,
  FieldErrorsAddressData,
  ViolationFields,
} from '@/validators/validatorTypes';
import { AddressTypeLiteral, OrderFormData } from '@/types/OrderFormTypes';
import { CountryValidator } from '@/validators/CountryValidator';
import {
  FormAddresses,
  FormBusinessAddress,
  FormDeviatingAddress,
} from '@/models/OrderData';

export class OrderFormValidator {
  private personalCoreDataValidator = new PersonalCoreDataValidator();
  private countryValidator = new CountryValidator();
  private personalEmailDataValidator = new PersonalEmailDataValidator();
  private personalPhoneDataValidator = new PersonalPhoneDataValidator();
  private streetAddressDataValidator = new StreetAddressDataValidator();
  private postOfficeBoxAddressDataValidator =
    new PostOfficeBoxAddressDataValidator();

  validate(
    data: FormAddresses,
    fullAddressRequired: boolean,
    emailRequired: boolean,
    availableCountryOptions: { text: string; value: string }[],
  ): AddressViolations {
    const addressViolations: AddressViolations = {
      common: [],
      privateAddress: [],
      businessAddress: [],
      deliveryAddress: [],
      invoiceAddress: [],
    };

    if (data.privateAddress !== null) {
      addressViolations.privateAddress = this.validatePersonalData(
        data.privateAddress,
        emailRequired,
      ).concat(
        this.validateAddress(
          data.privateAddress,
          fullAddressRequired,
          availableCountryOptions,
        ),
      );
    } else if (data.businessAddress !== null) {
      addressViolations.businessAddress = this.validatePersonalData(
        data.businessAddress,
        emailRequired,
      ).concat(
        this.validateAddress(
          data.businessAddress,
          fullAddressRequired,
          availableCountryOptions,
        ),
      );
      if (
        data.deliveryAddress !== null &&
        this.addressCanBeValidated(data.deliveryAddress)
      ) {
        addressViolations.deliveryAddress = this.validateAddress(
          data.deliveryAddress,
          fullAddressRequired,
          availableCountryOptions,
        );
      }
      if (
        data.invoiceAddress !== null &&
        this.addressCanBeValidated(data.invoiceAddress)
      ) {
        addressViolations.invoiceAddress = this.validateAddress(
          data.invoiceAddress,
          fullAddressRequired,
          availableCountryOptions,
        );
      }
    }

    return addressViolations;
  }

  addressCanBeValidated(
    address: OrderFormData | FormBusinessAddress | FormDeviatingAddress | null,
  ): boolean {
    if (address === null) return false;

    return !(
      address.street === '' &&
      address.streetNumber === '' &&
      address.zipCode === '' &&
      address.city === '' &&
      address.postOfficeBox === '' &&
      address.postOfficeBoxCity === '' &&
      address.postOfficeBoxZipCode === ''
    );
  }

  validatePersonalData(
    data: OrderFormData | FormBusinessAddress,
    emailRequired: boolean,
  ): ViolationFields[] {
    let violations = this.personalCoreDataValidator.validate(data);

    // validate email address only if present in data OR if required
    if ((data.email && data.email.length > 0) || emailRequired) {
      //If email is required, but empty, add violation immediately
      if (emailRequired && (!data.email || data.email.length === 0)) {
        violations.push(ViolationFields.EMAIL);
        // Else: Use email validator
      } else {
        violations = this.personalEmailDataValidator
          .validate(data.email)
          .concat(violations);
      }
    }
    // validate phoneNumber only if present in data
    if (data.phone && data.phone.length > 0) {
      violations = this.personalPhoneDataValidator
        .validate(data.phone)
        .concat(violations);
    }
    if (data.mobilePhone && data.mobilePhone.length > 0) {
      const mobilePhoneViolations = this.personalPhoneDataValidator.validate(
        data.mobilePhone,
      );
      if (mobilePhoneViolations.length > 0) {
        violations.push(ViolationFields.MOBILE_PHONE);
      }
    }
    if (data.businessPhone && data.phone.length > 0) {
      const businessPhoneViolations = this.personalPhoneDataValidator.validate(
        data.businessPhone,
      );
      if (businessPhoneViolations.length > 0) {
        violations.push(ViolationFields.BUSINESS_PHONE);
      }
    }

    return violations;
  }

  validateAddress(
    data: OrderFormData | FormBusinessAddress | FormDeviatingAddress,
    fullAddressRequired: boolean,
    availableCountryOptions: { text: string; value: string }[],
  ): ViolationFields[] {
    let violations = this.countryValidator.validate(
      data,
      availableCountryOptions,
    );
    if (fullAddressRequired) {
      if (data.addressType === AddressTypeLiteral.STREET_ADDRESS) {
        violations = this.streetAddressDataValidator
          .validate(data)
          .concat(violations);
      } else {
        violations = this.postOfficeBoxAddressDataValidator
          .validate(data)
          .concat(violations);
      }
    }

    return violations;
  }

  createInitialAddressFieldErrors(): FieldErrorsAddressData {
    return {
      salutation: false,
      firstName: false,
      lastName: false,
      email: false,
      phoneNumber: false,
      businessPhone: false,
      mobilePhone: false,
      companyName: false,
      country: false,
      street: false,
      streetNumber: false,
      additionalInfo: false,
      floor: false,
      room: false,
      zipCode: false,
      city: false,
      district: false,
      addressAddition: false,
      postOfficeBox: false,
      postOfficeBoxZipCode: false,
      postOfficeBoxCity: false,
    };
  }

  // setFieldErrors(
  //   fieldErrorsAddress: FieldErrorsAddressData,
  //   violations: string[],
  // ): void {
  //   fieldErrorsAddress.salutation = violations.includes(
  //     ViolationFields.SALUTATION,
  //   );
  //   fieldErrorsAddress.companyName = violations.includes(
  //     ViolationFields.COMPANY_NAME,
  //   );
  //   fieldErrorsAddress.lastName = violations.includes(
  //     ViolationFields.LAST_NAME,
  //   );
  //   fieldErrorsAddress.firstName = violations.includes(
  //     ViolationFields.FIRST_NAME,
  //   );
  //   fieldErrorsAddress.email = violations.includes(ViolationFields.EMAIL);
  //   fieldErrorsAddress.phoneNumber = violations.includes(
  //     ViolationFields.PHONE_NUMBER,
  //   );
  //   fieldErrorsAddress.street = violations.includes(ViolationFields.STREET);
  //   fieldErrorsAddress.streetNumber = violations.includes(
  //     ViolationFields.STREET_NUMBER,
  //   );
  //   fieldErrorsAddress.zipCode = violations.includes(ViolationFields.ZIP_CODE);
  //   fieldErrorsAddress.city = violations.includes(ViolationFields.CITY);
  //   fieldErrorsAddress.country = violations.includes(ViolationFields.COUNTRY);
  //   fieldErrorsAddress.postOfficeBox = violations.includes(
  //     ViolationFields.POST_OFFICE_BOX,
  //   );
  //   fieldErrorsAddress.postOfficeBoxZipCode = violations.includes(
  //     ViolationFields.POST_OFFICE_BOX_ZIP_CODE,
  //   );
  //   fieldErrorsAddress.postOfficeBoxCity = violations.includes(
  //     ViolationFields.POST_OFFICE_BOX_CITY,
  //   );
  // }

  resetFieldError(
    fieldErrorsAddress: FieldErrorsAddressData,
    field: string,
  ): void {
    switch (field) {
      case ViolationFields.SALUTATION:
        fieldErrorsAddress.salutation = false;
        break;
      case ViolationFields.COMPANY_NAME:
        fieldErrorsAddress.companyName = false;
        break;
      case ViolationFields.FIRST_NAME:
        fieldErrorsAddress.firstName = false;
        break;
      case ViolationFields.LAST_NAME:
        fieldErrorsAddress.lastName = false;
        break;
      case ViolationFields.EMAIL:
        fieldErrorsAddress.email = false;
        break;
      case ViolationFields.PHONE_NUMBER:
        fieldErrorsAddress.phoneNumber = false;
        break;
      case ViolationFields.POST_OFFICE_BOX:
        fieldErrorsAddress.postOfficeBox = false;
        break;
      case ViolationFields.POST_OFFICE_BOX_CITY:
        fieldErrorsAddress.postOfficeBoxCity = false;
        break;
      case ViolationFields.POST_OFFICE_BOX_ZIP_CODE:
        fieldErrorsAddress.postOfficeBoxZipCode = false;
        break;
      case ViolationFields.STREET:
        fieldErrorsAddress.street = false;
        break;
      case ViolationFields.STREET_NUMBER:
        fieldErrorsAddress.streetNumber = false;
        break;
      case ViolationFields.ZIP_CODE:
        fieldErrorsAddress.zipCode = false;
        break;
      case ViolationFields.CITY:
        fieldErrorsAddress.city = false;
        break;
      case ViolationFields.COUNTRY:
        fieldErrorsAddress.country = false;
        break;
    }
  }
}

import { SingleUserOrderBonus } from '@/types/OrderBonusesTypes';

export type GtmGenericEventData = {
  category: string;
  action: string;
  label: string;
};

export type GtmTransactionEventData = {
  category: string;
  action: string;
  label: string;
};

export type GtmGenericEventOrderBonusData = {
  category: string;
  action: string;
  label: string;
  orderBonuses: SingleUserOrderBonus[];
};

export enum CheckoutTypes {
  STANDARD_SUBSCRIPTION = 'Standardabo',
  GIFT_SUBSCRIPTION = 'Geschenkabo',
  READERS_RECRUIT_READERS_SUBSCRIPTION = 'LWL-Abo',
}

export type OrderBonusGtm = {
  id: string;
  name: string;
};

<template>
  <div id="app" class="app-internal" v-if="hasInternalAccess && loaded">
    <div id="wrapperInternal">
      <TheOverlay :show="!isLoggedIn" :opacity="1" />
      <TheHeader />
      <router-view v-if="isLoggedIn" />
      <TheFooter />
    </div>
  </div>
</template>

<script lang="ts">
  import TheHeader from '@/components/TheHeader.vue';
  import TheFooter from '@/components/TheFooter.vue';
  import AuthService from '@/services/auth-service';
  import SetupService from '@/services/setup-service';
  import InternalAdminsService from '@/services/internal-admins-service';
  import TheOverlay from '@/components/Overlay/TheOverlay.vue';
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'AppInternal',
    components: {
      TheHeader,
      TheFooter,
      TheOverlay,
    },
    async beforeCreate() {
      SetupService.setTenant();
      await SetupService.setParameters();
      SetupService.changeFavicon();
      SetupService.changeMetaTitle();
    },
    setup() {
      const store = useStore();
      const loaded = ref(false);

      const isLoggedIn = computed(() => {
        return store.state.isLoggedIn;
      });

      const hasInternalAccess = computed(() => {
        return store.state.userRole;
      });

      const initUserRole = () => {
        if (store.state.adminData._links.ADMIN_ADMINS) {
          store.commit('changeUserRole', 'admin');
        } else if (
          !store.state.adminData._links.ADMIN_ADMINS &&
          store.state.adminData._links.ADMIN_TENANT_ADMINS
        ) {
          store.commit('changeUserRole', 'keyuser');
        } else if (
          !store.state.adminData._links.ADMIN_ADMINS &&
          !store.state.adminData._links.ADMIN_TENANT_ADMINS &&
          store.state.adminData._links.CONFIGURE_SALES_CAMPAIGNS
        ) {
          store.commit('changeUserRole', 'user');
        }
      };

      onMounted(async () => {
        await AuthService.checkLogin('internal', true);
        await InternalAdminsService.requestAdminOptions();
        initUserRole();
        await store.dispatch('updateAdminLogoutUrl');
        loaded.value = true;
      });

      return { isLoggedIn, hasInternalAccess, loaded };
    },
  });
</script>

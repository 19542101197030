<template>
  <FormWrapper additional-class="gpnr-form">
    <MessageBox
      v-if="formInvalidOrWrongNumber"
      :type="'no-bg'"
      :content="content.gpNr.error.formValidationMessage"
    />

    <MessageBox
      v-if="gpNrIsInvalidOrWrong"
      :content="content.gpNr.error.gpNrNotValid"
    />
    <FormElementInputField
      label="Kundennummer"
      :value="connectUsersInputs.gpNr"
      @input="connectUsersInputs.gpNr = $event"
      :field-has-error="gpNrIsInvalidOrWrong"
      maxlength="10"
    />

    <p class="legal-text gp-nr-hint margin-top-large">
      {{ content.gpNr.gpNrInputHint }}
    </p>

    <FormElementInputField
      label="Nachname / Firmenname"
      :value="connectUsersInputs.name"
      @input="connectUsersInputs.name = $event"
      :field-has-error="nameInvalidOrNotMatchingWithGpNumber"
    />
    <!--    <MessageBox-->
    <!--      v-if="agbGpNrCheckboxIsInvalid"-->
    <!--      :content="content.gpNr.error.agb"-->
    <!--    />-->
    <p
      class="legal-text agb-check margin-top-large"
      :class="{
        // 'error-checkbox': agbGpNrCheckboxIsInvalid,
      }"
    >
      <DisplayElementAgbTextBlock
        :show-additional-legal-text="false"
        :content="content"
      />
    </p>
    <TheOverlay
      :show="gpNrConnectInProgress"
      :opacity="0.3"
      :spiner-size="20"
      class="d-inline-block | w-100 mt-3"
      :isFullPage="false"
    >
      <BasicButton
        :disabled="gpNrConnectInProgress"
        variant="primary"
        class="mt-0"
        @buttonClicked="submit"
        >Weiter</BasicButton
      >
    </TheOverlay>
  </FormWrapper>
</template>

<script lang="ts">
  import { computed, defineComponent, PropType, reactive, ref } from 'vue';
  import FormElementInputField from '@/components/FormElements/FormElementInputField.vue';
  import DisplayElementAgbTextBlock from '@/components/DisplayElements/DisplayElementAgbTextBlock.vue';
  import MessageBox from '@/components/Alerts/MessageBox.vue';
  import { GpNrConnectFormData } from '@/types/GpNrConnectFormTypes';
  import { TenantContent } from '@/models/tenant';
  import TheOverlay from '@/components/Overlay/TheOverlay.vue';
  import BasicButton from '@/components/Buttons/BasicButton.vue';
  import FormWrapper from '@/components/FormElements/FormWrapper.vue';

  export default defineComponent({
    name: 'GpNrConnectForm',
    components: {
      FormWrapper,
      FormElementInputField,
      DisplayElementAgbTextBlock,
      MessageBox,
      TheOverlay,
      BasicButton,
    },
    props: {
      content: {
        type: Object as PropType<TenantContent>,
        required: true,
      },
      gpConnectWrongNumber: {
        type: Boolean,
        required: true,
      },
      gpNrConnectInProgress: {
        type: Boolean,
        required: true,
      },
    },
    emits: ['formValidated'],
    setup(props, { emit }) {
      const connectUsersInputs: GpNrConnectFormData = reactive({
        name: '',
        gpNr: '',
        agbCheckBox: false,
      });

      // validation will get triggered only if "validate" is true.
      // this happens when the user attempts to submit.
      // the form will only be valid if the computed properties
      // "gpNrIsValid" & "nameIsValid" & "!agbGpNrCheckboxIsInvalid" are true.
      const validate = ref(false);

      // TODO:  import this function later
      const isRequiredInputFilled = function (
        inputValue: string,
        requiredLength: number,
      ) {
        return inputValue && inputValue.length >= requiredLength;
      };

      // Single input validation states: gpNr, name, agb
      const gpNrIsValid = computed(
        () =>
          // TODO: use imported function later
          !!(
            isRequiredInputFilled(connectUsersInputs.gpNr, 10) &&
            connectUsersInputs.gpNr.length < 11
          ),
      );
      const nameIsValid = computed(
        // TODO: use imported function later
        () =>
          !!isRequiredInputFilled(connectUsersInputs.name, 2) && validate.value,
      );
      const agbGpNrCheckboxIsInvalid = computed(
        () => !connectUsersInputs.agbCheckBox && validate.value,
      );

      // Validation:
      // in case of invalid state, the submit will not trigger the request (in parent component)
      // instead the computed properties for each input will be false and marked as error (by props and classes for each input)
      // 2022-03-14: Checkbox for Terms of Service were removed
      const formIsValid = computed(
        () =>
          gpNrIsValid.value &&
          nameIsValid.value &&
          // connectUsersInputs.agbCheckBox && // agbCheckbox was removed
          validate.value,
      );

      // For displaying the main error message on top of the form
      // (if form is invalid or the user gets error response after submitting)
      const formInvalidOrWrongNumber = computed(
        () =>
          (props.gpConnectWrongNumber || !formIsValid.value) && validate.value,
      );

      // Error case: GpNr not valid or rejected after submitting (wrong number)
      const gpNrIsInvalidOrWrong = computed(
        () =>
          (!gpNrIsValid.value || props.gpConnectWrongNumber) && validate.value,
      );

      // Error case: Name does not fill the validation requirements or is not
      // matching with GpNr after submitting
      const nameInvalidOrNotMatchingWithGpNumber = computed(
        () =>
          (gpNrIsInvalidOrWrong.value || !nameIsValid.value) && validate.value,
      );

      //submit emits the input values to parent component and triggers GpConnectToUser(gpNr,name)
      const submit = function () {
        validate.value = true;
        if (formIsValid.value) {
          emit('formValidated', connectUsersInputs);
        }
      };

      return {
        connectUsersInputs,
        nameIsValid,
        agbGpNrCheckboxIsInvalid,
        formIsValid,
        gpNrIsInvalidOrWrong,
        validate,
        formInvalidOrWrongNumber,
        nameInvalidOrNotMatchingWithGpNumber,
        submit,
      };
    },
  });
</script>

import {
  ValidatorInterface,
  ViolationFields,
} from '@/validators/validatorTypes';
import { OrderFormData } from '@/types/OrderFormTypes';
import { FormBusinessAddress, FormDeviatingAddress } from '@/models/OrderData';

export class PostOfficeBoxAddressDataValidator implements ValidatorInterface {
  validate(
    target: OrderFormData | FormBusinessAddress | FormDeviatingAddress,
  ): ViolationFields[] {
    const violations: ViolationFields[] = [];
    const patternPostOfficeBox = /^\d+$/;

    if (
      !target.postOfficeBox ||
      target.postOfficeBox.length <= 2 ||
      patternPostOfficeBox.exec(target.postOfficeBox) === null
    ) {
      violations.push(ViolationFields.POST_OFFICE_BOX);
    }
    if (!target.postOfficeBoxCity || target.postOfficeBoxCity.length <= 2) {
      violations.push(ViolationFields.POST_OFFICE_BOX_CITY);
    }
    if (
      !target.postOfficeBoxZipCode ||
      target.postOfficeBoxZipCode.length > 10
    ) {
      violations.push(ViolationFields.POST_OFFICE_BOX_ZIP_CODE);
    }

    return violations;
  }
}

<template>
  <FormSection class="form-element-phone-number">
    <FormElementInputField
      without-label
      readonly
      :value="countryCode"
      class="col-md-3 col-sm-6 col-6 form-element-phone-number__field"
      :field-id="
        commonTexts.userInterface.formFieldLabels.phoneNumber.countryCode +
        fieldIdSlug
      "
    />
    <FormElementInputField
      :label="commonTexts.userInterface.formFieldLabels.phoneNumber.areaCode"
      :field-id="
        commonTexts.userInterface.formFieldLabels.phoneNumber.areaCode +
        fieldIdSlug
      "
      :value="areaCode"
      :field-has-error="fieldHasError"
      input-type="tel"
      @input="updateAreaCode($event)"
      @blur="submitPhoneNumber"
      class="col-md-3 col-sm-6 col-6 form-element-phone-number__field"
    />
    <FormElementInputField
      :label="
        commonTexts.userInterface.formFieldLabels.phoneNumber.subscriberNumber
      "
      :value="subscriberNumber"
      :field-has-error="fieldHasError"
      :field-id="
        commonTexts.userInterface.formFieldLabels.phoneNumber.subscriberNumber +
        fieldIdSlug
      "
      input-type="tel"
      @input="updateSubscriberNumber($event)"
      @blur="submitPhoneNumber"
      class="col-md-6 col-sm-12 col-12 form-element-phone-number__field"
    />
  </FormSection>
</template>

<script lang="ts">
  import FormElementInputField from '@/components/FormElements/FormElementInputField.vue';
  import commonTexts from '@/data/commonTexts';
  import { defineComponent, ref, watch } from 'vue';
  import {
    addNationalTrunkPrefixToAreaCode,
    checkPhoneNumber,
    parseAlphaCountryCodeToPhoneCountryCode,
    removeWhiteSpacesInString,
    splitPhoneNumberInParts,
  } from '@/composables/form-functions';
  import FormSection from '@/components/FormElements/FormSection.vue';

  export default defineComponent({
    name: 'FormElementPhoneNumber',
    components: { FormSection, FormElementInputField },
    props: {
      label: {
        type: String,
        required: true,
      },
      value: {
        type: String,
        required: true,
      },
      country: {
        type: String,
        required: true,
      },
      fieldHasError: {
        type: Boolean,
        required: false,
        default: false,
      },
      fieldIdSlug: {
        type: String,
        required: false,
        default: '',
      },
    },
    emits: ['input'],
    setup(props, { emit }) {
      const COUNTRY_CODE_PREFIX = '+';
      const COUNTRY_CODE_DEFAULT = '49';
      const SUBSCRIBER_NUMBER_SEPARATOR = '-';
      const initialCallingCode = parseAlphaCountryCodeToPhoneCountryCode(
        props.country,
      );
      const countryCode = ref(
        COUNTRY_CODE_PREFIX +
          (initialCallingCode ? initialCallingCode : COUNTRY_CODE_DEFAULT),
      );
      let currentCountry = props.country;

      const areaCode = ref(splitPhoneNumberInParts(props.value).areaCode);
      const subscriberNumber = ref(
        splitPhoneNumberInParts(props.value).subscriberNumber,
      );

      watch(props, () => {
        const phoneNumber = splitPhoneNumberInParts(props.value);
        areaCode.value = phoneNumber.areaCode;
        subscriberNumber.value = phoneNumber.subscriberNumber;

        // Only perform country code parsing, if really necessary:
        if (currentCountry !== props.country) {
          const countryCallingCode = parseAlphaCountryCodeToPhoneCountryCode(
            props.country,
          );
          countryCode.value =
            COUNTRY_CODE_PREFIX +
            (countryCallingCode ? countryCallingCode : COUNTRY_CODE_DEFAULT);
          currentCountry = props.country;
        }
      });

      const updateAreaCode = function (newAreaCode: string) {
        // Pre-validate input: AreaCodes must not contain anything except digits
        const pattern = /^\d+$/;
        areaCode.value = removeWhiteSpacesInString(newAreaCode);
        if (!pattern.test(areaCode.value)) {
          areaCode.value = areaCode.value.replace(new RegExp(/[^\d]/, 'g'), '');
        }
      };

      const updateSubscriberNumber = function (newSubscriberNumber: string) {
        // Pre-validate input: SubscriberNumbers must not contain anything except digits, but may contain a '-'
        //    (not at start and only once)
        const pattern = /^\d+-?\d+$/;
        subscriberNumber.value = removeWhiteSpacesInString(newSubscriberNumber);
        if (!pattern.test(subscriberNumber.value)) {
          const subscriberNumberParts = subscriberNumber.value.split('-');
          if (subscriberNumberParts.length > 1) {
            let normalizedNumber = '';
            for (let i = 0; i < subscriberNumberParts.length; i++) {
              if (i === subscriberNumberParts.length - 1) {
                normalizedNumber += SUBSCRIBER_NUMBER_SEPARATOR;
              }
              normalizedNumber += subscriberNumberParts[i];
            }
            subscriberNumber.value = normalizedNumber;
          }
          subscriberNumber.value = subscriberNumber.value.replace(
            new RegExp(/[^\d-]/, 'g'),
            '',
          );
        }
      };

      const submitPhoneNumber = function () {
        // Only emit value, if number is complete, and valid(?)
        if (
          areaCode.value &&
          subscriberNumber.value &&
          checkPhoneNumber(
            areaCode.value,
            subscriberNumber.value,
            currentCountry,
          )
        ) {
          areaCode.value = addNationalTrunkPrefixToAreaCode(
            areaCode.value,
            subscriberNumber.value,
            currentCountry,
          );
          emit(
            'input',
            areaCode.value +
              SUBSCRIBER_NUMBER_SEPARATOR +
              subscriberNumber.value,
          );
        } else if (areaCode.value && subscriberNumber.value) {
          emit(
            'input',
            areaCode.value +
              SUBSCRIBER_NUMBER_SEPARATOR +
              subscriberNumber.value,
          );
        } else if (areaCode.value === '' && subscriberNumber.value === '') {
          emit('input', '');
        }
      };

      return {
        commonTexts,
        countryCode,
        updateAreaCode,
        submitPhoneNumber,
        updateSubscriberNumber,
        areaCode,
        subscriberNumber,
      };
    },
  });
</script>

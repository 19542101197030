export const ValueAddedTaxTexts = {
  digital: {
    basicText: 'Preis inkl. MwSt.',
  },
  magazine: {
    basicText:
      'Preis inkl. MwSt. (Porto im Ausland abhängig von Land und Anzahl)',
  },
  print: {
    basicText: 'Preis inkl. MwSt.',
    noResultAbroad:
      'Der Preis kann für Ihre Adresse derzeit nicht ermittelt werden. Bitte entnehmen Sie ihn der Angebotsbeschreibung.<br />' +
      'Preis inkl. MwSt. (Lieferung frei Haus)',
    userAbroad: 'Preis inkl. MwSt. (Lieferung frei Haus)',
  },
};

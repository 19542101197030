import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-element-checkbox__inner" }
const _hoisted_2 = ["id", "value", "checked", "disabled"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "form-element-checkbox__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(["form-element-checkbox", {
        'form-element-checkbox--disabled': _ctx.disabled,
        'form-element-checkbox--error': _ctx.hasError,
        'form-element-checkbox--multi-line': _ctx.multiLineLabel,
      }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("input", {
          id: _ctx.name,
          class: "form-element-checkbox__input",
          type: "checkbox",
          value: _ctx.inputValue,
          checked: _ctx.value,
          disabled: _ctx.disabled,
          onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args)))
        }, null, 40, _hoisted_2),
        _createElementVNode("label", { for: _ctx.name }, [
          _createElementVNode("span", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ], 8, _hoisted_3)
      ])
    ], 2)
  ]))
}
<template>
  <a v-if="isLink && href" :href="href">
    <slot />
  </a>
  <a v-else-if="href" :href="href" class="basic-button__container">
    <button
      type="button"
      :disabled="disabled"
      @click="$emit('buttonClicked')"
      class="basic-button"
      :class="[
        {
          'basic-button--primary': variant === 'primary',
          'basic-button--none': variant === 'none',
          'basic-button--link': variant === 'link',
          'basic-button--transparent': transparent,
          'basic-button--sm': size === 'sm',
          'basic-button--disabled': disabled,
          'basic-button--no-arrow': noArrow,
        },
        additionalButtonClasses,
      ]"
    >
      <slot />
    </button>
  </a>
  <button
    v-else
    type="button"
    :disabled="disabled"
    @click="$emit('buttonClicked')"
    class="basic-button"
    :class="[
      {
        'basic-button--primary': variant === 'primary',
        'basic-button--none': variant === 'none',
        'basic-button--link': variant === 'link',
        'basic-button--transparent': transparent,
        'basic-button--sm': size === 'sm',
        'basic-button--disabled': disabled,
        'basic-button--no-arrow': noArrow,
      },
      additionalButtonClasses,
    ]"
  >
    <slot />
  </button>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';

  export default defineComponent({
    name: 'BasicButton',
    props: {
      transparent: {
        type: Boolean,
        required: false,
        default: false,
      },
      size: {
        type: String,
        required: false,
        default: '',
      },
      variant: {
        type: String as PropType<'primary' | 'none' | 'link'>,
        required: false,
        default: 'primary',
      },
      href: {
        type: String,
        required: false,
        default: '',
      },
      noArrow: {
        type: Boolean,
        required: false,
        default: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      additionalButtonClasses: {
        type: String,
        required: false,
        default: '',
      },
      isLink: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['buttonClicked'],
  });
</script>

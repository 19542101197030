<template>
  <div class="osc-address-form">
    <div class="form-row">
      <FormElementSelectField
        v-if="processBusinessAddress && businessAddress"
        label="Land"
        class="col-md-12"
        :selectOptions="countryOptions"
        :value="businessAddress.country"
        @input="updateCountry($event)"
        :field-has-error="fieldErrorsBusiness.country"
        @selectionChanged="$emit('resetFieldErrorBusiness', 'country')"
      />
      <FormElementSelectField
        v-else
        label="Land"
        class="col-md-12"
        :selectOptions="countryOptions"
        :value="orderFormData.country"
        @input="updateCountry($event)"
        :field-has-error="fieldErrors.country"
        @selectionChanged="$emit('resetFieldError', 'country')"
      />
    </div>
    <div v-if="addressRequired && !isDigitalProductType">
      <div class="">
        <FormElementRadioButtons
          :value="
            processBusinessAddress
              ? businessAddress
                ? businessAddress.addressType
                : orderFormData.addressType
              : orderFormData.addressType
          "
          @input="updateSelectedAddressType($event)"
          :radioOptions="formAddressTypeOptions"
          class="mb-4"
        />
      </div>
      <PostOfficeBoxForm
        v-if="postOfficeBoxSelected && !processBusinessAddress"
        :address-data="orderFormData"
        :field-errors="fieldErrors"
        :address-check-result="addressCheckResult?.privateAddress"
        @addressDataInput="updatePrivateAddressData($event)"
        @resetFieldError="$emit('resetFieldError', $event)"
      />
      <PostOfficeBoxForm
        v-else-if="
          postOfficeBoxSelected && processBusinessAddress && businessAddress
        "
        :address-data="businessAddress"
        :field-errors="fieldErrorsBusiness"
        :address-check-result="addressCheckResult?.businessAddress"
        @addressDataInput="updateBusinessAddressData($event)"
        @resetFieldError="$emit('resetFieldErrorBusiness', $event)"
      />
      <StreetAddressForm
        v-else-if="processBusinessAddress && businessAddress"
        is-business-address
        :address-data="businessAddress"
        :field-errors="fieldErrorsBusiness"
        field-id-slug="business"
        :address-check-result="addressCheckResult?.businessAddress"
        @addressDataInput="updatePrivateAddressData($event)"
        @resetFieldError="$emit('resetFieldErrorBusiness', $event)"
      />
      <StreetAddressForm
        v-else
        :address-data="orderFormData"
        :field-errors="fieldErrors"
        field-id-slug="private"
        :address-check-result="addressCheckResult?.privateAddress"
        @addressDataInput="updatePrivateAddressData($event)"
        @resetFieldError="$emit('resetFieldError', $event)"
      />
    </div>
    <div v-if="addressRequired && isDigitalProductType">
      <StreetAddressFormSimple
        class="mt-4"
        :address-data="orderFormData"
        :field-errors="fieldErrors"
        field-id-slug="digital"
        :address-check-result="addressCheckResult?.privateAddress"
        @addressDataInput="updatePrivateAddressData($event)"
        @resetFieldError="$emit('resetFieldError', $event)"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, Ref, watch, PropType } from 'vue';
  import { addressTypeOptions } from '@/utils/selectOptions';
  import StreetAddressForm from '@/components/Forms/StreetAddressForm.vue';
  import StreetAddressFormSimple from '@/components/Forms/StreetAddressFormSimple.vue';
  import PostOfficeBoxForm from '@/components/Forms/PostOfficeBoxForm.vue';
  import FormElementSelectField from '@/components/FormElements/FormElementSelectField.vue';
  import FormElementRadioButtons from '@/components/FormElements/FormElementRadioButtons.vue';
  import { OrderFormData, AddressTypeLiteral } from '@/types/OrderFormTypes';
  import PrintProductTypeLiteral from '@/utils/printProductTypeLiteral';
  import { FieldErrorsAddressData } from '@/validators/validatorTypes';
  import {
    AddressValidationResult,
    FormBusinessAddress,
  } from '@/models/OrderData';

  export default defineComponent({
    name: 'AddressForm',
    components: {
      StreetAddressFormSimple,
      PostOfficeBoxForm,
      StreetAddressForm,
      FormElementSelectField,
      FormElementRadioButtons,
    },
    emits: ['addressDataInput', 'resetFieldErrorBusiness', 'resetFieldError'],
    props: {
      orderFormData: {
        type: Object as PropType<OrderFormData>, // OrderFormData to handle future address form adjustments easier (several addresses etc.)
        required: true,
      },
      fieldErrors: {
        type: Object as PropType<FieldErrorsAddressData>,
        required: true,
      },
      fieldErrorsBusiness: {
        type: Object as PropType<FieldErrorsAddressData>,
        required: true,
      },
      addressCheckResult: {
        type: Object as PropType<AddressValidationResult>,
        required: false,
      },
      addressRequired: {
        type: Boolean,
        required: false,
        default: true,
      },
      countryOptions: {
        type: Array,
        required: true,
      },
      productType: {
        type: String,
        required: true,
      },
      processBusinessAddress: {
        type: Boolean,
        required: false,
        default: false,
      },
      businessAddress: {
        type: Object as PropType<FormBusinessAddress>,
        required: false,
      },
    },
    setup(props, { emit }) {
      const productType = ref(props.productType);
      const isPrintProductType: Ref<boolean> = ref(
        productType.value === PrintProductTypeLiteral.PRINT,
      );
      const isDigitalProductType: Ref<boolean> = ref(
        productType.value === PrintProductTypeLiteral.DIGITAL,
      );
      const formAddressTypeOptions = addressTypeOptions;

      watch(props, () => {
        if (props.productType !== productType.value) {
          productType.value = props.productType;
          isPrintProductType.value =
            productType.value === PrintProductTypeLiteral.PRINT;
          isDigitalProductType.value =
            productType.value === PrintProductTypeLiteral.DIGITAL;
        }
      });

      const postOfficeBoxSelected = computed(() => {
        if (props.processBusinessAddress) {
          return (
            props.businessAddress?.addressType ===
            AddressTypeLiteral.POST_OFFICE_BOX
          );
        } else {
          return (
            props.orderFormData.addressType ===
            AddressTypeLiteral.POST_OFFICE_BOX
          );
        }
      });

      const emitInput = function (data: OrderFormData | FormBusinessAddress) {
        emit('addressDataInput', data);
      };

      const updateSelectedAddressType = function (
        newSelectedAddressType: string,
      ) {
        const data = props.processBusinessAddress
          ? Object.assign({}, props.businessAddress)
          : Object.assign({}, props.orderFormData);

        data.addressType = newSelectedAddressType as AddressTypeLiteral;
        emitInput(data);
      };

      const updateCountry = function (newCountry: string) {
        const data = props.processBusinessAddress
          ? Object.assign({}, props.businessAddress)
          : Object.assign({}, props.orderFormData);
        data.country = newCountry;
        emitInput(data);
      };

      const updatePrivateAddressData = function (input: OrderFormData) {
        const data = Object.assign({}, input);
        emitInput(data);
      };

      const updateBusinessAddressData = function (input: OrderFormData) {
        const data = Object.assign({}, input);
        emitInput(data);
      };

      return {
        updateCountry,
        updateSelectedAddressType,
        updatePrivateAddressData,
        postOfficeBoxSelected,
        formAddressTypeOptions,
        isPrintProductType,
        isDigitalProductType,
        updateBusinessAddressData,
      };
    },
  });
</script>

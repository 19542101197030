import {
  CheckoutTypes,
  GtmGenericEventData,
  GtmGenericEventOrderBonusData,
  OrderBonusGtm,
} from '@/models/Gtm';
import store from '@/store';
import md5 from 'md5';
import { SingleUserOrderBonus } from '@/types/OrderBonusesTypes';
import {
  getEmailFromAddresses,
  getGpNrOfPurchaser,
} from '@/composables/general-functions';

export default class GtmService {
  public static getCheckoutType() {
    if (store.state.formData.salesCampaign !== null) {
      if (store.state.formData.salesCampaign.giftSubscription) {
        return CheckoutTypes.GIFT_SUBSCRIPTION;
      } else if (store.state.formData.salesCampaign.lwl) {
        return CheckoutTypes.READERS_RECRUIT_READERS_SUBSCRIPTION;
      }
    }
    return CheckoutTypes.STANDARD_SUBSCRIPTION;
  }

  public static parseOrderBonusesForGtm(
    orderBonuses: SingleUserOrderBonus[],
  ): OrderBonusGtm[] {
    return orderBonuses.map((orderBonus: SingleUserOrderBonus) => {
      return {
        id: orderBonus.bonus.materialNumber,
        name: orderBonus.bonus.title,
      };
    });
  }

  public static pushGenericEvent(data: GtmGenericEventData) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataLayer = (window as any).dataLayer;
    const { category, action, label } = data;
    let bonuses = undefined;

    if (store.state.orderBonusCart && store.state.orderBonusCart.length > 0) {
      bonuses = this.parseOrderBonusesForGtm(store.state.orderBonusCart);
    }

    dataLayer.push({
      event: 'genericevent',
      genericevent: {
        category: category,
        action: action,
        label: label,
        checkoutTyp: this.getCheckoutType(),
        zugabe: bonuses,
      },
    });
  }

  public static pushOrderBonusEvent(data: GtmGenericEventOrderBonusData) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataLayer = (window as any).dataLayer;
    const { category, action, label, orderBonuses } = data;

    dataLayer.push({
      event: 'genericevent',
      genericevent: {
        category: category,
        action: action,
        label: label,
        checkoutTyp: this.getCheckoutType(),
        zugabe: this.parseOrderBonusesForGtm(orderBonuses),
      },
    });
  }

  public static pushTransactionEvent() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataLayer = (window as any).dataLayer;
    const salesCampaignData = store.state.formData.salesCampaign;
    const email = getEmailFromAddresses(store.state.formData.addresses);
    const crossid = email ? md5(email) : 'no email';

    let bonuses = undefined;

    if (store.state.orderBonusCart && store.state.orderBonusCart.length > 0) {
      bonuses = this.parseOrderBonusesForGtm(store.state.orderBonusCart);
    }

    const wt =
      store.state.parameters.advertisingMedium === undefined
        ? undefined
        : store.state.parameters.advertisingMedium;
    dataLayer.push({
      event: 'transaction',
      products: {
        id: salesCampaignData.salesCampaignId,
        name: salesCampaignData.name,
        product_price: salesCampaignData.priceForTracking,
        product_price_net: salesCampaignData.priceNetForTracking,
        currency: 'EUR',
        bonusName: '', // empty for now
        categoryName: store.state.formData.trackingData.categoryName,
        zugabe: bonuses,
      },
      conversion: {
        type: 'sale',
        transaction_id: store.state.transactionId,
        temporary_id: store.state.createOrderResponse.temporaryOrderId
          ? store.state.createOrderResponse.temporaryOrderId
          : '',
        crossid: crossid,
        total_price: salesCampaignData.priceForTracking,
        total_price_net: salesCampaignData.priceNetForTracking,
        cuid: getGpNrOfPurchaser(),
        werbetraeger: wt,
      },
    });
  }
}

import { completeSalutationOptions } from '@/utils/selectOptions';

export const user = {
  salutationText(salutationID: string): string {
    const salutation = completeSalutationOptions.find(
      (object) => object.value === salutationID,
    );
    if (salutation) {
      return salutation.text;
    } else {
      return '';
    }
  },
};

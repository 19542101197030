import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f009a692"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "errorMsg message-box" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["error-message", {
        'no-bg': _ctx.type === 'no-bg',
        'yellow': _ctx.type === 'yellow',
      }]),
      innerHTML: _ctx.content
    }, null, 10, _hoisted_2)
  ]))
}
<template>
  <div class="side-area">
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'SideArea',
  });
</script>

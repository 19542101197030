<template>
  <div class="row" :class="additionalClass">
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FormRow',
    props: {
      additionalClass: {
        type: String,
        required: false,
        default: '',
      },
    },
  });
</script>

<template>
  <ContentArea>
    <div>
      <div class="order-bonus-view">
        <header>
          <h2 class="main-content-title">
            {{
              commonFrontendTexts.userFrontend.orderBonusSelectionView.mainTitle
            }}
          </h2>
        </header>
        <div>
          <span class="mb-4 d-block">{{
            commonFrontendTexts.userFrontend.orderBonusSelectionView.mainText
          }}</span>
        </div>
        <OrderBonusSelectionForm
          :order-bonuses="bonuses"
          :selected-bonuses="orderBonusCart"
          :cart-is-full="orderBonusCartIsFull"
          @orderBonusSelected="toggleOrderBonusCartState($event)"
          @resetConfiguration="setComplexOrderBonusConfiguration([])"
          @complexOrderBonusFullyConfigured="
            setComplexOrderBonusConfiguration($event)
          "
        />
        <div>
          <span class="d-block" v-if="!orderBonusCartIsFull">{{
            commonFrontendTexts.userFrontend.orderBonusSelectionView
              .confirmationText
          }}</span>
        </div>
        <BasicButton
          variant="primary"
          type="submit"
          class="main-content-button btn-save"
          :disabled="!orderBonusCartIsFull"
          @buttonClicked="$emit('confirmOrderBonusSelection')"
          >Weiter
        </BasicButton>
      </div>
    </div>
  </ContentArea>
</template>

<script lang="ts">
  import { defineComponent, PropType, Ref, ref, watch } from 'vue';
  import commonTexts from '@/data/commonTexts';
  import { SingleUserOrderBonus } from '@/types/OrderBonusesTypes';
  import OrderBonusSelectionForm from '@/components/Forms/OrderBonusSelectionForm.vue';
  import { FormData } from '@/models/OrderData';
  import GtmService from '@/services/gtm-service';
  import {
    createOrderBonusFromVariantConfiguration,
    isComplexOrderBonus,
  } from '@/composables/order-bonuses';
  import ContentArea from '@/components/PageLayout/ContentArea.vue';
  import { useStore } from 'vuex';
  import BasicButton from '@/components/Buttons/BasicButton.vue';

  export default defineComponent({
    name: 'OrderBonus',
    components: { OrderBonusSelectionForm, ContentArea, BasicButton },
    props: {
      formData: {
        type: Object as PropType<FormData>,
        required: true,
      },
      orderBonusCart: {
        type: Array as PropType<SingleUserOrderBonus[]>,
        required: true,
      },
      bonusesLoaded: {
        type: Boolean,
        required: true,
      },
      orderBonusCartIsFull: {
        type: Boolean,
        required: true,
      },
    },
    emits: [
      'confirmOrderBonusSelection',
      'removeItemFromCart',
      'formPrefilled',
    ],
    setup(props, { emit }) {
      const store = useStore();
      const initialFormData: Ref<FormData> = ref(props.formData);
      const orderBonusCart: Ref<SingleUserOrderBonus[]> = ref(
        props.orderBonusCart,
      );

      const complexOrderBonusConfiguration: Ref<string[]> = ref([]);
      const setComplexOrderBonusConfiguration = function (
        newConfiguration: string[],
      ) {
        complexOrderBonusConfiguration.value = newConfiguration;
      };

      const commonFrontendTexts = commonTexts;
      const bonuses: Ref<SingleUserOrderBonus[]> = ref(
        props.formData.salesCampaign.bonuses
          ? props.formData.salesCampaign.bonuses
          : [],
      );

      const toggleOrderBonusCartState = function (materialNumber: string) {
        const foundBonus = bonuses.value.find((bonus: SingleUserOrderBonus) => {
          return bonus.bonus.materialNumber === materialNumber;
        });
        // In case of complex bonuses, selectedBonus.bonus will be overwritten
        //    => Make a copy of foundBonus and not write directly into reference!
        let selectedBonus: SingleUserOrderBonus | undefined = undefined;
        if (foundBonus) {
          selectedBonus = Object.assign({}, foundBonus);
        }

        // If selected bonus is a complex bonus…
        if (selectedBonus && isComplexOrderBonus(selectedBonus.bonus)) {
          // …create a virtual orderBonus from the variant data
          const variant = createOrderBonusFromVariantConfiguration(
            selectedBonus.bonus,
            complexOrderBonusConfiguration.value,
          );
          if (variant) {
            selectedBonus.bonus = variant;
          } else {
            return;
          }
        }

        // 1. If Bonus exists, add or remove Bonus
        if (selectedBonus) {
          const selectedBonusAlreadyAdded = orderBonusCart.value.some(
            (alreadySelectedBonus: SingleUserOrderBonus) =>
              alreadySelectedBonus.bonus.materialNumber ===
              selectedBonus?.bonus.materialNumber,
          );

          // 2a. Only add a bonus, if not already added and if cart is not full
          if (!selectedBonusAlreadyAdded && !props.orderBonusCartIsFull) {
            orderBonusCart.value.push(selectedBonus);

            // Fixed bonuses are not added by user interaction, but automatically
            if (!selectedBonus.fixedBonus) {
              GtmService.pushOrderBonusEvent({
                category: 'Checkout',
                action: 'Zugabe ausgewaehlt',
                label: store.state.formData.salesCampaign.salesCampaignId,
                orderBonuses: [selectedBonus],
              });
            }
            // 2b. If already in cart: Remove from cart by MaterialNumber
          } else if (selectedBonusAlreadyAdded) {
            emit('removeItemFromCart', selectedBonus.bonus.materialNumber);
          }
        }
      };

      const addFixedItemsToCart = function () {
        bonuses.value.forEach((bonus: SingleUserOrderBonus) => {
          if (bonus.fixedBonus) {
            toggleOrderBonusCartState(bonus.bonus.materialNumber);
          }
        });
      };

      watch(props, () => {
        initialFormData.value = props.formData;
        addFixedItemsToCart();

        if (props.bonusesLoaded) {
          emit('formPrefilled');
        }
      });

      return {
        initialFormData,
        commonFrontendTexts,
        bonuses,
        toggleOrderBonusCartState,
        setComplexOrderBonusConfiguration,
      };
    },
  });
</script>

<template>
  <div class="icon-trash-can">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g fill="none" fill-rule="evenodd" v-if="visible">
        <g
          transform="translate(-1141.000000, -960.000000) translate(835.000000, 864.000000) translate(22.000000, 57.000000) translate(284.000000, 39.000000)"
        >
          <path
            stroke="#ffffff"
            stroke-width="1.5"
            d="M19.313 5.878L17.688 21.316 6.313 21.316 4.688 5.878"
            class="icon-trash-can__path"
          />
          <path
            stroke="#ffffff"
            fill="#D8D8D8"
            d="M2.5 5.566H21.5V6.566H2.5z"
            class="icon-trash-can__path"
          />
          <path
            stroke="#ffffff"
            stroke-width="1.5"
            d="M8.499 5.584L10.291 2 13.709 2 15.501 5.584"
            class="icon-trash-can__path"
          />
          <path d="M0 0H24V24H0z" />
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'IconTrashCan',
    props: {
      visible: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
  });
</script>

import { ViolationFields } from '@/validators/validatorTypes';
import { OrderFormData } from '@/types/OrderFormTypes';
import { FormBusinessAddress, FormDeviatingAddress } from '@/models/OrderData';

export class CountryValidator {
  validate(
    target: OrderFormData | FormBusinessAddress | FormDeviatingAddress,
    availableCountryOptions: { text: string; value: string }[],
  ): ViolationFields[] {
    const violations: ViolationFields[] = [];

    // If Country is empty or not contained in available country options
    if (
      !target.country ||
      !availableCountryOptions.some(
        (countryOption) => countryOption.value === target.country,
      )
    ) {
      violations.push(ViolationFields.COUNTRY);
    }

    return violations;
  }
}

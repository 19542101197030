import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "order-students-view" }
const _hoisted_2 = { class: "main-content-title" }
const _hoisted_3 = { class: "order-students-view__copy" }
const _hoisted_4 = { class: "order-students-view__form" }
const _hoisted_5 = { class: "order-students-view__submit" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StudentEnrollmentUploadForm = _resolveComponent("StudentEnrollmentUploadForm")!
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_ContentArea = _resolveComponent("ContentArea")!

  return (_openBlock(), _createBlock(_component_ContentArea, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("header", null, [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.commonTexts.userFrontend.studentEnrollmentForm.headline), 1)
          ]),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.commonTexts.userFrontend.studentEnrollmentForm.copy), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_StudentEnrollmentUploadForm, {
              "file-name": _ctx.imageUpload.fileName
            }, null, 8, ["file-name"])
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BasicButton, {
              variant: "primary",
              type: "submit",
              onButtonClicked: _ctx.proceedToPayment
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.commonTexts.userFrontend.studentEnrollmentForm.submitButtonLabel), 1)
              ]),
              _: 1
            }, 8, ["onButtonClicked"])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
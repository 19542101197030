import { ViolationFields } from '@/validators/validatorTypes';

export class MilesAndMoreValidator {
  private getMultiplier = (position: number, numberLength: number) => {
    // Multiplier depends on length of number that must be validated
    // for an even number of digits (including check digit at last position)
    if (numberLength % 2 === 0) {
      // digits at an even index-position must be multiplied by 2, else 1
      return position % 2 === 0 ? 2 : 1;
    } else {
      // for an odd number of digits (including check digit at last position)
      // digits at an even index-position must be multiplied by 1, else 2
      return position % 2 === 0 ? 1 : 2;
    }
  };

  private getDigitalSum = (a: number) => {
    // If number has more than 1 digit:
    if (a > 9) {
      let value = a;
      let sum = 0;
      // get digital sum by adding remainder to sum
      //    and reducing value by last digit
      //    (proceed until value is empty)
      while (value) {
        sum += value % 10;
        value = Math.floor(value / 10);
      }
      return sum;
    } else {
      return a;
    }
  };

  private getNearestTen = (a: number) => {
    let roundedNumber = a;
    if (roundedNumber % 10 !== 0) {
      roundedNumber = Math.ceil(roundedNumber / 10) * 10;
    }
    return roundedNumber;
  };

  validate(target: string): ViolationFields[] {
    const violations: ViolationFields[] = [];
    // extract digits to check
    //    (Miles&More numbers consist of either 9, 15, or 16 digits with
    //    the last digit being the check-value)
    const numberToCheck = target.substring(0, target.length - 1);
    let errorNoNumber = false;

    let resultSum = 0;
    // 1. Get sum of digits (1st to second to last position)
    for (let i = numberToCheck.length - 1; i >= 0; i--) {
      // 2. Get multiplier for digit according to target length and position
      //      (either 1 or 2)
      const multiplier = this.getMultiplier(i, target.length);
      // 3. Get digit of current position
      const number = parseInt(numberToCheck[i], 10);
      // 4. If digit is not a number: Abort and add violation
      if (isNaN(number)) {
        errorNoNumber = true;
        break;
      } else {
        // Add calculated digit value to sum
        resultSum += this.calculateDigitValue(number, multiplier);
      }
    }

    // If a digit was not a number: add violation
    if (errorNoNumber) {
      violations.push(ViolationFields.MILES_AND_MORE);
    } else {
      // 5. Round number to nearest 10 above (e.g. 71 to 80)
      const roundedNumber = this.getNearestTen(resultSum);
      // 6: Get number that must be equal to check-value:
      const numberToValidate = roundedNumber - resultSum;
      if (numberToValidate !== parseInt(target[target.length - 1], 10)) {
        violations.push(ViolationFields.MILES_AND_MORE);
      }
    }

    return violations;
  }

  // Calculates value of digit to be added to sum:
  // 1. Multiply digit by multiplier
  // 2. If result has 2 digits, build digital sum (done in getDigitalSum(number: number))
  private calculateDigitValue(a: number, multiplier: number) {
    let result = a * multiplier;
    result = this.getDigitalSum(result);
    return result;
  }
}

import store from '@/store';
import SetupService from '@/services/setup-service';
import router from '@/router';
import { ConnectorFactory } from '@/services/connectors/connectorFactory';
import {
  SalesCampaignDetailsData,
  SingleSalesCampaignData,
} from '@/models/SalesCampaigns';
import { AxiosError } from 'axios';

export default class InternalAdminsService {
  public static tenant = SetupService.getTenantId();

  public static requestAdminOptions() {
    // return internalAPI
    //   .get('home')
    const internalAdminConnector = ConnectorFactory.getInternalAdminConnector();
    return internalAdminConnector
      .getAdminData()
      .then((response) => {
        store.commit('changeAdminData', response);
      })
      .catch((error) => {
        console.log(error.response.data);
        router.push({ name: 'Start' });
      });
  }

  public static requestUsersList(url: string) {
    const internalAdminConnector = ConnectorFactory.getInternalAdminConnector();
    // return internalAPI
    //   .get(url)
    return internalAdminConnector
      .getAdminUserData(url)
      .then((response) => {
        const adminUsers = { ...response };
        if (!adminUsers._embedded) {
          adminUsers._embedded = { users: [] };
          // response._embedded.users = [];
        }
        store.commit('changeUsersList', adminUsers);
      })
      .catch((error) => {
        console.log(error.response.data);
        router.push({ name: 'Internal' });
      });
  }

  public static addNewUser(url: string, newUserMail: string) {
    const internalAdminConnector = ConnectorFactory.getInternalAdminConnector();
    // return internalAPI
    //   .post(url, newUserMail, {
    //     headers: { 'Content-Type': 'text/plain' },
    //   })
    return internalAdminConnector
      .addNewUser(url, newUserMail)
      .catch((error) => {
        console.log(error.response.data);
        router.push({ name: 'Internal' });
      });
  }
  public static deleteUser(url: string, selectedUser: string) {
    const internalAdminConnector = ConnectorFactory.getInternalAdminConnector();
    // return internalAPI.delete(url, { data: selectedUser })
    return internalAdminConnector
      .deleteUser(url, selectedUser)
      .catch((error) => {
        console.log(error.response.data);
        router.push({ name: 'Internal' });
      });
  }

  public static requestSalesCampaignsListForAdmin(selectedTenant: string) {
    // return internalAPI
    //   .get('admin/availableSalesCampaigns/' + selectedTenant)
    //   .then((response) => {
    const internalAdminConnector = ConnectorFactory.getInternalAdminConnector();
    return internalAdminConnector
      .getSalesCampaignsApprovalList(selectedTenant)
      .then((response) => {
        store.commit('changeSalesCampaignsListForAdmin', response);
      })
      .catch((error) => {
        console.log(error.response.data);
        router.push({ name: 'Internal' });
      });
  }

  public static saveApprovedSalesCampaigns(
    selectedTenant: string,
    selectedSalesCampaigns: string[],
  ) {
    // return internalAPI
    //   .post(
    //     'admin/approvedSalesCampaigns/' + selectedTenant,
    //     selectedSalesCampaigns,
    //   )
    const internalAdminConnector = ConnectorFactory.getInternalAdminConnector();
    return internalAdminConnector
      .saveApprovedSalesCampaigns(selectedTenant, selectedSalesCampaigns)
      .catch((error) => {
        console.log(error.response.data);
        router.push({ name: 'Internal' });
      });
  }

  public static requestSalesCampaignsListForTenant() {
    const internalAdminConnector = ConnectorFactory.getInternalAdminConnector();
    return (
      internalAdminConnector
        .getSalesCampaignsForTenant()
        // return internalAPI
        //   .get('tenant/salesCampaigns')
        .then((response) => {
          store.commit('changeSalesCampaignsListForTenant', response);
        })
        .catch((error) => {
          console.log(error.response.data);
          router.push({ name: 'Internal' });
        })
    );
  }

  public static requestSingleSalesCampaign(salesCampaignId: string) {
    const internalAdminConnector = ConnectorFactory.getInternalAdminConnector();
    return (
      internalAdminConnector
        .getSalesCampaignById(salesCampaignId)
        // return internalAPI
        //   .get('/tenant/salesCampaign/' + salesCampaignId)
        .then((response) => {
          store.commit('changeSingleSalesCampaign', response.salesCampaign);
          store.commit(
            'changeBillingFrequencyGroups',
            response.billingFrequencyGroups,
          );
          store.commit('changeCountryGroups', response.countryGroups);
        })
        .catch((error) => {
          console.log(error.response.data);
          router.push({ name: 'Internal' });
        })
    );
  }

  public static async saveSingleSalesCampaign(
    salesCampaignData: SingleSalesCampaignData | SalesCampaignDetailsData,
  ) {
    // return internalAPI
    //   .post('/tenant/salesCampaign', JSON.stringify(salesCampaignData), {
    //     headers: { 'Content-Type': 'application/json' },
    //   })
    const internalAdminConnector = ConnectorFactory.getInternalAdminConnector();
    try {
      return await internalAdminConnector.saveSingleSalesCampaign(
        salesCampaignData,
      );
    } catch (error) {
      if ((error as AxiosError).response?.data) {
        console.log((error as AxiosError).response?.data);
      } else {
        console.log(error);
      }
      await router.push({ name: 'SalesCampaignsOverview' });
    }
  }

  public static convertCountryGroups(countryGroups: {
    [key: string]: string;
  }): { value: string; text: string }[] {
    const convertedCountryGroups = [];

    for (const [key, value] of Object.entries(countryGroups)) {
      convertedCountryGroups.push({ value: key, text: value as string });
    }
    return convertedCountryGroups;
  }
}

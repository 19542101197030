import { PaymentConnectorInterface } from '@/services/connectors/interfaces';
import PaymentMethodLiteral from '@/utils/paymentMethodLiteral';
import { customizingAPI, orderAPI, paymentAPI } from '@/services/http-service';
import store from '@/store';
import { AxiosResponse } from 'axios';
import {
  BasicCheckRequestData,
  PaymentUrlProcessResponse,
} from '@/types/PaymentTypes';
import { FormDataToken } from '@/models/BaseTypes';
import {
  CreditCardData,
  CreditCardIFrameData,
  CreditCardPaymentInitRequestData,
  PayPalData,
  PayPalPaymentInitRequestData,
} from '@/models/PaymentServiceRequest';

export default class PaymentConnector implements PaymentConnectorInterface {
  getBillingFrequencies(
    salesCampaignId: string,
    paymentMethod: PaymentMethodLiteral,
  ): Promise<AxiosResponse> {
    return customizingAPI.get(
      `salesCampaign/${salesCampaignId}/paymentMethod/${paymentMethod}/billingFrequencies`,
      { headers: { token: JSON.stringify(store.state.formDataToken) } },
    );
  }

  checkIbanSwift(iban: string, swift: string): Promise<AxiosResponse> {
    return orderAPI.post(
      'order/checkIbanSwift',
      {
        iban: iban,
        swift: swift,
      },
      { headers: { token: JSON.stringify(store.state.formDataToken) } },
    );
  }

  getCreditCardIFrameUrl(
    data: BasicCheckRequestData,
    formDataToken: FormDataToken | null,
  ): Promise<PaymentUrlProcessResponse> {
    return paymentAPI
      .post('creditcard/check/init', data, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response) => {
        return response.data;
      });
  }

  getPayPalAuthorizationUrl(
    data: PayPalPaymentInitRequestData,
    formDataToken: FormDataToken | null,
  ): Promise<PaymentUrlProcessResponse> {
    const path = 'paypal/billingagreement/init';

    return paymentAPI
      .post(path, data, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response) => response.data);
  }

  getPaymentResult(
    processId: string,
    serviceTicket: string,
    uri: string,
    formDataToken: FormDataToken | null,
  ): Promise<CreditCardData | PayPalData> {
    return paymentAPI
      .get(
        uri + '?processId=' + processId + '&serviceTicket=' + serviceTicket,
        { headers: { token: JSON.stringify(formDataToken) } },
      )
      .then((response) => {
        return response.data;
      });
  }

  getCreditCardAuthorizationUrl(
    data: CreditCardPaymentInitRequestData,
    formDataToken: FormDataToken | null,
  ): Promise<PaymentUrlProcessResponse> {
    return paymentAPI
      .post('creditcard/billingagreement/init', data, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response) => {
        return response.data;
      });
  }

  getCreditCardIframeData(
    processId: string,
    serviceTicket: string,
    formDataToken: FormDataToken | null,
  ): Promise<CreditCardIFrameData> {
    return paymentAPI
      .get(
        'creditcard/check/result' +
          '?processId=' +
          processId +
          '&serviceTicket=' +
          serviceTicket,
        { headers: { token: JSON.stringify(formDataToken) } },
      )
      .then((response) => {
        return response.data;
      });
  }

  getCreditCardOneOffPaymentAuthorizationUrl(
    data: CreditCardPaymentInitRequestData,
    formDataToken: FormDataToken | null,
  ): Promise<PaymentUrlProcessResponse> {
    return paymentAPI
      .post('creditcard/oneoffpayment/init', data, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response) => {
        return response.data;
      });
  }
}

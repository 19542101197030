import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "order-bonus-shopping-cart-form" }
const _hoisted_2 = { class: "order-bonus-shopping-cart-form__content" }
const _hoisted_3 = { class: "order-bonus-shopping-cart-form__title" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "order-bonus-shopping-cart-form__button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisplayElementOrderBonusCartContent = _resolveComponent("DisplayElementOrderBonusCartContent")!
  const _component_BasicButton = _resolveComponent("BasicButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.commonFrontendTexts.userFrontend.orderBonusSelectionView.cartHeadline), 1),
      (_ctx.showEmptyState)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.commonFrontendTexts.userFrontend.orderBonusSelectionView
            .confirmationText), 1))
        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.selectedOrderBonuses, (orderBonus, index) => {
            return (_openBlock(), _createBlock(_component_DisplayElementOrderBonusCartContent, {
              "order-bonus": orderBonus.bonus,
              "non-removable-bonus": orderBonus.fixedBonus,
              key: orderBonus.bonus.materialNumber,
              class: _normalizeClass({ 'mt-1': index > 0 }),
              onRemoveItem: ($event: any) => (_ctx.$emit('removeItemFromCart', index))
            }, null, 8, ["order-bonus", "non-removable-bonus", "class", "onRemoveItem"]))
          }), 128))
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_BasicButton, {
        variant: "primary",
        type: "submit",
        class: "mt-0 btn-save",
        onButtonClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('confirmOrderBonusSelection'))),
        disabled: _ctx.showEmptyState || !_ctx.orderBonusCartIsFull
      }, {
        default: _withCtx(() => [
          _createTextVNode("Weiter ")
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}
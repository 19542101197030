<template>
  <div class="vld-parent">
    <div class="overlay">
      <loading
        v-if="show ? true : null"
        :active="show"
        :is-full-page="isFullPage"
        :lock-scroll="true"
        :opacity="opacity"
      >
        <Spinner :size="spinerSize" :line-size="4" />
      </loading>
    </div>
    <slot />
  </div>
</template>

<script type="ts">
  import { defineComponent } from 'vue';
  import Loading from 'vue3-loading-overlay';
  import Spinner from 'vue-simple-spinner/src/components/Spinner.vue'

  export default defineComponent({
    name: 'TheOverlay',
    components: { Loading, Spinner },
    props: {
      isFullPage: {
          type: Boolean,
          required: false,
          default: true,
      },
      spinerSize: {
        type: Number,
        required: false,
        default: 20,
      },
      opacity: {
        type: Number,
        required: false,
        default: 0.8,
      },
      show: {
        type: Boolean,
        required: true,
        default: false,
      }
    },
    // setup() {
    //   return {  }
    // },
  })
</script>

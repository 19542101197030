<template>
  <div class="icon-success-check">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="20"
      viewBox="0 0 23 20"
    >
      <g fill="none" fill-rule="evenodd">
        <g
          transform="translate(-513.000000, -897.000000) translate(245.000000, 569.000000) translate(40.000000, 268.000000) translate(0.000000, 40.000000) translate(151.320455, 20.000000) translate(77.293182, 0.000000)"
        >
          <ellipse
            class="icon-success-check__fill"
            cx="10.886"
            cy="10"
            fill="#000000"
            rx="10.886"
            ry="10"
          />
          <g stroke="#FFF" stroke-linecap="square" stroke-width="1.5">
            <path
              d="M0 3.333L2.419 5.556 8.467 0"
              transform="translate(6.652778, 7.222222)"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'IconSuccessCheck',
  });
</script>

import { OrderFormData } from '@/types/OrderFormTypes';
import { FormBusinessAddress, FormDeviatingAddress } from '@/models/OrderData';
// import { UserInterfaceMessage } from '@/utils/userInterfaceMessages';

export interface ValidatorInterface {
  validate(
    target: OrderFormData | FormBusinessAddress | FormDeviatingAddress | string,
  ): ViolationFields[];
}

export type AddressViolations = {
  common: ViolationFields[];
  privateAddress: ViolationFields[];
  businessAddress: ViolationFields[];
  deliveryAddress: ViolationFields[];
  invoiceAddress: ViolationFields[];
};

export enum ViolationFields {
  SALUTATION = 'salutation',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  COMPANY_NAME = 'companyName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  BUSINESS_PHONE = 'businessPhone',
  MOBILE_PHONE = 'mobilePhone',
  STREET = 'street',
  STREET_NUMBER = 'streetNumber',
  ZIP_CODE = 'zipCode',
  CITY = 'city',
  COUNTRY = 'country',
  POST_OFFICE_BOX = 'postOfficeBox',
  POST_OFFICE_BOX_ZIP_CODE = 'postOfficeBoxZipCode',
  POST_OFFICE_BOX_CITY = 'postOfficeBoxCity',
  MILES_AND_MORE = 'milesAndMore',
  IBAN = 'iban',
  PVA = 'pva',
  // Admin interface errors:
  SALES_CAMPAIGN_NUMBER_OF_BONUSES = 'salesCampaignNumberOfBonuses',
  COMPLEX_ORDER_BONUS_MATERIAL_NUMBER = 'complexOrderBonusMaterialNumber',
  COMPLEX_ORDER_BONUS_DUPLICATE_VARIANTS = 'complexOrderBonusDuplicateVariants',
  COMPLEX_ORDER_BONUS_VARIANT_NOT_CONFIGURED = 'complexOrderBonusVariantNotConfigured',
  COMPLEX_ORDER_BONUS_PROPERTY_VALUES = 'complexOrderBonusPropertyValues',
  COMPLEX_ORDER_BONUS_DUPLICATE_PROPERTY_NAME = 'complexOrderBonusDuplicatePropertyName',
  COMPLEX_ORDER_BONUS_VARIANT_INVALID = 'complexOrderBonusDuplicatePropertyName',
}

export type FieldErrorsAddressData = {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  businessPhone: boolean;
  mobilePhone: boolean;
  salutation: boolean;
  email: boolean;
  companyName: boolean;
  country: boolean;
  zipCode: boolean;
  addressAddition: boolean;
  streetNumber: boolean;
  city: boolean;
  street: boolean;
  district: boolean;
  additionalInfo: boolean;
  floor: boolean;
  room: boolean;
  postOfficeBoxZipCode: boolean;
  postOfficeBoxCity: boolean;
  postOfficeBox: boolean;
};

export enum ValidationResultValue {
  FIELD_VIOLATIONS,
  // ADDRESS_CHECK_FAILED,
  // ADDRESS_CHECK_SUGGESTION,
  // INVALID_ADDRESS_IGNORE,
  SUCCESS,
  EMPTY,
}

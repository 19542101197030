<template>
  <div class="display-element-order-bonus-details-preview">
    <ul class="display-element-order-bonus-details-preview__bullet-list">
      <li v-for="(item, index) in orderBonus.infoPoints" :key="index">
        {{ item }}
      </li>
    </ul>
    <div
      v-if="orderBonus.link && orderBonus.linkLabel"
      class="display-element-order-bonus-details-preview__link"
    >
      <a
        :href="orderBonus.link"
        :title="orderBonus.linkLabel"
        target="_blank"
        rel="noopener noreferrer"
        ><IconCommandLink /> {{ orderBonus.linkLabel }}</a
      >
    </div>
    <div
      v-if="orderBonus.text && orderBonus.textLabel"
      class="display-element-order-bonus-details-preview__additional-text"
    >
      <div
        class="display-element-order-bonus-details-preview__additional-text-label"
        :class="{
          'display-element-order-bonus-details-preview__additional-text-label--active':
            showAdditionalText,
        }"
        @click="showAdditionalText = !showAdditionalText"
      >
        <IconShowMore :is-closed="!showAdditionalText" /><span>{{
          orderBonus.textLabel
        }}</span>
      </div>
      <transition name="fade">
        <div
          v-if="showAdditionalText"
          class="display-element-order-bonus-details-preview__additional-text-content"
        >
          {{ orderBonus.text }}
        </div>
      </transition>
    </div>
    <hr class="mt-4 mb-4" />
    <div
      v-if="showOrderBonusConfigurationForm"
      class="display-element-order-bonus-details-preview__configuration-form"
    >
      <ComplexOrderBonusConfigurationSelectionForm
        :order-bonus="orderBonus"
        :configuration-selection-options="orderBonusConfigurationOptions"
        :chosen-values="chosenValues"
        :disabled="orderBonusIsInCart"
        @configurationChanged="$emit('configurationChanged', $event)"
      />
    </div>
    <div
      v-if="showPriceInfo"
      class="display-element-order-bonus-details-preview__price-info"
    >
      <span>{{
        commonText.userFrontend.orderBonusSelectionView
          .detailPreviewPriceInfoLabel
      }}</span>
      <span>{{ priceInfo }}</span>
    </div>
    <div
      v-if="isInteractive"
      class="display-element-order-bonus-details-preview__button"
    >
      <BasicButton
        variant="primary"
        type="submit"
        no-arrow
        :disabled="disabledButton"
        class="mt-0 btn-save btn-primary--blank"
        @buttonClicked="$emit('orderBonusSelected', orderBonus.materialNumber)"
        >{{ buttonLabel }}
      </BasicButton>
    </div>
  </div>
</template>

<script lang="ts">
  import IconCommandLink from '@/components/Icons/IconCommandLink.vue';
  import IconShowMore from '@/components/Icons/IconShowMore.vue';
  import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
  import commonTexts from '@/data/commonTexts';
  import { parseNumberToCurrency } from '@/composables/general-functions';
  import { isComplexOrderBonus } from '@/composables/order-bonuses';
  import ComplexOrderBonusConfigurationSelectionForm from '@/components/Forms/ComplexOrderBonusConfigurationSelectionForm.vue';
  import {
    InternalComplexOrderBonus,
    OrderBonus,
  } from '@/types/OrderBonusesTypes';
  import BasicButton from '@/components/Buttons/BasicButton.vue';

  export default defineComponent({
    name: 'DisplayElementOrderBonusDetailsPreview',
    components: {
      ComplexOrderBonusConfigurationSelectionForm,
      IconShowMore,
      IconCommandLink,
      BasicButton,
    },
    props: {
      orderBonus: {
        type: Object as PropType<InternalComplexOrderBonus | OrderBonus>,
        required: true,
      },
      isInteractive: {
        type: Boolean,
        required: false,
        default: false,
      },
      disabledButton: {
        type: Boolean,
        required: false,
        default: false,
      },
      customLabel: {
        type: String,
        required: false,
        default: '',
      },
      orderBonusConfigurationOptions: {
        type: Array,
        required: false,
        default: () => {
          return [];
        },
      },
      chosenValues: {
        type: Array,
        required: false,
        default: () => {
          return [];
        },
      },
      showPriceInfo: {
        type: Boolean,
        required: false,
        default: true,
      },
      calculatedPrice: {
        type: Number,
        required: false,
      },
      orderBonusIsInCart: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      const showAdditionalText = ref(false);
      const commonText = commonTexts;
      const priceInfo = computed(() => {
        if (props.calculatedPrice) {
          return parseNumberToCurrency(props.calculatedPrice);
        } else {
          if (
            props.orderBonus.additionalCharges &&
            props.orderBonus.additionalCharges[0]
          ) {
            return parseNumberToCurrency(
              props.orderBonus.additionalCharges[0].value,
            );
          }
        }
        return '';
      });

      onMounted(() => {
        // Show additional text on details view open, if orderBonus is in cart
        showAdditionalText.value = props.orderBonusIsInCart;
      });

      const buttonLabel = computed(() => {
        return props.customLabel
          ? props.customLabel
          : commonTexts.userFrontend.orderBonusSelectionView.buttonLabelEnabled;
      });

      const showOrderBonusConfigurationForm = computed(() => {
        return isComplexOrderBonus(props.orderBonus);
      });

      return {
        showAdditionalText,
        commonText,
        priceInfo,
        buttonLabel,
        showOrderBonusConfigurationForm,
      };
    },
  });
</script>

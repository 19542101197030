<template>
  <span id="globalErrorsMsg">
    <div
      v-if="gpConnectError"
      class="error-message message-box-yellow text-left"
      v-html="gpConnectErrorMessage"
    />
    <div
      v-if="gpConnectSuccess"
      class="error-message message-box-yellow text-left"
    >
      {{ commonTexts.userFrontend.address.connectSuccess }}
    </div>
    <div v-if="addressChanged || addressSuggested" class="corrected">
      {{ commonTexts.userFrontend.address.addressChanged }}
    </div>
    <div v-if="addressInvalid" class="error-message text-left">
      {{ commonTexts.userFrontend.address.addressInvalid }}
    </div>
    <div v-if="addressIgnore" class="error-message text-left">
      {{ commonTexts.userFrontend.address.addressIgnore }}
    </div>
    <div
      v-if="!addressComplete || personalDataIncomplete"
      class="error-message text-left"
    >
      {{ commonTexts.userFrontend.address.formIncomplete }}
    </div>
  </span>
</template>

<script lang="ts">
  import commonTexts from '@/data/commonTexts';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'AddressError',
    props: {
      addressInvalid: {
        type: Boolean,
        default: false,
      },
      addressChanged: {
        type: Boolean,
        default: false,
      },
      addressIgnore: {
        type: Boolean,
        default: false,
      },
      addressSuggested: {
        type: Boolean,
        default: false,
      },
      addressComplete: {
        type: Boolean,
        default: true,
      },
      addressAlreadyChecked: {
        type: Boolean,
        default: false,
      },
      personalDataIncomplete: {
        type: Boolean,
        default: false,
      },
      gpConnectError: {
        type: Boolean,
        default: false,
      },
      gpConnectErrorMessage: {
        type: String,
        required: false,
        default: commonTexts.userFrontend.address.connectError,
      },
      gpConnectSuccess: {
        type: Boolean,
        default: false,
      },
    },
    setup() {
      return { commonTexts };
    },
  });
</script>

import { FormDataToken } from '@/models/BaseTypes';
import {
  addressValidationResponseFromAPI,
  CreateOrderResponse,
  FormAddresses,
  InitialFormDataResponseData,
  OrderData,
  PriceResult,
  PriceResultRequestData,
  PriceWithDeliveryDate,
  PriceWithDeliveryDateRequestData,
} from '@/models/OrderData';
import { OrderConnectorInterface } from '@/services/connectors/interfaces';
import {
  addressCheckResponse,
  createdOrder,
  initialFormData,
  initialFormDataDebuggingSample,
  priceWithDeliveryDate,
} from '@/services/connectors/stub-data';
import PaymentMethodLiteral from '@/utils/paymentMethodLiteral';

export default class OrderConnectorStub implements OrderConnectorInterface {
  getInitialFormData(
    queryParameter: 'oid' | 'wa',
    product: string,
    tenant: string,
  ): Promise<InitialFormDataResponseData> {
    console.log('Getting Initial Form Data');
    console.log('Query Parameter: ' + queryParameter);
    console.log('Product: ' + product);
    console.log('Tenant: ' + tenant);

    const getInitialFormData = () => {
      switch (product.toLowerCase()) {
        case 'digital':
        case 'dszbk':
          return initialFormData.dszbk;
        case 'lwl':
          return initialFormData.lwl;
        case 'stud':
          return initialFormData.dsbs4wk;
        case 'prefill':
          return initialFormData.prefill;
        case 'schnorrerno':
          return { ...initialFormData.dszbk, schnorrer: true };
        case 'schnorrerok':
          return {
            ...initialFormData.dszbk,
            schnorrer: true,
            salesCampaign: {
              ...initialFormData.dszbk.salesCampaign,
              allowSchnorren: true,
            },
          };
        case 'gplocked':
          return {
            ...initialFormData.dszbk,
            gpLockedOrArchived: true,
          };
        case 'orderexists':
          return {
            ...initialFormData.dszbk,
            existingSubscription: true,
            salesCampaign: {
              ...initialFormData.dszbk.salesCampaign,
              preventOrderWithExistingSubscription: true,
            },
          };
        case 'followup':
          return {
            ...initialFormData.dszbk,
            existingSubscription: true,
            salesCampaign: {
              ...initialFormData.dszbk.salesCampaign,
              allowOrderDespiteExistingSubscription: false,
            },
          };
        case 'jump':
          return {
            ...initialFormData.prefill,
            paymentData: [
              {
                paymentMethod: PaymentMethodLiteral.SEPA,
                truncatedCardPan: '',
                ccIns: '',
                ccName: '',
                ccId: '',
                validUntil: '',
                pseudoCardPan: '',
                displayCCName: '',
                iban: '89370400440532013000',
                swift: '',
                bankDataId: '',
                bankName: 'Mustermann Bank',
                bankAccountOwner: 'Max Mustery',
                bankCountryCode: 'DE',
                creditCardPayment: false,
                payPalCode: '',
                billingFrequency: '',
              },
            ],
          };
        case 'magazine':
          return {
            ...initialFormData.lan4hp,
          };
        case 'company':
          return {
            ...initialFormData.company,
          };
        case 'gift':
          return {
            ...initialFormData.gfes,
          };
        case 'debug':
          return initialFormDataDebuggingSample;
        default:
          return initialFormData.fes;
      }
    };

    return new Promise<InitialFormDataResponseData>((resolve, reject) => {
      setTimeout(() => {
        if (product.toLowerCase() === 'notexisting') {
          reject({
            name: 'Error 404',
            message: 'INVALID_SALES_CAMPAIGN_ID',
            config: {},
            isAxiosError: true,
            toJSON: () => ({}),
            response: {
              status: 404,
              statusText: 'Not Found',
              headers: {},
              config: {},
              data: {
                message: 'INVALID_SALES_CAMPAIGN_ID',
                status: 404,
                error: 'Not Found',
              },
            },
          });
        } else {
          resolve(getInitialFormData());
        }
      }, 500);
    });
  }

  checkAddresses(
    address: FormAddresses,
    salesCampaignId: string,
    formDataToken: FormDataToken | null,
  ): Promise<addressValidationResponseFromAPI> {
    console.log('Checking Addresses');
    console.dir(address);
    console.log('Sales Campaign ID: ' + salesCampaignId);
    console.dir('Form Data Token: ' + formDataToken);

    let response: addressValidationResponseFromAPI = addressCheckResponse.ok;
    if (address.privateAddress?.lastName.toLowerCase().includes('subtitel')) {
      response = addressCheckResponse.subTitle;
    } else if (
      address.privateAddress?.street.toLowerCase().includes('falsch')
    ) {
      response = addressCheckResponse.streetError;
    } else if (address.privateAddress?.city.toLowerCase().includes('korr')) {
      response = addressCheckResponse.corrected;
    } else if (address.privateAddress?.city.toLowerCase().includes('select')) {
      response = addressCheckResponse.select;
    }

    return new Promise<addressValidationResponseFromAPI>((resolve) => {
      setTimeout(() => {
        resolve(response);
      }, 500);
    });
  }

  getPriceWithDeliveryDate(
    salesCampaignId: string,
    formDataToken: FormDataToken | null,
    data: PriceWithDeliveryDateRequestData,
  ): Promise<PriceWithDeliveryDate> {
    console.log('Getting Price with Delivery Date');
    console.log('Sales Campaign ID: ' + salesCampaignId);
    console.dir('Form Data Token: ' + formDataToken);
    console.dir(data);

    let result: PriceWithDeliveryDate;
    switch (salesCampaignId.toLowerCase()) {
      case 'lan4hp':
        result = priceWithDeliveryDate.magazine;
        break;
      default:
        result = priceWithDeliveryDate.default;
        break;
    }

    return new Promise<PriceWithDeliveryDate>((resolve) => {
      setTimeout(() => {
        resolve(result);
      }, 500);
    });
  }

  getPriceForDate(
    salesCampaignId: string,
    formDataToken: FormDataToken | null,
    data: PriceResultRequestData,
  ): Promise<PriceResult> {
    console.log('Getting Price for Date');
    console.log('Sales Campaign ID: ' + salesCampaignId);
    console.dir('Form Data Token: ' + formDataToken);
    console.dir(data);
    return new Promise<PriceResult>((resolve) => {
      setTimeout(() => {
        resolve({
          price: 76.34,
          annualPrice: 458.05,
          priceGui: '76,34',
          noResultAbroad: false,
          // "specialOffer": false
        });
      }, 500);
    });
  }

  createOrder(
    formDataToken: FormDataToken | null,
    data: OrderData,
  ): Promise<CreateOrderResponse> {
    console.log('Creating Order');
    console.log('Form Data Token: ');
    console.dir(formDataToken);
    console.log('Data: ');
    console.dir(data);

    let responseData = createdOrder.ok;
    if (
      data.purchaserAddresses?.privateAddress?.lastName
        .toLowerCase()
        .includes('temp')
    ) {
      responseData = createdOrder.temp;
    } else if (
      data.purchaserAddresses?.privateAddress?.lastName
        .toLowerCase()
        .includes('registrierung')
    ) {
      responseData = createdOrder.reg;
    }

    return new Promise<CreateOrderResponse>((resolve) => {
      setTimeout(() => {
        resolve(responseData);
      }, 500);
    });
  }

  getCountries(salesCampaignId: string): Promise<{ [key: string]: string }> {
    console.log('Getting Countries');
    console.log('Sales Campaign ID: ' + salesCampaignId);

    return new Promise<{ [key: string]: string }>((resolve) => {
      setTimeout(() => {
        resolve({
          DE: 'Deutschland',
          AT: 'Österreich',
          CH: 'Schweiz',
        });
      }, 500);
    });
  }

  getPaymentMethodsForSalesCampaign(
    salesCampaignId: string,
    countryCode: string,
  ): Promise<string[]> {
    console.log('Getting Payment Methods for Sales Campaign');
    console.log('Sales Campaign ID: ' + salesCampaignId);
    console.log('Country Code: ' + countryCode);

    let result: PaymentMethodLiteral[] = [];
    switch (countryCode.toLowerCase()) {
      case 'ch':
        result = [
          PaymentMethodLiteral.PAYPAL,
          PaymentMethodLiteral.CREDIT_CARD,
        ];
        break;
      case 'at':
        result = [
          PaymentMethodLiteral.PAYPAL,
          PaymentMethodLiteral.CREDIT_CARD,
          PaymentMethodLiteral.INVOICE,
        ];
        break;
      case 'de':
        result = [
          PaymentMethodLiteral.CREDIT_CARD,
          PaymentMethodLiteral.INVOICE,
          PaymentMethodLiteral.SEPA,
        ];
        break;
      default:
        result = [
          PaymentMethodLiteral.CREDIT_CARD,
          PaymentMethodLiteral.PAYPAL,
          PaymentMethodLiteral.INVOICE,
          PaymentMethodLiteral.SEPA,
        ];
        break;
    }

    return new Promise<string[]>((resolve) => {
      setTimeout(() => {
        resolve(result);
      }, 500);
    });
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "student-enrollment-upload-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementFileUpload = _resolveComponent("FormElementFileUpload")!
  const _component_TheOverlay = _resolveComponent("TheOverlay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TheOverlay, {
      id: "wrapperFormElementFileUpload",
      show: _ctx.uploadInProgress,
      opacity: 1,
      isFullPage: false
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormElementFileUpload, {
          value: _ctx.fileName,
          caption: _ctx.commonTexts.userFrontend.studentEnrollmentForm.uploadCaption,
          "has-error": _ctx.uploadError,
          message: _ctx.uploadMessage,
          "upload-success": _ctx.uploadSuccess,
          "show-preview-image-after-upload": false,
          id: "orderBonusCustomizingFileUpload",
          onFileSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.uploadFile($event))),
          onImageDeleted: _ctx.removeImage,
          onUnsupportedFileTypeError: _ctx.setInternalValidationError,
          "valid-file-formats": ".jpg,.jpeg,.png,.gif,.pdf",
          class: "mt-3"
        }, null, 8, ["value", "caption", "has-error", "message", "upload-success", "onImageDeleted", "onUnsupportedFileTypeError"])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar" }
const _hoisted_2 = { class: "navbar-item" }
const _hoisted_3 = { class: "navbar-brand navbar-item" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "progressbar-wrapper navbar-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.showBackButton)
        ? (_openBlock(), _createBlock(_component_BasicButton, {
            key: 0,
            variant: "link",
            onButtonClicked: _ctx.stepBack,
            "additional-button-classes": "color-text-color navbar-back"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Zurück")
            ]),
            _: 1
          }, 8, ["onButtonClicked"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        src: _ctx.logoPath,
        alt: "SZ Plus"
      }, null, 8, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (!_ctx.isInternalView)
        ? (_openBlock(), _createBlock(_component_ProgressBar, {
            key: 0,
            steps: 5
          }))
        : _createCommentVNode("", true)
    ])
  ]))
}
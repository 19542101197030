import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "deviating-address-form" }
const _hoisted_2 = {
  key: 0,
  class: "form-row deviating-address-form__row"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 4,
  class: "deviating-address-form__remove-data-button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconShowMore = _resolveComponent("IconShowMore")!
  const _component_FormElementSelectField = _resolveComponent("FormElementSelectField")!
  const _component_FormElementRadioButtons = _resolveComponent("FormElementRadioButtons")!
  const _component_PostOfficeBoxForm = _resolveComponent("PostOfficeBoxForm")!
  const _component_StreetAddressForm = _resolveComponent("StreetAddressForm")!
  const _component_IconTrashCan = _resolveComponent("IconTrashCan")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "deviating-address-form__show-more-button deviating-address-form--clickable",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showDeviatingAddress = !_ctx.showDeviatingAddress))
    }, [
      _createVNode(_component_IconShowMore, {
        "is-closed": !_ctx.showDeviatingAddress
      }, null, 8, ["is-closed"]),
      _createElementVNode("span", {
        class: _normalizeClass(["deviating-address-form__show-more-label", {
          'deviating-address-form__show-more-label--active':
            _ctx.showDeviatingAddress,
        }])
      }, _toDisplayString(_ctx.formLabel), 3)
    ]),
    (_ctx.showDeviatingAddress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_FormElementSelectField, {
            label: _ctx.commonTexts.userInterface.formFieldLabels.country,
            class: "col-md-12",
            selectOptions: _ctx.countryOptions,
            value: _ctx.value.country,
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateCountry($event))),
            "field-has-error": _ctx.fieldErrorsAddress.country,
            onSelectionChanged: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('resetFieldError', 'country')))
          }, null, 8, ["label", "selectOptions", "value", "field-has-error"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showDeviatingAddress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_FormElementRadioButtons, {
            name: `${_ctx.fieldIdSlug}-addressType`,
            value: _ctx.value.addressType,
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updateSelectedAddressType($event))),
            radioOptions: _ctx.addressTypeOptions,
            class: "mb-4"
          }, null, 8, ["name", "value", "radioOptions"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.showDeviatingAddress && _ctx.postOfficeBoxSelected)
      ? (_openBlock(), _createBlock(_component_PostOfficeBoxForm, {
          key: 2,
          "address-data": _ctx.value,
          "field-errors": _ctx.fieldErrorsAddress,
          "address-check-result": _ctx.addressCheckResult,
          "field-id-slug": _ctx.fieldIdSlug,
          onAddressDataInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('updateDeviatingAddress', $event))),
          onResetFieldError: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('resetFieldError', $event)))
        }, null, 8, ["address-data", "field-errors", "address-check-result", "field-id-slug"]))
      : (_ctx.showDeviatingAddress && !_ctx.postOfficeBoxSelected)
        ? (_openBlock(), _createBlock(_component_StreetAddressForm, {
            key: 3,
            "field-id-slug": _ctx.fieldIdSlug,
            "address-check-result": _ctx.addressCheckResult,
            "is-business-address": "",
            "address-data": _ctx.value,
            "field-errors": _ctx.fieldErrorsAddress,
            onAddressDataInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('updateDeviatingAddress', $event))),
            onResetFieldError: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('resetFieldError', $event)))
          }, null, 8, ["field-id-slug", "address-check-result", "address-data", "field-errors"]))
        : _createCommentVNode("", true),
    (_ctx.showDeviatingAddress)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_IconTrashCan, {
            class: "deviating-address-form--clickable",
            onClick: _ctx.deleteDeviatingAddress
          }, null, 8, ["onClick"]),
          _createElementVNode("span", {
            onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.deleteDeviatingAddress && _ctx.deleteDeviatingAddress(...args))),
            class: "deviating-address-form__remove-data-label deviating-address-form--clickable"
          }, _toDisplayString(_ctx.commonTexts.userInterface.buttons.deleteEntry), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}
<template>
  <div class="the-page-body">
    <slot />
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'ThePageBody',
  });
</script>

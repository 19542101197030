import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "private-address-addition" }
const _hoisted_2 = {
  key: 0,
  class: "form-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementCheckBox = _resolveComponent("FormElementCheckBox")!
  const _component_FormElementInputField = _resolveComponent("FormElementInputField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormElementCheckBox, {
      value: _ctx.additionalInfoSelected,
      name: "privateAddressAddition",
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.additionalInfoSelected = $event)),
      class: "mb-1"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.commonTexts.userInterface.formFieldLabels.showAddressAdditionFormPrivate), 1)
      ]),
      _: 1
    }, 8, ["value"]),
    (_ctx.additionalInfoSelected)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_FormElementInputField, {
            label: _ctx.commonTexts.userInterface.formFieldLabels.addressAddition,
            "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels.addressAddition +
          _ctx.fieldIdSlug
        ,
            value: _ctx.value.addressAddition,
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update(_ctx.formFields.ADDRESS_ADDITION, $event))),
            "field-has-error": _ctx.fieldErrors.addressAddition,
            class: "col-md-12 mb-4",
            maxlength: "35"
          }, null, 8, ["label", "field-id", "value", "field-has-error"]),
          _createVNode(_component_FormElementInputField, {
            label: _ctx.commonTexts.userInterface.formFieldLabels.floor,
            "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels.floor + _ctx.fieldIdSlug
        ,
            value: _ctx.value.floor,
            onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.update(_ctx.formFields.FLOOR, $event))),
            "field-has-error": _ctx.fieldErrors.floor,
            class: "col-md-6 mb-4",
            maxlength: "6"
          }, null, 8, ["label", "field-id", "value", "field-has-error"]),
          _createVNode(_component_FormElementInputField, {
            label: _ctx.commonTexts.userInterface.formFieldLabels.room,
            "field-id": _ctx.commonTexts.userInterface.formFieldLabels.room + _ctx.fieldIdSlug,
            value: _ctx.value.room,
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.update(_ctx.formFields.ROOM, $event))),
            "field-has-error": _ctx.fieldErrors.room,
            class: "col-md-6 mb-4"
          }, null, 8, ["label", "field-id", "value", "field-has-error"]),
          _createVNode(_component_FormElementInputField, {
            label: _ctx.commonTexts.userInterface.formFieldLabels.additionalInfoPrivate,
            "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels.additionalInfoPrivate +
          _ctx.fieldIdSlug
        ,
            value: _ctx.value.additionalInfo,
            onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.update(_ctx.formFields.ADDITIONAL_INFO, $event))),
            "field-has-error": _ctx.fieldErrors.additionalInfo,
            class: "col-md-12 mb-4",
            maxlength: "50"
          }, null, 8, ["label", "field-id", "value", "field-has-error"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
<template>
  <div class="footer">
    <div class="container">
      <ul class="links">
        <li>
          <a :href="content.legal.imprintSrc" target="_blank" rel="noopener"
            >Impressum</a
          >
        </li>
        <li>
          <a :href="content.legal.privacySrc" target="_blank" rel="noopener"
            >Datenschutz</a
          >
        </li>
        <li>
          <span @click="loadWindowPrivacyManagerModal()"
            >Datenschutz-Einstellungen</span
          >
        </li>
      </ul>
      <div class="copyright">{{ content.legal.copyright }}</div>
    </div>
  </div>
</template>

<script lang="ts">
  import SourcepointService from '@/services/sourcepoint-service';
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'TheFooter',
    setup() {
      const store = useStore();
      const content = computed(() => store.getters.content);

      const privacyManagerID = computed(() => {
        return SourcepointService.getSourcepointConfig().privacyManagerID;
      });

      const loadWindowPrivacyManagerModal = function () {
        const _window = window as Window;
        if (
          _window._sp_ &&
          _window._sp_.gdpr &&
          _window._sp_.gdpr.loadPrivacyManagerModal
        ) {
          _window._sp_.gdpr.loadPrivacyManagerModal(privacyManagerID.value);
        }
      };

      return { content, loadWindowPrivacyManagerModal };
    },
  });
</script>

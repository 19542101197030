import { AxiosError } from 'axios';

export const formatDate = (date: string) => {
  return date.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1');
};

export function logRequestErrors(error: Error | AxiosError) {
  if ('config' in error) {
    console.log(error.config);
  }
  if ('response' in error && error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if ('request' in error && error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error);
  }
  return error;
}

<template>
  <div
    class="display-element-order-bonus-tile"
    :class="{
      'display-element-order-bonus-tile--embedded': embedded,
      'display-element-order-bonus-tile--active': active,
      'display-element-order-bonus-tile--in-cart': inCart,
    }"
    @click="$emit('click')"
  >
    <div class="display-element-order-bonus-tile__inner">
      <div class="display-element-order-bonus-tile__chosen-icon">
        <IconSuccessCheck />
      </div>
      <div class="display-element-order-bonus-tile__title">
        {{ orderBonus.title }}
      </div>
      <div class="display-element-order-bonus-tile__image">
        <IconImagePlaceholder
          class="display-element-order-bonus-tile__image--placeholder"
          v-if="showImagePlaceHolder"
        />
        <img v-else :src="orderBonus.imagePath" alt="" />
      </div>
      <div class="display-element-order-bonus-tile__details">
        <div class="display-element-order-bonus-tile__details-icon">
          <IconTilde :active="active" />
        </div>
        <div class="display-element-order-bonus-tile__details-label">
          Details
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, onMounted, PropType, ref, watch } from 'vue';
  import IconTilde from '@/components/Icons/IconTilde.vue';
  import IconImagePlaceholder from '@/components/Icons/IconImagePlaceholder.vue';
  import IconSuccessCheck from '@/components/Icons/IconSuccessCheck.vue';
  import {
    InternalComplexOrderBonus,
    OrderBonus,
  } from '@/types/OrderBonusesTypes';

  export default defineComponent({
    name: 'DisplayElementOrderBonusTile',
    components: { IconSuccessCheck, IconImagePlaceholder, IconTilde },
    props: {
      orderBonus: {
        type: Object as PropType<OrderBonus | InternalComplexOrderBonus>,
        required: true,
      },
      embedded: {
        type: Boolean,
        required: false,
        default: false,
      },
      active: {
        type: Boolean,
        required: false,
        default: false,
      },
      inCart: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['openOrderBonusDetails', 'click'],
    setup(props, { emit }) {
      const showImagePlaceHolder = ref(true);

      onMounted(() => {
        showImagePlaceHolder.value = !props.orderBonus.imagePath;
      });

      watch(props, () => {
        showImagePlaceHolder.value = !props.orderBonus.imagePath;
      });

      const openDetails = function () {
        emit('openOrderBonusDetails', props.orderBonus.materialNumber);
      };

      return {
        showImagePlaceHolder,
        openDetails,
      };
    },
  });
</script>

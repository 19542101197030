<template>
  <div class="miles-and-more-form">
    <div class="miles-and-more-form__content">
      <div class="miles-and-more-form__content-headline">
        {{ commonTexts.userFrontend.milesAndMore.form.headline }}
      </div>
      <div class="miles-and-more-form__content-text">
        {{ commonTexts.userFrontend.milesAndMore.form.text }}
      </div>
    </div>
    <div class="miles-and-more-form__content-field">
      <FormElementInputField
        :value="milesAndMoreId"
        @input="milesAndMoreId = $event"
        :label="commonTexts.userFrontend.milesAndMore.form.label"
        :field-has-error="hasError"
        @blur="updateMilesAndMoreId"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import FormElementInputField from '@/components/FormElements/FormElementInputField.vue';
  import { defineComponent, Ref, ref } from 'vue';
  import commonTexts from '@/data/commonTexts';
  import { MilesAndMoreValidator } from '@/validators/MilesAndMoreValidator';

  export default defineComponent({
    name: 'MilesAndMoreForm',
    components: { FormElementInputField },
    props: {
      value: {
        type: String,
        required: true,
      },
    },
    emits: ['updateMilesAndMoreId', 'hasError'],
    setup(props, { emit }) {
      const milesAndMoreId: Ref<string> = ref(props.value);
      const contentTexts = commonTexts;
      const hasError = ref(false);
      const validator = new MilesAndMoreValidator();

      const updateMilesAndMoreId = function () {
        hasError.value = false;
        // If no miles and more id was given or given number was validated:
        if (
          !milesAndMoreId.value ||
          validator.validate(milesAndMoreId.value).length === 0
        ) {
          emit('hasError', false);
          emit('updateMilesAndMoreId', milesAndMoreId.value);
        } else {
          hasError.value = true;
          emit('hasError', true);
        }
      };

      return {
        milesAndMoreId,
        commonTexts,
        hasError,
        updateMilesAndMoreId,
        contentTexts,
      };
    },
  });
</script>

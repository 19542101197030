<template>
  <div
    :id="modalId"
    class="osc-modal"
    v-if="showModal"
    :class="{
      'osc-modal--xl': size === 'xl',
      'osc-modal--l': size === 'l',
    }"
  >
    <div class="osc-modal__mask" @click.self="$emit('modalClosed')">
      <div class="osc-modal__container">
        <div class="osc-modal__header">
          <slot name="header" />
        </div>
        <div
          class="osc-modal__body"
          :class="{
            'osc-modal__body--no-padding': !hasFooter,
            'osc-modal__body--no-body-scroll': noBodyScroll,
          }"
        >
          <slot name="body" />
        </div>
        <div
          class="osc-modal__footer"
          :class="{
            'osc-modal__footer--disabled': !hasFooter,
          }"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref, useSlots, watch } from 'vue';

  export default defineComponent({
    name: 'OscModal',
    props: {
      modalId: {
        type: String,
        required: true,
      },
      showModal: {
        type: Boolean,
        required: true,
      },
      noBodyScroll: {
        type: Boolean,
        required: false,
        default: false,
      },
      size: {
        type: String,
        required: false,
        default: 'm',
      },
    },
    setup(props, { emit }) {
      const slots = useSlots();
      const displayModal = ref(props.showModal);

      watch(props, () => {
        displayModal.value = props.showModal;
      });

      watch(displayModal, () => {
        emit('input', displayModal.value);
      });

      const hasFooter = computed(() => {
        return !!slots.footer;
      });

      return {
        displayModal,
        hasFooter,
      };
    },
  });
</script>

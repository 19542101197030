<template>
  <div class="personal-business-data-form">
    <div class="form-row">
      <FormElementSelectField
        v-if="nameEditable"
        class="col-md-6 mb-4"
        :label="commonTexts.userInterface.formFieldLabels.salutation"
        :selectOptions="salutationOptionsCompany"
        :value="value.salutation"
        @input="updatePersonalData(formFields.SALUTATION, $event)"
        :field-has-error="fieldErrors.salutation"
        @selectionChanged="resetFieldError('salutation')"
      />
      <DisplayElementUserSalutation
        show-company-name
        :address="value"
        v-else
        class="col-md-12 mb-4"
      />
    </div>
    <div class="form-row">
      <FormElementInputField
        v-if="nameEditable"
        :label="commonTexts.userInterface.formFieldLabels.companyName"
        :value="value.companyName"
        @input="updatePersonalData(formFields.COMPANY_NAME, $event)"
        :field-has-error="fieldErrors.companyName"
        @focus="resetFieldError('companyName')"
        class="col-md-12 mb-4"
        maxlength="35"
      />
    </div>
    <div class="form-row">
      <FormElementCheckBox
        :value="showCompanySuffixes"
        @input="showCompanySuffixes = $event"
        name="showCompanySuffixes"
        v-if="nameEditable"
        >{{
          commonTexts.userInterface.formFieldLabels
            .showAdditionalFieldsPersonalBusinessData
        }}</FormElementCheckBox
      >
    </div>
    <div class="form-row" v-if="showCompanySuffixes && nameEditable">
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.companySuffix1"
        :value="value.companySuffix1"
        @input="updatePersonalData(formFields.COMPANY_SUFFIX_1, $event)"
        :readonly="!nameEditable"
        class="col-md-6 mb-4"
      />
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.companySuffix2"
        :value="value.companySuffix2"
        @input="updatePersonalData(formFields.COMPANY_SUFFIX_2, $event)"
        :readonly="!nameEditable"
        class="col-md-6 mb-4"
      />
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.companySuffix3"
        :value="value.companySuffix3"
        @input="updatePersonalData(formFields.COMPANY_SUFFIX_3, $event)"
        :readonly="!nameEditable"
        class="col-md-6"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import FormElementSelectField from '@/components/FormElements/FormElementSelectField.vue';
  import DisplayElementUserSalutation from '@/components/DisplayElements/DisplayElementUserSalutation.vue';
  import FormElementInputField from '@/components/FormElements/FormElementInputField.vue';
  import { defineComponent, onMounted, ref, watch, PropType } from 'vue';
  import FormElementCheckBox from '@/components/FormElements/FormElementCheckBox.vue';
  import commonTexts from '@/data/commonTexts';
  import { salutationOptionsCompany } from '@/utils/selectOptions';
  import {
    FieldErrorsAddressData,
    ViolationFields,
  } from '@/validators/validatorTypes';
  import { FormBusinessAddress } from '@/models/OrderData';

  export default defineComponent({
    name: 'PersonalBusinessDataForm',
    components: {
      FormElementSelectField,
      DisplayElementUserSalutation,
      FormElementInputField,
      FormElementCheckBox,
    },
    emits: ['resetFieldError', 'personalDataInput'],
    props: {
      nameEditable: {
        type: Boolean,
        required: true,
      },
      value: {
        type: Object as PropType<FormBusinessAddress>,
        required: true,
      },
      fieldErrors: {
        type: Object as PropType<FieldErrorsAddressData>,
        required: true,
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setup(props: any, context: any) {
      enum formFields {
        SALUTATION = 'salutation',
        COMPANY_NAME = 'companyName',
        COMPANY_SUFFIX_1 = 'companySuffix1',
        COMPANY_SUFFIX_2 = 'companySuffix2',
        COMPANY_SUFFIX_3 = 'companySuffix3',
      }

      const updatePersonalData = function (
        field: formFields,
        newValue: string,
      ) {
        const data = Object.assign({}, props.value);
        switch (field) {
          case formFields.SALUTATION:
            data.salutation = newValue;
            break;
          case formFields.COMPANY_NAME:
            data.companyName = newValue;
            break;
          case formFields.COMPANY_SUFFIX_1:
            data.companySuffix1 = newValue;
            break;
          case formFields.COMPANY_SUFFIX_2:
            data.companySuffix2 = newValue;
            break;
          case formFields.COMPANY_SUFFIX_3:
            data.companySuffix3 = newValue;
            break;
        }

        context.emit('personalDataInput', data);
      };

      const showCompanySuffixes = ref(false);

      onMounted(() => {
        if (
          props.value.companySuffix1 !== '' ||
          props.value.companySuffix2 !== '' ||
          props.value.companySuffix3 !== ''
        ) {
          showCompanySuffixes.value = true;
        }
      });
      watch(
        [
          () => props.value.companySuffix1,
          () => props.value.companySuffix2,
          () => props.value.companySuffix3,
        ],
        () => {
          if (
            props.value.companySuffix1 !== '' ||
            props.value.companySuffix2 !== '' ||
            props.value.companySuffix3 !== ''
          ) {
            showCompanySuffixes.value = true;
          }
        },
      );

      const resetFieldError = function (field: string) {
        switch (field) {
          case formFields.SALUTATION:
            context.emit('resetFieldError', ViolationFields.SALUTATION);
            break;
          case formFields.COMPANY_NAME:
            context.emit('resetFieldError', ViolationFields.COMPANY_NAME);
            break;
        }
      };

      return {
        showCompanySuffixes,
        commonTexts,
        salutationOptionsCompany,
        formFields,
        updatePersonalData,
        resetFieldError,
      };
    },
  });
</script>

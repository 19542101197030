import { GetterTree } from 'vuex';
import {
  AdminOrderBonusesGetterTypes,
  AdminOrderBonusesState,
} from '@/store/modules/adminOrderBonuses/types';
import { AppRootState } from '@/store/interfaces';

const getters: GetterTree<AdminOrderBonusesState, AppRootState> = {
  [AdminOrderBonusesGetterTypes.GET_BONUSES_COLLECTION](
    state: AdminOrderBonusesState,
  ) {
    return state.bonusesCollection;
  },
  [AdminOrderBonusesGetterTypes.GET_BONUS](state: AdminOrderBonusesState) {
    return state.bonus;
  },
  [AdminOrderBonusesGetterTypes.GET_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION](
    state: AdminOrderBonusesState,
  ) {
    return state.availableSalesCampaignBonusesCollection;
  },
};

export default getters;

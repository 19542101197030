enum PaymentMethodLiteral {
  CREDIT_CARD = 'Q',
  PAYPAL = 'P',
  SEPA = '7',
  INVOICE = 'Y',
}

// Type guard function to check if a value is a PaymentMethodLiteral
export function isPaymentMethodLiteral(
  value: string | PaymentMethodLiteral,
): value is PaymentMethodLiteral {
  // Check if the value is one of the enum's values
  return Object.values(PaymentMethodLiteral).includes(
    value as PaymentMethodLiteral,
  );
}

export default PaymentMethodLiteral;

import { DateTime } from 'luxon';
import errorMessages from '@/data/errorMessages';
import { FormAddresses, LoggedInCustomerRole } from '@/models/OrderData';
import commonConfig from '@/data/commonConfig';
import store from '@/store';

// Parses 'YYYY-MM-DD' strings to 'DD.MM.YYYY'
//    In case of input param = null a default message is returned
export function parseIsoDateStringToLocalDateString(
  isoDate: string | null,
): string {
  if (isoDate) {
    return DateTime.fromISO(isoDate).setLocale('de').toLocaleString({
      year: 'numeric',
      day: '2-digit',
      month: '2-digit',
    });
  } else {
    return errorMessages.general.parsing.noDate;
  }
}

export function parseNumberToCurrency(price: number) {
  return price
    .toFixed(2)
    .replace('.', ',')
    .concat(commonConfig.defaultCurrencyString);
}

// export function parsePriceInfoToNumber(priceInfo: string) {
//   return parseFloat(
//     priceInfo.replace(commonConfig.defaultCurrency, '').replace(',', '.'),
//   );
// }

export function getFirstNameLastNameFromAddresses(addresses: FormAddresses) {
  let firstName = '';
  let lastName = '';
  // TODO: Verify, that this works for company names in the Payment Backend
  if (addresses.privateAddress !== null) {
    firstName = addresses.privateAddress.firstName;
    lastName = addresses.privateAddress.lastName;
  } else if (addresses.businessAddress !== null) {
    lastName = addresses.businessAddress.companyName;
    if (addresses.businessAddress.companySuffix1) {
      firstName = addresses.businessAddress.companySuffix1;
    }
  }

  return { firstName, lastName };
}

export function getEmailFromAddresses(addresses: FormAddresses) {
  let email = '';
  if (addresses.privateAddress !== null) {
    email = addresses.privateAddress.email;
  } else if (addresses.businessAddress !== null) {
    email = addresses.businessAddress.email;
  }
  return email;
}

export function getMainAddressFromAddresses(addresses: FormAddresses) {
  if (addresses.privateAddress !== null) {
    return addresses.privateAddress;
  } else if (addresses.businessAddress !== null) {
    return addresses.businessAddress;
  }
  return undefined;
}

export function createDummyAddress() {
  return {
    additionalInfo: '',
    addressAddition: '',
    addressNumber: '',
    businessAddress: false,
    businessPhone: '',
    city: '',
    country: '',
    district: '',
    email: '',
    firstName: '',
    floor: '',
    lastName: '',
    mobilePhone: '',
    nameEditable: true,
    phone: '',
    postOfficeBox: '',
    postOfficeBoxCity: '',
    postOfficeBoxZipCode: '',
    room: '',
    salutation: '',
    street: '',
    streetNumber: '',
    title: '',
    zipCode: '',
  };
}

export function getAddressesOfPurchaser() {
  if (store.state.formData.salesCampaign.giftSubscription) {
    return store.state.recruiterAddresses;
  } else {
    return store.state.formData.addresses;
  }
}

export function getGpNrOfPurchaser() {
  let result = store.state.formData.gpNr;

  if (
    (store.state.formData.salesCampaign.giftSubscription &&
      store.state.userIsReceiver) ||
    (store.state.formData.salesCampaign.lwl && !store.state.userIsReceiver)
  ) {
    result = '';
  }
  return result;
}

export function getUserRole() {
  let userRole = LoggedInCustomerRole.PURCHASER;

  if (store.state.formData.salesCampaign.giftSubscription) {
    userRole = store.state.userIsReceiver
      ? LoggedInCustomerRole.RECIPIENT
      : LoggedInCustomerRole.PURCHASER;
  } else if (store.state.formData.salesCampaign.lwl) {
    userRole = store.state.userIsReceiver
      ? LoggedInCustomerRole.PURCHASER
      : LoggedInCustomerRole.AGENT;
  }

  return userRole;
}

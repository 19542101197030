import store from '@/store';
import router from '@/router';
import {
  CardOneOffPayRequest,
  CreditCardPaymentInitRequestData,
} from '@/models/PaymentServiceRequest';
import OrderService from '@/services/order-service';
import { PaymentData } from '@/models/OrderData';
import PaymentService from '@/services/payment-service';
import {
  getAddressesOfPurchaser,
  getFirstNameLastNameFromAddresses,
  getGpNrOfPurchaser,
} from '@/composables/general-functions';
import { ConnectorFactory } from '@/services/connectors/connectorFactory';

export default class CardOneOffPayService {
  public static async startAgreementSCA() {
    await OrderService.persistState();
    this.getPayForm(window.location)
      .then((response) => {
        if (response && response.absUrl) {
          window.location.href = response.absUrl;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public static buildInitialRequestDto(
    customerId: string,
    firstname: string,
    lastname: string,
    orderId: string,
    orderName: string,
    amountInCent: number,
    paymentData: PaymentData,
    successUrl: string,
    backUrl: string,
    errorUrl: string,
  ): CardOneOffPayRequest {
    const rootUrl = window.location.protocol + '//' + window.location.host;
    return {
      user: {
        customerId: customerId === '' ? undefined : customerId,
        firstname,
        lastname,
      },
      cardData: {
        pseudocardpan: paymentData.pseudoCardPan,
        truncatedcardpan: paymentData.truncatedCardPan,
      },
      shopInfo: {
        shopDomain: rootUrl,
      },
      order: {
        orderId,
        orderName,
        amountInCent,
      },
      settleAccount: false, // always false - done by SAP
      returnUrls: {
        successUrl,
        backUrl,
        errorUrl,
      },
    };
  }

  public static getReturnUrlSuccess(
    absUrlPrefix: string,
    payContextToken: string,
  ) {
    const resolved = router.resolve({
      name: 'CardOneOffPayResult',
    });
    return `${absUrlPrefix}${resolved.href}?ctxtoken=${payContextToken}`;
  }

  public static getReturnUrlError(absUrlPrefix: string, mode: string) {
    const resolved = router.resolve({
      name: 'CardOneOffPayResult',
      // params: { mode: mode },
    });
    return `${absUrlPrefix}${resolved.href}?mode=${mode}`;
  }

  public static getPayForm(currentLoc: Location) {
    const formData = store.state.formData;
    const salesCampaignId = formData.salesCampaign.salesCampaignId;
    const customerId = getGpNrOfPurchaser();
    const campaign = formData.salesCampaign;

    const orderId = this.randomString(20);
    const orderName = campaign.name;

    // Converts to Cent, if priceWithDeliveryDate is called, priceWithDeliveryDate is to be used:
    let amountInCent: number = 100 * campaign.priceForTracking;

    if (store.state.priceResultFetchSuccessful) {
      if (store.state.priceResult.annualPrice > 0) {
        amountInCent = 100 * store.state.priceResult.annualPrice;
      } else {
        amountInCent = 100 * store.state.priceResult.price;
      }
    }

    const absUrlPrefix = currentLoc.protocol + '//' + currentLoc.host;
    const payContextToken = PaymentService.buildPayContextToken(
      customerId,
      salesCampaignId,
    );

    const successUrl = this.getReturnUrlSuccess(absUrlPrefix, payContextToken);
    const errorUrl = this.getReturnUrlError(absUrlPrefix, 'error');
    const backUrl = this.getReturnUrlError(absUrlPrefix, 'back');

    const paymentData: PaymentData = OrderService.createPaymentData();

    const addresses = getAddressesOfPurchaser();

    const { firstName, lastName } =
      getFirstNameLastNameFromAddresses(addresses);

    const requestDto = this.buildInitialRequestDto(
      customerId,
      firstName,
      lastName,
      orderId,
      orderName,
      amountInCent,
      paymentData,
      successUrl,
      backUrl,
      errorUrl,
    );

    const paymentConnector = ConnectorFactory.getPaymentConnector();
    return (
      paymentConnector
        .getCreditCardOneOffPaymentAuthorizationUrl(
          requestDto as CreditCardPaymentInitRequestData,
          store.state.formDataToken,
        )
        // return paymentAPI
        //   .post('creditcard/oneoffpayment/init', requestDto, {
        //     headers: { token: JSON.stringify(store.state.formDataToken) },
        //   })
        .then((responseBody) => {
          // const responseBody = response.data;
          PaymentService.storePayFrameUrl(responseBody.absUrl);
          PaymentService.storeProcessId(responseBody.processId);
          return responseBody;
        })
        .catch((error) => {
          console.log(error.response.data);
          router.push({
            name: 'ErrorPage',
            params: {
              type: 'server',
            },
          });
        })
    );
  }

  public static requestPayResult() {
    const processId = PaymentService.getProcessId();
    const serviceTicket = '' + PaymentService.getServiceTicket();
    return PaymentService.requestPayResult(
      processId,
      serviceTicket,
      'creditcard/oneoffpayment/result',
    );
  }

  private static randomString(length: number) {
    const chars =
      '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }
}

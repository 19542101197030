import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "the-page-content" }
const _hoisted_2 = {
  key: 0,
  class: "the-page-content__title"
}
const _hoisted_3 = { class: "the-page-content__body" }
const _hoisted_4 = {
  key: 1,
  class: "the-page-content--button-content-area"
}
const _hoisted_5 = {
  key: 0,
  class: "the-page-content__side-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h1", null, _toDisplayString(_ctx.title), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.mainClass)
      }, [
        (_ctx.hasMainArea)
          ? _renderSlot(_ctx.$slots, "main-content-area", { key: 0 })
          : _createCommentVNode("", true),
        (_ctx.hasButtonArea)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "button-content-area")
            ]))
          : _createCommentVNode("", true)
      ], 2),
      (_ctx.hasSidebar)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "side-content-area")
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
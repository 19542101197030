<template>
  <span class="errorMsg message-box">
    <div
      class="error-message"
      :class="{
        'no-bg': type === 'no-bg',
        'yellow': type === 'yellow',
      }"
      v-html="content"
    ></div>
  </span>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'MessageBox',
    props: {
      content: {
        type: String,
        required: true,
      },
      type: {
        type: String,
        required: false,
        default: '',
      },
    },
  });
</script>

<style scoped>
  /* need to set "!important" here to prevent interference with common styles */
  /* TODO: common css needs to be refactored and needs to be better scoped/prefixed (i.e. Success.vue SCSS error messages) */
  .no-bg {
    background: none !important;
  }

  .yellow {
    background: #fff7e0 !important;
    color: #c69300 !important;
    padding: 20px 30px !important;
  }

  .error-message {
    display: block !important;
  }
</style>

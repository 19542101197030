import {
  ValidatorInterface,
  ViolationFields,
} from '@/validators/validatorTypes';

export class PersonalEmailDataValidator implements ValidatorInterface {
  validate(target: string): ViolationFields[] {
    const violations = [];
    //pattern identifies email addresses according to RFC 5322
    const pattern =
      // eslint-disable-next-line no-control-regex
      /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
    const matches = pattern.exec(target.toLowerCase());
    // if matches are not found: E-Mail violates RFC 5322
    if (matches === null) {
      violations.push(ViolationFields.EMAIL);
    }

    return violations;
  }
}

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "osc-street-address-form-simple" }
const _hoisted_2 = { class: "form-row osc-street-address-form-simple__street-input" }
const _hoisted_3 = { class: "form-row" }
const _hoisted_4 = { class: "form-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementSelectField = _resolveComponent("FormElementSelectField")!
  const _component_FormElementInputField = _resolveComponent("FormElementInputField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.streetSuggestion && !_ctx.suggestedAddressWasSelected)
        ? (_openBlock(), _createBlock(_component_FormElementSelectField, {
            key: 0,
            class: "col-md-9 mb-1 pt-2",
            selectOptions: 
          _ctx.addressCheckResult ? _ctx.addressCheckResult.streetCorrectionOptions : []
        ,
            value: _ctx.selectedSuggestion,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectedSuggestion = $event)),
            embedded: "",
            "field-value-corrected": _ctx.streetCorrected
          }, null, 8, ["selectOptions", "value", "field-value-corrected"]))
        : (_openBlock(), _createBlock(_component_FormElementInputField, {
            key: 1,
            label: _ctx.commonTexts.userInterface.formFieldLabels.street,
            "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels.street + _ctx.fieldIdSlug
        ,
            class: "col-md-9 mb-4",
            value: _ctx.addressData.street,
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.update(_ctx.formFields.STREET, $event))),
            "field-value-corrected": _ctx.streetCorrected,
            "field-has-error": _ctx.fieldErrorStreet,
            onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.resetFieldError('street'))),
            maxlength: "35"
          }, null, 8, ["label", "field-id", "value", "field-value-corrected", "field-has-error"])),
      _createVNode(_component_FormElementInputField, {
        label: _ctx.commonTexts.userInterface.formFieldLabels.streetNumber,
        "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels.streetNumber + _ctx.fieldIdSlug
        ,
        class: "col-md-3 mb-4",
        value: _ctx.addressData.streetNumber,
        onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.update(_ctx.formFields.STREET_NUMBER, $event))),
        "field-has-error": _ctx.fieldErrorStreetNumber,
        onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.resetFieldError('streetNumber')))
      }, null, 8, ["label", "field-id", "value", "field-has-error"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FormElementInputField, {
        label: 
          _ctx.commonTexts.userInterface.formFieldLabels.additionalInfoPrivateSimple
        ,
        "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels
            .additionalInfoPrivateSimple + _ctx.fieldIdSlug
        ,
        value: _ctx.addressData.additionalInfo,
        onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.update(_ctx.formFields.ADDITIONAL_INFO, $event))),
        "field-has-error": _ctx.fieldErrors ? _ctx.fieldErrors.additionalInfo : false,
        class: "col-md-12 mb-4"
      }, null, 8, ["label", "field-id", "value", "field-has-error"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_FormElementInputField, {
        label: _ctx.commonTexts.userInterface.formFieldLabels.zipCode,
        "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels.zipCode + _ctx.fieldIdSlug
        ,
        class: "col-md-3 mb-4",
        value: _ctx.addressData.zipCode,
        onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.update(_ctx.formFields.ZIP_CODE, $event))),
        "field-value-corrected": _ctx.zipCodeCorrected,
        "field-has-error": _ctx.fieldErrorZipCode,
        onFocus: _cache[7] || (_cache[7] = ($event: any) => (_ctx.resetFieldError('zipCode'))),
        maxlength: "10"
      }, null, 8, ["label", "field-id", "value", "field-value-corrected", "field-has-error"]),
      (_ctx.citySuggestion && !_ctx.suggestedAddressWasSelected)
        ? (_openBlock(), _createBlock(_component_FormElementSelectField, {
            key: 0,
            class: "col-md-9 pt-2",
            value: _ctx.selectedSuggestion,
            onInput: _cache[8] || (_cache[8] = ($event: any) => (_ctx.selectedSuggestion = $event)),
            selectOptions: 
          _ctx.addressCheckResult ? _ctx.addressCheckResult.cityCorrectionOptions : []
        ,
            "field-value-corrected": _ctx.cityCorrected,
            embedded: ""
          }, null, 8, ["value", "selectOptions", "field-value-corrected"]))
        : (_openBlock(), _createBlock(_component_FormElementInputField, {
            key: 1,
            label: _ctx.commonTexts.userInterface.formFieldLabels.city,
            "field-id": _ctx.commonTexts.userInterface.formFieldLabels.city + _ctx.fieldIdSlug,
            class: "col-md-9",
            value: _ctx.addressData.city,
            onInput: _cache[9] || (_cache[9] = ($event: any) => (_ctx.update(_ctx.formFields.CITY, $event))),
            "field-value-corrected": _ctx.cityCorrected,
            "field-has-error": _ctx.fieldErrorCity,
            onFocus: _cache[10] || (_cache[10] = ($event: any) => (_ctx.resetFieldError('city'))),
            maxlength: "35"
          }, null, 8, ["label", "field-id", "value", "field-value-corrected", "field-has-error"]))
    ])
  ]))
}
<template>
  <!--  <div>-->
  <!--    <b-form-checkbox-->
  <!--      :checked="value"-->
  <!--      @input="$emit('input', $event)"-->
  <!--      :disabled="disabled"-->
  <!--      class="form-element-checkbox"-->
  <!--    >-->
  <!--      <slot></slot>-->
  <!--    </b-form-checkbox>-->
  <!--  </div>-->
  <div>
    <div
      class="form-element-checkbox"
      :class="{
        'form-element-checkbox--disabled': disabled,
        'form-element-checkbox--error': hasError,
        'form-element-checkbox--multi-line': multiLineLabel,
      }"
    >
      <div class="form-element-checkbox__inner">
        <input
          :id="name"
          class="form-element-checkbox__input"
          type="checkbox"
          :value="inputValue"
          :checked="value"
          :disabled="disabled"
          @change="handleChange"
        /><label :for="name"
          ><span class="form-element-checkbox__label"><slot /></span
        ></label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue';

  export default defineComponent({
    name: 'FormElementCheckBox',
    props: {
      name: {
        type: String,
        required: true,
      },
      value: {
        type: Boolean,
        required: true,
      },
      disabled: {
        type: Boolean,
      },
      hasError: {
        type: Boolean,
        required: false,
        default: false,
      },
      multiLineLabel: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['input'],
    setup(props, { emit }) {
      const inputValue = computed(() => {
        return props.value ? 'true' : 'false';
      });

      const handleChange = function (
        event: Event & { target: HTMLInputElement },
      ) {
        emit('input', event.target.checked);
      };

      return {
        inputValue,
        handleChange,
      };
    },
  });
</script>

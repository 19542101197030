<template>
  <div class="complex-order-bonus-configuration-selection-form">
    <div class="complex-order-bonus-configuration-selection-form__headline">
      {{ headline }}
    </div>
    <div
      class="row complex-order-bonus-configuration-selection-form__configuration-fields"
    >
      <div
        v-for="(configurationOption, index) of configurationSelectionOptions"
        :key="index"
        class="col-md-6 complex-order-bonus-configuration-selection-form__configuration-field"
      >
        <FormElementSelectField
          :select-options="configurationOption.values"
          :label="configurationOption.name"
          :value="chosenOptions[index]"
          :disabled="disabled"
          @input="updateChosenOptions(index, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref } from 'vue';
  import commonTexts from '@/data/commonTexts';
  import FormElementSelectField from '@/components/FormElements/FormElementSelectField.vue';

  export default defineComponent({
    name: 'ComplexOrderBonusConfigurationSelectionForm',
    components: { FormElementSelectField },
    props: {
      orderBonus: {
        type: Object,
        required: true,
      },
      title: {
        type: String,
        required: false,
      },
      configurationSelectionOptions: {
        type: Array,
        required: true,
      },
      chosenValues: {
        type: Array,
        required: true,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['configurationChanged'],
    setup(props, { emit }) {
      const headline = computed(() => {
        return props.title
          ? props.title
          : commonTexts.userFrontend.orderBonusSelectionView
              .complexOrderBonusConfigurationSelectionTitle;
      });

      const chosenOptions = ref(props.chosenValues);

      const updateChosenOptions = function (
        index: number,
        updatedValue: string,
      ) {
        chosenOptions.value.splice(index, 1, updatedValue);
        emit('configurationChanged', chosenOptions.value);
      };

      return {
        headline,
        chosenOptions,
        updateChosenOptions,
      };
    },
  });
</script>

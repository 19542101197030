import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "price-list" }
const _hoisted_2 = {
  key: 0,
  class: "price-list__price-infos"
}
const _hoisted_3 = { class: "price" }
const _hoisted_4 = {
  key: 1,
  class: "price-list__price-infos"
}
const _hoisted_5 = { class: "price" }
const _hoisted_6 = {
  key: 2,
  class: "price-list__total-price"
}
const _hoisted_7 = { class: "price-list__total-price-label" }
const _hoisted_8 = {
  key: 3,
  class: "price-list__order-bonuses"
}
const _hoisted_9 = { class: "price-list__order-bonuses-cell" }
const _hoisted_10 = { class: "price-list__order-bonuses-cell" }
const _hoisted_11 = {
  key: 0,
  class: "price-list-caption"
}
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("table", null, [
      (_ctx.showPredefinedPrices && _ctx.showPriceList)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.salesCampaign.priceInfos, (price, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                _createElementVNode("td", null, _toDisplayString(price.description), 1),
                _createElementVNode("td", _hoisted_3, _toDisplayString(price.price), 1)
              ]))
            }), 128))
          ]))
        : (
          _ctx.showCalculatedPrices && _ctx.monthlyPriceGreaterThanZero && _ctx.showPriceList
        )
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_4, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", null, _toDisplayString(_ctx.monthlyPriceLabel), 1),
                _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.monthlyPrice), 1)
              ])
            ]))
          : _createCommentVNode("", true),
      (_ctx.totalPrice !== '' && _ctx.showCalculatedPrices && _ctx.showPriceList)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_6, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.commonTexts.userFrontend.priceList.totalLabel), 1),
              _createElementVNode("td", null, _toDisplayString(_ctx.totalPrice), 1)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.displayOrderBonusCart)
        ? (_openBlock(), _createElementBlock("tbody", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bonusCart, (selectedBonus) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: selectedBonus.bonus.materialNumber,
                class: "price-list__order-bonuses-row"
              }, [
                _createElementVNode("td", _hoisted_9, _toDisplayString(selectedBonus.bonus.title), 1),
                _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.renderPrice(selectedBonus.bonus.additionalCharges[0].value)), 1)
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.productIsWithCosts && _ctx.valueAddedTaxText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createElementVNode("span", { innerHTML: _ctx.valueAddedTaxText }, null, 8, _hoisted_12)
        ]))
      : _createCommentVNode("", true)
  ]))
}
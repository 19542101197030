<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'IconChevronRight',
  });
</script>

<template>
  <svg
    viewBox="0 0 16 16"
    width="1em"
    height="1em"
    focusable="false"
    role="img"
    aria-label="chevron right"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    class="bi-chevron-right b-icon bi"
    data-v-41be6633=""
  >
    <g data-v-41be6633="">
      <path
        fill-rule="evenodd"
        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
      ></path>
    </g>
  </svg>
</template>

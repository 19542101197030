import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "main-content-title" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_OscModal = _resolveComponent("OscModal")!

  return (_openBlock(), _createBlock(_component_OscModal, {
    "modal-id": "sub-title-modal",
    "show-modal": _ctx.openModal,
    onModalClosed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('modalClosed'))),
    "no-body-scroll": ""
  }, {
    body: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.commonTexts.userFrontend.orderForm.subTitleModalHeadline), 1),
      _createElementVNode("div", { innerHTML: _ctx.subTitleCopy }, null, 8, _hoisted_2),
      _createVNode(_component_BasicButton, {
        class: "mt-0",
        onButtonClicked: _ctx.handleChangeToOutLinkClick
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.commonTexts.userFrontend.orderForm.subTitleChangeTenantButtonLabel), 1)
        ]),
        _: 1
      }, 8, ["onButtonClicked"]),
      _createVNode(_component_BasicButton, {
        class: "mb-0 mt-3",
        transparent: "",
        onButtonClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('modalClosed')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.commonTexts.userFrontend.orderForm.subTitleCancelButtonLabel), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["show-modal"]))
}
import store from '@/store';
import router from '@/router';
import {
  CardAboPayRequest,
  CreditCardPaymentInitRequestData,
} from '@/models/PaymentServiceRequest';
import OrderService from '@/services/order-service';
import { PaymentData } from '@/models/OrderData';
import PaymentService from '@/services/payment-service';
import {
  getFirstNameLastNameFromAddresses,
  getGpNrOfPurchaser,
} from '@/composables/general-functions';
import { ConnectorFactory } from '@/services/connectors/connectorFactory';

export default class CardAboService {
  public static async startAgreementSCA() {
    await OrderService.persistState();
    const formData = store.state.formData;

    const { firstName, lastName } = getFirstNameLastNameFromAddresses(
      formData.addresses,
    );

    this.getPayForm(
      window.location,
      formData.salesCampaign.salesCampaignId,
      getGpNrOfPurchaser(),
      firstName,
      lastName,
    )
      .then((response) => {
        if (response && response.absUrl) {
          window.location.href = response.absUrl;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  public static buildInitialRequestDto(
    customerId: string,
    firstname: string,
    lastname: string,
    paymentData: PaymentData,
    successUrl: string,
    backUrl: string,
    errorUrl: string,
  ): CardAboPayRequest {
    const rootUrl = window.location.protocol + '//' + window.location.host;
    return {
      user: {
        customerId: customerId === '' ? undefined : customerId,
        firstname,
        lastname,
      },
      cardData: {
        pseudocardpan: paymentData.pseudoCardPan,
        truncatedcardpan: paymentData.truncatedCardPan,
      },
      shopInfo: {
        shopDomain: rootUrl,
      },
      returnUrls: {
        successUrl,
        backUrl,
        errorUrl,
      },
    };
  }

  public static getReturnUrlSuccess(
    absUrlPrefix: string,
    payContextToken: string,
  ) {
    const resolved = router.resolve({
      name: 'CardAgreementResult',
    });
    return `${absUrlPrefix}${resolved.href}?ctxtoken=${payContextToken}`;
  }

  public static getReturnUrlError(absUrlPrefix: string, mode: string) {
    const resolved = router.resolve({
      name: 'CardAgreementResult',
      // params: { mode: mode },
    });
    return `${absUrlPrefix}${resolved.href}?mode=${mode}`;
  }

  public static getPayForm(
    currentLoc: Location,
    salesCampaignId: string,
    customerId: string,
    firstName: string,
    lastName: string,
  ) {
    const absUrlPrefix = currentLoc.protocol + '//' + currentLoc.host;
    const payContextToken = PaymentService.buildPayContextToken(
      customerId,
      salesCampaignId,
    );

    const successUrl = this.getReturnUrlSuccess(absUrlPrefix, payContextToken);
    const errorUrl = this.getReturnUrlError(absUrlPrefix, 'error');
    const backUrl = this.getReturnUrlError(absUrlPrefix, 'back');
    const paymentData = OrderService.createPaymentData();

    const requestDto = this.buildInitialRequestDto(
      customerId,
      firstName,
      lastName,
      paymentData,
      successUrl,
      backUrl,
      errorUrl,
    );

    const paymentConnector = ConnectorFactory.getPaymentConnector();

    return (
      paymentConnector
        .getCreditCardAuthorizationUrl(
          requestDto as CreditCardPaymentInitRequestData,
          store.state.formDataToken,
        )
        // return paymentAPI
        //   .post('creditcard/billingagreement/init', requestDto, {
        //     headers: { token: JSON.stringify(store.state.formDataToken) },
        //   })
        .then((response) => {
          const responseBody = response;
          PaymentService.storePayFrameUrl(responseBody.absUrl);
          PaymentService.storeProcessId(responseBody.processId);
          return responseBody;
        })
        .catch((error) => {
          console.log(error.response.data);
          router.push({
            name: 'ErrorPage',
            params: { type: 'server' },
          });
        })
    );
  }

  public static requestPayResult() {
    const processId = PaymentService.getProcessId();
    const serviceTicket = '' + PaymentService.getServiceTicket();
    return PaymentService.requestPayResult(
      processId,
      serviceTicket,
      'creditcard/billingagreement/result',
    );
  }
}

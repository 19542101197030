import { ActionTree } from 'vuex';
import {
  ConfigActionTypes,
  ConfigMutationTypes,
  ConfigState,
} from '@/store/modules/config/types';
import { AppRootState } from '@/store/interfaces';
import ConfigService from '@/services/config-service';

const actions: ActionTree<ConfigState, AppRootState> = {
  async [ConfigActionTypes.LOAD_CONFIG]({ commit }) {
    await ConfigService.getConfig().then((configData) => {
      commit(ConfigMutationTypes.SET_CONFIG, configData);
    });
  },
};

export default actions;

<template>
  <div class="order-bonus-shopping-cart-form">
    <div class="order-bonus-shopping-cart-form__content">
      <div class="order-bonus-shopping-cart-form__title">
        {{
          commonFrontendTexts.userFrontend.orderBonusSelectionView.cartHeadline
        }}
      </div>
      <div v-if="showEmptyState">
        {{
          commonFrontendTexts.userFrontend.orderBonusSelectionView
            .confirmationText
        }}
      </div>
      <DisplayElementOrderBonusCartContent
        v-else
        v-for="(orderBonus, index) in selectedOrderBonuses"
        :order-bonus="orderBonus.bonus"
        :non-removable-bonus="orderBonus.fixedBonus"
        :key="orderBonus.bonus.materialNumber"
        :class="{ 'mt-1': index > 0 }"
        @removeItem="$emit('removeItemFromCart', index)"
      />
    </div>
    <div class="order-bonus-shopping-cart-form__button">
      <BasicButton
        variant="primary"
        type="submit"
        class="mt-0 btn-save"
        @buttonClicked="$emit('confirmOrderBonusSelection')"
        :disabled="showEmptyState || !orderBonusCartIsFull"
        >Weiter
      </BasicButton>
    </div>
  </div>
</template>

<script lang="ts">
  import commonTexts from '@/data/commonTexts';
  import { computed, defineComponent, PropType } from 'vue';
  import DisplayElementOrderBonusCartContent from '@/components/DisplayElements/DisplayElementOrderBonusCartContent.vue';
  import BasicButton from '@/components/Buttons/BasicButton.vue';
  import { SingleUserOrderBonus } from '@/types/OrderBonusesTypes';

  export default defineComponent({
    name: 'OrderBonusShoppingCartForm',
    components: { DisplayElementOrderBonusCartContent, BasicButton },
    props: {
      selectedOrderBonuses: {
        type: Array as PropType<SingleUserOrderBonus[]>,
        required: false,
        default: () => {
          [];
        },
      },
      orderBonusSelected: {
        type: Boolean,
        required: true,
      },
      orderBonusCartIsFull: {
        type: Boolean,
        required: true,
      },
    },
    setup(props) {
      const commonFrontendTexts = commonTexts;
      const showEmptyState = computed(() => {
        const orderBonusNotYetSelected = !props.orderBonusSelected;
        const noOrderBonusInCart =
          props.selectedOrderBonuses && props.selectedOrderBonuses.length === 0;
        return orderBonusNotYetSelected && noOrderBonusInCart;
      });

      return {
        commonFrontendTexts,
        showEmptyState,
      };
    },
  });
</script>

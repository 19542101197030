<template>
  <div class="icon-command-link">
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="8">
      <path
        fill="#ffffff"
        fill-rule="evenodd"
        d="M1 .71L1.608 0l3.391 3.999H5v.002L4.999 4 1.608 8 1 7.29 3.801 4 1 .71z"
      />
    </svg>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'IconCommandLink',
  });
</script>

<template>
  <div class="osc-street-address-form">
    <div class="form-row osc-street-address-form__street-input">
      <FormElementSelectField
        v-if="streetSuggestion && !suggestedAddressWasSelected"
        class="col-md-9 pt-2 mb-4"
        :selectOptions="
          addressCheckResult ? addressCheckResult.streetCorrectionOptions : []
        "
        value="selectedSuggestion"
        @input="selectedSuggestion = $event"
        embedded
        :field-value-corrected="streetCorrected"
      />
      <FormElementInputField
        v-else
        :label="commonTexts.userInterface.formFieldLabels.street"
        :field-id="
          commonTexts.userInterface.formFieldLabels.street + fieldIdSlug
        "
        class="col-md-9 mb-4"
        :value="addressData.street"
        @input="update(formFields.STREET, $event)"
        :field-value-corrected="streetCorrected"
        :field-has-error="fieldErrorStreet"
        @focus="resetFieldError('street')"
        maxlength="35"
      />
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.streetNumber"
        :field-id="
          commonTexts.userInterface.formFieldLabels.streetNumber + fieldIdSlug
        "
        class="col-md-3 mb-4"
        :value="addressData.streetNumber"
        @input="update(formFields.STREET_NUMBER, $event)"
        :field-has-error="fieldErrorStreetNumber"
        @focus="resetFieldError('streetNumber')"
      />
    </div>
    <div class="form-row">
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.zipCode"
        :field-id="
          commonTexts.userInterface.formFieldLabels.zipCode + fieldIdSlug
        "
        class="col-md-3 mb-4"
        :value="addressData.zipCode"
        @input="update(formFields.ZIP_CODE, $event)"
        :field-value-corrected="zipCodeCorrected"
        :field-has-error="fieldErrorZipCode"
        @focus="resetFieldError('zipCode')"
        maxlength="10"
      />
      <FormElementSelectField
        v-if="citySuggestion && !suggestedAddressWasSelected"
        class="col-md-9 pt-2 mb-4"
        value="selectedSuggestion"
        @input="selectedSuggestion = $event"
        :selectOptions="
          addressCheckResult ? addressCheckResult.cityCorrectionOptions : []
        "
        :field-value-corrected="cityCorrected"
        embedded
      />
      <FormElementInputField
        v-else
        :label="commonTexts.userInterface.formFieldLabels.city"
        :field-id="commonTexts.userInterface.formFieldLabels.city + fieldIdSlug"
        class="col-md-9 mb-4"
        :value="addressData.city"
        @input="update(formFields.CITY, $event)"
        :field-value-corrected="cityCorrected"
        :field-has-error="fieldErrorCity"
        @focus="resetFieldError('city')"
        maxlength="35"
      />
    </div>
    <BusinessAddressAdditionForm
      v-if="isBusinessAddress && 'majorCustomerCity' in addressData"
      :value="addressData"
      :field-id-slug="fieldIdSlug"
      :field-errors="fieldErrors"
      @addressDataInput="$emit('addressDataInput', $event)"
    />
    <PrivateAddressAdditionForm
      v-else-if="'addressAddition' in addressData"
      :value="addressData"
      :field-errors="fieldErrors"
      @addressDataInput="$emit('addressDataInput', $event)"
    />
  </div>
</template>

<script lang="ts">
  import {
    computed,
    defineComponent,
    onMounted,
    ref,
    watch,
    PropType,
  } from 'vue';
  import {
    FieldErrorsAddressData,
    ViolationFields,
  } from '@/validators/validatorTypes';
  import FormElementSelectField from '@/components/FormElements/FormElementSelectField.vue';
  import FormElementInputField from '@/components/FormElements/FormElementInputField.vue';
  import BusinessAddressAdditionForm from '@/components/Forms/BusinessAddressAdditionForm.vue';
  import PrivateAddressAdditionForm from '@/components/Forms/PrivateAddressAdditionForm.vue';
  import commonTexts from '@/data/commonTexts';
  import {
    FormBusinessAddress,
    FormDeviatingAddress,
    InternalSingleAddressValidationResult,
  } from '@/models/OrderData';
  import { OrderFormData } from '@/types/OrderFormTypes';

  export default defineComponent({
    name: 'StreetAddressForm',
    components: {
      PrivateAddressAdditionForm,
      BusinessAddressAdditionForm,
      FormElementSelectField,
      FormElementInputField,
    },
    emits: ['addressDataInput', 'resetFieldError'],
    props: {
      addressData: {
        type: Object as PropType<
          OrderFormData | FormBusinessAddress | FormDeviatingAddress
        >, // OrderFormStreetAddress to enable multiple implementations later on
        required: true,
      },
      fieldErrors: {
        type: Object as PropType<FieldErrorsAddressData>,
        required: true,
      },
      addressCheckResult: {
        type: Object as PropType<InternalSingleAddressValidationResult>,
        required: false,
      },
      isBusinessAddress: {
        type: Boolean,
        required: false,
        default: false,
      },
      fieldIdSlug: {
        type: String,
        required: false,
        default: '',
      },
    },
    setup(props, { emit }) {
      const additionalInfoSelected = ref(false);
      const selectedSuggestion = ref(0);
      const suggestedAddressWasSelected = ref(false);
      const citySuggestion = ref(false);
      const streetSuggestion = ref(false);
      const streetCorrected = ref(false);
      const zipCodeCorrected = ref(false);
      const cityCorrected = ref(false);

      enum formFields {
        STREET = 'street',
        STREET_NUMBER = 'streetNumber',
        ZIP_CODE = 'zipCode',
        CITY = 'city',
      }

      const update = function (field: formFields, value: string) {
        const data = Object.assign({}, props.addressData);
        switch (field) {
          case formFields.STREET:
            data.street = value;
            break;
          case formFields.STREET_NUMBER:
            data.streetNumber = value;
            break;
          case formFields.ZIP_CODE:
            data.zipCode = value;
            break;
          case formFields.CITY:
            data.city = value;
            break;
        }

        emit('addressDataInput', data);
      };

      const setFieldCorrections = function () {
        citySuggestion.value =
          props.addressCheckResult !== undefined &&
          props.addressCheckResult.cityCorrectionOptions &&
          props.addressCheckResult.cityCorrectionOptions.length > 0;
        streetSuggestion.value =
          props.addressCheckResult !== undefined &&
          props.addressCheckResult.streetCorrectionOptions &&
          props.addressCheckResult.streetCorrectionOptions.length > 0;
        streetCorrected.value =
          props.addressCheckResult !== undefined &&
          props.addressCheckResult.streetCorrected;
        zipCodeCorrected.value =
          props.addressCheckResult !== undefined &&
          props.addressCheckResult.zipCodeCorrected;
        cityCorrected.value =
          props.addressCheckResult !== undefined &&
          props.addressCheckResult.cityCorrected;
      };

      onMounted(() => {
        setFieldCorrections();
      });

      watch(props, () => {
        setFieldCorrections();
      });

      const fieldErrorStreet = computed(
        () =>
          props.fieldErrors.street ||
          (props.addressCheckResult !== undefined &&
            props.addressCheckResult.streetError),
      );
      const fieldErrorStreetNumber = computed(
        () =>
          props.fieldErrors.streetNumber ||
          (props.addressCheckResult !== undefined &&
            props.addressCheckResult.streetNumberError),
      );
      const fieldErrorCity = computed(
        () =>
          props.fieldErrors.city ||
          (props.addressCheckResult !== undefined &&
            props.addressCheckResult.cityError),
      );
      const fieldErrorZipCode = computed(
        () =>
          props.fieldErrors.zipCode ||
          (props.addressCheckResult !== undefined &&
            props.addressCheckResult.zipCodeError),
      );

      const resetSuggestionMode = function () {
        citySuggestion.value = false;
        streetSuggestion.value = false;
        streetCorrected.value = false;
        zipCodeCorrected.value = false;
        cityCorrected.value = false;
        selectedSuggestion.value = 0;
      };

      watch(selectedSuggestion, () => {
        if (selectedSuggestion.value == -1) {
          suggestedAddressWasSelected.value = false;
          resetSuggestionMode();
        } else if (selectedSuggestion.value > 0) {
          const suggestedAddress:
            | { city: string; street: string; zipCode: string; id: number }
            | undefined =
            props.addressCheckResult?.addressCorrectionOptions?.find(
              (address: {
                city: string;
                street: string;
                zipCode: string;
                id: number;
              }) => {
                if (address.id == selectedSuggestion.value) {
                  return address;
                }
              },
            );
          if (suggestedAddress) {
            const { city, street, zipCode } = suggestedAddress;
            const data = Object.assign({}, props.addressData);

            data.city = city;
            data.street = street;
            data.zipCode = zipCode;
            suggestedAddressWasSelected.value = true;

            emit('addressDataInput', data);
          }
          resetSuggestionMode();
        }
      });

      const resetFieldError = function (field: string) {
        switch (field) {
          case 'street':
            emit('resetFieldError', ViolationFields.STREET);
            break;
          case 'streetNumber':
            emit('resetFieldError', ViolationFields.STREET_NUMBER);
            break;
          case 'zipCode':
            emit('resetFieldError', ViolationFields.ZIP_CODE);
            break;
          case 'city':
            emit('resetFieldError', ViolationFields.CITY);
            break;
        }
      };

      return {
        formFields,
        update,
        fieldErrorStreet,
        fieldErrorStreetNumber,
        fieldErrorCity,
        fieldErrorZipCode,
        additionalInfoSelected,
        selectedSuggestion,
        streetSuggestion,
        citySuggestion,
        streetCorrected,
        zipCodeCorrected,
        cityCorrected,
        resetFieldError,
        suggestedAddressWasSelected,
        commonTexts,
      };
    },
  });
</script>

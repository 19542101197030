<template>
  <div
    :class="{
      'form-element-select-field-container--not-empty': fieldValueNotEmpty,
    }"
    class="form-element-select-field-container"
  >
    <select
      class="form-element-select-field"
      :class="{
        'form-element-select-field--disabled': disabled,
        'form-element-select-field--corrected': fieldValueCorrected,
        'form-element-select-field--error': fieldHasError,
        'form-element-select-field--not-selected': !isSelected,
      }"
      @change="handleSelectionChange($event.target.value)"
      :id="fieldId ? fieldId : label"
    >
      <option
        value=""
        disabled
        :selected="value === '' || !fieldValueNotEmpty"
        hidden
      />
      <option
        v-for="option in options"
        :id="fieldId ? fieldId : label"
        :key="option.value"
        :selected="value !== '' && value === option.value"
        class="form-element-select-field__option"
        :disabled="option.disabled"
      >
        {{
          valueAsText && option.value && option.value !== '00'
            ? option.value
            : option.text
        }}
      </option>
    </select>
    <label
      v-if="label.length > 0"
      :for="fieldId ? fieldId : label"
      class="form-element-select-field-container__label"
      >{{ label }}</label
    >
  </div>
</template>

<script lang="ts">
  import { computed, ComputedRef, defineComponent, PropType, ref } from 'vue';
  import { SelectOption } from '@/models/OrderData';
  // import FormSection from '@/components/FormElements/FormSection.vue';

  export default defineComponent({
    name: 'FormElementSelectField',
    // components: { FormSection },
    props: {
      value: {
        type: [String, Number],
        required: true,
      },
      label: {
        type: String,
        required: false,
        default: '',
      },
      selectOptions: {
        type: Array as PropType<SelectOption[] | string[]>,
        required: true,
      },
      fieldValueCorrected: {
        type: Boolean,
        required: false,
        default: false,
      },
      fieldHasError: {
        type: Boolean,
        required: false,
        default: false,
      },
      fieldId: {
        type: String,
        required: false,
      },
      name: {
        type: String,
        required: false,
      },
      disabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      removeLabelIfFilled: {
        type: Boolean,
        required: false,
        default: false,
      },
      embedded: {
        type: Boolean,
        required: false,
        default: false,
      },
      detached: {
        type: Boolean,
        required: false,
        default: false,
      },
      valueAsText: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['input', 'selectionChanged'],
    setup(props, { emit }) {
      const open = ref(false);

      const selectedText = ref('');

      const options: ComputedRef<SelectOption[]> = computed(() => {
        const result: SelectOption[] = [];
        if (props.selectOptions.length > 0) {
          if (typeof props.selectOptions[0] === 'string') {
            (props.selectOptions as string[]).forEach((option) => {
              result.push({ value: option, text: option });
            });
          } else {
            (props.selectOptions as SelectOption[]).forEach((option) => {
              result.push(option);
            });
          }
        }

        return result;
      });

      const selectedValue = function (text: string) {
        if (props.valueAsText) {
          const selectedOption = options.value.find(
            (option) => option.value === text,
          );
          if (selectedOption) {
            selectedText.value = selectedOption.text;
            return selectedOption.value;
          }
          return '';
        } else {
          const selectedOption = options.value.find(
            (option) => option.text === text,
          );
          if (selectedOption) {
            selectedText.value = selectedOption.text;
            return selectedOption.value;
          }
          return '';
        }
      };

      const isSelected = computed(() => {
        return selectedText.value !== '' || props.value !== '';
      });

      const fieldValueNotEmpty = computed(
        () =>
          (typeof props.value === 'string' && props.value.length > 0) ||
          options.value.some((option) => option.value === props.value),
      );

      const isInputFilled: ComputedRef<boolean> = computed(() => {
        return !!(
          props.value &&
          ((typeof props.value === 'string' && props.value.length > 0) ||
            typeof props.value === 'number')
        );
      });

      const handleSelectionChange = function (newValue: string) {
        emit('input', selectedValue(newValue));
        emit('selectionChanged');
      };

      // const handleFieldChange = function (e: string) {
      //   emit('input', e);
      //   emit('selectionChanged');
      // };

      const showLabel = computed(() => {
        return props.removeLabelIfFilled ? !isInputFilled.value : true;
      });

      return {
        isInputFilled,
        handleSelectionChange,
        showLabel,
        fieldValueNotEmpty,
        isSelected,
        open,
        options,
      };
    },
  });
</script>

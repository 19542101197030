import {
  ValidatorInterface,
  ViolationFields,
} from '@/validators/validatorTypes';
import { OrderFormData } from '@/types/OrderFormTypes';
import { FormBusinessAddress } from '@/models/OrderData';

export class PersonalCoreDataValidator implements ValidatorInterface {
  validate(target: OrderFormData | FormBusinessAddress): ViolationFields[] {
    const violations = [];
    if (
      !target.salutation ||
      target.salutation === '00' ||
      target.salutation.length === 0
    ) {
      violations.push(ViolationFields.SALUTATION);
    }
    if ((target as FormBusinessAddress).companyName !== undefined) {
      if ((target as FormBusinessAddress).companyName.length < 2) {
        violations.push(ViolationFields.COMPANY_NAME);
      }
    } else {
      if (
        !(target as OrderFormData).firstName ||
        (target as OrderFormData).firstName.length < 2
      ) {
        violations.push(ViolationFields.FIRST_NAME);
      }
      if (
        !(target as OrderFormData).lastName ||
        (target as OrderFormData).lastName.length < 2
      ) {
        violations.push(ViolationFields.LAST_NAME);
      }
    }

    return violations;
  }
}

<template>
  <div :class="borderAreaClass">
    <slot />
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue';
  export default defineComponent({
    name: 'ContentArea',
    props: {
      isFoldable: {
        type: Boolean,
        default: false,
        required: false,
      },
      isFoldableOpen: {
        type: Boolean,
        default: false,
        required: false,
      },
      borderTop: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    setup(props) {
      const prefix = 'content-area';
      const contentClass = 'content-area--main-content';

      const foldableClass = computed(() => {
        const foldableStatus = props.isFoldableOpen
          ? `${prefix}--main-foldable-open`
          : `${prefix}--main-foldable-close`;
        return props.isFoldable ? foldableStatus : '';
      });

      const borderAreaClass = computed(() => {
        const borderClass = props.borderTop ? `${prefix}--border-top` : '';

        if (props.isFoldable) {
          return `${prefix} ${foldableClass.value} ${borderClass}`;
        } else {
          return `${prefix} ${contentClass} ${borderClass}`;
        }
      });

      return { borderAreaClass };
    },
  });
</script>

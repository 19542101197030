import SetupService from '@/services/setup-service';
import sourcepointMapping from '@/data/sourcepoint-map.json';
import { SourcepointConfig } from '@/models/sourcepoint';
import store from '@/store';
import { loadScript } from 'vue-plugin-load-script';

export default class SourcepointService {
  public static accountId = 348;

  public static getSourcepointConfig(): SourcepointConfig {
    const hostname = SetupService.getHostname();
    const config = sourcepointMapping.find((sourcepoint) => {
      if (sourcepoint.hostname === hostname) {
        return sourcepoint;
      }
    });
    return {
      propertyId: config?.propertyId ?? 0,
      cname: config?.cname ?? '',
      privacyManagerID: config?.privacyManagerID ?? 0,
    };
  }

  public static async loadSourcePoint() {
    const sourcepointConfig = this.getSourcepointConfig();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _window = window as any;

    _window._sp_queue = [];
    _window._sp_ = {
      config: {
        accountId: this.accountId,
        propertyId: sourcepointConfig.propertyId,
        baseEndpoint: sourcepointConfig.cname,
        gdpr: {},
      },
    };

    await loadScript(
      `${sourcepointConfig.cname}/unified/wrapperMessagingWithoutDetection.js`,
    );

    this.pushConsentDataLayer();
  }

  public static pushConsentDataLayer() {
    if (store.state.tenant === 'sz') {
      loadScript('/js/consentLayerPush.js');
    }
  }
}

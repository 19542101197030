<template>
  <div class="business-address-addition-form mb-4">
    <FormElementCheckBox
      :value="additionalInfoSelected"
      @input="additionalInfoSelected = $event"
      :name="fieldIdSlug + '-businessAddressAddition'"
    >
      {{
        commonTexts.userInterface.formFieldLabels
          .showAddressAdditionFormBusiness
      }}
    </FormElementCheckBox>
    <div
      class="form-row business-address-addition-form__row mb-4 mt-4"
      v-if="additionalInfoSelected"
    >
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.floor"
        :field-id="
          commonTexts.userInterface.formFieldLabels.floor + fieldIdSlug
        "
        :value="value.floor"
        @input="update(formFields.FLOOR, $event)"
        :field-has-error="fieldErrors.floor"
        class="col-md-6"
        maxlength="6"
      />
      <FormElementCheckBox
        :value="value.mailDeliverySelected"
        @input="update(formFields.MAIL_DELIVERY_SELECTED, $event)"
        class="col-md-6 mt-3 mt-md-4"
        :name="fieldIdSlug + 'businessAddressAdditionMailDelivery'"
        maxlength="6"
        >{{
          commonTexts.userInterface.formFieldLabels.selectMailDelivery
        }}</FormElementCheckBox
      >
    </div>
    <div
      class="form-row business-address-addition-form__row mb-4"
      v-if="additionalInfoSelected"
    >
      <FormElementInputField
        :label="
          commonTexts.userInterface.formFieldLabels.additionalInfoBusiness
        "
        :field-id="
          commonTexts.userInterface.formFieldLabels.additionalInfoBusiness +
          fieldIdSlug
        "
        :value="value.additionalInfo"
        @input="update(formFields.ADDITIONAL_INFO, $event)"
        :field-has-error="fieldErrors.additionalInfo"
        class="col-md-12"
        maxlength="50"
      />
    </div>
    <div
      class="form-row business-address-addition-form__row"
      v-if="additionalInfoSelected"
    >
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.majorCustomerZipCode"
        :field-id="
          commonTexts.userInterface.formFieldLabels.majorCustomerZipCode +
          fieldIdSlug
        "
        class="col-md-4 mb-4 mb-md-0"
        :value="value.majorCustomerZipCode"
        @input="update(formFields.MAJOR_CUSTOMER_ZIP_CODE, $event)"
        maxlength="10"
      />
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.majorCustomerCity"
        :field-id="
          commonTexts.userInterface.formFieldLabels.majorCustomerCity +
          fieldIdSlug
        "
        class="col-md-8"
        :value="value.majorCustomerCity"
        @input="update(formFields.MAJOR_CUSTOMER_CITY, $event)"
        maxlength="35"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import FormElementInputField from '@/components/FormElements/FormElementInputField.vue';
  import FormElementCheckBox from '@/components/FormElements/FormElementCheckBox.vue';
  import { defineComponent, onMounted, ref, PropType, watch } from 'vue';
  import commonTexts from '@/data/commonTexts';
  import { FieldErrorsAddressData } from '@/validators/validatorTypes';
  import {
    FormBusinessAddress,
    FormDeviatingAddress,
  } from '@/models/OrderData';

  export default defineComponent({
    name: 'BusinessAddressAdditionForm',
    components: {
      FormElementInputField,
      FormElementCheckBox,
    },
    props: {
      value: {
        type: Object as PropType<FormBusinessAddress | FormDeviatingAddress>,
        required: true,
      },
      fieldErrors: {
        type: Object as PropType<FieldErrorsAddressData>,
        required: true,
      },
      fieldIdSlug: {
        type: String,
        required: false,
        default: '',
      },
    },
    emits: ['addressDataInput'],
    setup(props, { emit }) {
      enum formFields {
        FLOOR = 'floor',
        MAIL_DELIVERY_SELECTED = 'mailDeliverySelected',
        ADDITIONAL_INFO = 'additionalInfo',
        MAJOR_CUSTOMER_ZIP_CODE = 'majorCustomerZipCode',
        MAJOR_CUSTOMER_CITY = 'majorCustomerCity',
      }
      const additionalInfoSelected = ref(false);

      onMounted(() => {
        if (
          props.value.floor !== '' ||
          props.value.additionalInfo !== '' ||
          props.value.majorCustomerZipCode !== '' ||
          props.value.majorCustomerCity !== '' ||
          props.value.mailDeliverySelected
        ) {
          additionalInfoSelected.value = true;
        }
      });
      watch(
        [
          () => props.value.majorCustomerZipCode,
          () => props.value.floor,
          () => props.value.mailDeliverySelected,
          () => props.value.majorCustomerCity,
          () => props.value.additionalInfo,
        ],
        () => {
          if (
            props.value.floor !== '' ||
            props.value.additionalInfo !== '' ||
            props.value.majorCustomerZipCode !== '' ||
            props.value.majorCustomerCity !== '' ||
            props.value.mailDeliverySelected
          ) {
            additionalInfoSelected.value = true;
          }
        },
      );

      const update = function (field: formFields, value: string | boolean) {
        const data = Object.assign({}, props.value);
        switch (field) {
          case formFields.FLOOR:
            data.floor = value as string;
            break;
          case formFields.MAIL_DELIVERY_SELECTED:
            data.mailDeliverySelected = value as boolean;
            break;
          case formFields.MAJOR_CUSTOMER_ZIP_CODE:
            data.majorCustomerZipCode = value as string;
            break;
          case formFields.MAJOR_CUSTOMER_CITY:
            data.majorCustomerCity = value as string;
            break;
          case formFields.ADDITIONAL_INFO:
            data.additionalInfo = value as string;
            break;
        }

        emit('addressDataInput', data);
      };

      return {
        formFields,
        update,
        additionalInfoSelected,
        commonTexts,
      };
    },
  });
</script>

<template>
  <fieldset class="form-group" :class="additionalClass">
    <slot />
  </fieldset>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'FormSection',
    props: {
      additionalClass: {
        type: String,
        required: false,
        default: '',
      },
    },
  });
</script>

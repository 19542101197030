import { DateTime } from 'luxon';
// import {
//   AdditionalPageContentCacheData,
//   AdditionPageContentData,
// } from '@/types/configurationTypes';

// const STORAGE_THRESHOLD_MS = 86400000;

export function readDataFromClientStorage(key: string): string | null {
  return sessionStorage.getItem(key);
}

export function removeStorageItemFromClientStorage(key: string): void {
  sessionStorage.removeItem(key);
}

export function writeDataToClientStorage(key: string, value: string): void {
  sessionStorage.setItem(key, value);
}

export function storageItemIsValidInTimeLimit(
  key: string,
  millisecondLimit: number,
): boolean {
  const storageItem = readDataFromClientStorage(key);

  if (storageItem) {
    const storageItemTime = DateTime.fromMillis(parseFloat(storageItem));
    return (
      DateTime.now().diff(storageItemTime).milliseconds <= millisecondLimit
    );
  }

  return false;
}

// export function storeAdditionalPageData(additionalPageContent: AdditionPageContentData, pageKey: string) {
//   const data: AdditionalPageContentCacheData = {
//     data: additionalPageContent,
//     savedAt: DateTime.now().toISO()
//   }
//   writeDataToClientStorage(pageKey, JSON.stringify(data));
// }

// export function restoreAdditionalPageData(pageKey: string) {
//   const cachedData = readDataFromClientStorage(pageKey);
//   if (cachedData) {
//     const parsedData: AdditionalPageContentCacheData = JSON.parse(cachedData);
//     const savedAt = DateTime.fromISO(parsedData.savedAt);
//     const now = DateTime.now();
//
//     // If content is up-to-date (not older than threshold): return
//     if (now.diff(savedAt).milliseconds < STORAGE_THRESHOLD_MS) {
//       return parsedData.data;
//     } else {
//       // If content is too old: Invalidate entry;
//       removeStorageItemFromClientStorage(pageKey);
//     }
//   }
//   return null;
// }

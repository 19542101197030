import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "legal-text gp-nr-hint margin-top-large" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageBox = _resolveComponent("MessageBox")!
  const _component_FormElementInputField = _resolveComponent("FormElementInputField")!
  const _component_DisplayElementAgbTextBlock = _resolveComponent("DisplayElementAgbTextBlock")!
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_TheOverlay = _resolveComponent("TheOverlay")!
  const _component_FormWrapper = _resolveComponent("FormWrapper")!

  return (_openBlock(), _createBlock(_component_FormWrapper, { "additional-class": "gpnr-form" }, {
    default: _withCtx(() => [
      (_ctx.formInvalidOrWrongNumber)
        ? (_openBlock(), _createBlock(_component_MessageBox, {
            key: 0,
            type: 'no-bg',
            content: _ctx.content.gpNr.error.formValidationMessage
          }, null, 8, ["content"]))
        : _createCommentVNode("", true),
      (_ctx.gpNrIsInvalidOrWrong)
        ? (_openBlock(), _createBlock(_component_MessageBox, {
            key: 1,
            content: _ctx.content.gpNr.error.gpNrNotValid
          }, null, 8, ["content"]))
        : _createCommentVNode("", true),
      _createVNode(_component_FormElementInputField, {
        label: "Kundennummer",
        value: _ctx.connectUsersInputs.gpNr,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.connectUsersInputs.gpNr = $event)),
        "field-has-error": _ctx.gpNrIsInvalidOrWrong,
        maxlength: "10"
      }, null, 8, ["value", "field-has-error"]),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.content.gpNr.gpNrInputHint), 1),
      _createVNode(_component_FormElementInputField, {
        label: "Nachname / Firmenname",
        value: _ctx.connectUsersInputs.name,
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.connectUsersInputs.name = $event)),
        "field-has-error": _ctx.nameInvalidOrNotMatchingWithGpNumber
      }, null, 8, ["value", "field-has-error"]),
      _createElementVNode("p", {
        class: _normalizeClass(["legal-text agb-check margin-top-large", {
        // 'error-checkbox': agbGpNrCheckboxIsInvalid,
      }])
      }, [
        _createVNode(_component_DisplayElementAgbTextBlock, {
          "show-additional-legal-text": false,
          content: _ctx.content
        }, null, 8, ["content"])
      ]),
      _createVNode(_component_TheOverlay, {
        show: _ctx.gpNrConnectInProgress,
        opacity: 0.3,
        "spiner-size": 20,
        class: "d-inline-block | w-100 mt-3",
        isFullPage: false
      }, {
        default: _withCtx(() => [
          _createVNode(_component_BasicButton, {
            disabled: _ctx.gpNrConnectInProgress,
            variant: "primary",
            class: "mt-0",
            onButtonClicked: _ctx.submit
          }, {
            default: _withCtx(() => [
              _createTextVNode("Weiter")
            ]),
            _: 1
          }, 8, ["disabled", "onButtonClicked"])
        ]),
        _: 1
      }, 8, ["show"])
    ]),
    _: 1
  }))
}
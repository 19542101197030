import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "order-digital" }
const _hoisted_2 = {
  key: 0,
  class: "main-content-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconChevronRight = _resolveComponent("IconChevronRight")!
  const _component_GpNrConnectForm = _resolveComponent("GpNrConnectForm")!
  const _component_ContentArea = _resolveComponent("ContentArea")!
  const _component_AddressError = _resolveComponent("AddressError")!
  const _component_OrderForm = _resolveComponent("OrderForm")!
  const _component_TheOverlay = _resolveComponent("TheOverlay")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.gpConnectError && _ctx.showGpNrConnectForm && !_ctx.gpConnectSuccess)
      ? (_openBlock(), _createBlock(_component_ContentArea, {
          key: 0,
          borderTop: !_ctx.addressFormExpanded,
          isFoldable: true,
          isFoldableOpen: _ctx.gpNrConnectFormExpanded,
          class: _normalizeClass(["gpnr-form-container mb-4", {
        active: _ctx.gpNrConnectFormExpanded,
        collapsed: !_ctx.gpNrConnectFormExpanded && _ctx.showGpNrConnectForm,
      }])
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createElementVNode("header", null, [
                _createElementVNode("h2", {
                  class: _normalizeClass(["title clickable", { 'form-collapsed-title': !_ctx.gpNrConnectFormExpanded }]),
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.formCollapse && _ctx.formCollapse(...args)))
                }, [
                  _createVNode(_component_IconChevronRight),
                  _createTextVNode(" " + _toDisplayString(_ctx.content.gpNr.headlineWithGpNr), 1)
                ], 2)
              ]),
              (_ctx.gpNrConnectFormExpanded)
                ? (_openBlock(), _createBlock(_component_GpNrConnectForm, {
                    key: 0,
                    content: _ctx.content,
                    "gp-connect-wrong-number": _ctx.gpConnectWrongNumber,
                    "gp-nr-connect-in-progress": _ctx.gpNrConnectInProgress,
                    onFormValidated: _cache[1] || (_cache[1] = ($event: any) => (_ctx.submitGpNrConnectForm($event)))
                  }, null, 8, ["content", "gp-connect-wrong-number", "gp-nr-connect-in-progress"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["borderTop", "isFoldableOpen", "class"]))
      : _createCommentVNode("", true),
    _createVNode(_component_ContentArea, {
      borderTop: !_ctx.gpNrConnectFormExpanded,
      isFoldable: true,
      isFoldableOpen: _ctx.addressFormExpanded,
      id: "main-order-form",
      ref: "mainOrderForm",
      class: _normalizeClass(["address-data-container", {
        active: _ctx.addressFormExpanded,
        collapsed: !_ctx.addressFormExpanded && _ctx.showGpNrConnectForm,
      }])
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("header", null, [
            (!_ctx.showGpNrConnectForm || _ctx.gpConnectError || _ctx.gpConnectSuccess)
              ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString(_ctx.content.start.headline2), 1))
              : (_openBlock(), _createElementBlock("h2", {
                  key: 1,
                  class: _normalizeClass(["title clickable", { 'form-collapsed-title': !_ctx.addressFormExpanded }]),
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.formCollapse && _ctx.formCollapse(...args)))
                }, [
                  _createVNode(_component_IconChevronRight),
                  _createElementVNode("span", null, _toDisplayString(_ctx.content.gpNr.headlineWithoutGpNr), 1)
                ], 2))
          ]),
          (!_ctx.gpNrConnectFormExpanded)
            ? (_openBlock(), _createBlock(_component_AddressError, {
                key: 0,
                addressChanged: _ctx.addressChanged,
                addressInvalid: _ctx.addressInvalid || _ctx.orderFormOptionalFieldWrong,
                addressIgnore: _ctx.addressIgnore,
                addressSuggested: _ctx.addressSuggested,
                addressComplete: _ctx.addressComplete,
                addressAlreadyChecked: _ctx.formSubmitted,
                gpConnectError: _ctx.gpConnectError,
                "gp-connect-error-message": _ctx.gpConnectErrorMessage,
                gpConnectSuccess: _ctx.gpConnectSuccess,
                "personal-data-incomplete": _ctx.orderFormPersonalDataIsInvalid
              }, null, 8, ["addressChanged", "addressInvalid", "addressIgnore", "addressSuggested", "addressComplete", "addressAlreadyChecked", "gpConnectError", "gp-connect-error-message", "gpConnectSuccess", "personal-data-incomplete"]))
            : _createCommentVNode("", true),
          _createVNode(_component_TheOverlay, {
            show: _ctx.submittingFormData,
            isFullPage: false
          }, {
            default: _withCtx(() => [
              (
              _ctx.gpConnectError ||
              (_ctx.showGpNrConnectForm && !_ctx.gpNrConnectFormExpanded) ||
              !_ctx.showGpNrConnectForm
            )
                ? (_openBlock(), _createBlock(_component_OrderForm, {
                    key: 0,
                    "address-check-result": _ctx.addressValidationResult,
                    "order-form-data": _ctx.orderFormData,
                    "form-data-currently-validating": _ctx.isValidating,
                    "country-options": _ctx.countryOptions,
                    "name-is-editable": _ctx.isNameEditable || !_ctx.userExists,
                    "request-email-phone-number": false,
                    "request-region-select": _ctx.showRegionSelect,
                    "full-address-required": _ctx.isAddressRequired,
                    "product-type": _ctx.formData.salesCampaign.printProductType,
                    "email-is-mandatory": false,
                    "request-miles-and-more-id": _ctx.requestMilesAndMoreId,
                    "miles-and-more-id": _ctx.formData.mmNumber,
                    pva: _ctx.formData.pva,
                    onFormValidated: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submitOrderForm($event))),
                    onUpdateMilesAndMoreId: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('updateMilesAndMoreId', $event))),
                    onUpdatePrivateData: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updatePrivateData($event))),
                    onUpdatedPva: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updatePva($event)))
                  }, null, 8, ["address-check-result", "order-form-data", "form-data-currently-validating", "country-options", "name-is-editable", "request-region-select", "full-address-required", "product-type", "request-miles-and-more-id", "miles-and-more-id", "pva"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["show"])
        ])
      ]),
      _: 1
    }, 8, ["borderTop", "isFoldableOpen", "class"])
  ]))
}
<template>
  <div
    class="form-element-input-field"
    :class="{
      'form-element-input-field--not-empty': fieldValueNotEmpty || withoutLabel,
      'form-element-input-field--disabled': readonly,
      'form-element-input-field--error': fieldHasError,
      'form-element-input-field--corrected': fieldValueCorrected,
    }"
  >
    <input
      :id="fieldId ? fieldId : label"
      class="form-element form-element-input-field__input"
      :type="inputType"
      :value="value"
      :maxlength="maxlength"
      :readonly="readonly"
      @input="$emit('input', $event.target.value)"
      @blur="handleBlur"
      @focus="handleFocus"
      @focusout="handleFocusOut"
      @keydown="isValidCharacter($event)"
    />
    <label
      v-if="label.length > 0"
      :for="fieldId ? fieldId : label"
      class="form-element-input-field__label"
      >{{ label }}</label
    >
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent, ref } from 'vue';
  import { valueNotEmpty } from '@/composables/form-fields';

  export default defineComponent({
    name: 'FormElementInputField',
    props: {
      value: {
        type: String,
        required: true,
      },
      label: {
        required: false,
        type: String,
        default: '',
      },
      fieldValueCorrected: {
        type: Boolean,
        required: false,
        default: false,
      },
      fieldHasError: {
        type: Boolean,
        required: false,
        default: false,
      },
      fieldId: {
        type: String,
        required: false,
      },
      name: {
        type: String,
        required: false,
      },
      maxlength: {
        type: String,
        required: false,
      },
      readonly: {
        type: Boolean,
        required: false,
      },
      inputType: {
        type: String,
        required: false,
        default: 'text',
      },
      withoutLabel: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['input', 'blur', 'focus'],
    setup(props, { emit }) {
      const isInputFilled = computed(
        () => props.value && props.value.length >= 1,
      );

      const fieldIsFocused = ref(false);
      const fieldValueNotEmpty = computed(
        () => valueNotEmpty(props.value) || fieldIsFocused.value,
      );

      const handleFocus = function () {
        fieldIsFocused.value = true;
      };

      const handleFocusOut = function () {
        fieldIsFocused.value = false;
      };

      const handleBlur = function () {
        emit('blur');
        handleFocusOut();
      };

      const isValidCharacter = function (event: KeyboardEvent) {
        const controlCharacters = [
          'Backspace',
          'Delete',
          'ArrowLeft',
          'ArrowRight',
          'ArrowUp',
          'ArrowDown',
          'Tab',
          'Shift',
          'Enter',
        ];
        if (controlCharacters.includes(event.key)) {
          return true;
        }
        if (props.inputType === 'number' || props.inputType === 'tel') {
          const validCharacters = new RegExp('[\\d-]+', 'g');
          if (!validCharacters.test(event.key)) {
            event.preventDefault();
            return false;
          }
        }
        return true;
      };

      return {
        isInputFilled,
        handleBlur,
        fieldValueNotEmpty,
        handleFocus,
        handleFocusOut,
        isValidCharacter,
      };
    },
  });
</script>

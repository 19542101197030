import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["selected"]
const _hoisted_3 = ["id", "selected", "disabled"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{
      'form-element-select-field-container--not-empty': _ctx.fieldValueNotEmpty,
    }, "form-element-select-field-container"])
  }, [
    _createElementVNode("select", {
      class: _normalizeClass(["form-element-select-field", {
        'form-element-select-field--disabled': _ctx.disabled,
        'form-element-select-field--corrected': _ctx.fieldValueCorrected,
        'form-element-select-field--error': _ctx.fieldHasError,
        'form-element-select-field--not-selected': !_ctx.isSelected,
      }]),
      onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleSelectionChange($event.target.value))),
      id: _ctx.fieldId ? _ctx.fieldId : _ctx.label
    }, [
      _createElementVNode("option", {
        value: "",
        disabled: "",
        selected: _ctx.value === '' || !_ctx.fieldValueNotEmpty,
        hidden: ""
      }, null, 8, _hoisted_2),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          id: _ctx.fieldId ? _ctx.fieldId : _ctx.label,
          key: option.value,
          selected: _ctx.value !== '' && _ctx.value === option.value,
          class: "form-element-select-field__option",
          disabled: option.disabled
        }, _toDisplayString(_ctx.valueAsText && option.value && option.value !== '00'
            ? option.value
            : option.text), 9, _hoisted_3))
      }), 128))
    ], 42, _hoisted_1),
    (_ctx.label.length > 0)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.fieldId ? _ctx.fieldId : _ctx.label,
          class: "form-element-select-field-container__label"
        }, _toDisplayString(_ctx.label), 9, _hoisted_4))
      : _createCommentVNode("", true)
  ], 2))
}
<template>
  <div class="the-page-content">
    <!-- page title -->
    <div class="the-page-content__title" v-if="title">
      <h1>{{ title }}</h1>
    </div>

    <div class="the-page-content__body">
      <!-- main -->
      <div :class="mainClass">
        <slot name="main-content-area" v-if="hasMainArea" />

        <div class="the-page-content--button-content-area" v-if="hasButtonArea">
          <slot name="button-content-area" />
        </div>
      </div>

      <!-- sidebar -->
      <div class="the-page-content__side-content" v-if="hasSidebar">
        <slot name="side-content-area" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { computed, defineComponent } from 'vue';

  export default defineComponent({
    name: 'ThePageContent',
    props: {
      title: {
        type: String,
        default: '',
        required: false,
      },
      isFoldable: {
        type: Boolean,
        default: false,
        required: false,
      },
    },
    setup(props, { slots }) {
      const prefix = 'the-page-content';

      const hasMainArea = computed(() => {
        return slots['main-content-area'];
      });

      const hasSidebar = computed(() => {
        return slots['side-content-area'];
      });

      const hasButtonArea = computed(() => {
        return slots['button-content-area'];
      });

      const mainClass = computed(() => {
        return props.isFoldable
          ? `${prefix}__main-content-foldable`
          : `${prefix}__main-content`;
      });

      return { mainClass, hasMainArea, hasSidebar, hasButtonArea };
    },
  });
</script>

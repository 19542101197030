<template>
  <div class="icon-show-more" :class="{ 'icon-show-more--closed': isClosed }">
    <div class="icon-show-more__inner">
      <div class="icon-show-more__dash">&nbsp;</div>
      <div class="icon-show-more__dash">&nbsp;</div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'IconShowMore',
    props: {
      isClosed: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  });
</script>

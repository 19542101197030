<template>
  <div class="order-bonus-selection-form">
    <PageRow
      v-for="(row, i) in arrangedOrderBonuses"
      :key="i"
      class="order-bonus-selection-form__row"
    >
      <DisplayElementOrderBonusTile
        v-for="orderBonus in row"
        :key="orderBonus.bonus.materialNumber"
        :order-bonus="orderBonus.bonus"
        :in-cart="itemIsInCart(orderBonus.bonus.materialNumber)"
        embedded
        :active="activeItem === orderBonus.bonus.materialNumber"
        class="form-group order-bonus-selection-form__tile"
        @click="switchActiveItem(orderBonus.bonus.materialNumber)"
      />
      <transition name="fade">
        <DisplayElementOrderBonusDetailsPreview
          v-if="showDetailView(i) && activeOrderBonus"
          :order-bonus="activeOrderBonus.bonus"
          is-interactive
          :disabled-button="
            (!activeItemRemovable && cartIsFull) || !configuredItemExists
          "
          :custom-label="detailViewButtonLabel"
          :order-bonus-configuration-options="properties"
          :chosen-values="chosenValues"
          :calculated-price="priceForConfiguration"
          :order-bonus-is-in-cart="itemInCart"
          @orderBonusSelected="selectOrderBonus($event)"
          @configurationChanged="updateChosenValues($event)"
          class="mb-3"
        />
      </transition>
    </PageRow>
  </div>
</template>

<script lang="ts">
  import DisplayElementOrderBonusTile from '@/components/DisplayElements/DisplayElementOrderBonusTile.vue';
  import {
    computed,
    ComputedRef,
    defineComponent,
    Ref,
    ref,
    watch,
    PropType,
  } from 'vue';
  import DisplayElementOrderBonusDetailsPreview from '@/components/DisplayElements/DisplayElementOrderBonusDetailsPreview.vue';
  import {
    ComplexOrderBonusVariant,
    OrderBonus,
    SingleComplexOrderBonusProperty,
    SingleUserOrderBonus,
  } from '@/types/OrderBonusesTypes';
  import GtmService from '@/services/gtm-service';
  import commonTexts from '@/data/commonTexts';
  import {
    chosenConfigurationExistsForComplexOrderBonus,
    getVariantForChosenConfiguration,
    isComplexOrderBonus,
  } from '@/composables/order-bonuses';
  import { useStore } from 'vuex';
  import { useGrid } from 'vue-screen';
  import PageRow from '@/components/PageLayout/PageRow.vue';

  export default defineComponent({
    name: 'OrderBonusSelectionForm',
    components: {
      PageRow,
      DisplayElementOrderBonusDetailsPreview,
      DisplayElementOrderBonusTile,
    },
    props: {
      orderBonuses: {
        type: Array as PropType<SingleUserOrderBonus[]>,
        required: true,
      },
      selectedBonuses: {
        type: Array as PropType<SingleUserOrderBonus[]>,
        required: true,
      },
      cartIsFull: {
        type: Boolean,
        required: true,
      },
    },
    emits: [
      'orderBonusSelected',
      'resetConfiguration',
      'complexOrderBonusFullyConfigured',
    ],
    setup(props, { emit }) {
      const store = useStore();
      const grid = useGrid('bootstrap');
      const activeItem = ref('');
      const chosenValues: Ref<string[]> = ref([]);

      const itemsPerRow = computed(() => {
        if (grid.breakpoint === 'xs' || grid.breakpoint === 'sm') {
          return 2;
        }
        return 3;
      });

      const orderBonusIsComplex = function (orderBonus: OrderBonus) {
        return isComplexOrderBonus(orderBonus);
      };

      // Will re-arrange order-bonuses into chunks of n elements
      //  where n = number of elements per row
      const arrangedOrderBonuses = computed(() => {
        const result: SingleUserOrderBonus[][] = [];
        for (
          let currentOrderBonusesArrayIndex = 0;
          currentOrderBonusesArrayIndex < props.orderBonuses.length;
          currentOrderBonusesArrayIndex += itemsPerRow.value
        ) {
          const row = [];
          for (
            let currentOrderBonusesRowIndex = currentOrderBonusesArrayIndex;
            currentOrderBonusesRowIndex <
              currentOrderBonusesArrayIndex + itemsPerRow.value &&
            currentOrderBonusesRowIndex < props.orderBonuses.length;
            currentOrderBonusesRowIndex++
          ) {
            row.push(props.orderBonuses[currentOrderBonusesRowIndex]);
          }
          result.push(row);
        }
        return result;
      });

      const activeOrderBonus: ComputedRef<SingleUserOrderBonus | undefined> =
        computed(() => {
          return props.orderBonuses.find(
            (orderBonus: SingleUserOrderBonus) =>
              orderBonus.bonus.materialNumber === activeItem.value,
          );
        });

      const properties = computed(() => {
        if (
          activeOrderBonus.value &&
          orderBonusIsComplex(activeOrderBonus.value.bonus)
        ) {
          return activeOrderBonus.value.bonus.properties;
        } else {
          return [];
        }
      });

      const switchActiveItem = function (newActiveItem: string) {
        emit('resetConfiguration');
        if (newActiveItem === activeItem.value) {
          activeItem.value = '';
        } else {
          activeItem.value = newActiveItem;

          if (activeOrderBonus.value) {
            GtmService.pushOrderBonusEvent({
              category: 'Checkout',
              action: 'Zugabe Detailansicht',
              label: store.state.formData.salesCampaign.salesCampaignId,
              orderBonuses: [activeOrderBonus.value],
            });
          }
        }
      };

      const itemInCart = computed(() => {
        // For complex order bonuses it it necessary to check, if the selected bonus is
        //    a variant of the complex bonus.
        if (
          activeOrderBonus.value &&
          orderBonusIsComplex(activeOrderBonus.value.bonus)
        ) {
          return props.selectedBonuses.some(
            (singleSelectedBonus: SingleUserOrderBonus) => {
              return activeOrderBonus.value?.bonus.variants?.some(
                (variant: ComplexOrderBonusVariant) =>
                  variant.materialNumber ===
                  singleSelectedBonus.bonus.materialNumber,
              );
            },
          );
        } else {
          // Simple order bonuses:
          return props.selectedBonuses.some(
            (singleSelectedBonus: SingleUserOrderBonus) =>
              singleSelectedBonus.bonus.materialNumber === activeItem.value,
          );
        }
      });
      const activeItemRemovable = computed(() => {
        if (
          activeOrderBonus.value &&
          orderBonusIsComplex(activeOrderBonus.value.bonus)
        ) {
          return props.selectedBonuses.some(
            (singleSelectedBonus: SingleUserOrderBonus) =>
              activeOrderBonus.value?.bonus.variants?.some(
                (variant: ComplexOrderBonusVariant) =>
                  variant.materialNumber ===
                  singleSelectedBonus.bonus.materialNumber,
              ),
          );
        } else {
          return props.selectedBonuses.some(
            (singleSelectedBonus: SingleUserOrderBonus) =>
              singleSelectedBonus.bonus.materialNumber === activeItem.value &&
              !singleSelectedBonus.fixedBonus,
          );
        }
      });

      watch(activeItem, () => {
        if (
          activeOrderBonus.value &&
          orderBonusIsComplex(activeOrderBonus.value.bonus)
        ) {
          if (itemInCart.value) {
            // 1. Reset chosenValues
            chosenValues.value = [];
            // 2. Get VirtualOrderBonus from cart
            const selectedVirtualBonus = props.selectedBonuses.find(
              (selectedBonus: SingleUserOrderBonus) =>
                activeOrderBonus.value?.bonus?.variants?.some(
                  (variant: ComplexOrderBonusVariant) =>
                    selectedBonus.bonus.materialNumber ===
                    variant.materialNumber,
                ),
            );
            if (selectedVirtualBonus) {
              const chosenVariant =
                activeOrderBonus.value?.bonus?.variants?.find(
                  (variant: ComplexOrderBonusVariant) =>
                    variant.materialNumber ===
                    selectedVirtualBonus.bonus.materialNumber,
                );
              if (chosenVariant) {
                chosenValues.value = chosenVariant.propertyValues.map(
                  (propertyValue: SingleComplexOrderBonusProperty) =>
                    propertyValue.value,
                );
              }
            }
          } else {
            // If item is not in cart: Reset values on open
            chosenValues.value = [];
            if (activeOrderBonus.value?.bonus?.properties) {
              activeOrderBonus.value.bonus.properties.forEach(() => {
                chosenValues.value.push('');
              });
            }
          }
        }
      });

      const selectOrderBonus = function (materialNumber: string) {
        // For complex order bonuses: Emit chosen configuration as well:
        if (
          activeOrderBonus.value &&
          orderBonusIsComplex(activeOrderBonus.value.bonus)
        ) {
          emit('complexOrderBonusFullyConfigured', chosenValues.value);
        }

        activeItem.value = '';
        emit('orderBonusSelected', materialNumber);
      };

      const itemIsInCart = function (materialNumber: string) {
        return props.selectedBonuses.some(
          (singleSelectedBonus: SingleUserOrderBonus) => {
            // If complex order bonus: Check if materialNumber is in variants:
            if (isComplexOrderBonus(singleSelectedBonus.bonus)) {
              return singleSelectedBonus.bonus.variants?.some((variant) => {
                return variant.materialNumber === materialNumber;
              });
            } else {
              return (
                singleSelectedBonus.bonus.materialNumber === materialNumber
              );
            }
          },
        );
      };

      const showDetailView = function (rowNumberIndex: number) {
        // Get Position and row of current detail view element
        const rowNumber = rowNumberIndex + 1;

        // Get Position and row of bonus to be opened
        const indexOfOpenedItem = props.orderBonuses.findIndex(
          (orderBonus: SingleUserOrderBonus) =>
            orderBonus.bonus.materialNumber === activeItem.value,
        );
        const rowNumberOpenedItem =
          indexOfOpenedItem === -1
            ? -1
            : Math.ceil((indexOfOpenedItem + 1) / itemsPerRow.value);

        // Assert: Detail View Row is equal to row of opened item
        const openedItemInRowOfIndex =
          indexOfOpenedItem === -1 ? false : rowNumber === rowNumberOpenedItem;

        // Assert: Detail view should be open (an item was clicked)
        const detailViewIsActive = activeItem.value && activeOrderBonus.value;

        return detailViewIsActive && openedItemInRowOfIndex;
      };

      const detailViewButtonLabel = computed(() => {
        if (itemInCart.value && activeItemRemovable.value) {
          return commonTexts.userFrontend.orderBonusSelectionView
            .buttonLabelRemoveItem;
        } else if (props.cartIsFull) {
          return commonTexts.userFrontend.orderBonusSelectionView
            .buttonLabelCartFull;
        }

        return commonTexts.userFrontend.orderBonusSelectionView
          .buttonLabelEnabled;
      });

      const updateChosenValues = function (updatedChosenValues: string[]) {
        for (let i = 0; i < updatedChosenValues.length; i++) {
          chosenValues.value.splice(i, 1, updatedChosenValues[i]);
        }
      };

      const configuredItemExists = computed(() => {
        if (
          activeOrderBonus.value &&
          isComplexOrderBonus(activeOrderBonus.value.bonus)
        ) {
          return chosenConfigurationExistsForComplexOrderBonus(
            chosenValues.value,
            activeOrderBonus.value.bonus,
          );
        } else {
          return true;
        }
      });

      const priceForConfiguration: ComputedRef<number | undefined> = computed(
        () => {
          if (
            activeOrderBonus.value &&
            isComplexOrderBonus(activeOrderBonus.value.bonus) &&
            configuredItemExists.value
          ) {
            const configuredVariant = getVariantForChosenConfiguration(
              chosenValues.value,
              activeOrderBonus.value.bonus,
            );
            if (configuredVariant && configuredVariant.additionalCharges) {
              return configuredVariant.additionalCharges[0].value;
            }
          }
          return undefined;
        },
      );

      return {
        activeItem,
        switchActiveItem,
        activeOrderBonus,
        itemInCart,
        selectOrderBonus,
        itemIsInCart,
        showDetailView,
        itemsPerRow,
        arrangedOrderBonuses,
        detailViewButtonLabel,
        activeItemRemovable,
        orderBonusIsComplex,
        properties,
        chosenValues,
        updateChosenValues,
        configuredItemExists,
        priceForConfiguration,
      };
    },
  });
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "osc-post-office-box-form" }
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { class: "form-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementInputField = _resolveComponent("FormElementInputField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormElementInputField, {
        value: _ctx.addressData.postOfficeBox,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatePostOfficeBoxData(_ctx.formFields.BOX, $event))),
        label: _ctx.commonTexts.userInterface.formFieldLabels.postOfficeBox,
        "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels.postOfficeBox + _ctx.fieldIdSlug
        ,
        class: "col-md-12 mb-4",
        "field-has-error": _ctx.postOfficeBoxError,
        "field-value-corrected": 
          _ctx.addressCheckResult ? _ctx.addressCheckResult.postOfficeBoxCorrected : false
        ,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetFieldError('postOfficeBox'))),
        maxlength: "10"
      }, null, 8, ["value", "label", "field-id", "field-has-error", "field-value-corrected"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FormElementInputField, {
        label: _ctx.commonTexts.userInterface.formFieldLabels.zipCode,
        "field-id": 
          _ctx.commonTexts.userInterface.formFieldLabels.zipCode + _ctx.fieldIdSlug
        ,
        class: "col-md-3 mb-4",
        value: _ctx.addressData.postOfficeBoxZipCode,
        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatePostOfficeBoxData(_ctx.formFields.ZIP_CODE, $event))),
        "field-has-error": _ctx.fieldErrors.postOfficeBoxZipCode,
        "field-value-corrected": 
          _ctx.addressCheckResult
            ? _ctx.addressCheckResult.postOfficeBoxZipCodeCorrected
            : false
        ,
        onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetFieldError('postOfficeBoxZipCode'))),
        maxlength: "10"
      }, null, 8, ["label", "field-id", "value", "field-has-error", "field-value-corrected"]),
      _createVNode(_component_FormElementInputField, {
        label: _ctx.commonTexts.userInterface.formFieldLabels.city,
        "field-id": _ctx.commonTexts.userInterface.formFieldLabels.city + _ctx.fieldIdSlug,
        class: "col-md-9 mb-4",
        value: _ctx.addressData.postOfficeBoxCity,
        onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updatePostOfficeBoxData(_ctx.formFields.CITY, $event))),
        "field-has-error": _ctx.fieldErrors.postOfficeBoxCity,
        "field-value-corrected": 
          _ctx.addressCheckResult
            ? _ctx.addressCheckResult.postOfficeBoxCityCorrected
            : false
        ,
        onFocus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.resetFieldError('postOfficeBoxCity'))),
        maxlength: "35"
      }, null, 8, ["label", "field-id", "value", "field-has-error", "field-value-corrected"])
    ])
  ]))
}
<template>
  <FormRow additional-class="mx-0">
    <FormSection additional-class="mb-0">
      <p class="region-select-label-1">
        {{ commonTexts.userInterface.formFieldLabels.regionSelect.label }}
      </p>
      <div class="region-select">
        <FormElementSelectField
          :value="district"
          :selectOptions="options"
          :field-has-error="hasError"
          field-id="region"
          @input="$emit('input', $event)"
        />
      </div>
      <p class="region-select-label-note">
        {{ commonTexts.userInterface.formFieldLabels.regionSelect.note }}
      </p>
    </FormSection>
  </FormRow>
</template>

<script lang="ts">
  import { computed, defineComponent, Ref, ref, watch } from 'vue';
  import FormElementSelectField from '@/components/FormElements/FormElementSelectField.vue';
  import commonTexts from '@/data/commonTexts';
  import { SelectOption } from '@/models/OrderData';
  import { useStore } from 'vuex';
  import FormSection from '@/components/FormElements/FormSection.vue';
  import FormRow from '@/components/FormElements/FormRow.vue';

  export default defineComponent({
    name: 'RegionSelectForm',
    components: { FormRow, FormSection, FormElementSelectField },
    props: {
      value: {
        type: String,
        required: true,
      },
      hasError: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['input'],
    setup(props) {
      const store = useStore();
      const district: Ref<string> = ref(props.value);
      // const options = regionOptions;
      const options = computed(() => {
        if (
          store.getters.formData.regionSelectionOptions &&
          Object.keys(store.getters.formData.regionSelectionOptions).length > 0
        ) {
          const rawOptions = store.getters.formData.regionSelectionOptions as {
            [key: string]: string;
          };
          const result: SelectOption[] = [];

          for (const [key, value] of Object.entries(rawOptions)) {
            result.push({ value: key, text: value });
          }

          return result;
        }
        return [];
      });

      watch(props, () => {
        if (props.value !== district.value) {
          district.value = props.value;
        }
      });

      return { district, options, commonTexts };
    },
  });
</script>

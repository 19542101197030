import { MutationTree } from 'vuex';
import {
  AdminOrderBonusesMutationTypes,
  AdminOrderBonusesState,
} from '@/store/modules/adminOrderBonuses/types';
import {
  BasicOrderBonus,
  OrderBonus,
  SalesCampaignOrderBonus,
} from '@/types/OrderBonusesTypes';

const mutations: MutationTree<AdminOrderBonusesState> = {
  [AdminOrderBonusesMutationTypes.SET_BONUSES_COLLECTION](
    state: AdminOrderBonusesState,
    bonusesCollection: BasicOrderBonus[],
  ) {
    state.bonusesCollection = bonusesCollection;
  },
  [AdminOrderBonusesMutationTypes.SET_BONUS](
    state: AdminOrderBonusesState,
    bonus: OrderBonus | undefined,
  ) {
    const nonStringProperties = [
      'cashBonus',
      'additionalCharges',
      'infoPoints',
    ];

    if (bonus) {
      // Sanitize received orderBonus object to eliminate null values:
      for (const [key, value] of Object.entries(bonus)) {
        if (value === null && !nonStringProperties.includes(key)) {
          bonus[key] = '';
        }
      }
    }

    state.bonus = bonus;
  },
  [AdminOrderBonusesMutationTypes.SET_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION](
    state: AdminOrderBonusesState,
    availableBonuses: SalesCampaignOrderBonus[] | undefined,
  ) {
    state.availableSalesCampaignBonusesCollection = availableBonuses;
  },
};

export default mutations;

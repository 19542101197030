import axios from 'axios';

const authURL = process.env.VUE_APP_API_AUTH_BASEURL;
const orderURL = process.env.VUE_APP_API_ORDER_BASEURL;
const payURL = process.env.VUE_APP_API_PAY_BASEURL;
const internalURL = process.env.VUE_APP_API_INTERNAL_BASEURL;
const customizingURL = process.env.VUE_APP_API_CUSTOMIZING_BASEURL;

export const authAPI = axios.create({
  baseURL: authURL,
});
export const orderAPI = axios.create({
  baseURL: orderURL,
});

export const paymentAPI = axios.create({
  baseURL: payURL,
});

// TODO: Use customizingURL + /smwh/ in requests
export const internalAPI = axios.create({
  baseURL: internalURL,
});

export const customizingAPI = axios.create({
  baseURL: customizingURL,
});

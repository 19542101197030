<template>
  <OscModal
    modal-id="sub-title-modal"
    :show-modal="openModal"
    @modalClosed="$emit('modalClosed')"
    no-body-scroll
  >
    <template v-slot:body>
      <h2 class="main-content-title">
        {{ commonTexts.userFrontend.orderForm.subTitleModalHeadline }}
      </h2>
      <div v-html="subTitleCopy" />
      <BasicButton class="mt-0" @buttonClicked="handleChangeToOutLinkClick">
        {{ commonTexts.userFrontend.orderForm.subTitleChangeTenantButtonLabel }}
      </BasicButton>
      <BasicButton
        class="mb-0 mt-3"
        transparent
        @buttonClicked="$emit('modalClosed')"
      >
        {{ commonTexts.userFrontend.orderForm.subTitleCancelButtonLabel }}
      </BasicButton>
    </template>
  </OscModal>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import commonTexts from '@/data/commonTexts';
  import BasicButton from '@/components/Buttons/BasicButton.vue';
  import OscModal from '@/components/Modals/OscModal.vue';
  import GtmService from '@/services/gtm-service';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'SubTitleModal',
    components: { BasicButton, OscModal },
    props: {
      subTitleOutLink: {
        type: String,
        required: true,
      },
      subTitleCopy: {
        type: String,
        required: true,
      },
      openModal: {
        type: Boolean,
        required: true,
      },
    },
    setup(props) {
      const store = useStore();
      const handleChangeToOutLinkClick = () => {
        GtmService.pushGenericEvent({
          category: commonTexts.userFrontend.tracking.categories.checkout,
          action: commonTexts.userFrontend.tracking.subTitleChange.abort,
          label: store.state.formData.salesCampaign.salesCampaignId,
        });
        window.location.href = props.subTitleOutLink;
      };

      return { commonTexts, handleChangeToOutLinkClick };
    },
  });
</script>

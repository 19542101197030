import {
  BasicOrderBonus,
  OrderBonus,
  SalesCampaignOrderBonus,
} from '@/types/OrderBonusesTypes';

export type AdminOrderBonusesState = {
  bonusesCollection: BasicOrderBonus[];
  bonus: OrderBonus | undefined;
  availableSalesCampaignBonusesCollection:
    | SalesCampaignOrderBonus[]
    | undefined;
};

export enum AdminOrderBonusesGetterTypes {
  GET_BONUSES_COLLECTION = 'GET_BONUSES_COLLECTION',
  GET_BONUS = 'GET_BONUS',
  GET_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION = 'GET_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION',
}

export enum AdminOrderBonusesMutationTypes {
  SET_BONUSES_COLLECTION = 'SET_BONUSES_COLLECTION',
  SET_BONUS = 'SET_BONUS',
  SET_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION = 'SET_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION',
}

export enum AdminOrderBonusesActionTypes {
  LOAD_BONUSES_COLLECTION = 'LOAD_BONUSES_COLLECTION',
  LOAD_BONUS = 'LOAD_BONUS',
  UPDATE_BONUS = 'UPDATE_BONUS',
  LOAD_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION = 'LOAD_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION',
  RESET_BONUS = 'RESET_BONUS',
  RESET_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION = 'RESET_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION',
  UPLOAD_ORDER_BONUS_IMAGE = 'UPLOAD_ORDER_BONUS_IMAGE',
  CREATE_COMPLEX_ORDER_BONUS = 'CREATE_COMPLEX_ORDER_BONUS',
}

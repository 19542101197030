// import {
//   ComplexOrderBonusProperty,
//   ComplexOrderBonusVariant,
//   SingleComplexOrderBonusProperty,
// } from '@/types/OrderBonusesTypes';
import {
  CountryCode,
  getCountryCallingCode,
  isPossibleNumber,
  isSupportedCountry,
  isValidPhoneNumber,
  parsePhoneNumber,
} from 'libphonenumber-js';
import { DateTime } from 'luxon';

// export function isRequiredInputFilled(
//   inputValue: string,
//   requiredLength: number,
// ) {
//   return inputValue && inputValue.length >= requiredLength;
// }

export function isInputFilled(inputValue: string) {
  return inputValue && inputValue.length >= 1;
}

export function removeWhiteSpacesInString(input: string): string {
  return input.replace(new RegExp(/\s/, 'g'), '');
}

export function splitPhoneNumberInParts(phoneNumberString: string): {
  areaCode: string;
  subscriberNumber: string;
} {
  const phoneNumber = {
    areaCode: '',
    subscriberNumber: '',
  };

  if (phoneNumberString) {
    // The regex will declare the first dash as the separator
    //    To achieve this, everything after the first dash is included in capturing parentheses
    //    to get only the first occurrence and split between area code and subscriber number
    //    Caveat: This will lead to an array of three strings, if separator is found, with the last being empty
    const splitNumber = phoneNumberString.trim().split(/-(.+)/);
    if (splitNumber.length === 1) {
      phoneNumber.subscriberNumber = splitNumber[0];
    } else if (splitNumber.length === 3) {
      phoneNumber.areaCode = splitNumber[0];
      phoneNumber.subscriberNumber = splitNumber[1];
    }
  }

  return phoneNumber;
}

export function parseAlphaCountryCodeToPhoneCountryCode(
  alphaCode: string,
): string | undefined {
  if (isSupportedCountry(alphaCode)) {
    return getCountryCallingCode(alphaCode as CountryCode).toString();
  } else {
    return undefined;
  }
}

export function checkPhoneNumber(
  areaCode: string,
  subscriberNumber: string,
  countryCode: string,
): boolean {
  const parsedPhoneNumber = areaCode + subscriberNumber;
  return (
    isPossibleNumber(parsedPhoneNumber, countryCode as CountryCode) &&
    isValidPhoneNumber(parsedPhoneNumber, countryCode as CountryCode)
  );
}

export function addNationalTrunkPrefixToAreaCode(
  areaCode: string,
  subscriberNumber: string,
  countryCode: string,
): string {
  let result = areaCode;
  // Proceed only, if country is supported!
  if (isSupportedCountry(countryCode)) {
    // 1. Parse area code + subscriber number to PhoneNumber
    let parsedPhoneNumber = parsePhoneNumber(
      areaCode + subscriberNumber,
      countryCode as CountryCode,
      // 2. Convert to national format WITH national trunk prefix
    ).format('NATIONAL', { nationalPrefix: true });
    // 2a. Remove unnecessary spaces and chars
    parsedPhoneNumber = removeWhiteSpacesInString(parsedPhoneNumber);
    parsedPhoneNumber = parsedPhoneNumber.replace(new RegExp(/[)(]/, 'g'), '');
    // 3. Find position of area code in parsed and formatted phone number
    const positionOfAreaCodeAfterParsing = parsedPhoneNumber.indexOf(areaCode);
    // 4. If parsed phone number does not start with area code, extract national trunk prefix and prepend to return value
    if (0 < positionOfAreaCodeAfterParsing) {
      const nationalTrunkPrefix = parsedPhoneNumber.substring(
        0,
        positionOfAreaCodeAfterParsing,
      );
      result = nationalTrunkPrefix + result;
    }
  }
  return result;
}

// export function getAvailableSelectCombinations(
//   existingFieldValues: ComplexOrderBonusProperty[],
//   chosenValues: SingleComplexOrderBonusProperty[],
//   existingVariants: ComplexOrderBonusVariant[],
// ): ComplexOrderBonusProperty[] {
//   let availableFieldValues: ComplexOrderBonusProperty[] = [];
//   // Clone all existing variants into array of available variants
//   let availableVariants: ComplexOrderBonusVariant[] = [];
//
//   // 1. Filter existingVariants according to chosenValues (if chosenValues is empty, all existing Variants are used)
//   for (
//     let chosenValueIndex = 0;
//     chosenValueIndex < chosenValues.length;
//     chosenValueIndex++
//   ) {
//     availableVariants = existingVariants.filter(
//       (existingVariant: ComplexOrderBonusVariant) => {
//         // return all existing variants with chosenValue as propertyValue
//         existingVariant.propertyValues.some(
//           (propertyValue: SingleComplexOrderBonusProperty) =>
//             propertyValue.name === chosenValues[chosenValueIndex].name &&
//             propertyValue.value === chosenValues[chosenValueIndex].value,
//         );
//       },
//     );
//   }
//
//   // Check, if chosen values fit any variant; if not: reset fields
//   if (availableVariants.length === 0) {
//     // TODO: Evaluate, if necessary
//     chosenValues = [];
//     availableFieldValues = [...existingFieldValues];
//   } else {
//     // 2. Build available Values Matrix from filtered existingVariants
//     //    (only, if chose values are incomplete)
//     if (chosenValues.length < existingVariants[0].propertyValues.length) {
//       for (const availableVariant of availableVariants) {
//         // if every chosen value is available for this variant (name and value must be identical)
//         if (
//           chosenValues.every(chosenValue =>
//             availableVariant.propertyValues.some(
//               propertyValue =>
//                 chosenValue.name === propertyValue.name &&
//                 chosenValue.value === propertyValue.value,
//             ),
//           )
//         ) {
//           for (const propertyValue of availableVariant.propertyValues) {
//             // Only property values, that are NOT already selected:
//             if (
//               !chosenValues.some(
//                 chosenValue => chosenValue.name === propertyValue.name,
//               )
//             ) {
//               // add to availableFieldValues:
//               // if name not yet defined, add complete entry:
//               if (
//                 !availableFieldValues.some(
//                   availableFieldValue =>
//                     availableFieldValue.name === propertyValue.name,
//                 )
//               ) {
//                 availableFieldValues.push({
//                   name: propertyValue.name,
//                   values: [propertyValue.value],
//                 });
//               } else {
//                 // otherwise add value to name:
//                 availableFieldValues
//                   .find(
//                     availableFieldValue =>
//                       availableFieldValue.name === propertyValue.name,
//                   )
//                   ?.values?.push(propertyValue.value);
//               }
//             }
//           }
//         }
//       }
//     }
//     // 3. Make sure, that for chosen values all values are available to be able to change back
//     for (const chosenValue of chosenValues) {
//       const fieldValue = existingFieldValues.find(
//         existingFieldValue => existingFieldValue.name === chosenValue.name,
//       );
//       if (fieldValue) {
//         availableFieldValues.push(fieldValue);
//       }
//     }
//   }
//
//   return availableFieldValues;
// }

export function parseDateStringFromForm(input: string) {
  if (input === '') {
    return null;
  }
  const datePattern = /\d{1,2}\.\d{1,2}\.\d{4}/;
  if (datePattern.test(input)) {
    const inputDateTime = DateTime.fromFormat(input, 'd.M.yy');
    if (inputDateTime.diffNow().milliseconds > 0) {
      return inputDateTime.toFormat('yyyy-MM-dd');
    }
  }
  return '';
}

export function parseIsoDateToLocalizedDate(date: string | null) {
  if (date === null) {
    return '';
  }

  return DateTime.fromISO(date).setLocale('de').toLocaleString({
    year: 'numeric',
    day: '2-digit',
    month: '2-digit',
  });
}

export function convertLocalizedDateStringToDateTime(date: string) {
  return DateTime.fromFormat(date, 'd.M.yy');
}

// export function removeWhiteSpace(string: string, index?: string) {
//   return string.replace(/\s/g, '') + index;
// }

export type ConfigState = {
  config: AppConfiguration;
};

export type AppConfiguration = {
  urls: {
    loginUrl: string;
    logoutUrl: string;
    registerUrl: string;
  };
};

export enum ConfigGetterTypes {
  GET_CONFIG = 'GET_CONFIG',
}

export enum ConfigMutationTypes {
  SET_CONFIG = 'SET_CONFIG',
}

export enum ConfigActionTypes {
  LOAD_CONFIG = 'LOAD_CONFIG',
}

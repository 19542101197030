import {
  AuthConnectorInterface,
  CustomizingConnectorInterface,
  DataStoreConnectorInterface,
  ImageUploadInterface,
  InternalAdminConnectorInterface,
  OrderConnectorInterface,
  PaymentConnectorInterface,
} from '@/services/connectors/interfaces';
import CustomizingConnector from '@/services/connectors/customizing-connector';
import { ImageUploadConnectorName } from '@/services/connectors/types';
import PaymentConnector from '@/services/connectors/payment-connector';
import { StudentEnrollmentUploadConnector } from '@/services/connectors/student-enrollment-upload-connector';
import OrderConnectorStub from '@/services/connectors/order-connector-stub';
import OrderConnector from '@/services/connectors/order-connector';
import CustomizingConnectorStub from '@/services/connectors/customizing-connector-stub';
import AuthConnectorStub from '@/services/connectors/auth-connector-stub';
import AuthConnector from '@/services/connectors/auth-connector';
import { PaymentConnectorStub } from '@/services/connectors/payment-connector-stub';
import { InternalAdminConnectorStub } from '@/services/connectors/internal-admin-connector-stub';
import { InternalAdminConnector } from '@/services/connectors/internal-admin-connector';
import { StudentEnrollmentUploadConnectorStub } from '@/services/connectors/student-enrollment-upload-connector-stub';
import { DataStoreConnector } from '@/services/connectors/data-store-connector';
import { DataStoreConnectorStub } from '@/services/connectors/data-store-connector-stub';

export class ConnectorFactory {
  // Used as a fallback if NODE_ENV is not set
  private static mode = 'development'; // 'production' | 'development'
  private static getMode = function (): string {
    return process.env.NODE_ENV ?? ConnectorFactory.mode;
  };
  static getCustomizingConnector = function (): CustomizingConnectorInterface {
    if (ConnectorFactory.getMode() === 'development') {
      return new CustomizingConnectorStub();
    } else {
      return new CustomizingConnector();
    }
  };

  static getImageUploadConnector = function (
    connectorType: ImageUploadConnectorName,
  ): ImageUploadInterface {
    const useStub = ConnectorFactory.getMode() === 'development';

    switch (connectorType) {
      case ImageUploadConnectorName.CUSTOMIZING:
        // StudentEnrollmentUploadConnectorStub is fine for the stubbed responses!
        return useStub
          ? new StudentEnrollmentUploadConnectorStub()
          : new CustomizingConnector();
      case ImageUploadConnectorName.STUDENT_ENROLLMENT:
        return useStub
          ? new StudentEnrollmentUploadConnectorStub()
          : new StudentEnrollmentUploadConnector();
    }
  };

  static getPaymentConnector = function (): PaymentConnectorInterface {
    if (ConnectorFactory.getMode() === 'development') {
      return new PaymentConnectorStub();
    } else {
      return new PaymentConnector();
    }
  };

  static getOrderConnector = function (): OrderConnectorInterface {
    if (ConnectorFactory.getMode() === 'development') {
      return new OrderConnectorStub();
    } else {
      return new OrderConnector();
    }
  };

  static getAuthConnector = function (): AuthConnectorInterface {
    if (ConnectorFactory.getMode() === 'development') {
      return new AuthConnectorStub();
    } else {
      return new AuthConnector();
    }
  };

  static getInternalAdminConnector =
    function (): InternalAdminConnectorInterface {
      if (ConnectorFactory.getMode() === 'development') {
        return new InternalAdminConnectorStub();
      } else {
        return new InternalAdminConnector();
      }
    };

  static getDataStoreConnector = function (): DataStoreConnectorInterface {
    if (ConnectorFactory.getMode() === 'development') {
      return new DataStoreConnectorStub();
    } else {
      return new DataStoreConnector();
    }
  };
}

import {
  BasicOrderBonus,
  OrderBonus,
  SalesCampaignOrderBonus,
} from '@/types/OrderBonusesTypes';
import { ImageUploadResponse } from '@/types/ImageUploadTypes';
import { ConnectorFactory } from '@/services/connectors/connectorFactory';
import { ImageUploadConnectorName } from '@/services/connectors/types';
import { AxiosResponse } from 'axios';

export default class AdminOrderBonusesService {
  public static loadAdminOrderBonusesCollection(
    getActiveBonusesOnly: boolean,
  ): Promise<BasicOrderBonus[]> {
    const customizingConnector = ConnectorFactory.getCustomizingConnector();
    return customizingConnector.getOrderBonuses(getActiveBonusesOnly);
  }
  public static loadAdminOrderBonus(
    materialNumber: string,
  ): Promise<OrderBonus> {
    const customizingConnector = ConnectorFactory.getCustomizingConnector();
    return customizingConnector.getOrderBonus(materialNumber);
  }
  public static loadAvailableOrderBonusesForSalesCampaign(
    salesCampaignId: string,
  ): Promise<SalesCampaignOrderBonus[]> {
    const customizingConnector = ConnectorFactory.getCustomizingConnector();
    return customizingConnector.getAvailableOrderBonusesForSalesCampaign(
      salesCampaignId,
    );
  }
  public static updateOrderBonus(
    materialNumber: string,
    orderBonus: OrderBonus,
  ): Promise<Response> {
    const customizingConnector = ConnectorFactory.getCustomizingConnector();
    return customizingConnector.updateOrderBonus(materialNumber, orderBonus);
  }
  public static uploadOrderBonusImage(
    materialNumber: string,
    file: File,
  ): Promise<ImageUploadResponse> {
    const imageUploadConnector = ConnectorFactory.getImageUploadConnector(
      ImageUploadConnectorName.CUSTOMIZING,
    );
    return imageUploadConnector.uploadImage(file, { id: materialNumber });
  }
  public static createComplexOrderBonus(
    materialNumber: string,
    orderBonus: OrderBonus,
  ): Promise<AxiosResponse> {
    const customizingConnector = ConnectorFactory.getCustomizingConnector();
    return customizingConnector.createComplexOrderBonus(
      materialNumber,
      orderBonus,
    );
  }

  public static deleteComplexOrderBonus(materialNumber: string) {
    const customizingConnector = ConnectorFactory.getCustomizingConnector();
    return customizingConnector.deleteComplexOrderBonus(materialNumber);
  }
}

import {
  ConfigState,
  ConfigMutationTypes,
  AppConfiguration,
} from '@/store/modules/config/types';
import { MutationTree } from 'vuex';

const mutations: MutationTree<ConfigState> = {
  [ConfigMutationTypes.SET_CONFIG](
    state: ConfigState,
    config: AppConfiguration,
  ) {
    state.config = config;
  },
};

export default mutations;

import store from '@/store';
import router from '@/router';
import {
  alreadyRedirectedToLoginBeforeOffset,
  removeAlreadyRedirectedToLoginFlag,
  setAlreadyRedirectedToLoginFlag,
} from '@/composables/page-functions';
import { ConnectorFactory } from '@/services/connectors/connectorFactory';
import { UserConnectRequestData } from '@/types/AuthTypes';

export default class AuthService {
  public static async checkLogin(
    redirectPath: string,
    loginRequired: boolean,
    useScreenParameter?: boolean,
  ) {
    const authConnector = ConnectorFactory.getAuthConnector();
    const screen = useScreenParameter
      ? store.getters.parameters.authScreen
      : undefined;
    return new Promise<void>((resolve, reject) => {
      authConnector
        .getLoginState(redirectPath, loginRequired, screen)
        // authAPI
        //   .get('isLoggedIn/' + redirectPath + '?loginRequired=' + loginRequired)
        .then((data) => {
          // const data = response.data;
          const alreadyRedirectedToLogin =
            !alreadyRedirectedToLoginBeforeOffset();
          // Check, if according to Auth Server user is logged in
          if (!data.loggedIn) {
            // If user is not logged in, check, if a login is required (according to argument)
            if (!loginRequired) {
              // If user is not logged in, and login is NOT required, check, if already redirected to Auth Server
              if (!alreadyRedirectedToLogin) {
                // If not already redirected, set SessionStorage Flag alreadyRedirectedToLogin true
                //    to prevent endless-loop between App and Auth Server redirects, …
                setAlreadyRedirectedToLoginFlag();
                // … and move to redirect URL from isLoggedIn response:
                window.location.href = data.serviceTicketUrl;
              } else {
                // If already redirected (but not logged in, login NOT required): Set isLoggedInState in Store
                store.commit('changeIsLoggedIn', false);
                // Reset of SessionStorageFlag prevented not-logged-in users returning from external pages (payment)
                //    from successfully retrieving their application state => out-commented
                // TODO: Refactor checkLogin Process fundamentally
                // window.sessionStorage.setItem('alreadyRedirectedToLogin', 'false');
              }
            } else {
              // If not logged in, but Login is required: Move to redirect URL from isLoggedIn response:
              window.location.href = data.serviceTicketUrl;
            }
          } else {
            // If logged in: Set isLoggedIn flag in Store …
            store.commit('changeIsLoggedIn', true);
            // … and reset alreadyRedirectedToLogin to false for future users using the same browser tab without login
            removeAlreadyRedirectedToLoginFlag();
          }
          store.commit('changeLoginChecked', true);
          resolve();
        })
        .catch((error) => {
          console.log(error.response.data);
          router.push({
            name: 'ErrorPage',
            params: { type: 'server' },
          });
          reject();
        });
    });
  }

  public static connectGpNrToUser(connectUserObj: UserConnectRequestData) {
    const authConnector = ConnectorFactory.getAuthConnector();
    return authConnector.connectUser(connectUserObj);
    // return authAPI
    //   .post('gp-service/v1/connectUser', JSON.stringify(connectUserObj), {
    //     headers: { 'Content-Type': 'application/json' },
    //   })
    //   .then(response => {
    //     return response.data;
    //   })
    //   .catch(error => {
    //     console.log(error.response.data);
    //   });
  }

  public static resetAlreadyRedirectedToLoginFlag() {
    removeAlreadyRedirectedToLoginFlag();
  }
}

<template>
  <div class="osc-personal-data-email-phone-form">
    <div class="osc-personal-data-email-phone-form__text">
      {{ commonTexts.userInterface.formFieldLabels.emailPhoneFormLabel }}
    </div>
    <div class="form-row">
      <FormElementInputField
        :value="value.email"
        @input="updatePersonalData(formFields.EMAIL, $event)"
        :label="commonTexts.userInterface.formFieldLabels.email"
        :field-has-error="fieldErrors.email"
        @focus="resetFieldError(formFields.EMAIL)"
        class="col-md-12"
      />
    </div>
    <div class="form-row">
      <FormElementPhoneNumber
        :value="value.phone"
        @input="updatePersonalData(formFields.PHONE, $event)"
        :label="commonTexts.userInterface.formFieldLabels.phone"
        field-id-slug="main"
        :field-has-error="fieldErrors.phoneNumber"
        @focus.native="resetFieldError(formFields.PHONE)"
        class="col-md-12"
        maxlength="30"
        :country="selectedCountry"
      />
    </div>
    <div class="form-row" v-if="value.mobilePhone">
      <FormElementPhoneNumber
        :value="value.mobilePhone"
        @input="updatePersonalData(formFields.MOBILE_PHONE, $event)"
        :label="commonTexts.userInterface.formFieldLabels.mobilePhone"
        field-id-slug="mobile"
        :field-has-error="fieldErrors.mobilePhone"
        @focus.native="resetFieldError(formFields.MOBILE_PHONE)"
        class="col-md-12"
        maxlength="30"
        :country="selectedCountry"
      />
    </div>
    <div class="form-row" v-if="value.businessPhone">
      <FormElementPhoneNumber
        :value="value.businessPhone"
        @input="updatePersonalData(formFields.BUSINESS_PHONE, $event)"
        :label="commonTexts.userInterface.formFieldLabels.businessPhone"
        field-id-slug="business"
        :field-has-error="fieldErrors.businessPhone"
        @focus.native="resetFieldError(formFields.BUSINESS_PHONE)"
        class="col-md-12"
        maxlength="30"
        :country="selectedCountry"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import FormElementInputField from '@/components/FormElements/FormElementInputField.vue';
  import {
    FieldErrorsAddressData,
    ViolationFields,
  } from '@/validators/validatorTypes';
  import { computed, defineComponent, PropType } from 'vue';
  import FormElementPhoneNumber from '@/components/FormElements/FormElementPhoneNumber.vue';
  import commonTexts from '@/data/commonTexts';
  import { OrderFormData } from '@/types/OrderFormTypes';
  import { FormBusinessAddress } from '@/models/OrderData';

  export default defineComponent({
    name: 'PersonalDataEmailPhoneForm',
    components: {
      FormElementPhoneNumber,
      FormElementInputField,
    },
    emits: ['personalDataInput', 'resetFieldError'],
    props: {
      value: {
        type: Object as PropType<OrderFormData | FormBusinessAddress>,
        required: true,
      },
      fieldErrors: {
        type: Object as PropType<FieldErrorsAddressData>,
        required: true,
      },
      selectedCountry: {
        type: String,
        required: true,
      },
    },
    setup(props, { emit }) {
      enum formFields {
        EMAIL = 'email',
        PHONE = 'phoneNumber',
        BUSINESS_PHONE = 'businessPhone',
        MOBILE_PHONE = 'mobilePhone',
      }

      const showBusinessPhoneField = computed(() => {
        return props.value.businessPhone && props.value.businessPhone !== '';
      });

      const showMobilePhoneField = computed(() => {
        return props.value.mobilePhone && props.value.mobilePhone !== '';
      });

      const updatePersonalData = function (
        field: formFields,
        newValue: string,
      ) {
        const data = Object.assign({}, props.value);
        switch (field) {
          case formFields.EMAIL:
            data.email = newValue;
            break;
          case formFields.PHONE:
            data.phone = newValue;
            break;
          case formFields.BUSINESS_PHONE:
            data.businessPhone = newValue;
            break;
          case formFields.MOBILE_PHONE:
            data.mobilePhone = newValue;
            break;
        }

        emit('personalDataInput', data);
      };

      const resetFieldError = function (field: formFields) {
        switch (field) {
          case formFields.EMAIL:
            emit('resetFieldError', ViolationFields.EMAIL);
            break;
          case formFields.PHONE:
            emit('resetFieldError', ViolationFields.PHONE_NUMBER);
            break;
          case formFields.BUSINESS_PHONE:
            emit('resetFieldError', ViolationFields.BUSINESS_PHONE);
            break;
          case formFields.MOBILE_PHONE:
            emit('resetFieldError', ViolationFields.MOBILE_PHONE);
            break;
        }
      };

      return {
        resetFieldError,
        updatePersonalData,
        formFields,
        showBusinessPhoneField,
        showMobilePhoneField,
        commonTexts,
      };
    },
  });
</script>

export type LoginStateResponseData = {
  loggedIn: boolean;
  serviceTicketUrl: string;
};

export type UserConnectRequestData = {
  gpNr: string;
  name: string;
};

export enum GpConnectionResult {
  HAS_SUID = 'HAS_SUID',
  WRONG_GP_NUMBER = 'WRONG_GP_NUMBER',
  NO_GP_NUMBER = 'NO_GP_NUMBER',
  GP_LOCKED = 'GP_LOCKED',
  SUCCESS = 'SUCCESS',
  SUCCESS_CSV = 'SUCCESS_CSV',
  FAILURE = 'FAILURE',
}

export type AuthScreen = 'registration' | 'default';

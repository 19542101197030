<template>
  <div class="price-list">
    <table>
      <tbody
        v-if="showPredefinedPrices && showPriceList"
        class="price-list__price-infos"
      >
        <tr v-for="(price, index) in salesCampaign.priceInfos" :key="index">
          <td>{{ price.description }}</td>
          <td class="price">{{ price.price }}</td>
        </tr>
      </tbody>
      <tbody
        v-else-if="
          showCalculatedPrices && monthlyPriceGreaterThanZero && showPriceList
        "
        class="price-list__price-infos"
      >
        <tr>
          <td>{{ monthlyPriceLabel }}</td>
          <td class="price">{{ monthlyPrice }}</td>
        </tr>
      </tbody>
      <tbody
        v-if="totalPrice !== '' && showCalculatedPrices && showPriceList"
        class="price-list__total-price"
      >
        <tr>
          <td class="price-list__total-price-label">
            {{ commonTexts.userFrontend.priceList.totalLabel }}
          </td>
          <td>
            {{ totalPrice }}
          </td>
        </tr>
      </tbody>
      <tbody v-if="displayOrderBonusCart" class="price-list__order-bonuses">
        <tr
          v-for="selectedBonus in bonusCart"
          :key="selectedBonus.bonus.materialNumber"
          class="price-list__order-bonuses-row"
        >
          <td class="price-list__order-bonuses-cell">
            {{ selectedBonus.bonus.title }}
          </td>
          <td class="price-list__order-bonuses-cell">
            {{ renderPrice(selectedBonus.bonus.additionalCharges[0].value) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="productIsWithCosts && valueAddedTaxText"
      class="price-list-caption"
    >
      <span v-html="valueAddedTaxText"></span>
    </div>
  </div>
</template>

<script lang="ts">
  import PrintProductTypeLiteral from '@/utils/printProductTypeLiteral';
  import { ValueAddedTaxTexts } from '@/utils/valueAddedTaxTexts';
  import { computed, defineComponent, PropType } from 'vue';
  import commonTexts from '@/data/commonTexts';
  import { parseNumberToCurrency } from '@/composables/general-functions';
  import commonConfig from '@/data/commonConfig';
  import {
    getPriceListFlags,
    getUserLocationFlags,
  } from '@/composables/general-flags';
  import { useStore } from 'vuex';
  import { FormDataSalesCampaign } from '@/models/OrderData';

  export default defineComponent({
    name: 'PriceList',
    props: {
      salesCampaign: {
        type: Object as PropType<FormDataSalesCampaign>,
        required: true,
      },
      bonusCart: {
        type: Array,
        required: false,
        default: () => {
          return [];
        },
      },
      displayOrderBonusCart: {
        type: Boolean,
        required: false,
        default: false,
      },
      showTotal: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      const store = useStore();
      const productIsWithCosts = computed(
        () => store.state.formData.salesCampaign.withCosts,
      );

      const valueAddedTaxText = computed(() => {
        let valueAddedTaxText;

        // const noResultAbroad = context.root.$store.getters.noResultAbroad;
        const { userIsAbroad } = getUserLocationFlags();

        switch (store.state.formData.salesCampaign.printProductType) {
          case PrintProductTypeLiteral.MAGAZINE:
            valueAddedTaxText = ValueAddedTaxTexts.magazine.basicText;
            break;
          case PrintProductTypeLiteral.PRINT:
            valueAddedTaxText = userIsAbroad.value
              ? ValueAddedTaxTexts.print.userAbroad
              : ValueAddedTaxTexts.print.basicText;
            break;
          default:
            valueAddedTaxText = ValueAddedTaxTexts.digital.basicText;
        }

        return valueAddedTaxText;
      });

      const renderPrice = parseNumberToCurrency;

      const totalPrice = computed(() => {
        const priceResult = store.state.priceResult;
        if (priceResult.annualPrice) {
          return parseNumberToCurrency(priceResult.annualPrice);
        }
        return '';
      });

      const monthlyPrice = computed(() => {
        const priceResult = store.state.priceResult;
        if (priceResult.priceGui) {
          return priceResult.priceGui + commonConfig.defaultCurrencyString;
        }
        return '';
      });

      const monthlyPriceGreaterThanZero = computed(() => {
        const priceResult = store.state.priceResult;
        if (priceResult.price) {
          return priceResult.price > 0;
        }
        return false;
      });

      const showCalculatedPrices = computed(() => {
        const priceResult = store.state.priceResult;
        if (priceResult) {
          return priceResult.annualPrice !== 0 || priceResult.price !== 0;
        }
        return false;
      });

      const showPredefinedPrices = computed(() => {
        const priceResult = store.state.priceResult;
        if (priceResult) {
          return priceResult.annualPrice === 0 && priceResult.price === 0;
        }
        return true;
      });

      const monthlyPriceLabel = computed(() => {
        if (props.salesCampaign.specialOffer) {
          return commonTexts.userFrontend.priceList.monthlyLabelFallback;
        } else if (
          props.salesCampaign.printProductType ===
          PrintProductTypeLiteral.MAGAZINE
        ) {
          return commonTexts.userFrontend.priceList.monthlyLabelMagazine;
        } else {
          return commonTexts.userFrontend.priceList.monthlyLabel;
        }
      });

      const { showPriceList } = getPriceListFlags();

      return {
        productIsWithCosts,
        valueAddedTaxText,
        renderPrice,
        commonTexts,
        totalPrice,
        monthlyPrice,
        showCalculatedPrices,
        showPredefinedPrices,
        monthlyPriceGreaterThanZero,
        monthlyPriceLabel,
        showPriceList,
      };
    },
  });
</script>

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "order-collapse",
    class: _normalizeClass({
      'collapsable-box': true,
      'collapsable-box--open': _ctx.open,
    })
  }, [
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ], 2))
}
<template>
  <div class="display-element-order-bonus-cart__content">
    <div class="display-element-order-bonus-cart__image">
      <IconImagePlaceholder v-if="!orderBonus.imagePath" />
      <img v-else :src="orderBonus.imagePath" alt />
    </div>
    <div class="display-element-order-bonus-cart__bonus-title">
      {{ orderBonus.title }}
    </div>
    <div class="display-element-order-bonus-cart__price">
      <span>{{ price }}</span>
      <div v-if="!nonRemovableBonus" @click="$emit('removeItem')">
        <IconTrashCan class="display-element-order-bonus-cart__remove-icon" />
      </div>
      <IconTrashCan
        v-else
        :visible="false"
        class="display-element-order-bonus-cart__remove-icon"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import IconImagePlaceholder from '@/components/Icons/IconImagePlaceholder.vue';
  import IconTrashCan from '@/components/Icons/IconTrashCan.vue';
  import { computed, defineComponent, PropType } from 'vue';
  import { parseNumberToCurrency } from '@/composables/general-functions';
  import {
    InternalComplexOrderBonus,
    OrderBonus,
  } from '@/types/OrderBonusesTypes';

  export default defineComponent({
    name: 'DisplayElementOrderBonusCartContent',
    components: { IconImagePlaceholder, IconTrashCan },
    props: {
      orderBonus: {
        type: Object as PropType<InternalComplexOrderBonus | OrderBonus>,
        required: true,
      },
      nonRemovableBonus: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      const price = computed(() => {
        if (
          props.orderBonus.additionalCharges &&
          props.orderBonus.additionalCharges[0]
        ) {
          return parseNumberToCurrency(
            props.orderBonus.additionalCharges[0].value,
          );
        } else {
          return '';
        }
      });

      return {
        price,
      };
    },
  });
</script>

import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["href"]
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isLink && _ctx.href)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        href: _ctx.href
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 8, _hoisted_1))
    : (_ctx.href)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: _ctx.href,
          class: "basic-button__container"
        }, [
          _createElementVNode("button", {
            type: "button",
            disabled: _ctx.disabled,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('buttonClicked'))),
            class: _normalizeClass(["basic-button", [
        {
          'basic-button--primary': _ctx.variant === 'primary',
          'basic-button--none': _ctx.variant === 'none',
          'basic-button--link': _ctx.variant === 'link',
          'basic-button--transparent': _ctx.transparent,
          'basic-button--sm': _ctx.size === 'sm',
          'basic-button--disabled': _ctx.disabled,
          'basic-button--no-arrow': _ctx.noArrow,
        },
        _ctx.additionalButtonClasses,
      ]])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 10, _hoisted_3)
        ], 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("button", {
          key: 2,
          type: "button",
          disabled: _ctx.disabled,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('buttonClicked'))),
          class: _normalizeClass(["basic-button", [
      {
        'basic-button--primary': _ctx.variant === 'primary',
        'basic-button--none': _ctx.variant === 'none',
        'basic-button--link': _ctx.variant === 'link',
        'basic-button--transparent': _ctx.transparent,
        'basic-button--sm': _ctx.size === 'sm',
        'basic-button--disabled': _ctx.disabled,
        'basic-button--no-arrow': _ctx.noArrow,
      },
      _ctx.additionalButtonClasses,
    ]])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 10, _hoisted_4))
}
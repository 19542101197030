<template>
  <div class="gift-subscription-limit-selection-form">
    <span class="mb-4 d-block">
      {{
        commonTexts.userFrontend.orderRecruiterView
          .giftSubscriptionLimitSubHeader
      }}
    </span>
    <FormElementRadioButtons
      :value="value"
      :radio-options="options"
      @input="changeSelection($event)"
      stacked
    />
  </div>
</template>

<script lang="ts">
  import commonTexts from '@/data/commonTexts';
  import FormElementRadioButtons from '@/components/FormElements/FormElementRadioButtons.vue';
  import { giftSubscriptionLimitOptions } from '@/utils/selectOptions';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'GiftSubscriptionLimitSelectionForm',
    components: { FormElementRadioButtons },
    props: {
      value: {
        type: Number,
        required: true,
      },
    },
    emits: ['changeRecruiterGiftSubscriptionLimit'],
    setup(_, { emit }) {
      const options = giftSubscriptionLimitOptions;
      const changeSelection = function (newValue: number) {
        emit('changeRecruiterGiftSubscriptionLimit', newValue);
      };
      return { options, commonTexts, changeSelection };
    },
  });
</script>

import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer" }
const _hoisted_2 = { class: "container" }
const _hoisted_3 = { class: "links" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "copyright" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: _ctx.content.legal.imprintSrc,
            target: "_blank",
            rel: "noopener"
          }, "Impressum", 8, _hoisted_4)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("a", {
            href: _ctx.content.legal.privacySrc,
            target: "_blank",
            rel: "noopener"
          }, "Datenschutz", 8, _hoisted_5)
        ]),
        _createElementVNode("li", null, [
          _createElementVNode("span", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadWindowPrivacyManagerModal()))
          }, "Datenschutz-Einstellungen")
        ])
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.content.legal.copyright), 1)
    ])
  ]))
}
import {
  ValidatorInterface,
  ViolationFields,
} from '@/validators/validatorTypes';

export class PersonalPhoneDataValidator implements ValidatorInterface {
  validate(target: string): ViolationFields[] {
    const violations = [];
    // allows phone numbers to consist only of any number of digits or dashes
    const pattern = /^[\d-]*$/;
    const matches = pattern.exec(target);
    //length 30: SAP has maxLength prefix of 10, phoneNumber of 20 => 30
    if (matches === null || target.length > 30) {
      violations.push(ViolationFields.PHONE_NUMBER);
    }

    return violations;
  }
}

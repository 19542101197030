import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "app",
  class: "app-internal"
}
const _hoisted_2 = { id: "wrapperInternal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheOverlay = _resolveComponent("TheOverlay")!
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_TheFooter = _resolveComponent("TheFooter")!

  return (_ctx.hasInternalAccess && _ctx.loaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TheOverlay, {
            show: !_ctx.isLoggedIn,
            opacity: 1
          }, null, 8, ["show"]),
          _createVNode(_component_TheHeader),
          (_ctx.isLoggedIn)
            ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_TheFooter)
        ])
      ]))
    : _createCommentVNode("", true)
}
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { id: "globalErrorsMsg" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = {
  key: 1,
  class: "error-message message-box-yellow text-left"
}
const _hoisted_4 = {
  key: 2,
  class: "corrected"
}
const _hoisted_5 = {
  key: 3,
  class: "error-message text-left"
}
const _hoisted_6 = {
  key: 4,
  class: "error-message text-left"
}
const _hoisted_7 = {
  key: 5,
  class: "error-message text-left"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_ctx.gpConnectError)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "error-message message-box-yellow text-left",
          innerHTML: _ctx.gpConnectErrorMessage
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.gpConnectSuccess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.commonTexts.userFrontend.address.connectSuccess), 1))
      : _createCommentVNode("", true),
    (_ctx.addressChanged || _ctx.addressSuggested)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.commonTexts.userFrontend.address.addressChanged), 1))
      : _createCommentVNode("", true),
    (_ctx.addressInvalid)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.commonTexts.userFrontend.address.addressInvalid), 1))
      : _createCommentVNode("", true),
    (_ctx.addressIgnore)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.commonTexts.userFrontend.address.addressIgnore), 1))
      : _createCommentVNode("", true),
    (!_ctx.addressComplete || _ctx.personalDataIncomplete)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.commonTexts.userFrontend.address.formIncomplete), 1))
      : _createCommentVNode("", true)
  ]))
}
<template>
  <div class="order-digital">
    <ContentArea
      :borderTop="!addressFormExpanded"
      :isFoldable="true"
      :isFoldableOpen="gpNrConnectFormExpanded"
      v-if="!gpConnectError && showGpNrConnectForm && !gpConnectSuccess"
      class="gpnr-form-container mb-4"
      :class="{
        active: gpNrConnectFormExpanded,
        collapsed: !gpNrConnectFormExpanded && showGpNrConnectForm,
      }"
    >
      <div>
        <header>
          <h2
            class="title clickable"
            @click="formCollapse"
            :class="{ 'form-collapsed-title': !gpNrConnectFormExpanded }"
          >
            <IconChevronRight />
            {{ content.gpNr.headlineWithGpNr }}
          </h2>
        </header>

        <GpNrConnectForm
          v-if="gpNrConnectFormExpanded"
          :content="content"
          :gp-connect-wrong-number="gpConnectWrongNumber"
          :gp-nr-connect-in-progress="gpNrConnectInProgress"
          @formValidated="submitGpNrConnectForm($event)"
        />
      </div>
    </ContentArea>
    <ContentArea
      :borderTop="!gpNrConnectFormExpanded"
      :isFoldable="true"
      :isFoldableOpen="addressFormExpanded"
      id="main-order-form"
      ref="mainOrderForm"
      class="address-data-container"
      :class="{
        active: addressFormExpanded,
        collapsed: !addressFormExpanded && showGpNrConnectForm,
      }"
    >
      <div>
        <header>
          <h2
            v-if="!showGpNrConnectForm || gpConnectError || gpConnectSuccess"
            class="main-content-title"
          >
            {{ content.start.headline2 }}
          </h2>
          <h2
            v-else
            class="title clickable"
            @click="formCollapse"
            :class="{ 'form-collapsed-title': !addressFormExpanded }"
          >
            <IconChevronRight />
            <span>
              {{ content.gpNr.headlineWithoutGpNr }}
            </span>
          </h2>
        </header>
        <AddressError
          v-if="!gpNrConnectFormExpanded"
          :addressChanged="addressChanged"
          :addressInvalid="addressInvalid || orderFormOptionalFieldWrong"
          :addressIgnore="addressIgnore"
          :addressSuggested="addressSuggested"
          :addressComplete="addressComplete"
          :addressAlreadyChecked="formSubmitted"
          :gpConnectError="gpConnectError"
          :gp-connect-error-message="gpConnectErrorMessage"
          :gpConnectSuccess="gpConnectSuccess"
          :personal-data-incomplete="orderFormPersonalDataIsInvalid"
        />
        <TheOverlay :show="submittingFormData" :isFullPage="false">
          <OrderForm
            v-if="
              gpConnectError ||
              (showGpNrConnectForm && !gpNrConnectFormExpanded) ||
              !showGpNrConnectForm
            "
            :address-check-result="addressValidationResult"
            :order-form-data="orderFormData"
            :form-data-currently-validating="isValidating"
            :country-options="countryOptions"
            :name-is-editable="isNameEditable || !userExists"
            :request-email-phone-number="false"
            :request-region-select="showRegionSelect"
            :full-address-required="isAddressRequired"
            :product-type="formData.salesCampaign.printProductType"
            :email-is-mandatory="false"
            :request-miles-and-more-id="requestMilesAndMoreId"
            :miles-and-more-id="formData.mmNumber"
            :pva="formData.pva"
            @formValidated="submitOrderForm($event)"
            @updateMilesAndMoreId="$emit('updateMilesAndMoreId', $event)"
            @updatePrivateData="updatePrivateData($event)"
            @updatedPva="updatePva($event)"
          />
        </TheOverlay>
      </div>
    </ContentArea>
  </div>
</template>
<script lang="ts">
  import AddressError from '@/components/AddressError.vue';
  import AuthService from '@/services/auth-service';
  import { regionOptions } from '@/utils/selectOptions';
  import OrderForm from '@/components/Forms/OrderForm.vue';
  import { getOrderFormLogic } from '@/composables/order-form';
  import { computed, defineComponent, nextTick, Ref, ref, watch } from 'vue';
  import { isInputFilled } from '@/composables/form-functions';
  import GpNrConnectForm from '@/components/Forms/GpNrConnectForm.vue';
  import { GpNrConnectFormData } from '@/types/GpNrConnectFormTypes';
  import { OrderFormData } from '@/types/OrderFormTypes';
  import ContentArea from '@/components/PageLayout/ContentArea.vue';
  import commonTexts from '@/data/commonTexts';
  import TheOverlay from '@/components/Overlay/TheOverlay.vue';
  import { useStore } from 'vuex';
  import IconChevronRight from '@/components/Icons/IconChevronRight.vue';

  export default defineComponent({
    name: 'OrderDigital',
    components: {
      IconChevronRight,
      GpNrConnectForm,
      OrderForm,
      AddressError,
      ContentArea,
      TheOverlay,
    },
    props: {
      prefillFormData: {
        type: Boolean,
        required: true,
      },
      requestMilesAndMoreId: {
        type: Boolean,
        required: false,
        default: false,
      },
      isFollowUpForm: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['formPrefilled', 'updateMilesAndMoreId', 'reloadPage'],
    setup(props, { emit }) {
      const store = useStore();
      const mainOrderForm: Ref<HTMLDivElement | null> = ref(null);

      const {
        orderFormData,
        streetError,
        streetNumberError,
        cityError,
        streetCorrected,
        zipCodeCorrected,
        cityCorrected,
        addressIgnore,
        citySuggestion,
        addressOptions,
        cityOptions,
        streetOptions,
        addressSuggested,
        fullAddressNeeded,
        isValidating,
        formSubmitted,
        addressFormSubmitted,
        orderFormAddressIsInvalid,
        orderFormAddressHasEmptyFields,
        orderFormPersonalDataIsInvalid,
        orderFormOptionalFieldWrong,
        content,
        formData,
        address,
        userExists,
        isNameEditable,
        addressInvalid,
        addressComplete,
        addressChanged,
        goToCheckData,
        countryOptions,
        prefillForm,
        showRegionSelect,
        submitOrderForm,
        isAddressRequired,
        addressValidationResult,
        submittingFormData,
      } = getOrderFormLogic(null, null, null);

      // GP Connect Form
      const expandGpNrConnectForm = ref(false);
      const gpNrConnectInProgress = ref(false);
      const gpConnectWrongNumber = ref(false);
      const gpConnectError = ref(false);
      const gpConnectErrorMessage = ref(
        commonTexts.userFrontend.address.connectError,
      );
      const gpConnectSuccess = ref(false);

      // GP Connect Computed properties and methods
      const gpNr = computed(() => store.state.formData.gpNr);

      const showGpNrConnectForm = computed(() => {
        // Show GpNrConnect form only if the user has no gpNr connected to his/her account
        // & salesCampaign type is digital (always true in this component)
        // & SAP connection is available
        return gpNr.value === null && !store.state.formData.sapConnectionFailed;
      });

      const formCollapse = function () {
        if (showGpNrConnectForm.value) {
          expandGpNrConnectForm.value = !expandGpNrConnectForm.value;
        }
      };

      const resetGpNrConnectionResponse = function () {
        gpConnectWrongNumber.value = false;
        gpConnectError.value = false;
        gpConnectSuccess.value = false;
      };

      const submitGpNrConnectForm = function (event: GpNrConnectFormData) {
        gpNrConnectInProgress.value = true;
        // Reset error message
        gpConnectErrorMessage.value =
          commonTexts.userFrontend.address.connectError;
        AuthService.connectGpNrToUser({
          gpNr: event.gpNr,
          name: event.name,
        }).then((gpNrConnectionResponse) => {
          switch (gpNrConnectionResponse) {
            //ERRORS
            case 'HAS_SUID':
              resetGpNrConnectionResponse();
              gpConnectError.value = true;
              gpConnectErrorMessage.value = content.value.gpNr.error.gpUsed;
              break;
            case 'NO_GP_NUMBER':
              resetGpNrConnectionResponse();
              gpConnectError.value = true;
              break;
            case 'GP_LOCKED':
              resetGpNrConnectionResponse();
              gpConnectError.value = true;
              break;
            case 'FAILURE':
              resetGpNrConnectionResponse();
              gpConnectError.value = true;
              break;
            //SUCCESS
            case 'SUCCESS':
              resetGpNrConnectionResponse();
              gpConnectSuccess.value = true;
              break;
            case 'SUCCESS_CSV':
              resetGpNrConnectionResponse();
              gpConnectSuccess.value = true;
              break;
            //WRONG NUMBER
            case 'WRONG_GP_NUMBER':
              resetGpNrConnectionResponse();
              gpConnectWrongNumber.value = true;
              break;
            default:
              resetGpNrConnectionResponse();
              gpConnectSuccess.value = false;
            // TODO: evaluate how to proceed with undefined response messages
          }
          expandGpNrConnectForm.value = gpConnectWrongNumber.value;
          gpNrConnectInProgress.value = false;
          emit('reloadPage');
        });
      };

      // Form control
      const gpNrConnectFormExpanded = computed(() => {
        return expandGpNrConnectForm.value && showGpNrConnectForm.value;
      });

      const addressFormExpanded = computed(() => {
        return !expandGpNrConnectForm.value && showGpNrConnectForm.value;
      });

      watch(props, () => {
        if (props.prefillFormData) {
          prefillForm();
          if (props.isFollowUpForm) {
            nextTick(() => {
              if (mainOrderForm.value) {
                // caveat: in firefox scroll won't work, if argument is given
                mainOrderForm.value.scrollIntoView();
              }
            });
          }
          emit('formPrefilled');
        }
      });

      const updatePrivateData = function (updatedData: OrderFormData) {
        orderFormData.value = updatedData;
      };

      const updatePva = function (newPva: string) {
        formData.value.pva = newPva;
        store.commit('setPva', newPva);
      };

      return {
        orderFormData,
        addressValidationResult,
        gpNrConnectInProgress,
        regionOptions,
        streetError,
        streetNumberError,
        cityError,
        streetCorrected,
        zipCodeCorrected,
        cityCorrected,
        addressIgnore,
        citySuggestion,
        addressOptions,
        cityOptions,
        streetOptions,
        addressSuggested,
        fullAddressNeeded,
        isValidating,
        formSubmitted,
        addressFormSubmitted,
        orderFormAddressIsInvalid,
        orderFormAddressHasEmptyFields,
        orderFormPersonalDataIsInvalid,
        orderFormOptionalFieldWrong,
        addressFormExpanded,
        formCollapse,
        content,
        formData,
        address,
        userExists,
        isNameEditable,
        addressInvalid,
        addressComplete,
        addressChanged,
        goToCheckData,
        countryOptions,
        showRegionSelect,
        submitOrderForm,
        gpConnectError,
        gpConnectErrorMessage,
        showGpNrConnectForm,
        gpNrConnectFormExpanded,
        gpConnectSuccess,
        gpConnectWrongNumber,
        submitGpNrConnectForm,
        isAddressRequired,
        isInputFilled,
        updatePrivateData,
        submittingFormData,
        updatePva,
        mainOrderForm,
      };
    },
  });
</script>

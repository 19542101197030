import { customizingAPI } from '@/services/http-service';
import {
  CustomizingConnectorInterface,
  ImageUploadInterface,
  ImageUploadOptions,
} from '@/services/connectors/interfaces';
import { AppConfiguration } from '@/store/modules/config/types';
import {
  BasicOrderBonus,
  OrderBonus,
  SalesCampaignOrderBonus,
} from '@/types/OrderBonusesTypes';
import { ImageUploadResponse } from '@/types/ImageUploadTypes';
import { AxiosResponse } from 'axios';

// TODO: Move response data parsing into services!
export default class CustomizingConnector
  implements CustomizingConnectorInterface, ImageUploadInterface
{
  getConfiguration(): Promise<AppConfiguration> {
    return customizingAPI
      .get('configuration')
      .then((response) => response.data)
      .catch((error) => {
        // "Request aborted" is not unexpected to happen with id server redirects
        if (error.code !== 'ECONNABORTED') {
          throw new Error(error.message);
        }
      });
  }

  getOrderBonus(materialNumber: string): Promise<OrderBonus> {
    return customizingAPI
      .get('swmh/tenant/bonus/' + materialNumber)
      .then((response) => response.data as OrderBonus);
  }

  getOrderBonuses(getActiveBonusesOnly: boolean): Promise<BasicOrderBonus[]> {
    if (getActiveBonusesOnly) {
      return customizingAPI
        .get('swmh/tenant/bonuses', {
          params: { active: getActiveBonusesOnly },
        })
        .then((response) => response.data as BasicOrderBonus[]);
    } else {
      return customizingAPI
        .get('swmh/tenant/bonuses')
        .then((response) => response.data as BasicOrderBonus[]);
    }
  }

  updateOrderBonus(
    materialNumber: string,
    orderBonus: OrderBonus,
  ): Promise<Response> {
    return customizingAPI.put(
      'swmh/tenant/bonus/' + materialNumber,
      orderBonus,
    );
  }

  uploadImage(
    image: File,
    options: ImageUploadOptions,
  ): Promise<ImageUploadResponse> {
    const formData = new FormData();
    formData.append('imageFile', image);

    return customizingAPI
      .post('swmh/tenant/bonus/' + options.id + '/image', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data as ImageUploadResponse)
      .catch((error) => {
        console.log('connector', error.response);
        return error.response.data;
      });
  }

  getAvailableOrderBonusesForSalesCampaign(
    salesCampaignId: string,
  ): Promise<SalesCampaignOrderBonus[]> {
    return customizingAPI
      .get(`swmh/tenant/salesCampaign/${salesCampaignId}/availableBonuses`)
      .then((response) => response.data as SalesCampaignOrderBonus[]);
  }

  createComplexOrderBonus(
    materialNumber: string,
    orderBonus: OrderBonus,
  ): Promise<AxiosResponse> {
    return customizingAPI.post(
      'swmh/tenant/bonus/' + materialNumber,
      orderBonus,
    );
    // .then(response => response.status as CreateComplexOrderBonusResponseCode);
  }

  deleteComplexOrderBonus(materialNumber: string): Promise<Response> {
    return customizingAPI.delete(`swmh/tenant/bonus/${materialNumber}`);
  }
}

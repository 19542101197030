import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "display-element-agb-text-block__content" }
const _hoisted_2 = ["href"]
const _hoisted_3 = ["href"]
const _hoisted_4 = {
  key: 0,
  class: "display-element-agb-text-block__content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["display-element-agb-text-block", {
      'display-element-agb-text-block--integrated': _ctx.isIntegratedElement,
    }])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createTextVNode(" Es gelten unsere "),
      _createElementVNode("a", {
        href: _ctx.content.legal.agbSrc,
        target: "_blank"
      }, "AGB", 8, _hoisted_2),
      _createTextVNode(". Informationen zum Datenschutz finden Sie "),
      _createElementVNode("a", {
        href: _ctx.content.legal.privacySrc,
        target: "_blank"
      }, "hier", 8, _hoisted_3),
      _createTextVNode(". ")
    ]),
    (_ctx.showAdditionalLegalText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.content.verification.agbAdditional), 1))
      : _createCommentVNode("", true)
  ], 2))
}
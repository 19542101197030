import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "osc-personal-data-email-phone-form" }
const _hoisted_2 = { class: "osc-personal-data-email-phone-form__text" }
const _hoisted_3 = { class: "form-row" }
const _hoisted_4 = { class: "form-row" }
const _hoisted_5 = {
  key: 0,
  class: "form-row"
}
const _hoisted_6 = {
  key: 1,
  class: "form-row"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementInputField = _resolveComponent("FormElementInputField")!
  const _component_FormElementPhoneNumber = _resolveComponent("FormElementPhoneNumber")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.commonTexts.userInterface.formFieldLabels.emailPhoneFormLabel), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FormElementInputField, {
        value: _ctx.value.email,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.EMAIL, $event))),
        label: _ctx.commonTexts.userInterface.formFieldLabels.email,
        "field-has-error": _ctx.fieldErrors.email,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetFieldError(_ctx.formFields.EMAIL))),
        class: "col-md-12"
      }, null, 8, ["value", "label", "field-has-error"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_FormElementPhoneNumber, {
        value: _ctx.value.phone,
        onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.PHONE, $event))),
        label: _ctx.commonTexts.userInterface.formFieldLabels.phone,
        "field-id-slug": "main",
        "field-has-error": _ctx.fieldErrors.phoneNumber,
        onFocus: _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetFieldError(_ctx.formFields.PHONE))),
        class: "col-md-12",
        maxlength: "30",
        country: _ctx.selectedCountry
      }, null, 8, ["value", "label", "field-has-error", "country"])
    ]),
    (_ctx.value.mobilePhone)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_FormElementPhoneNumber, {
            value: _ctx.value.mobilePhone,
            onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.MOBILE_PHONE, $event))),
            label: _ctx.commonTexts.userInterface.formFieldLabels.mobilePhone,
            "field-id-slug": "mobile",
            "field-has-error": _ctx.fieldErrors.mobilePhone,
            onFocus: _cache[5] || (_cache[5] = ($event: any) => (_ctx.resetFieldError(_ctx.formFields.MOBILE_PHONE))),
            class: "col-md-12",
            maxlength: "30",
            country: _ctx.selectedCountry
          }, null, 8, ["value", "label", "field-has-error", "country"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.value.businessPhone)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_FormElementPhoneNumber, {
            value: _ctx.value.businessPhone,
            onInput: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.BUSINESS_PHONE, $event))),
            label: _ctx.commonTexts.userInterface.formFieldLabels.businessPhone,
            "field-id-slug": "business",
            "field-has-error": _ctx.fieldErrors.businessPhone,
            onFocus: _cache[7] || (_cache[7] = ($event: any) => (_ctx.resetFieldError(_ctx.formFields.BUSINESS_PHONE))),
            class: "col-md-12",
            maxlength: "30",
            country: _ctx.selectedCountry
          }, null, 8, ["value", "label", "field-has-error", "country"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "osc-modal__container" }
const _hoisted_3 = { class: "osc-modal__header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showModal)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: _ctx.modalId,
        class: _normalizeClass(["osc-modal", {
      'osc-modal--xl': _ctx.size === 'xl',
      'osc-modal--l': _ctx.size === 'l',
    }])
      }, [
        _createElementVNode("div", {
          class: "osc-modal__mask",
          onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('modalClosed')), ["self"]))
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _renderSlot(_ctx.$slots, "header")
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(["osc-modal__body", {
            'osc-modal__body--no-padding': !_ctx.hasFooter,
            'osc-modal__body--no-body-scroll': _ctx.noBodyScroll,
          }])
            }, [
              _renderSlot(_ctx.$slots, "body")
            ], 2),
            _createElementVNode("div", {
              class: _normalizeClass(["osc-modal__footer", {
            'osc-modal__footer--disabled': !_ctx.hasFooter,
          }])
            }, [
              _renderSlot(_ctx.$slots, "footer")
            ], 2)
          ])
        ])
      ], 10, _hoisted_1))
    : _createCommentVNode("", true)
}
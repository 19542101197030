// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import PrintProductTypeLiteral from '@/utils/printProductTypeLiteral';

const countries = {
  fullSet: { DE: '' },
};
const regionSelectionOptions = {
  sz: { MDMC: '' },
};

const salesCampaign = {
  fes: {
    salesCampaignId: '',
    name: '',
    infoPoints: [],
    priceInfos: [],
    cancellationInfo: '',
    priceForTracking: 0,
    priceNetForTracking: 0,
    recurring: true,
    printProductType: PrintProductTypeLiteral.PRINT,
    withCosts: true,
    registrationMandatory: false,
    noResultAbroad: false,
    telAdvertEnabled: true,
    mailAdvertEnabled: true,
    emailMandatory: true,
    showStartDate: true,
    showGPTypeSelection: true,
    showDeliveryAddressCompany: true,
    showInvoiceAddressCompany: true,
    allowOrderDespiteExistingSubscription: false,
    preventOrderWithExistingSubscription: false,
    studentSubscription: false,
    giftSubscription: false,
    timeLimitSelectable: false,
    lwl: false,
    numberOfBonuses: 0,
    bonuses: [],
    subscriptionStartDate: null,
    subscriptionEndDate: null,
    subscriptionIssueStartNumber: null,
    subscriptionIssueEndNumber: null,
    specialOffer: false,
  },
};

const dummyBonus = {
  materialNumber: '',
  validFrom: '',
  label: '',
  cashBonus: false,
  additionalCharges: [],
  active: true,
  title: '',
  textLabel: '',
  text: '',
  linkLabel: '',
  link: '',
  infoPoints: [],
  classification: 'NON_MONETARY',
  imagePath: '',
  properties: [],
  variants: [],
};

const orderBonuses = {
  complex: {
    bonus: dummyBonus,
    fixedBonus: false,
    validUntil: null,
  },
  simple1: {
    bonus: dummyBonus,
    fixedBonus: false,
    validUntil: null,
  },
  simple2: {
    bonus: dummyBonus,
    fixedBonus: false,
    validUntil: null,
  },
  simple3: {
    bonus: dummyBonus,
    fixedBonus: false,
    validUntil: null,
  },
  simple4: {
    bonus: dummyBonus,
    fixedBonus: false,
    validUntil: null,
  },
};

const simpleBonuses = new Array(1).fill(orderBonuses.simple1);
simpleBonuses.push(
  orderBonuses.simple2,
  orderBonuses.simple3,
  orderBonuses.simple4,
);

const dummySalesCampaign = {
  gpNr: null,
  orderWithoutAddress: false,
  addresses: null,
  salesCampaign: salesCampaign.fes,
  offerId: null,
  schnorrer: false,
  existingSubscription: false,
  gotoCheckData: false,
  registrationMandatory: false,
  gpLockedOrArchived: false,
  paymentData: [],
  paymentMethodKeys: [],
  countries: countries.fullSet,
  regionSelectionOptions: regionSelectionOptions.sz,
  trackingData: {
    categoryName: '',
  },
  email: '',
  mmNumber: '',
  sapConnectionFailed: false,
  token: {
    content: {
      random: '',
      expirationTimestamp: 0,
    },
    signature: '',
  },
};

export const initialFormData = {
  fes: dummySalesCampaign,
  dszbk: dummySalesCampaign,
  lwl: dummySalesCampaign,
  dsbs4wk: dummySalesCampaign,
  prefill: dummySalesCampaign,
  lan4hp: dummySalesCampaign,
  company: dummySalesCampaign,
  gfes: dummySalesCampaign,
};

const dummySingleAddressCheckResult = {
  address: null,
  fullAddressNeeded: false,
  hasErrors: false,
  corrected: false,
  streetError: false,
  streetNumberError: false,
  zipCodeError: false,
  cityError: false,
  postOfficeBoxError: false,
  zipCodeCorrected: false,
  cityCorrected: false,
  districtCorrected: false,
  streetCorrected: false,
  postOfficeBoxCorrected: false,
  postOfficeBoxZipCodeCorrected: false,
  postOfficeBoxCityCorrected: false,
  plzOrtKorOptions: null,
  strKorOptions: null,
  adrKorOptions: null,
};

const dummyAddressCheckResponse = {
  privateAddressCheckResult: dummySingleAddressCheckResult,
  businessAddressCheckResult: dummySingleAddressCheckResult,
  deliveryAddressCheckResult: dummySingleAddressCheckResult,
  invoiceAddressCheckResult: dummySingleAddressCheckResult,
  subTitleOutLink: '',
  subTitleInfoTextParagraphs: [],
};

export const addressCheckResponse = {
  ok: dummyAddressCheckResponse,
  streetError: dummyAddressCheckResponse,
  subTitle: dummyAddressCheckResponse,
  corrected: dummyAddressCheckResponse,
  select: dummyAddressCheckResponse,
};

export const priceWithDeliveryDate = {
  default: {
    priceResult: {
      price: 0,
      annualPrice: 0,
      priceGui: '',
      noResultAbroad: false,
    },
    deliveryDateInfo: {
      earliestDate: '',
      invalidDates: [],
    },
    releaseCalendar: null,
  },
  magazine: {
    priceResult: {
      price: 0,
      annualPrice: 0,
      priceGui: '',
      noResultAbroad: false,
    },
    deliveryDateInfo: {
      earliestDate: '',
      invalidDates: [],
    },
    releaseCalendar: null,
  },
};

export const createdOrder = {
  ok: {
    orderNumbers: [],
    temporary: false,
    registrationPossible: false,
    validFrom: '',
    gpNr: '',
    lastName: '',
    temporaryOrderId: '',
  },
  temp: {
    orderNumbers: [],
    temporary: false,
    registrationPossible: false,
    validFrom: '',
    gpNr: '',
    lastName: '',
    temporaryOrderId: '',
  },
  reg: {
    orderNumbers: [],
    temporary: false,
    registrationPossible: false,
    validFrom: '',
    gpNr: '',
    lastName: '',
    temporaryOrderId: '',
  },
};

export const billingFrequencyGroups = {
  default: {
    'FK ALLE': {
      code: '',
      text: '',
      billingFrequencies: [],
    },
  },
};

const countryGroups = {
  complete: { 'DE ONLY': '' },
};

const dummySingleSalesCampaignResponseData = {
  salesCampaign: {
    id: '',
    baseInfo: {
      text: '',
      validFrom: '',
      validUntil: '',
      paymentRhythm: '',
      withCosts: true,
      noSchnorren: false,
      printProduct: '',
      printProductType: PrintProductTypeLiteral.PRINT,
    },
    priceGui: '',
    name: '',
    tenantId: '',
    offerInfo: '',
    active: true,
    infoPoints: [],
    priceInfos: [],
    validFrom: '',
    validUntil: '',
    countryGroup: '',
    paymentMethods: [],
    emailMandatory: true,
    orderCsv: false,
    orderWithoutAddress: false,
    showStartDate: true,
    mailAdvertEnabled: true,
    telAdvertEnabled: true,
    allowOrderDespiteExistingSubscription: false,
    preventOrderWithExistingSubscription: false,
    allowSchnorren: true,
    showGPTypeSelection: true,
    showDeliveryAddressCompany: true,
    showInvoiceAddressCompany: true,
    showTimePeriod: false,
    delayedCharging: 0,
    billingFrequencyGroup: '',
    studentSubscription: false,
    giftSubscription: false,
    timeLimitSelectable: false,
    lwl: false,
    subscriptionStartDate: null,
    subscriptionEndDate: null,
    subscriptionIssueStartNumber: null,
    subscriptionIssueEndNumber: null,
    numberOfBonuses: 0,
    countries: null,
    bonuses: [],
  },
  countryGroups: countryGroups.complete,
  billingFrequencyGroups: billingFrequencyGroups.default,
  _links: {},
};

export const adminSingleSalesCampaigns = {
  fes: dummySingleSalesCampaignResponseData,
  lwl: dummySingleSalesCampaignResponseData,
  dszbk: dummySingleSalesCampaignResponseData,
  lan4hp: dummySingleSalesCampaignResponseData,
};

export const availableBonuses = { dszbk: [] };

export const initialFormDataDebuggingSample = initialFormData.fes;

import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "osc-address-form" }
const _hoisted_2 = { class: "form-row" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementSelectField = _resolveComponent("FormElementSelectField")!
  const _component_FormElementRadioButtons = _resolveComponent("FormElementRadioButtons")!
  const _component_PostOfficeBoxForm = _resolveComponent("PostOfficeBoxForm")!
  const _component_StreetAddressForm = _resolveComponent("StreetAddressForm")!
  const _component_StreetAddressFormSimple = _resolveComponent("StreetAddressFormSimple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.processBusinessAddress && _ctx.businessAddress)
        ? (_openBlock(), _createBlock(_component_FormElementSelectField, {
            key: 0,
            label: "Land",
            class: "col-md-12",
            selectOptions: _ctx.countryOptions,
            value: _ctx.businessAddress.country,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateCountry($event))),
            "field-has-error": _ctx.fieldErrorsBusiness.country,
            onSelectionChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('resetFieldErrorBusiness', 'country')))
          }, null, 8, ["selectOptions", "value", "field-has-error"]))
        : (_openBlock(), _createBlock(_component_FormElementSelectField, {
            key: 1,
            label: "Land",
            class: "col-md-12",
            selectOptions: _ctx.countryOptions,
            value: _ctx.orderFormData.country,
            onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateCountry($event))),
            "field-has-error": _ctx.fieldErrors.country,
            onSelectionChanged: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('resetFieldError', 'country')))
          }, null, 8, ["selectOptions", "value", "field-has-error"]))
    ]),
    (_ctx.addressRequired && !_ctx.isDigitalProductType)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_FormElementRadioButtons, {
              value: 
            _ctx.processBusinessAddress
              ? _ctx.businessAddress
                ? _ctx.businessAddress.addressType
                : _ctx.orderFormData.addressType
              : _ctx.orderFormData.addressType
          ,
              onInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateSelectedAddressType($event))),
              radioOptions: _ctx.formAddressTypeOptions,
              class: "mb-4"
            }, null, 8, ["value", "radioOptions"])
          ]),
          (_ctx.postOfficeBoxSelected && !_ctx.processBusinessAddress)
            ? (_openBlock(), _createBlock(_component_PostOfficeBoxForm, {
                key: 0,
                "address-data": _ctx.orderFormData,
                "field-errors": _ctx.fieldErrors,
                "address-check-result": _ctx.addressCheckResult?.privateAddress,
                onAddressDataInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updatePrivateAddressData($event))),
                onResetFieldError: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('resetFieldError', $event)))
              }, null, 8, ["address-data", "field-errors", "address-check-result"]))
            : (
          _ctx.postOfficeBoxSelected && _ctx.processBusinessAddress && _ctx.businessAddress
        )
              ? (_openBlock(), _createBlock(_component_PostOfficeBoxForm, {
                  key: 1,
                  "address-data": _ctx.businessAddress,
                  "field-errors": _ctx.fieldErrorsBusiness,
                  "address-check-result": _ctx.addressCheckResult?.businessAddress,
                  onAddressDataInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.updateBusinessAddressData($event))),
                  onResetFieldError: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('resetFieldErrorBusiness', $event)))
                }, null, 8, ["address-data", "field-errors", "address-check-result"]))
              : (_ctx.processBusinessAddress && _ctx.businessAddress)
                ? (_openBlock(), _createBlock(_component_StreetAddressForm, {
                    key: 2,
                    "is-business-address": "",
                    "address-data": _ctx.businessAddress,
                    "field-errors": _ctx.fieldErrorsBusiness,
                    "field-id-slug": "business",
                    "address-check-result": _ctx.addressCheckResult?.businessAddress,
                    onAddressDataInput: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updatePrivateAddressData($event))),
                    onResetFieldError: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('resetFieldErrorBusiness', $event)))
                  }, null, 8, ["address-data", "field-errors", "address-check-result"]))
                : (_openBlock(), _createBlock(_component_StreetAddressForm, {
                    key: 3,
                    "address-data": _ctx.orderFormData,
                    "field-errors": _ctx.fieldErrors,
                    "field-id-slug": "private",
                    "address-check-result": _ctx.addressCheckResult?.privateAddress,
                    onAddressDataInput: _cache[11] || (_cache[11] = ($event: any) => (_ctx.updatePrivateAddressData($event))),
                    onResetFieldError: _cache[12] || (_cache[12] = ($event: any) => (_ctx.$emit('resetFieldError', $event)))
                  }, null, 8, ["address-data", "field-errors", "address-check-result"]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.addressRequired && _ctx.isDigitalProductType)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_StreetAddressFormSimple, {
            class: "mt-4",
            "address-data": _ctx.orderFormData,
            "field-errors": _ctx.fieldErrors,
            "field-id-slug": "digital",
            "address-check-result": _ctx.addressCheckResult?.privateAddress,
            onAddressDataInput: _cache[13] || (_cache[13] = ($event: any) => (_ctx.updatePrivateAddressData($event))),
            onResetFieldError: _cache[14] || (_cache[14] = ($event: any) => (_ctx.$emit('resetFieldError', $event)))
          }, null, 8, ["address-data", "field-errors", "address-check-result"])
        ]))
      : _createCommentVNode("", true)
  ]))
}
<template>
  <div class="icon-info">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <title>Details</title>
      <g fill="none" fill-rule="evenodd">
        <path d="M0 0h24v24H0z"></path>
        <g stroke-linecap="square" transform="translate(2.5 2.5)">
          <circle
            cx="10"
            cy="10"
            r="9.5"
            stroke="#fff"
            stroke-width="1.5"
            class="outside icon-info__stroke"
          ></circle>
          <g class="inside">
            <path
              stroke="#fff"
              stroke-width="1.5"
              d="M8 8h2v5"
              class="icon-info__stroke"
            ></path>
            <path
              stroke="#fff"
              stroke-width="1.5"
              d="M8 14h4"
              class="icon-info__stroke"
            ></path>
            <circle
              cx="10"
              cy="5"
              r="1"
              fill="#fff"
              fill-rule="nonzero"
              class="icon-info__fill"
            ></circle>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'IconInfo',
  });
</script>

<template>
  <div
    class="display-element-agb-text-block"
    :class="{
      'display-element-agb-text-block--integrated': isIntegratedElement,
    }"
  >
    <!--    <span class="display-element-agb-text-block__content">-->
    <!--      Es gelten die-->
    <!--      <a :href="content.legal.agbSrc" target="_blank">AGB</a>. Die Hinweise zum-->
    <!--      <a :href="content.legal.cancellationSrc" target="_blank"-->
    <!--        >Widerrufsrecht</a-->
    <!--      >-->
    <!--      und zum-->
    <!--      <a :href="content.legal.privacySrc" target="_blank">Datenschutz</a>-->
    <!--      habe ich gelesen.-->
    <!--    </span>-->
    <span class="display-element-agb-text-block__content">
      Es gelten unsere
      <a :href="content.legal.agbSrc" target="_blank">AGB</a>. Informationen zum
      Datenschutz finden Sie
      <a :href="content.legal.privacySrc" target="_blank">hier</a>.
    </span>
    <span
      v-if="showAdditionalLegalText"
      class="display-element-agb-text-block__content"
    >
      {{ content.verification.agbAdditional }}
    </span>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { TenantContent } from '@/models/tenant';

  export default defineComponent({
    name: 'DisplayElementAgbTextBlock',
    props: {
      content: {
        type: Object as PropType<TenantContent>,
        required: true,
      },
      showAdditionalLegalText: {
        type: Boolean,
        required: true,
      },
      isIntegratedElement: {
        type: Boolean,
        required: false,
        default: true,
      },
    },
  });
</script>

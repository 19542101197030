const errorMessages = {
  general: {
    parsing: {
      noDate: 'ohne Datum',
    },
  },
  userInterfaceOnly: {
    studentEnrollmentForm: {
      imageUploadErrors: {
        fileTooLarge: 'Datei ist zu groß, bitte eine kleinere Datei hochladen.',
        fileNameInvalid:
          'Der Dateiname muss insgesamt 5 Zeichen lang sein (inkl. Endung und Punkt).',
        fileTypeInvalid:
          'Der Dateityp ist unzulässig, bitte verwenden Sie nur JPG, PNG, PDF oder GIF Dateien.',
        noFile: 'Es wurde keine Datei ausgewählt.',
        serverError:
          'Es kam zu einem Serverfehler, bitte versuchen Sie es später noch einmal.',
        unpreparedBonus:
          'Ein Upload von Bildern ist nur nach Pflege und Speicherung der Zugabe möglich.',
        general:
          'Leider ist mit dem Upload Ihrer Datei etwas schiefgelaufen. Bitte prüfen Sie die Datei auf Größe, Format und Zugriffsrecht und versuchen Sie es nochmals. Vielen Dank.',
      },
    },
  },
  adminInterfaceOnly: {
    imageUploadErrors: {
      fileTooLarge: 'Datei ist zu groß, bitte eine kleinere Datei hochladen.',
      fileNameInvalid:
        'Der Dateiname muss insgesamt 5 Zeichen lang sein (inkl. Endung und Punkt).',
      fileTypeInvalid:
        'Der Dateityp ist unzulässig, bitte verwenden Sie nur PNG oder GIF Dateien.',
      noFile: 'Es wurde keine Datei ausgewählt.',
      serverError:
        'Es kam zu einem Serverfehler, bitte versuchen Sie es später noch einmal.',
      unpreparedBonus:
        'Ein Upload von Bildern ist nur nach Pflege und Speicherung der Zugabe möglich.',
    },
    complexOrderCreation: {
      duplicateVariantsError: 'Bitte überprüfe die zugewiesenen Ausprägungen!',
    },
  },
};

export default errorMessages;

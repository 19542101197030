import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "osc-name-form" }
const _hoisted_2 = { class: "form-row form-group mb-4" }
const _hoisted_3 = { class: "form-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementSelectField = _resolveComponent("FormElementSelectField")!
  const _component_DisplayElementUserSalutation = _resolveComponent("DisplayElementUserSalutation")!
  const _component_FormElementInputField = _resolveComponent("FormElementInputField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.nameEditable)
        ? (_openBlock(), _createBlock(_component_FormElementSelectField, {
            key: 0,
            class: "col-md-6",
            label: _ctx.commonTexts.userInterface.formFieldLabels.salutation,
            selectOptions: _ctx.salutationOptions,
            value: _ctx.value.salutation,
            onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.SALUTATION, $event))),
            "field-has-error": _ctx.fieldErrorSalutation,
            onSelectionChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetFieldError('salutation')))
          }, null, 8, ["label", "selectOptions", "value", "field-has-error"]))
        : _createCommentVNode("", true),
      (_ctx.nameEditable && !_ctx.isDigitalProductType)
        ? (_openBlock(), _createBlock(_component_FormElementSelectField, {
            key: 1,
            class: "col-md-6 mt-4 mt-md-0",
            label: _ctx.commonTexts.userInterface.formFieldLabels.title,
            selectOptions: _ctx.titleOptions,
            value: _ctx.value.title,
            onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.TITLE, $event)))
          }, null, 8, ["label", "selectOptions", "value"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.nameEditable)
        ? (_openBlock(), _createBlock(_component_DisplayElementUserSalutation, {
            key: 0,
            address: _ctx.value,
            class: "col-md-12 mb-4"
          }, null, 8, ["address"]))
        : _createCommentVNode("", true),
      (_ctx.nameEditable)
        ? (_openBlock(), _createBlock(_component_FormElementInputField, {
            key: 1,
            label: _ctx.commonTexts.userInterface.formFieldLabels.firstName,
            value: _ctx.value.firstName,
            onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.FIRST_NAME, $event))),
            "field-has-error": _ctx.fieldErrorFirstname,
            onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.resetFieldError('firstName'))),
            class: "col-md-12 mb-4",
            maxlength: "35"
          }, null, 8, ["label", "value", "field-has-error"]))
        : _createCommentVNode("", true),
      (_ctx.nameEditable)
        ? (_openBlock(), _createBlock(_component_FormElementInputField, {
            key: 2,
            label: _ctx.commonTexts.userInterface.formFieldLabels.lastName,
            value: _ctx.value.lastName,
            onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.updatePersonalData(_ctx.formFields.LAST_NAME, $event))),
            "field-has-error": _ctx.fieldErrorLastName,
            onFocus: _cache[6] || (_cache[6] = ($event: any) => (_ctx.resetFieldError('lastName'))),
            class: "col-md-12",
            maxlength: "35"
          }, null, 8, ["label", "value", "field-has-error"]))
        : _createCommentVNode("", true)
    ])
  ]))
}
import { ActionTree } from 'vuex';
import {
  AdminOrderBonusesActionTypes,
  AdminOrderBonusesMutationTypes,
  AdminOrderBonusesState,
} from '@/store/modules/adminOrderBonuses/types';
import { AppRootState } from '@/store/interfaces';
import AdminOrderBonusesService from '@/services/admin-order-bonuses-service';
import { BasicOrderBonus, OrderBonus } from '@/types/OrderBonusesTypes';
import { ImageUploadResponseMessage } from '@/types/ImageUploadTypes';
import { DateTime } from 'luxon';

const actions: ActionTree<AdminOrderBonusesState, AppRootState> = {
  async [AdminOrderBonusesActionTypes.LOAD_BONUSES_COLLECTION](
    { commit },
    payload = false,
  ) {
    await AdminOrderBonusesService.loadAdminOrderBonusesCollection(
      payload,
    ).then((orderBonuses) => {
      commit(
        AdminOrderBonusesMutationTypes.SET_BONUSES_COLLECTION,
        orderBonuses,
      );
    });
  },
  async [AdminOrderBonusesActionTypes.LOAD_BONUS]({ commit }, payload: string) {
    await AdminOrderBonusesService.loadAdminOrderBonus(payload).then(
      (orderBonus) => {
        commit(AdminOrderBonusesMutationTypes.SET_BONUS, orderBonus);
      },
    );
  },
  async [AdminOrderBonusesActionTypes.UPDATE_BONUS](
    context,
    payload: OrderBonus,
  ) {
    context.commit(AdminOrderBonusesMutationTypes.SET_BONUS, payload);

    return AdminOrderBonusesService.updateOrderBonus(
      payload.materialNumber,
      payload,
    ).then((response: Response) => {
      if (response.status === 200) {
        const bonuses: BasicOrderBonus[] = context.state.bonusesCollection;
        const index = bonuses.findIndex(
          (basicBonus) => basicBonus.materialNumber === payload.materialNumber,
        );
        if (index) {
          bonuses[index] = {
            active: payload.active,
            label: payload.label,
            materialNumber: payload.materialNumber,
            title: payload.title,
            validFrom: payload.validFrom,
          };
        }
      }
      return response;
    });
  },
  async [AdminOrderBonusesActionTypes.LOAD_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION](
    { commit },
    payload: string,
  ) {
    await AdminOrderBonusesService.loadAvailableOrderBonusesForSalesCampaign(
      payload,
    ).then((availableOrderBonuses) => {
      commit(
        AdminOrderBonusesMutationTypes.SET_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION,
        availableOrderBonuses,
      );
    });
  },
  [AdminOrderBonusesActionTypes.RESET_BONUS]({ commit }) {
    commit(AdminOrderBonusesMutationTypes.SET_BONUS, undefined);
  },
  [AdminOrderBonusesActionTypes.RESET_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION]({
    commit,
  }) {
    commit(
      AdminOrderBonusesMutationTypes.SET_AVAILABLE_SALES_CAMPAIGN_ORDER_BONUSES_COLLECTION,
      undefined,
    );
  },
  async [AdminOrderBonusesActionTypes.UPLOAD_ORDER_BONUS_IMAGE](
    context,
    payload: { materialNumber: string; file: FileList },
  ): Promise<ImageUploadResponseMessage> {
    return AdminOrderBonusesService.uploadOrderBonusImage(
      payload.materialNumber,
      payload.file[0],
    ).then((imageUploadResponse) => {
      const message = imageUploadResponse.message;
      if (message === ImageUploadResponseMessage.SUCCESS) {
        const orderBonus = context.state.bonus;
        if (orderBonus) {
          // Using a slug to force reload after image upload (path won't change)
          const slug = '?now=' + DateTime.now().toMillis().toString(10);
          orderBonus.imagePath = imageUploadResponse.path + slug;
          context.commit(AdminOrderBonusesMutationTypes.SET_BONUS, orderBonus);
        }
      }
      return message;
    });
  },
  async [AdminOrderBonusesActionTypes.CREATE_COMPLEX_ORDER_BONUS](
    context,
    payload: OrderBonus,
  ) {
    context.commit(AdminOrderBonusesMutationTypes.SET_BONUS, payload);

    // Response code should be evaluated in view
    return AdminOrderBonusesService.createComplexOrderBonus(
      payload.materialNumber,
      payload,
    );
  },
};

export default actions;

import state from '@/store/modules/adminOrderBonuses/state';
import getters from '@/store/modules/adminOrderBonuses/getters';
import mutations from '@/store/modules/adminOrderBonuses/mutations';
import actions from '@/store/modules/adminOrderBonuses/actions';

import { Module } from 'vuex';
import { AdminOrderBonusesState } from '@/store/modules/adminOrderBonuses/types';
import { AppRootState } from '@/store/interfaces';

const adminOrderBonusesModule: Module<AdminOrderBonusesState, AppRootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default adminOrderBonusesModule;

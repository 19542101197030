import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "order-bonus-selection-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DisplayElementOrderBonusTile = _resolveComponent("DisplayElementOrderBonusTile")!
  const _component_DisplayElementOrderBonusDetailsPreview = _resolveComponent("DisplayElementOrderBonusDetailsPreview")!
  const _component_PageRow = _resolveComponent("PageRow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrangedOrderBonuses, (row, i) => {
      return (_openBlock(), _createBlock(_component_PageRow, {
        key: i,
        class: "order-bonus-selection-form__row"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (orderBonus) => {
            return (_openBlock(), _createBlock(_component_DisplayElementOrderBonusTile, {
              key: orderBonus.bonus.materialNumber,
              "order-bonus": orderBonus.bonus,
              "in-cart": _ctx.itemIsInCart(orderBonus.bonus.materialNumber),
              embedded: "",
              active: _ctx.activeItem === orderBonus.bonus.materialNumber,
              class: "form-group order-bonus-selection-form__tile",
              onClick: ($event: any) => (_ctx.switchActiveItem(orderBonus.bonus.materialNumber))
            }, null, 8, ["order-bonus", "in-cart", "active", "onClick"]))
          }), 128)),
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              (_ctx.showDetailView(i) && _ctx.activeOrderBonus)
                ? (_openBlock(), _createBlock(_component_DisplayElementOrderBonusDetailsPreview, {
                    key: 0,
                    "order-bonus": _ctx.activeOrderBonus.bonus,
                    "is-interactive": "",
                    "disabled-button": 
            (!_ctx.activeItemRemovable && _ctx.cartIsFull) || !_ctx.configuredItemExists
          ,
                    "custom-label": _ctx.detailViewButtonLabel,
                    "order-bonus-configuration-options": _ctx.properties,
                    "chosen-values": _ctx.chosenValues,
                    "calculated-price": _ctx.priceForConfiguration,
                    "order-bonus-is-in-cart": _ctx.itemInCart,
                    onOrderBonusSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectOrderBonus($event))),
                    onConfigurationChanged: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateChosenValues($event))),
                    class: "mb-3"
                  }, null, 8, ["order-bonus", "disabled-button", "custom-label", "order-bonus-configuration-options", "chosen-values", "calculated-price", "order-bonus-is-in-cart"]))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024))
    }), 128))
  ]))
}
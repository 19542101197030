import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "order-bonus-view" }
const _hoisted_2 = { class: "main-content-title" }
const _hoisted_3 = { class: "mb-4 d-block" }
const _hoisted_4 = {
  key: 0,
  class: "d-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderBonusSelectionForm = _resolveComponent("OrderBonusSelectionForm")!
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_ContentArea = _resolveComponent("ContentArea")!

  return (_openBlock(), _createBlock(_component_ContentArea, null, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("header", null, [
            _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.commonFrontendTexts.userFrontend.orderBonusSelectionView.mainTitle), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.commonFrontendTexts.userFrontend.orderBonusSelectionView.mainText), 1)
          ]),
          _createVNode(_component_OrderBonusSelectionForm, {
            "order-bonuses": _ctx.bonuses,
            "selected-bonuses": _ctx.orderBonusCart,
            "cart-is-full": _ctx.orderBonusCartIsFull,
            onOrderBonusSelected: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleOrderBonusCartState($event))),
            onResetConfiguration: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setComplexOrderBonusConfiguration([]))),
            onComplexOrderBonusFullyConfigured: _cache[2] || (_cache[2] = ($event: any) => (
            _ctx.setComplexOrderBonusConfiguration($event)
          ))
          }, null, 8, ["order-bonuses", "selected-bonuses", "cart-is-full"]),
          _createElementVNode("div", null, [
            (!_ctx.orderBonusCartIsFull)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.commonFrontendTexts.userFrontend.orderBonusSelectionView
              .confirmationText), 1))
              : _createCommentVNode("", true)
          ]),
          _createVNode(_component_BasicButton, {
            variant: "primary",
            type: "submit",
            class: "main-content-button btn-save",
            disabled: !_ctx.orderBonusCartIsFull,
            onButtonClicked: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('confirmOrderBonusSelection')))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Weiter ")
            ]),
            _: 1
          }, 8, ["disabled"])
        ])
      ])
    ]),
    _: 1
  }))
}
export type ImageUploadResponse = {
  message: ImageUploadResponseMessage;
  path: string;
  status?: number;
  timestamp?: string;
  error?: string;
  id?: string;
};

export enum ImageUploadResponseMessage {
  SUCCESS = 'SUCCESS',
  NO_FILE = 'ERROR_NO_FILE_CONTENT',
  FILE_SIZE_EXCEEDED = 'ERROR_SIZE_EXCEEDED',
  INVALID_FILE_NAME = 'ERROR_INVALID_FILE_NAME',
  INVALID_SUFFIX = 'ERROR_INVALID_SUFFIX',
  INVALID_FILE_TYPE = 'ERROR_INVALID_FILE_TYPE',
  SERVER_ERROR = '500 INTERNAL_SERVER_ERROR',
  UPLOAD_FOR_UNPREPARED_BONUS = 'ERROR_BONUS_NOT_ADMINISTRATED',
}

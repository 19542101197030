<template>
  <TheOverlay class="vld-parent" :isFullPage="false" :show="isValidating">
    <div class="iban-field-form__content-headline">
      {{ commonTexts.userFrontend.cashBonus.displayElement.headline }}
    </div>
    <div class="form-element-iban-field form-row">
      <FormElementSelectField
        :value="swiftIban.bankCountryCode"
        :select-options="countryOptions"
        embedded
        class="col-md-2"
        @input="swiftIban.bankCountryCode = $event"
      />
      <FormElementInputField
        :value="swiftIban.iban"
        :field-has-error="hasError"
        label="IBAN"
        maxlength="32"
        @blur="updateIban()"
        class="col-md-10 mt-1 mt-md-0"
        @input="swiftIban.iban = $event"
      />
      <FormElementInputField
        v-if="ibanData.bankCountryCode !== commonConfig.domesticCountry"
        :value="swiftIban.swift"
        :field-has-error="hasError"
        :label="commonTexts.userInterface.formFieldLabels.swift"
        maxlength="15"
        class="col-12 mt-1"
        @input="swiftIban.swift = $event"
      />
    </div>
  </TheOverlay>
</template>

<script lang="ts">
  import FormElementInputField from '@/components/FormElements/FormElementInputField.vue';
  import { defineComponent, PropType, Ref, ref, watch } from 'vue';
  import OrderService from '@/services/order-service';
  import { IbanCoreData } from '@/types/PaymentTypes';
  import commonConfig from '@/data/commonConfig';
  import FormElementSelectField from '@/components/FormElements/FormElementSelectField.vue';
  import { countryOptions } from '@/utils/selectOptions';
  import commonTexts from '@/data/commonTexts';
  import TheOverlay from '@/components/Overlay/TheOverlay.vue';

  export default defineComponent({
    name: 'FormElementIbanField',
    components: { FormElementSelectField, FormElementInputField, TheOverlay },
    emits: ['ibanUpdated', 'ibanInvalid'],
    props: {
      ibanData: {
        type: Object as PropType<IbanCoreData>,
        required: true,
      },
      hasError: {
        type: Boolean,
        required: true,
      },
    },
    setup(props, { emit }) {
      const swiftIban: Ref<IbanCoreData> = ref(props.ibanData);
      const isValidating = ref(false);

      watch(props, () => {
        swiftIban.value = props.ibanData;
      });

      const updateIban = function () {
        if (swiftIban.value.iban) {
          isValidating.value = true;
          OrderService.checkIban(
            swiftIban.value.bankCountryCode + swiftIban.value.iban,
            swiftIban.value.swift,
          )
            .then((response) => {
              isValidating.value = false;
              if (response.data.checkFailed || response.data.ibanError) {
                emit('ibanInvalid');
              } else {
                const newIbanData: IbanCoreData = {
                  iban: swiftIban.value.iban,
                  swift: swiftIban.value.swift,
                  bankName: response.data.bankName,
                  bankCountryCode: swiftIban.value.bankCountryCode,
                };
                emit('ibanUpdated', newIbanData);
              }
            })
            .catch(() => {
              isValidating.value = false;
            });
        }
      };

      return {
        updateIban,
        commonConfig,
        countryOptions,
        commonTexts,
        swiftIban,
        isValidating,
      };
    },
  });
</script>

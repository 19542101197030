import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BasicButton = _resolveComponent("BasicButton")!

  return (_openBlock(), _createBlock(_component_BasicButton, {
    variant: "primary",
    class: _normalizeClass(["mt-0 mb-5", { 'btn-transparent': _ctx.transparent }]),
    onButtonClicked: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectToAuthentication()))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["class"]))
}
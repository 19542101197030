import { FormDataToken } from '@/models/BaseTypes';
import {
  addressValidationResponseFromAPI,
  CreateOrderResponse,
  FormAddresses,
  InitialFormDataResponseData,
  OrderData,
  PriceResult,
  PriceResultRequestData,
  PriceWithDeliveryDate,
  PriceWithDeliveryDateRequestData,
} from '@/models/OrderData';
import router from '@/router';
import { OrderConnectorInterface } from '@/services/connectors/interfaces';
import { orderAPI } from '@/services/http-service';

export default class OrderConnector implements OrderConnectorInterface {
  getInitialFormData(
    queryParameter: 'oid' | 'wa',
    product: string,
    tenant: string,
  ): Promise<InitialFormDataResponseData> {
    return orderAPI
      .get(`order/initialFormData?${queryParameter}=` + product, {
        headers: {
          'osc-tenant': tenant,
        },
      })
      .then((response) => {
        return response.data;
      });
  }

  checkAddresses(
    address: FormAddresses,
    salesCampaignId: string,
    formDataToken: FormDataToken | null,
  ): Promise<addressValidationResponseFromAPI> {
    const postData = {
      ...address,
      salesCampaignId,
    };

    return orderAPI
      .post('order/checkAddresses', postData, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
        router.push({
          name: 'ErrorPage',
          params: { type: 'server' },
        });
        return Promise.reject();
      });
  }

  getPriceWithDeliveryDate(
    salesCampaignId: string,
    formDataToken: FormDataToken | null,
    data: PriceWithDeliveryDateRequestData,
  ): Promise<PriceWithDeliveryDate> {
    return orderAPI
      .post(`/priceWithDeliveryDate/${salesCampaignId}`, data, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
        router.push({
          name: 'ErrorPage',
          params: { type: 'server' },
        });
        return Promise.reject();
      });
  }

  getPriceForDate(
    salesCampaignId: string,
    formDataToken: FormDataToken | null,
    data: PriceResultRequestData,
  ): Promise<PriceResult> {
    return orderAPI
      .post(`/priceForDate/${salesCampaignId}`, data, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
        router.push({
          name: 'ErrorPage',
          params: { type: 'server' },
        });
        return Promise.reject();
      });
  }

  createOrder(
    formDataToken: FormDataToken | null,
    data: OrderData,
  ): Promise<CreateOrderResponse> {
    return orderAPI
      .post('order/create', data, {
        headers: { token: JSON.stringify(formDataToken) },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
        router.push({
          name: 'ErrorPage',
          params: { type: 'server' },
        });
        return Promise.reject();
      });
  }

  getCountries(salesCampaignId: string): Promise<{ [key: string]: string }> {
    return orderAPI
      .get(`order/countries/${salesCampaignId}`)
      .then((response) => {
        return response.data;
      });
  }

  getPaymentMethodsForSalesCampaign(
    salesCampaignId: string,
    countryCode: string,
  ): Promise<string[]> {
    return orderAPI
      .get(`order/paymentmethods/${salesCampaignId}/${countryCode}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        if (error.response?.data) {
          console.log(error.response.data);
        } else {
          console.log(error);
        }
        router.push({
          name: 'ErrorPage',
          params: { type: 'server' },
        });
        return Promise.reject();
      });
  }
}

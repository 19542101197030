<template>
  <div id="app" class="appOrder">
    <TheOverlay :show="registrationMandatory && !isLoggedIn" :opacity="1" />
    <TheHeader />

    <ThePageBody v-if="isLoggedIn || !registrationMandatory">
      <div>
        <router-view />
      </div>
    </ThePageBody>

    <TheFooter />
  </div>
</template>

<script lang="ts">
  import TheHeader from '@/components/TheHeader.vue';
  import TheFooter from '@/components/TheFooter.vue';
  import SetupService from '@/services/setup-service';
  import SourcepointService from '@/services/sourcepoint-service';
  import { ConfigActionTypes } from '@/store/modules/config/types';
  import { StoreModules } from '@/store/types';
  import ThePageBody from '@/components/PageLayout/ThePageBody.vue';
  import { computed, defineComponent, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import TheOverlay from '@/components/Overlay/TheOverlay.vue';

  export default defineComponent({
    name: 'App',
    components: {
      TheHeader,
      TheFooter,
      ThePageBody,
      TheOverlay,
    },
    async beforeCreate() {
      SetupService.setTenant();
      await SetupService.setParameters();
      SetupService.changeFavicon();
      SetupService.changeMetaTitle();
    },
    setup() {
      const store = useStore();

      const isLoggedIn = computed(() => {
        return store.state.isLoggedIn;
      });

      const registrationMandatory = computed(() => {
        return store.state.formData.registrationMandatory;
      });

      // Was in created hook previously
      onMounted(async () => {
        // Moved into Start_old.vue to prevent race conditions with storedData restoration
        // await FormService.requestFormData();
        // await AuthService.checkLogin('', this.registrationMandatory);
        console.log('App.vue onMounted');
        await SourcepointService.loadSourcePoint();
        await store.dispatch(
          StoreModules.CONFIG_MODULE + ConfigActionTypes.LOAD_CONFIG,
        );
      });

      return { isLoggedIn, registrationMandatory };
    },
  });
</script>

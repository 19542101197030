import { AuthConnectorInterface } from '@/services/connectors/interfaces';
import {
  AuthScreen,
  GpConnectionResult,
  LoginStateResponseData,
  UserConnectRequestData,
} from '@/types/AuthTypes';

export default class AuthConnectorStub implements AuthConnectorInterface {
  getLoginState(
    redirectPath: string,
    loginRequired: boolean,
    authscreen?: AuthScreen,
  ): Promise<LoginStateResponseData> {
    console.log('Getting Login State');
    console.log('Redirect Path: ' + redirectPath);
    console.log('Login Required: ' + loginRequired);
    console.log('Screen: ' + authscreen);
    const loginStateParam = window.location.search.split('loginState=')[1];
    const loggedIn = !(loginStateParam === 'false');
    return new Promise<LoginStateResponseData>((resolve) => {
      setTimeout(() => {
        resolve({ loggedIn, serviceTicketUrl: '' });
      }, 500);
    });
  }
  connectUser(
    gpConnectData: UserConnectRequestData,
  ): Promise<GpConnectionResult> {
    console.log('Connecting User via stub');
    return new Promise<GpConnectionResult>((resolve) => {
      setTimeout(() => {
        console.log('posting to backend:', gpConnectData);
        resolve(GpConnectionResult.SUCCESS);
      }, 500);
    });
  }

  async getLogoutUrl(): Promise<string> {
    console.log('Getting Logout Url from stub');
    return new Promise<string>((resolve) => {
      setTimeout(() => {
        resolve('https://www.google.com');
      }, 500);
    });
  }
}

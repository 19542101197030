<template>
  <ContentArea>
    <div>
      <div id="order-recruiter" class="order-recruiter">
        <header>
          <h2 class="main-content-title">
            {{ headline }}
          </h2>
        </header>
        <AddressError
          :addressChanged="addressChanged"
          :addressInvalid="addressInvalid || orderFormOptionalFieldWrong"
          :addressIgnore="addressIgnore"
          :addressSuggested="addressSuggested"
          :addressComplete="addressComplete"
          :addressAlreadyChecked="formSubmitted"
          :personal-data-incomplete="orderFormPersonalDataIsInvalid"
        />
        <LoginInterface :login-url="appConfiguration.urls.loginUrl" />
        <TheOverlay :show="submittingFormData" :isFullPage="false">
          <OrderForm
            :address-check-result="addressValidationResult"
            :order-form-data="addresses.privateAddress"
            :form-data-currently-validating="isValidating"
            :country-options="countryOptions"
            :name-is-editable="isNameEditable"
            :request-email-phone-number="true"
            :request-region-select="false"
            :full-address-required="true"
            :product-type="formData.salesCampaign.printProductType"
            :email-is-mandatory="emailIsMandatory"
            :request-miles-and-more-id="requestMilesAndMoreId"
            :miles-and-more-id="formData.mmNumber"
            :gp-selection-is-enabled="gpTypeSelectionPossible"
            :business-address="addresses.businessAddress"
            :delivery-address="addresses.deliveryAddress"
            :delivery-address-enabled="deliveryAddressPossible"
            :invoice-address="addresses.invoiceAddress"
            :invoice-address-enabled="invoiceAddressPossible"
            :show-gift-subscription-limit-selection="
              formData.salesCampaign.timeLimitSelectable
            "
            :selected-gift-subscription-limit="selectedGiftSubscriptionLimit"
            :request-iban="requestIban"
            :iban-data="ibanData"
            @formValidated="submitOrderForm($event)"
            @updatePrivateData="updatePrivateData($event)"
            @updateBusinessData="updateBusinessData($event)"
            @updateDeliveryAddress="updateDeliveryAddress($event)"
            @deleteDeliveryAddress="deleteDeliveryAddress"
            @updateInvoiceAddress="updateInvoiceAddress($event)"
            @deleteInvoiceAddress="deleteInvoiceAddress"
            @updateMilesAndMoreId="$emit('updateMilesAndMoreId', $event)"
            @changeRecruiterGiftSubscriptionLimit="
              updateGiftSubscriptionLimit($event)
            "
            @updateIban="$emit('updateIban', $event)"
          />
        </TheOverlay>
      </div>
    </div>
  </ContentArea>
</template>

<script lang="ts">
  import { computed, defineComponent, Ref, ref, watch, PropType } from 'vue';
  import commonTexts from '@/data/commonTexts';
  import {
    addressFields,
    checkAddressChangedByValidation,
    checkAddressValidationResponse,
    checkViolationsForErrorTypes,
    deleteAddress,
    getBusinessAddressFunctionality,
    getEmptyAddressValidationResult,
    getEmptyBusinessAddress,
    getEmptyDeviatingAddress,
    getEmptyPrivateAddress,
    getOrderFormLogic,
    optionalFields,
    personalDataFields,
    prefillBusinessData,
    prefillPrivateAddress,
    processValidationErrors,
    setAddressesCorrectionOptions,
    showErrorOrWarningToUserAfterAddressValidation,
  } from '@/composables/order-form';
  import {
    AddressValidationResult,
    FormBusinessAddress,
    FormDeviatingAddress,
  } from '@/models/OrderData';
  import {
    OrderFormData,
    ValidationResultOrderForm,
  } from '@/types/OrderFormTypes';
  import OrderForm from '@/components/Forms/OrderForm.vue';
  import {
    AddressViolations,
    ValidationResultValue,
    ViolationFields,
  } from '@/validators/validatorTypes';
  import { scrollTo } from '@/composables/page-functions';
  import FormService from '@/services/form-service';
  import AddressError from '@/components/AddressError.vue';
  import LoginInterface from '@/components/GeneralInterface/LoginInterface.vue';
  import { StoreModules } from '@/store/types';
  import { ConfigGetterTypes } from '@/store/modules/config/types';
  import { SubscriptionType } from '@/types/GeneralInterfaceTypes';
  import ContentArea from '@/components/PageLayout/ContentArea.vue';
  import TheOverlay from '@/components/Overlay/TheOverlay.vue';
  import { IbanCoreData } from '@/types/PaymentTypes';
  import { useStore } from 'vuex';

  export default defineComponent({
    name: 'OrderRecruiter',
    components: {
      LoginInterface,
      AddressError,
      OrderForm,
      ContentArea,
      TheOverlay,
    },
    props: {
      prefillFormData: {
        type: Boolean,
        required: true,
      },
      subscriptionType: {
        type: Number,
        required: true,
      },
      requestMilesAndMoreId: {
        type: Boolean,
        required: false,
        default: false,
      },
      requestIban: {
        type: Boolean,
        required: false,
        default: false,
      },
      ibanData: {
        type: Object as PropType<IbanCoreData>,
        required: false,
      },
    },
    emits: [
      'formPrefilled',
      'recruiterAddressesSaved',
      'updateMilesAndMoreId',
      'updateIban',
    ],
    setup(props, { emit }) {
      const store = useStore();
      const {
        gpTypeSelectionPossible,
        deliveryAddressPossible,
        invoiceAddressPossible,
      } = getBusinessAddressFunctionality();

      const addresses: Ref<{
        privateAddress: OrderFormData;
        businessAddress: FormBusinessAddress;
        invoiceAddress: FormDeviatingAddress;
        deliveryAddress: FormDeviatingAddress;
      }> = ref(Object.assign({}, store.state.recruiterAddresses));
      if (addresses.value.privateAddress === null) {
        addresses.value.privateAddress = getEmptyPrivateAddress();
      }
      if (addresses.value.businessAddress === null) {
        addresses.value.businessAddress = getEmptyBusinessAddress();
      }
      if (addresses.value.invoiceAddress === null) {
        addresses.value.invoiceAddress = getEmptyDeviatingAddress();
      }
      if (addresses.value.deliveryAddress === null) {
        addresses.value.deliveryAddress = getEmptyDeviatingAddress();
      }

      const { countryOptions, formData, isValidating } = getOrderFormLogic(
        null,
        null,
        null,
      );

      addresses.value.privateAddress = prefillPrivateAddress(
        addresses.value.privateAddress,
        countryOptions,
        formData.value.email,
        !store.state.userIsReceiver,
      );
      if (gpTypeSelectionPossible.value) {
        addresses.value.businessAddress = prefillBusinessData(
          addresses.value.businessAddress,
          null,
          !store.state.userIsReceiver,
        ) as FormBusinessAddress;
      }
      if (deliveryAddressPossible.value) {
        addresses.value.deliveryAddress = prefillBusinessData(
          addresses.value.deliveryAddress,
          null,
          !store.state.userIsReceiver,
        ) as FormDeviatingAddress;
      }
      if (invoiceAddressPossible.value) {
        addresses.value.invoiceAddress = prefillBusinessData(
          addresses.value.invoiceAddress,
          null,
          !store.state.userIsReceiver,
        ) as FormDeviatingAddress;
      }

      const submittingFormData = ref(false);
      const emailIsMandatory = computed(() => {
        // Email is mandatory, if so specified in salesCampaign AND for Print / Magazines only – in digital campaigns
        //    the user already has registered with email and email input field is shown never
        // Mandatory for recruiter only in giftSubscription
        return (
          store.getters.formData.salesCampaign.emailMandatory &&
          store.getters.formData.salesCampaign.giftSubscription
        );
      });
      const addressValidationResult: Ref<AddressValidationResult> = ref(
        getEmptyAddressValidationResult(),
      );

      const addressChanged = computed(() => {
        return (
          checkAddressChangedByValidation(
            addressValidationResult.value.privateAddress,
          ) ||
          checkAddressChangedByValidation(
            addressValidationResult.value.businessAddress,
          ) ||
          checkAddressChangedByValidation(
            addressValidationResult.value.deliveryAddress,
          ) ||
          checkAddressChangedByValidation(
            addressValidationResult.value.invoiceAddress,
          )
        );
      });

      watch(props, () => {
        if (props.prefillFormData) {
          emit('formPrefilled');
        }
      });

      const isGiftSubscription = computed(() => {
        return store.state.formData.salesCampaign.giftSubscription;
      });
      const headline = computed(() => {
        return props.subscriptionType ===
          (SubscriptionType.GIFT_SUBSCRIPTION as unknown as number)
          ? commonTexts.userFrontend.orderRecruiterView.mainTitleGift
          : commonTexts.userFrontend.orderRecruiterView.mainTitleLwL;
      });

      const updatePrivateData = function (newAddress: OrderFormData) {
        addresses.value.privateAddress = newAddress;
      };

      const orderFormAddressHasEmptyFields = ref(false);
      const orderFormPersonalDataIsInvalid = ref(false);
      const orderFormOptionalFieldWrong = ref(false);
      const addressIgnore = ref(false);
      const addressInvalid = ref(false);
      const orderFormAddressIsInvalid = ref(false);
      const addressSuggested = ref(false);
      const formSubmitted = ref(false);

      const requiredFieldsValid = computed(() => {
        return !(
          orderFormPersonalDataIsInvalid.value ||
          orderFormAddressHasEmptyFields.value
        );
      });

      const addressComplete = computed(() => {
        return requiredFieldsValid.value;
      });

      const processViolationFieldsForAddress = function (
        violations: ViolationFields[],
      ) {
        if (!orderFormAddressHasEmptyFields.value) {
          orderFormAddressHasEmptyFields.value = checkViolationsForErrorTypes(
            violations,
            addressFields,
          );
        }
        if (!orderFormPersonalDataIsInvalid.value) {
          orderFormPersonalDataIsInvalid.value = checkViolationsForErrorTypes(
            violations,
            personalDataFields,
          );
        }
        if (!orderFormOptionalFieldWrong.value) {
          orderFormOptionalFieldWrong.value = checkViolationsForErrorTypes(
            violations,
            optionalFields,
          );
        }
      };

      const mapViolationFields = function (
        violations: AddressViolations,
      ): void {
        if (violations.common.length > 0) {
          orderFormAddressHasEmptyFields.value = checkViolationsForErrorTypes(
            violations.common,
            [ViolationFields.IBAN],
          );
        }
        if (violations.privateAddress.length > 0) {
          processViolationFieldsForAddress(violations.privateAddress);
        } else if (violations.businessAddress.length > 0) {
          processViolationFieldsForAddress(violations.businessAddress);
        }

        if (violations.deliveryAddress.length > 0) {
          processViolationFieldsForAddress(violations.deliveryAddress);
        }

        if (violations.invoiceAddress.length > 0) {
          processViolationFieldsForAddress(violations.invoiceAddress);
        }
      };

      const submitOrderForm = function (
        validationResult: ValidationResultOrderForm,
      ) {
        submittingFormData.value = true;
        // 1. ResetFieldErrors
        orderFormPersonalDataIsInvalid.value = false;
        orderFormAddressHasEmptyFields.value = false;
        orderFormOptionalFieldWrong.value = false;

        // 2. Evaluate validationResult
        if (validationResult.result === ValidationResultValue.SUCCESS) {
          isValidating.value = true;
          formSubmitted.value = true;

          // In Gift-Subscriptions recruiter's address must be complete
          if (isGiftSubscription.value) {
            store.commit('changeIsAddressComplete', true);
          }

          // 3. submitAddress to addressCheck
          FormService.validateAddress(validationResult.validatedData).then(
            (addressCheckResult) => {
              // a) Reset validation result
              addressValidationResult.value = getEmptyAddressValidationResult();

              // b) set flags
              isValidating.value = false;
              addressSuggested.value = false;

              if (
                showErrorOrWarningToUserAfterAddressValidation(
                  addressCheckResult,
                )
              ) {
                scrollTo('order-recruiter');
              } else {
                addressIgnore.value = false;
                orderFormAddressIsInvalid.value = false;
              }

              // Process Response
              if (addressInvalid.value && !addressIgnore.value) {
                orderFormAddressIsInvalid.value = false;
                addressIgnore.value = true;
                submittingFormData.value = false;
              } else if (checkAddressValidationResponse(addressCheckResult)) {
                setAddressesCorrectionOptions(
                  addressCheckResult,
                  addressValidationResult,
                );
                submittingFormData.value = false;
              } else if (
                showErrorOrWarningToUserAfterAddressValidation(
                  addressCheckResult,
                ) &&
                !addressIgnore.value
              ) {
                if (addressCheckResult.privateAddressCheckResult.address) {
                  orderFormAddressIsInvalid.value = processValidationErrors(
                    addressCheckResult.privateAddressCheckResult,
                    addresses.value.privateAddress,
                    addressValidationResult.value?.privateAddress,
                  );
                }
                if (
                  addresses.value.businessAddress &&
                  addressCheckResult.businessAddressCheckResult.address
                ) {
                  orderFormAddressIsInvalid.value = processValidationErrors(
                    addressCheckResult.businessAddressCheckResult,
                    addresses.value.businessAddress,
                    addressValidationResult.value?.businessAddress,
                  );
                }
                if (
                  addresses.value.deliveryAddress &&
                  addressCheckResult.deliveryAddressCheckResult.address
                ) {
                  orderFormAddressIsInvalid.value = processValidationErrors(
                    addressCheckResult.deliveryAddressCheckResult,
                    addresses.value.deliveryAddress,
                    addressValidationResult.value?.deliveryAddress,
                  );
                }
                if (
                  addresses.value.invoiceAddress &&
                  addressCheckResult.invoiceAddressCheckResult.address
                ) {
                  orderFormAddressIsInvalid.value = processValidationErrors(
                    addressCheckResult.invoiceAddressCheckResult,
                    addresses.value.invoiceAddress,
                    addressValidationResult.value?.invoiceAddress,
                  );
                }
                submittingFormData.value = false;
              } else {
                // Saving part
                store.commit(
                  'setRecruiterAddresses',
                  validationResult.validatedData,
                );
                scrollTo('order-recruiter');
                isValidating.value = false;
                emit('recruiterAddressesSaved');
              }
            },
          );
        } else if (
          validationResult.result === ValidationResultValue.FIELD_VIOLATIONS
        ) {
          store.commit('changeIsAddressComplete', false);
          mapViolationFields(validationResult.violations);
          scrollTo('order-recruiter');
          submittingFormData.value = false;
        }
      };

      const appConfiguration = computed(
        () =>
          store.getters[
            StoreModules.CONFIG_MODULE + ConfigGetterTypes.GET_CONFIG
          ],
      );

      const updateBusinessData = function (updatedData: FormBusinessAddress) {
        addresses.value.businessAddress = updatedData;
      };
      const updateDeliveryAddress = function (
        updatedData: FormDeviatingAddress,
      ) {
        addresses.value.deliveryAddress = updatedData;
      };
      const deleteDeliveryAddress = function () {
        deleteAddress(addresses.value.deliveryAddress);
      };
      const updateInvoiceAddress = function (
        updatedData: FormDeviatingAddress,
      ) {
        addresses.value.invoiceAddress = updatedData;
      };
      const deleteInvoiceAddress = function () {
        deleteAddress(addresses.value.invoiceAddress);
      };
      const updateGiftSubscriptionLimit = function (newValue: number) {
        store.dispatch('changeRecruiterGiftSubscriptionLimit', newValue);
      };

      const isNameEditable = computed(() => {
        if (store.state.recruiterAddresses.businessAddress) {
          return addresses.value.businessAddress.nameEditable;
        } else {
          return addresses.value.privateAddress.nameEditable;
        }
      });

      const selectedGiftSubscriptionLimit = computed(() => {
        return store.state.recruiterGiftSubscriptionLimit;
      });

      return {
        isGiftSubscription,
        commonTexts,
        headline,
        addresses,
        submittingFormData,
        countryOptions,
        emailIsMandatory,
        formData,
        addressValidationResult,
        isValidating,
        updatePrivateData,
        submitOrderForm,
        addressComplete,
        orderFormPersonalDataIsInvalid,
        addressSuggested,
        addressIgnore,
        formSubmitted,
        addressChanged,
        addressInvalid,
        orderFormOptionalFieldWrong,
        appConfiguration,
        gpTypeSelectionPossible,
        deliveryAddressPossible,
        invoiceAddressPossible,
        updateBusinessData,
        updateDeliveryAddress,
        updateInvoiceAddress,
        deleteDeliveryAddress,
        deleteInvoiceAddress,
        isNameEditable,
        selectedGiftSubscriptionLimit,
        updateGiftSubscriptionLimit,
      };
    },
  });
</script>

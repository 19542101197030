<template>
  <div class="private-address-addition">
    <FormElementCheckBox
      :value="additionalInfoSelected"
      name="privateAddressAddition"
      @input="additionalInfoSelected = $event"
      class="mb-1"
    >
      {{
        commonTexts.userInterface.formFieldLabels.showAddressAdditionFormPrivate
      }}
    </FormElementCheckBox>
    <div class="form-row" v-if="additionalInfoSelected">
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.addressAddition"
        :field-id="
          commonTexts.userInterface.formFieldLabels.addressAddition +
          fieldIdSlug
        "
        :value="value.addressAddition"
        @input="update(formFields.ADDRESS_ADDITION, $event)"
        :field-has-error="fieldErrors.addressAddition"
        class="col-md-12 mb-4"
        maxlength="35"
      />
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.floor"
        :field-id="
          commonTexts.userInterface.formFieldLabels.floor + fieldIdSlug
        "
        :value="value.floor"
        @input="update(formFields.FLOOR, $event)"
        :field-has-error="fieldErrors.floor"
        class="col-md-6 mb-4"
        maxlength="6"
      />
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.room"
        :field-id="commonTexts.userInterface.formFieldLabels.room + fieldIdSlug"
        :value="value.room"
        @input="update(formFields.ROOM, $event)"
        :field-has-error="fieldErrors.room"
        class="col-md-6 mb-4"
      />
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.additionalInfoPrivate"
        :field-id="
          commonTexts.userInterface.formFieldLabels.additionalInfoPrivate +
          fieldIdSlug
        "
        :value="value.additionalInfo"
        @input="update(formFields.ADDITIONAL_INFO, $event)"
        :field-has-error="fieldErrors.additionalInfo"
        class="col-md-12 mb-4"
        maxlength="50"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import FormElementInputField from '@/components/FormElements/FormElementInputField.vue';
  import FormElementCheckBox from '@/components/FormElements/FormElementCheckBox.vue';
  import { defineComponent, onMounted, ref, PropType, watch } from 'vue';
  import commonTexts from '@/data/commonTexts';
  import { OrderFormStreetAddress } from '@/types/OrderFormTypes';
  import { FieldErrorsAddressData } from '@/validators/validatorTypes';

  export default defineComponent({
    name: 'PrivateAddressAdditionForm',
    components: { FormElementCheckBox, FormElementInputField },
    emits: ['addressDataInput'],
    props: {
      value: {
        type: Object as PropType<OrderFormStreetAddress>,
        required: true,
      },
      fieldErrors: {
        type: Object as PropType<FieldErrorsAddressData>,
        required: true,
      },
      fieldIdSlug: {
        type: String,
        required: false,
        default: '',
      },
    },
    setup(props, { emit }) {
      enum formFields {
        FLOOR = 'floor',
        ROOM = 'room',
        ADDRESS_ADDITION = 'addressAddition',
        ADDITIONAL_INFO = 'additionalInfo',
      }
      const additionalInfoSelected = ref(false);

      onMounted(() => {
        if (
          props.value.room !== '' ||
          props.value.floor !== '' ||
          props.value.addressAddition !== '' ||
          props.value.additionalInfo !== ''
        ) {
          additionalInfoSelected.value = true;
        }
      });

      watch(
        [
          () => props.value.room,
          () => props.value.floor,
          () => props.value.addressAddition,
          () => props.value.additionalInfo,
        ],
        () => {
          if (
            props.value.room !== '' ||
            props.value.floor !== '' ||
            props.value.addressAddition !== '' ||
            props.value.additionalInfo !== ''
          ) {
            additionalInfoSelected.value = true;
          }
        },
      );

      const update = function (field: formFields, value: string) {
        const data = Object.assign({}, props.value);
        switch (field) {
          case formFields.FLOOR:
            data.floor = value;
            break;
          case formFields.ROOM:
            data.room = value;
            break;
          case formFields.ADDRESS_ADDITION:
            data.addressAddition = value;
            break;
          case formFields.ADDITIONAL_INFO:
            data.additionalInfo = value;
            break;
        }

        emit('addressDataInput', data);
      };

      return { update, formFields, additionalInfoSelected, commonTexts };
    },
  });
</script>

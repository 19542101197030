export enum ImageUploadConnectorName {
  CUSTOMIZING,
  STUDENT_ENROLLMENT,
}

export enum CreateComplexOrderBonusResponseCode {
  SUCCESS = 200,
  ERROR_WRONG_TENANT = 400,
  ERROR_EXISTS = 409,
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "gift-subscription-limit-selection-form" }
const _hoisted_2 = { class: "mb-4 d-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementRadioButtons = _resolveComponent("FormElementRadioButtons")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.commonTexts.userFrontend.orderRecruiterView
          .giftSubscriptionLimitSubHeader), 1),
    _createVNode(_component_FormElementRadioButtons, {
      value: _ctx.value,
      "radio-options": _ctx.options,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeSelection($event))),
      stacked: ""
    }, null, 8, ["value", "radio-options"])
  ]))
}
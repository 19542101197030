<template>
  <div class="osc-post-office-box-form">
    <div class="form-row">
      <FormElementInputField
        :value="addressData.postOfficeBox"
        @input="updatePostOfficeBoxData(formFields.BOX, $event)"
        :label="commonTexts.userInterface.formFieldLabels.postOfficeBox"
        :field-id="
          commonTexts.userInterface.formFieldLabels.postOfficeBox + fieldIdSlug
        "
        class="col-md-12 mb-4"
        :field-has-error="postOfficeBoxError"
        :field-value-corrected="
          addressCheckResult ? addressCheckResult.postOfficeBoxCorrected : false
        "
        @focus="resetFieldError('postOfficeBox')"
        maxlength="10"
      />
    </div>
    <div class="form-row">
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.zipCode"
        :field-id="
          commonTexts.userInterface.formFieldLabels.zipCode + fieldIdSlug
        "
        class="col-md-3 mb-4"
        :value="addressData.postOfficeBoxZipCode"
        @input="updatePostOfficeBoxData(formFields.ZIP_CODE, $event)"
        :field-has-error="fieldErrors.postOfficeBoxZipCode"
        :field-value-corrected="
          addressCheckResult
            ? addressCheckResult.postOfficeBoxZipCodeCorrected
            : false
        "
        @focus="resetFieldError('postOfficeBoxZipCode')"
        maxlength="10"
      />
      <FormElementInputField
        :label="commonTexts.userInterface.formFieldLabels.city"
        :field-id="commonTexts.userInterface.formFieldLabels.city + fieldIdSlug"
        class="col-md-9 mb-4"
        :value="addressData.postOfficeBoxCity"
        @input="updatePostOfficeBoxData(formFields.CITY, $event)"
        :field-has-error="fieldErrors.postOfficeBoxCity"
        :field-value-corrected="
          addressCheckResult
            ? addressCheckResult.postOfficeBoxCityCorrected
            : false
        "
        @focus="resetFieldError('postOfficeBoxCity')"
        maxlength="35"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import FormElementInputField from '../FormElements/FormElementInputField.vue';
  import { computed, defineComponent, PropType } from 'vue';
  import {
    FieldErrorsAddressData,
    ViolationFields,
  } from '@/validators/validatorTypes';
  import commonTexts from '@/data/commonTexts';
  import {
    FormBusinessAddress,
    FormDeviatingAddress,
    InternalSingleAddressValidationResult,
  } from '@/models/OrderData';
  import { OrderFormData } from '@/types/OrderFormTypes';

  export default defineComponent({
    name: 'PostOfficeBoxForm',
    components: {
      FormElementInputField,
    },
    emits: ['addressDataInput', 'resetFieldError'],
    props: {
      addressData: {
        type: Object as PropType<
          FormDeviatingAddress | OrderFormData | FormBusinessAddress
        >,
        required: true,
      },
      fieldErrors: {
        type: Object as PropType<FieldErrorsAddressData>,
        required: true,
      },
      addressCheckResult: {
        type: Object as PropType<InternalSingleAddressValidationResult>,
        required: false,
      },
      fieldIdSlug: {
        type: String,
        required: false,
        default: '',
      },
    },
    setup(props, { emit }) {
      enum formFields {
        BOX = 'postOfficeBox',
        ZIP_CODE = 'postOfficeBoxZipCode',
        CITY = 'postOfficeBoxCity',
      }

      const postOfficeBoxError = computed(
        () =>
          props.fieldErrors.postOfficeBox ||
          (props.addressCheckResult &&
            props.addressCheckResult.postOfficeBoxError),
      );

      const updatePostOfficeBoxData = function (
        field: formFields,
        newValue: string,
      ) {
        const data = Object.assign({}, props.addressData);
        switch (field) {
          case formFields.BOX:
            data.postOfficeBox = newValue;
            break;
          case formFields.ZIP_CODE:
            data.postOfficeBoxZipCode = newValue;
            break;
          case formFields.CITY:
            data.postOfficeBoxCity = newValue;
            break;
        }

        emit('addressDataInput', data);
      };

      const resetFieldError = function (field: string) {
        if (formFields.BOX === field) {
          emit('resetFieldError', ViolationFields.POST_OFFICE_BOX);
        } else if (formFields.ZIP_CODE === field) {
          emit('resetFieldError', ViolationFields.POST_OFFICE_BOX_ZIP_CODE);
        } else if (formFields.CITY === field) {
          emit('resetFieldError', ViolationFields.POST_OFFICE_BOX_CITY);
        }
      };

      return {
        postOfficeBoxError,
        resetFieldError,
        formFields,
        updatePostOfficeBoxData,
        commonTexts,
      };
    },
  });
</script>

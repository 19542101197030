<template>
  <div class="osc-order-form">
    <FormWrapper>
      <FormElementRadioButtons
        v-if="gpSelectionIsEnabled && nameIsEditable"
        :radio-options="customerTypeOptions"
        :value="gpType"
        @input="setGpType"
        class="margin-bottom-large"
      />
      <PersonalBusinessDataForm
        v-if="businessAddressSelected && businessAddress"
        class="mb-4"
        :name-editable="nameIsEditable"
        :value="businessAddress"
        :field-errors="fieldErrorsBusinessAddress"
        @resetFieldError="
          resetFieldError($event, AddressSubType.BUSINESS_ADDRESS)
        "
        @personalDataInput="updateAddressData($event)"
      />
      <PersonalDataForm
        v-else
        ref="personalDataForm"
        class="mb-4"
        :value="orderFormData"
        :name-editable="nameIsEditable"
        :field-errors="fieldErrorsPrivateAddress"
        :product-type="productType"
        @personalDataInput="updateAddressData($event)"
        @resetFieldError="
          resetFieldError($event, AddressSubType.PRIVATE_ADDRESS)
        "
      />
      <AddressForm
        class="mt-4"
        :process-business-address="businessAddressSelected"
        :order-form-data="orderFormData"
        :field-errors="fieldErrorsPrivateAddress"
        :field-errors-business="fieldErrorsBusinessAddress"
        :address-check-result="addressCheckResult"
        :address-required="fullAddressRequired"
        :country-options="countryOptions"
        :product-type="productType"
        :business-address="businessAddress"
        @addressDataInput="updateAddressData($event)"
        @resetFieldError="
          resetFieldError($event, AddressSubType.PRIVATE_ADDRESS)
        "
        @resetFieldErrorBusiness="
          resetFieldError($event, AddressSubType.BUSINESS_ADDRESS)
        "
      />
      <DeviatingAddressForm
        v-if="
          businessAddressSelected && deliveryAddressEnabled && deliveryAddress
        "
        field-id-slug="delivery"
        :address-check-result="addressCheckResult?.deliveryAddress"
        :field-errors-address="fieldErrorsDeliveryAddress"
        :value="deliveryAddress"
        :country-options="countryOptions"
        :form-label="
          commonTexts.userInterface.formFieldLabels.deliveryAddressFormLabel
        "
        @updateDeviatingAddress="$emit('updateDeliveryAddress', $event)"
        @deleteDeviatingAddress="$emit('deleteDeliveryAddress')"
        @resetFieldError="
          resetFieldError($event, AddressSubType.DELIVERY_ADDRESS)
        "
        class="mt-4"
      />
      <DeviatingAddressForm
        v-if="
          businessAddressSelected && invoiceAddressEnabled && invoiceAddress
        "
        field-id-slug="invoice"
        :address-check-result="addressCheckResult?.invoiceAddress"
        :field-errors-address="fieldErrorsInvoiceAddress"
        :value="invoiceAddress"
        :country-options="countryOptions"
        :form-label="
          commonTexts.userInterface.formFieldLabels.invoiceAddressFormLabel
        "
        @updateDeviatingAddress="$emit('updateInvoiceAddress', $event)"
        @deleteDeviatingAddress="$emit('deleteInvoiceAddress')"
        @resetFieldError="
          resetFieldError($event, AddressSubType.INVOICE_ADDRESS)
        "
      />
      <div v-if="requestEmailPhoneNumber && businessAddress">
        <PersonalDataEmailPhoneForm
          v-if="businessAddressSelected"
          :value="businessAddress"
          :field-errors="fieldErrorsBusinessAddress"
          :selected-country="businessAddress.country"
          @personalDataInput="updateAddressData($event)"
          @resetFieldError="
            resetFieldError($event, AddressSubType.BUSINESS_ADDRESS)
          "
        />
        <PersonalDataEmailPhoneForm
          v-else
          :value="orderFormData"
          :field-errors="fieldErrorsPrivateAddress"
          :selected-country="orderFormData.country"
          @personalDataInput="updateAddressData($event)"
          @resetFieldError="
            resetFieldError($event, AddressSubType.PRIVATE_ADDRESS)
          "
        />
      </div>
      <RegionSelectForm
        v-if="requestRegionSelect"
        :value="pva"
        :has-error="pvaFieldHasError"
        @input="updateDistrict($event)"
        class="mt-4"
      />
      <FormElementIbanField
        v-if="requestIban && ibanData"
        :iban-data="ibanData"
        :has-error="ibanFieldHasError"
        @ibanUpdated="updateIbanData($event)"
        @ibanInvalid="ibanFieldHasError = true"
      />
      <MilesAndMoreForm
        v-if="requestMilesAndMoreId"
        :value="milesAndMoreId"
        @updateMilesAndMoreId="$emit('updateMilesAndMoreId', $event)"
        @hasError="milesAndMoreFieldHasError = $event"
      />
      <GiftSubscriptionLimitSelectionForm
        v-if="showGiftSubscriptionLimitSelection"
        class="margin-top-xl"
        @changeRecruiterGiftSubscriptionLimit="
          $emit('changeRecruiterGiftSubscriptionLimit', $event)
        "
        :value="selectedGiftSubscriptionLimit"
      />
      <TheOverlay
        class="d-inline-block w-100"
        :isFullPage="false"
        :show="isValidating || formDataCurrentlyValidating"
        :opacity="0.3"
        :spiner-size="20"
      >
        <BasicButton
          :disabled="isValidating || formDataCurrentlyValidating"
          variant="primary"
          class="main-content-button"
          @buttonClicked="submit"
        >
          {{ commonTexts.userInterface.buttons.proceed }}
        </BasicButton>
      </TheOverlay>
    </FormWrapper>
  </div>
</template>

<script lang="ts">
  import PersonalDataForm from '@/components/Forms/PersonalDataForm.vue';
  import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    reactive,
    ref,
    watch,
  } from 'vue';
  import AddressForm from '@/components/Forms/AddressForm.vue';
  import PersonalDataEmailPhoneForm from '@/components/Forms/PersonalDataEmailPhoneForm.vue';
  import { OrderFormValidator } from '@/validators/OrderFormValidator';
  import RegionSelectForm from '@/components/Forms/RegionSelectForm.vue';
  import {
    FieldErrorsAddressData,
    ValidationResultValue,
    ViolationFields,
  } from '@/validators/validatorTypes';
  import {
    GpType,
    OrderFormData,
    ValidationResultOrderForm,
  } from '@/types/OrderFormTypes';
  import MilesAndMoreForm from '@/components/Forms/MilesAndMoreForm.vue';
  import { customerTypeOptions } from '@/utils/selectOptions';
  import FormElementRadioButtons from '@/components/FormElements/FormElementRadioButtons.vue';
  import PersonalBusinessDataForm from '@/components/Forms/PersonalBusinessDataForm.vue';
  import commonTexts from '@/data/commonTexts';
  import DeviatingAddressForm from '@/components/Forms/DeviatingAddressForm.vue';
  import {
    buildAddressFromFormData,
    checkIfDeviatingAddressExists,
    normalizeBusinessAndDeviatingAddressData,
    normalizePrivateAddressData,
  } from '@/composables/order-form';
  import {
    AddressSubType,
    AddressValidationResult,
    FormBusinessAddress,
    FormDeviatingAddress,
  } from '@/models/OrderData';
  import GiftSubscriptionLimitSelectionForm from '@/components/Forms/GiftSubscriptionLimitSelectionForm.vue';
  import FormElementIbanField from '@/components/FormElements/FormElementIbanField.vue';
  import { IbanCoreData } from '@/types/PaymentTypes';
  import commonConfig from '@/data/commonConfig';
  import { RegionSelectValidator } from '@/validators/RegionSelectValidator';
  import TheOverlay from '@/components/Overlay/TheOverlay.vue';
  import BasicButton from '@/components/Buttons/BasicButton.vue';
  import FormWrapper from '@/components/FormElements/FormWrapper.vue';

  export default defineComponent({
    name: 'OrderForm',
    components: {
      FormWrapper,
      FormElementIbanField,
      DeviatingAddressForm,
      PersonalBusinessDataForm,
      FormElementRadioButtons,
      RegionSelectForm,
      PersonalDataEmailPhoneForm,
      AddressForm,
      PersonalDataForm,
      MilesAndMoreForm,
      GiftSubscriptionLimitSelectionForm,
      TheOverlay,
      BasicButton,
    },
    emits: [
      'updateBusinessData',
      'updatePrivateData',
      'formValidated',
      'updateMilesAndMoreId',
      'changeRecruiterGiftSubscriptionLimit',
      'updateInvoiceAddress',
      'updateDeliveryAddress',
      'deleteInvoiceAddress',
      'deleteDeliveryAddress',
      'updatedPva',
      'ibanUpdated',
    ],
    props: {
      orderFormData: {
        type: Object as PropType<OrderFormData>, // OrderFormData
        required: true,
      },
      requestRegionSelect: {
        type: Boolean,
        required: false,
        default: false,
      },
      countryOptions: {
        type: Array as PropType<{ value: string; text: string }[]>,
        required: true,
      },
      nameIsEditable: {
        type: Boolean,
        required: false,
        default: true,
      },
      addressCheckResult: {
        type: Object as PropType<AddressValidationResult>, // addressValidationResponse
        required: false,
      },
      formDataCurrentlyValidating: {
        type: Boolean,
        required: false,
        default: false,
      },
      requestEmailPhoneNumber: {
        type: Boolean,
        required: false,
        default: false,
      },
      fullAddressRequired: {
        type: Boolean,
        required: false,
        default: false,
      },
      emailIsMandatory: {
        type: Boolean,
        required: true,
      },
      productType: {
        type: String,
        required: true,
      },
      requestMilesAndMoreId: {
        type: Boolean,
        required: false,
        default: false,
      },
      milesAndMoreId: {
        type: String,
        required: false,
        default: '',
      },
      gpSelectionIsEnabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      businessAddress: {
        type: Object as PropType<FormBusinessAddress>,
        required: false,
      },
      deliveryAddressEnabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      deliveryAddress: {
        type: Object as PropType<FormDeviatingAddress>,
        required: false,
      },
      invoiceAddressEnabled: {
        type: Boolean,
        required: false,
        default: false,
      },
      invoiceAddress: {
        type: Object as PropType<FormDeviatingAddress>,
        required: false,
      },
      pva: {
        type: String,
        required: false,
        default: '',
      },
      showGiftSubscriptionLimitSelection: {
        type: Boolean,
        required: false,
        default: false,
      },
      selectedGiftSubscriptionLimit: {
        type: Number,
        required: false,
        default: 0,
      },
      requestIban: {
        type: Boolean,
        required: false,
        default: false,
      },
      ibanData: {
        type: Object as PropType<IbanCoreData>,
        required: false,
      },
    },
    setup(props, { emit }) {
      const isValidating = ref(false);
      const formIsValid = ref(false);
      const milesAndMoreFieldHasError = ref(false);
      const ibanFieldHasError = ref(false);
      const pvaFieldHasError = ref(false);
      const gpType = ref(GpType.PRIVATE);
      const businessAddressSelected = computed(() => {
        return gpType.value === GpType.BUSINESS;
      });
      let gpTypeInitiallySet = false;

      const ibanIsInvalid = computed(() => {
        return props.ibanData
          ? props.ibanData.iban === '' ||
              (props.ibanData.bankCountryCode !==
                commonConfig.domesticCountry &&
                props.ibanData.swift === '')
          : false;
      });

      watch(props, () => {
        if (
          !gpTypeInitiallySet &&
          props.businessAddress &&
          props.businessAddress.companyName !== ''
        ) {
          gpType.value = GpType.BUSINESS;
          gpTypeInitiallySet = true;
        }
      });

      // Preparing Validation: Creating init AddressCheck Object, init Field Error Objects
      const orderFormValidator = new OrderFormValidator();
      const fieldErrorsPrivateAddress = reactive(
        orderFormValidator.createInitialAddressFieldErrors(),
      );
      const fieldErrorsBusinessAddress = reactive(
        orderFormValidator.createInitialAddressFieldErrors(),
      );
      const fieldErrorsDeliveryAddress = reactive(
        orderFormValidator.createInitialAddressFieldErrors(),
      );
      const fieldErrorsInvoiceAddress = reactive(
        orderFormValidator.createInitialAddressFieldErrors(),
      );

      const setFieldErrors = function (
        violations: ViolationFields[],
        fieldErrors: FieldErrorsAddressData,
      ) {
        fieldErrors.companyName =
          violations.indexOf(ViolationFields.COMPANY_NAME) >= 0;
        fieldErrors.firstName =
          violations.indexOf(ViolationFields.FIRST_NAME) >= 0;
        fieldErrors.lastName =
          violations.indexOf(ViolationFields.LAST_NAME) >= 0;
        fieldErrors.salutation =
          violations.indexOf(ViolationFields.SALUTATION) >= 0;
        fieldErrors.email = violations.indexOf(ViolationFields.EMAIL) >= 0;
        fieldErrors.phoneNumber =
          violations.indexOf(ViolationFields.PHONE_NUMBER) >= 0;
        fieldErrors.mobilePhone =
          violations.indexOf(ViolationFields.MOBILE_PHONE) >= 0;
        fieldErrors.businessPhone =
          violations.indexOf(ViolationFields.BUSINESS_PHONE) >= 0;

        fieldErrors.postOfficeBox =
          violations.indexOf(ViolationFields.POST_OFFICE_BOX) >= 0;
        fieldErrors.postOfficeBoxCity =
          violations.indexOf(ViolationFields.POST_OFFICE_BOX_CITY) >= 0;
        fieldErrors.postOfficeBoxZipCode =
          violations.indexOf(ViolationFields.POST_OFFICE_BOX_ZIP_CODE) >= 0;

        fieldErrors.street = violations.indexOf(ViolationFields.STREET) >= 0;
        fieldErrors.streetNumber =
          violations.indexOf(ViolationFields.STREET_NUMBER) >= 0;
        fieldErrors.zipCode = violations.indexOf(ViolationFields.ZIP_CODE) >= 0;
        fieldErrors.city = violations.indexOf(ViolationFields.CITY) >= 0;
      };

      const resetFieldError = function (
        fieldWithError: string,
        addressType: AddressSubType,
      ) {
        switch (addressType) {
          case AddressSubType.PRIVATE_ADDRESS:
            orderFormValidator.resetFieldError(
              fieldErrorsPrivateAddress,
              fieldWithError,
            );
            break;
          case AddressSubType.BUSINESS_ADDRESS:
            orderFormValidator.resetFieldError(
              fieldErrorsBusinessAddress,
              fieldWithError,
            );
            break;
          case AddressSubType.DELIVERY_ADDRESS:
            orderFormValidator.resetFieldError(
              fieldErrorsDeliveryAddress,
              fieldWithError,
            );
            break;
          case AddressSubType.INVOICE_ADDRESS:
            orderFormValidator.resetFieldError(
              fieldErrorsInvoiceAddress,
              fieldWithError,
            );
            break;
        }
      };

      const updateAddressData = function (updatedData: OrderFormData) {
        const data = Object.assign({}, updatedData);
        if (businessAddressSelected.value) {
          emit('updateBusinessData', data);
        } else {
          emit('updatePrivateData', data);
        }
      };

      const updateDistrict = function (newDistrict: string) {
        emit('updatedPva', newDistrict);
      };

      const submit = function () {
        let validationResult: ValidationResultOrderForm;
        isValidating.value = true;

        const address = buildAddressFromFormData(
          props.orderFormData,
          props.businessAddress ? props.businessAddress : null,
          props.deliveryAddress ? props.deliveryAddress : null,
          props.invoiceAddress ? props.invoiceAddress : null,
          businessAddressSelected.value,
        );

        // Validate form values (Frontend-Validation)
        const violations = orderFormValidator.validate(
          address,
          props.fullAddressRequired,
          props.emailIsMandatory,
          props.countryOptions,
        );

        if (props.requestRegionSelect) {
          const regionSelectValidator = new RegionSelectValidator();
          const regionSelectViolations = regionSelectValidator.validate(
            props.pva,
          );
          // For now only 1 Error possible => Length check suffices
          pvaFieldHasError.value = regionSelectViolations.length > 0;
        }

        if (milesAndMoreFieldHasError.value) {
          violations.common.push(ViolationFields.MILES_AND_MORE);
        }

        if (ibanFieldHasError.value) {
          violations.common.push(ViolationFields.IBAN);
        } else if (props.requestIban && ibanIsInvalid.value) {
          violations.common.push(ViolationFields.IBAN);
          ibanFieldHasError.value = true;
        }

        formIsValid.value =
          violations.common.length === 0 &&
          violations.privateAddress.length === 0 &&
          violations.businessAddress.length === 0 &&
          violations.deliveryAddress.length === 0 &&
          violations.invoiceAddress.length === 0 &&
          !pvaFieldHasError.value;

        setFieldErrors(violations.privateAddress, fieldErrorsPrivateAddress);
        setFieldErrors(violations.businessAddress, fieldErrorsBusinessAddress);
        setFieldErrors(violations.deliveryAddress, fieldErrorsDeliveryAddress);
        setFieldErrors(violations.invoiceAddress, fieldErrorsInvoiceAddress);

        // If there are frontend form violations:
        if (!formIsValid.value) {
          validationResult = {
            violations: violations,
            result: ValidationResultValue.FIELD_VIOLATIONS,
            validatedData: address,
          };
        } else {
          if (gpType.value === GpType.PRIVATE && address.privateAddress) {
            normalizePrivateAddressData(address.privateAddress);
            address.invoiceAddress = null;
            address.deliveryAddress = null;
            address.businessAddress = null;
          } else {
            address.privateAddress = null;
            if (address.businessAddress) {
              normalizeBusinessAndDeviatingAddressData(address.businessAddress);
            }
            if (
              props.deliveryAddressEnabled &&
              //redundant, but the compiler would complain otherwise
              address.deliveryAddress &&
              checkIfDeviatingAddressExists(address.deliveryAddress)
            ) {
              normalizeBusinessAndDeviatingAddressData(address.deliveryAddress);
            } else {
              address.deliveryAddress = null;
            }
            if (
              props.invoiceAddressEnabled &&
              address.invoiceAddress &&
              checkIfDeviatingAddressExists(address.invoiceAddress)
            ) {
              normalizeBusinessAndDeviatingAddressData(address.invoiceAddress);
            } else {
              address.invoiceAddress = null;
            }
          }
          // No violations detected by form => emit formdata to parent view for further processing
          validationResult = {
            violations: violations,
            result: ValidationResultValue.SUCCESS,
            validatedData: address,
          };
        }
        emit('formValidated', validationResult);
        isValidating.value = false;
      };

      onMounted(() => {
        if (props.businessAddress && props.businessAddress.companyName) {
          gpType.value = GpType.BUSINESS;
        }
      });

      const updateIbanData = function (newIbanData: IbanCoreData) {
        ibanFieldHasError.value = false;
        emit('ibanUpdated', newIbanData);
      };

      const setGpType = function (newGpType: GpType) {
        gpType.value = newGpType;
      };

      return {
        updateDistrict,
        fieldErrorsPrivateAddress,
        fieldErrorsBusinessAddress,
        fieldErrorsDeliveryAddress,
        fieldErrorsInvoiceAddress,
        submit,
        isValidating,
        updateAddressData,
        resetFieldError,
        milesAndMoreFieldHasError,
        pvaFieldHasError,
        customerTypeOptions,
        gpType,
        businessAddressSelected,
        commonTexts,
        AddressSubType,
        ibanFieldHasError,
        updateIbanData,
        setGpType,
      };
    },
  });
</script>

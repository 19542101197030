import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "display-element-order-bonus-cart__content" }
const _hoisted_2 = { class: "display-element-order-bonus-cart__image" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "display-element-order-bonus-cart__bonus-title" }
const _hoisted_5 = { class: "display-element-order-bonus-cart__price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconImagePlaceholder = _resolveComponent("IconImagePlaceholder")!
  const _component_IconTrashCan = _resolveComponent("IconTrashCan")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.orderBonus.imagePath)
        ? (_openBlock(), _createBlock(_component_IconImagePlaceholder, { key: 0 }))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: _ctx.orderBonus.imagePath,
            alt: ""
          }, null, 8, _hoisted_3))
    ]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.orderBonus.title), 1),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("span", null, _toDisplayString(_ctx.price), 1),
      (!_ctx.nonRemovableBonus)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('removeItem')))
          }, [
            _createVNode(_component_IconTrashCan, { class: "display-element-order-bonus-cart__remove-icon" })
          ]))
        : (_openBlock(), _createBlock(_component_IconTrashCan, {
            key: 1,
            visible: false,
            class: "display-element-order-bonus-cart__remove-icon"
          }))
    ])
  ]))
}
import {
  AddressViolations,
  ValidationResultValue,
} from '@/validators/validatorTypes';
import { FormAddresses } from '@/models/OrderData';

export type BasicAddressData = {
  //basic addressData
  country: string;
  district: string;
  addressType: AddressTypeLiteral;
  //streetAddressData
  street: string;
  streetNumber: string;
  floor: string;
  room: string;
  additionalInfo: string;
  addressAddition: string;
  zipCode: string;
  city: string;
  // postOfficeBox Address
  postOfficeBox: string;
  postOfficeBoxZipCode: string;
  postOfficeBoxCity: string;
};

export type OrderFormData = BasicAddressData & {
  // Personal Data
  firstName: string;
  lastName: string;
  salutation: string;
  title: string;
  // Additional personal data
  email: string;
  phone: string;
  businessPhone: string;
  mobilePhone: string;
  addressNumber: string;
  // Flags
  nameEditable: boolean;
  businessAddress: boolean;
};

// export type OrderFormPersonalData = {
//   firstName: string;
//   lastName: string;
//   salutation: string;
//   title: string;
// };
//
// export type OrderFormAdditionalPersonalData = {
//   email: string;
//   phone: string;
// };

export type OrderFormStreetAddress = {
  street: string;
  streetNumber: string;
  floor: string;
  room: string;
  additionalInfo: string;
  addressAddition: string;
  zipCode: string;
  city: string;
};

// export type OrderFormPostOfficeBoxAddress = {
//   box: string;
//   zipCode: string;
//   city: string;
// };

export enum AddressTypeLiteral {
  POST_OFFICE_BOX = 'Postfachadresse',
  STREET_ADDRESS = 'Straßenadresse',
}

export type ValidationResultOrderForm = {
  violations: AddressViolations;
  result: ValidationResultValue;
  validatedData: FormAddresses;
};

export enum GpType {
  PRIVATE = 'Privatkunden',
  BUSINESS = 'Geschäftskunden',
}

export enum FormDataParsingResult {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  GP_LOCKED = 'GP_LOCKED',
  TRIAL_EXPIRED = 'TRIAL_EXPIRED',
  ORDER_EXISTS = 'ORDER_EXISTS',
  FOLLOW_UP_OFFER = 'FOLLOW_UP_OFFER',
}

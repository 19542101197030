<template>
  <BasicButton
    variant="primary"
    class="mt-0 mb-5"
    @buttonClicked="redirectToAuthentication()"
    :class="{ 'btn-transparent': transparent }"
    ><slot
  /></BasicButton>
</template>

<script lang="ts">
  import AuthService from '@/services/auth-service';
  import GtmService from '@/services/gtm-service';
  import { defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import BasicButton from '@/components/Buttons/BasicButton.vue';

  export default defineComponent({
    name: 'ButtonAuth',
    components: { BasicButton },
    props: {
      type: {
        type: String,
        required: false,
        default: 'login',
      },
      link: {
        type: String,
        required: false,
        default: '',
      },
      transparent: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      const store = useStore();
      const login = async function () {
        const returnUrlLogin = encodeURIComponent(window.location.href);
        const outLink = props.link.replace('${redirect_uri}', returnUrlLogin);
        window.location.replace(outLink);
      };

      const register = async function () {
        const returnUrlRegistration =
          window.location.origin + '/registrationSuccess';

        sessionStorage.setItem(
          'lastName',
          store.state.createOrderResponse.lastName,
        );
        sessionStorage.setItem('gpNr', store.state.createOrderResponse.gpNr);

        const outLink = props.link.replace(
          '${redirect_uri}',
          returnUrlRegistration,
        );
        window.location.replace(outLink);
      };

      const redirectToAuthentication = function () {
        AuthService.resetAlreadyRedirectedToLoginFlag();
        if (props.type === 'login') {
          GtmService.pushGenericEvent({
            category: 'Checkout',
            action: 'Login und Registrierung',
            label: store.state.formData.salesCampaign.salesCampaignId,
          });

          login();
        } else {
          register();
        }
      };

      return {
        redirectToAuthentication,
      };
    },
  });
</script>

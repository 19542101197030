<template>
  <div class="order-details" :class="{ 'order-confirmed': orderConfirmed }">
    <div class="subtitle">
      <span>{{ salesCampaign.name }}</span>
      <BasicButton
        v-if="!orderConfirmed"
        class="btn"
        :class="{
          'not-collapsed': extendCollapsableBox,
          'collapsed': !extendCollapsableBox,
        }"
        type="button"
        variant="none"
        @buttonClicked="extendCollapsableBox = !extendCollapsableBox"
      >
        <IconInfo />
      </BasicButton>
      <!--      <BasicButton-->
      <!--        v-b-toggle.order-collapse-->
      <!--        v-if="!orderConfirmed"-->
      <!--        class="btn collapsed"-->
      <!--        type="button"-->
      <!--        variant="none"-->
      <!--      >-->
      <!--        <IconInfo />-->
      <!--      </BasicButton>-->
    </div>

    <CollapsableBox
      :open="extendCollapsableBox"
      id="order-collapse"
      v-if="!orderConfirmed"
    >
      <div>
        <ul>
          <li
            v-for="(infoPoint, index) in salesCampaign.infoPoints"
            :key="index"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18">
              <g fill="none" fill-rule="evenodd">
                <circle cx="9" cy="9" r="9" fill="#D8EFEC" />
                <path
                  stroke="#3B4157"
                  stroke-linecap="square"
                  stroke-width="1.5"
                  d="M5.5 9.5l2 2 5-5"
                />
              </g>
            </svg>
            <span>
              {{ infoPoint }}
            </span>
          </li>
        </ul>
      </div>
    </CollapsableBox>

    <PriceList
      v-if="showPriceList || showOrderBonusCart"
      :sales-campaign="salesCampaign"
      :bonus-cart="orderBonusCart"
      :display-order-bonus-cart="showOrderBonusCart"
      :show-total="showTotal"
    />

    <div class="cancellation-info" v-if="!orderConfirmed">
      <hr />
      <span v-html="salesCampaign.cancellationInfo"></span>
    </div>
  </div>
</template>
<script lang="ts">
  import PriceList from '@/components/PriceList.vue';
  import IconInfo from '@/components/Icons/IconInfo.vue';
  import { computed, defineComponent, ref } from 'vue';
  import { getPriceListFlags } from '@/composables/general-flags';
  import { useStore } from 'vuex';
  import BasicButton from '@/components/Buttons/BasicButton.vue';
  import CollapsableBox from '@/components/Collapsables/CollapsableBox.vue';

  export default defineComponent({
    name: 'OrderDetails',
    components: {
      CollapsableBox,
      IconInfo,
      PriceList,
      BasicButton,
    },
    props: {
      orderConfirmed: {
        type: Boolean,
        required: false,
        default: false,
      },
      hideOrderBonusCart: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      const store = useStore();
      const extendCollapsableBox = ref(false);

      const content = computed(() => store.getters.content);
      const salesCampaign = computed(
        () => store.getters.formData.salesCampaign,
      );
      const orderBonusCart = computed(() => store.state.orderBonusCart);

      const showOrderBonusCart = computed(() => {
        return !props.hideOrderBonusCart && orderBonusCart.value.length > 0;
      });

      const { showPriceList, showTotal } = getPriceListFlags();

      return {
        content,
        salesCampaign,
        orderBonusCart,
        showOrderBonusCart,
        showPriceList,
        showTotal,
        extendCollapsableBox,
      };
    },
  });
</script>

<template>
  <span> {{ salutationText }} {{ name }} </span>
</template>

<script lang="ts">
  import { user } from '@/services/user-service';
  import { computed, defineComponent, PropType } from 'vue';
  import { OrderFormData } from '@/types/OrderFormTypes';
  import { FormBusinessAddress } from '@/models/OrderData';

  export default defineComponent({
    name: 'DisplayElementUserSalutation',
    props: {
      address: {
        required: true,
        type: Object as PropType<OrderFormData | FormBusinessAddress>,
      },
      showCompanyName: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    setup(props) {
      const salutationText = computed(() =>
        user.salutationText(props.address.salutation),
      );
      const name = computed(() => {
        if (props.showCompanyName && 'companyName' in props.address) {
          let companyName = props.address.companyName;
          if (props.address.companySuffix1) {
            companyName = companyName.concat(' ', props.address.companySuffix1);
          }
          if (props.address.companySuffix2) {
            companyName = companyName.concat(' ', props.address.companySuffix2);
          }
          if (props.address.companySuffix3) {
            companyName = companyName.concat(' ', props.address.companySuffix3);
          }

          return companyName;
        } else if ('firstName' in props.address) {
          const name = props.address.firstName + ' ' + props.address.lastName;

          if (props.address.title && '00' !== props.address.title) {
            return props.address.title + ' ' + name;
          } else {
            return name;
          }
        }
        return '';
      });

      return {
        salutationText,
        name,
      };
    },
  });
</script>

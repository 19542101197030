<template>
  <div class="deviating-address-form">
    <div
      class="deviating-address-form__show-more-button deviating-address-form--clickable"
      @click="showDeviatingAddress = !showDeviatingAddress"
    >
      <IconShowMore :is-closed="!showDeviatingAddress" /><span
        class="deviating-address-form__show-more-label"
        :class="{
          'deviating-address-form__show-more-label--active':
            showDeviatingAddress,
        }"
        >{{ formLabel }}</span
      >
    </div>
    <div
      v-if="showDeviatingAddress"
      class="form-row deviating-address-form__row"
    >
      <FormElementSelectField
        :label="commonTexts.userInterface.formFieldLabels.country"
        class="col-md-12"
        :selectOptions="countryOptions"
        :value="value.country"
        @input="updateCountry($event)"
        :field-has-error="fieldErrorsAddress.country"
        @selectionChanged="$emit('resetFieldError', 'country')"
      />
    </div>
    <div v-if="showDeviatingAddress">
      <FormElementRadioButtons
        :name="`${fieldIdSlug}-addressType`"
        :value="value.addressType"
        @input="updateSelectedAddressType($event)"
        :radioOptions="addressTypeOptions"
        class="mb-4"
      />
    </div>
    <PostOfficeBoxForm
      v-if="showDeviatingAddress && postOfficeBoxSelected"
      :address-data="value"
      :field-errors="fieldErrorsAddress"
      :address-check-result="addressCheckResult"
      :field-id-slug="fieldIdSlug"
      @addressDataInput="$emit('updateDeviatingAddress', $event)"
      @resetFieldError="$emit('resetFieldError', $event)"
    />
    <StreetAddressForm
      v-else-if="showDeviatingAddress && !postOfficeBoxSelected"
      :field-id-slug="fieldIdSlug"
      :address-check-result="addressCheckResult"
      is-business-address
      :address-data="value"
      :field-errors="fieldErrorsAddress"
      @addressDataInput="$emit('updateDeviatingAddress', $event)"
      @resetFieldError="$emit('resetFieldError', $event)"
    />
    <div
      v-if="showDeviatingAddress"
      class="deviating-address-form__remove-data-button"
    >
      <IconTrashCan
        class="deviating-address-form--clickable"
        @click.native="deleteDeviatingAddress"
      /><span
        @click="deleteDeviatingAddress"
        class="deviating-address-form__remove-data-label deviating-address-form--clickable"
        >{{ commonTexts.userInterface.buttons.deleteEntry }}</span
      >
    </div>
  </div>
</template>

<script lang="ts">
  import StreetAddressForm from '@/components/Forms/StreetAddressForm.vue';
  import {
    computed,
    defineComponent,
    onMounted,
    ref,
    watch,
    PropType,
  } from 'vue';
  import IconShowMore from '@/components/Icons/IconShowMore.vue';
  import IconTrashCan from '@/components/Icons/IconTrashCan.vue';
  import commonTexts from '@/data/commonTexts';
  import FormElementSelectField from '@/components/FormElements/FormElementSelectField.vue';
  import FormElementRadioButtons from '@/components/FormElements/FormElementRadioButtons.vue';
  import { AddressTypeLiteral } from '@/types/OrderFormTypes';
  import { addressTypeOptions } from '@/utils/selectOptions';
  import PostOfficeBoxForm from '@/components/Forms/PostOfficeBoxForm.vue';
  import {
    FormDeviatingAddress,
    InternalSingleAddressValidationResult,
  } from '@/models/OrderData';
  import { FieldErrorsAddressData } from '@/validators/validatorTypes';

  export default defineComponent({
    name: 'DeviatingAddressForm',
    components: {
      PostOfficeBoxForm,
      FormElementRadioButtons,
      FormElementSelectField,
      IconTrashCan,
      IconShowMore,
      StreetAddressForm,
    },
    emits: [
      'updateDeviatingAddress',
      'deleteDeviatingAddress',
      'resetFieldError',
    ],
    props: {
      value: {
        type: Object as PropType<FormDeviatingAddress>,
        required: true,
      },
      formLabel: {
        type: String,
        required: true,
      },
      addressCheckResult: {
        type: Object as PropType<InternalSingleAddressValidationResult>,
        required: false,
      },
      fieldErrorsAddress: {
        type: Object as PropType<FieldErrorsAddressData>,
        required: true,
      },
      countryOptions: {
        type: Array,
        required: true,
      },
      fieldIdSlug: {
        type: String,
        required: false,
        default: '',
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setup(props: any, context: any) {
      const showDeviatingAddress = ref(false);

      onMounted(() => {
        showDeviatingAddress.value =
          props.value.street !== '' || props.value.postOfficeBox !== '';
      });

      watch(props, () => {
        const fieldErrorValues: boolean[] = Object.values(
          props.fieldErrorsAddress,
        );
        if (
          props.addressCheckResult.hasErrors ||
          props.addressCheckResult.corrected ||
          fieldErrorValues.some((errorValue) => errorValue)
        ) {
          showDeviatingAddress.value = true;
        }
      });

      const deleteDeviatingAddress = function () {
        showDeviatingAddress.value = false;
        context.emit('deleteDeviatingAddress');
      };

      const updateCountry = function (newValue: string) {
        const data = Object.assign({}, props.value);
        data.country = newValue;
        context.emit('updateDeviatingAddress', data);
      };

      const updateSelectedAddressType = function (
        newAddressType: AddressTypeLiteral,
      ) {
        const data = Object.assign({}, props.value);
        data.addressType = newAddressType;
        context.emit('updateDeviatingAddress', data);
      };

      const postOfficeBoxSelected = computed(
        () => props.value.addressType === AddressTypeLiteral.POST_OFFICE_BOX,
      );

      return {
        deleteDeviatingAddress,
        showDeviatingAddress,
        commonTexts,
        updateCountry,
        updateSelectedAddressType,
        addressTypeOptions,
        postOfficeBoxSelected,
      };
    },
  });
</script>

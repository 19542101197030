import store from '@/store';
import router from '@/router';
import tenants from '@/data/tenants.json';
import * as content from '@/data/content';
import { useCookies } from 'vue3-cookies';

export default class SetupService {
  public static async setParameters() {
    await router.isReady();
    const currentQuery = router.currentRoute.value.query;
    const wa = currentQuery.wa as string;
    const oid = currentQuery.oid as string;
    const wt = currentQuery.wt as string;
    const campaign = currentQuery.campaign as string;
    const ktnr = currentQuery.ktnr as string;
    const sourceUrl = (currentQuery.sourceUrl ||
      currentQuery.sourceurl) as string;
    const pianoTrackingId = currentQuery._ptid as string;
    const authScreen =
      currentQuery.screen && currentQuery.screen === 'register'
        ? 'registration'
        : 'default';

    if (wa || oid) {
      const parameters = {
        wa: wa,
        offerId: oid,
        advertisingMedium: wt,
        campaign: campaign,
        ktnr: ktnr,
        sourceUrl: sourceUrl,
        pianoTrackingId,
        authScreen,
      };
      store.commit('changeParameters', parameters);
    }
  }

  public static getHostname() {
    return window.location.hostname;
  }
  public static getDomain() {
    return this.getHostname().split('.').slice(1).join('.');
  }

  public static getTenantId() {
    const domain = this.getDomain();
    const tenant = tenants.find((x) =>
      x.domains.find((y) => y.domainName === domain),
    );
    const urlParameterTenant = router.currentRoute.value.query.tenant;
    if (tenant) {
      return tenant.name;
    } else if (urlParameterTenant) {
      // make use of url parameter as fallback for localdomain
      return tenant;
    }
  }

  public static setTenant() {
    const tenant = this.getTenantId();
    store.commit('changeTenant', tenant);
    document.documentElement.className = store.state.tenant;
  }

  public static changeFavicon() {
    const tenant = store.state.tenant;
    const url = `/${tenant}/favicon.ico`;
    const iconElement = document.querySelector(
      "link[rel*='icon']",
    ) as HTMLLinkElement;
    if (iconElement) {
      iconElement.href = url;
    }
  }

  public static getContent() {
    const tenant: keyof typeof content = store.state
      .tenant as keyof typeof content;
    return content[tenant];
  }

  public static changeMetaTitle() {
    document.title = this.getContent().meta.title;
  }

  public static isHostGtmIdAvailable() {
    const tenant = this.getTenantId();
    const host = this.getHostname();

    return tenants
      .find((x) => x.name === tenant)
      ?.domains.find((y) => y.domainName === host)?.gtmId;
  }

  public static getGtmId() {
    const tenant = this.getTenantId();
    const domainRef = this.isHostGtmIdAvailable()
      ? this.getHostname()
      : this.getDomain();

    return tenants
      .find((x) => x.name === tenant)
      ?.domains.find((y) => y.domainName === domainRef)?.gtmId;
  }

  public static cleanOrderDataCookies() {
    const { cookies } = useCookies();
    cookies.remove('osc-data');
  }
}

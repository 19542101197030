import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "appOrder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheOverlay = _resolveComponent("TheOverlay")!
  const _component_TheHeader = _resolveComponent("TheHeader")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ThePageBody = _resolveComponent("ThePageBody")!
  const _component_TheFooter = _resolveComponent("TheFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TheOverlay, {
      show: _ctx.registrationMandatory && !_ctx.isLoggedIn,
      opacity: 1
    }, null, 8, ["show"]),
    _createVNode(_component_TheHeader),
    (_ctx.isLoggedIn || !_ctx.registrationMandatory)
      ? (_openBlock(), _createBlock(_component_ThePageBody, { key: 0 }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_router_view)
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_TheFooter)
  ]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "iban-field-form__content-headline" }
const _hoisted_2 = { class: "form-element-iban-field form-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementSelectField = _resolveComponent("FormElementSelectField")!
  const _component_FormElementInputField = _resolveComponent("FormElementInputField")!
  const _component_TheOverlay = _resolveComponent("TheOverlay")!

  return (_openBlock(), _createBlock(_component_TheOverlay, {
    class: "vld-parent",
    isFullPage: false,
    show: _ctx.isValidating
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.commonTexts.userFrontend.cashBonus.displayElement.headline), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FormElementSelectField, {
          value: _ctx.swiftIban.bankCountryCode,
          "select-options": _ctx.countryOptions,
          embedded: "",
          class: "col-md-2",
          onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.swiftIban.bankCountryCode = $event))
        }, null, 8, ["value", "select-options"]),
        _createVNode(_component_FormElementInputField, {
          value: _ctx.swiftIban.iban,
          "field-has-error": _ctx.hasError,
          label: "IBAN",
          maxlength: "32",
          onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateIban())),
          class: "col-md-10 mt-1 mt-md-0",
          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.swiftIban.iban = $event))
        }, null, 8, ["value", "field-has-error"]),
        (_ctx.ibanData.bankCountryCode !== _ctx.commonConfig.domesticCountry)
          ? (_openBlock(), _createBlock(_component_FormElementInputField, {
              key: 0,
              value: _ctx.swiftIban.swift,
              "field-has-error": _ctx.hasError,
              label: _ctx.commonTexts.userInterface.formFieldLabels.swift,
              maxlength: "15",
              class: "col-12 mt-1",
              onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.swiftIban.swift = $event))
            }, null, 8, ["value", "field-has-error", "label"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["show"]))
}
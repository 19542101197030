import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "osc-order-form" }
const _hoisted_2 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementRadioButtons = _resolveComponent("FormElementRadioButtons")!
  const _component_PersonalBusinessDataForm = _resolveComponent("PersonalBusinessDataForm")!
  const _component_PersonalDataForm = _resolveComponent("PersonalDataForm")!
  const _component_AddressForm = _resolveComponent("AddressForm")!
  const _component_DeviatingAddressForm = _resolveComponent("DeviatingAddressForm")!
  const _component_PersonalDataEmailPhoneForm = _resolveComponent("PersonalDataEmailPhoneForm")!
  const _component_RegionSelectForm = _resolveComponent("RegionSelectForm")!
  const _component_FormElementIbanField = _resolveComponent("FormElementIbanField")!
  const _component_MilesAndMoreForm = _resolveComponent("MilesAndMoreForm")!
  const _component_GiftSubscriptionLimitSelectionForm = _resolveComponent("GiftSubscriptionLimitSelectionForm")!
  const _component_BasicButton = _resolveComponent("BasicButton")!
  const _component_TheOverlay = _resolveComponent("TheOverlay")!
  const _component_FormWrapper = _resolveComponent("FormWrapper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormWrapper, null, {
      default: _withCtx(() => [
        (_ctx.gpSelectionIsEnabled && _ctx.nameIsEditable)
          ? (_openBlock(), _createBlock(_component_FormElementRadioButtons, {
              key: 0,
              "radio-options": _ctx.customerTypeOptions,
              value: _ctx.gpType,
              onInput: _ctx.setGpType,
              class: "margin-bottom-large"
            }, null, 8, ["radio-options", "value", "onInput"]))
          : _createCommentVNode("", true),
        (_ctx.businessAddressSelected && _ctx.businessAddress)
          ? (_openBlock(), _createBlock(_component_PersonalBusinessDataForm, {
              key: 1,
              class: "mb-4",
              "name-editable": _ctx.nameIsEditable,
              value: _ctx.businessAddress,
              "field-errors": _ctx.fieldErrorsBusinessAddress,
              onResetFieldError: _cache[0] || (_cache[0] = ($event: any) => (
          _ctx.resetFieldError($event, _ctx.AddressSubType.BUSINESS_ADDRESS)
        )),
              onPersonalDataInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateAddressData($event)))
            }, null, 8, ["name-editable", "value", "field-errors"]))
          : (_openBlock(), _createBlock(_component_PersonalDataForm, {
              key: 2,
              ref: "personalDataForm",
              class: "mb-4",
              value: _ctx.orderFormData,
              "name-editable": _ctx.nameIsEditable,
              "field-errors": _ctx.fieldErrorsPrivateAddress,
              "product-type": _ctx.productType,
              onPersonalDataInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateAddressData($event))),
              onResetFieldError: _cache[3] || (_cache[3] = ($event: any) => (
          _ctx.resetFieldError($event, _ctx.AddressSubType.PRIVATE_ADDRESS)
        ))
            }, null, 8, ["value", "name-editable", "field-errors", "product-type"])),
        _createVNode(_component_AddressForm, {
          class: "mt-4",
          "process-business-address": _ctx.businessAddressSelected,
          "order-form-data": _ctx.orderFormData,
          "field-errors": _ctx.fieldErrorsPrivateAddress,
          "field-errors-business": _ctx.fieldErrorsBusinessAddress,
          "address-check-result": _ctx.addressCheckResult,
          "address-required": _ctx.fullAddressRequired,
          "country-options": _ctx.countryOptions,
          "product-type": _ctx.productType,
          "business-address": _ctx.businessAddress,
          onAddressDataInput: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateAddressData($event))),
          onResetFieldError: _cache[5] || (_cache[5] = ($event: any) => (
          _ctx.resetFieldError($event, _ctx.AddressSubType.PRIVATE_ADDRESS)
        )),
          onResetFieldErrorBusiness: _cache[6] || (_cache[6] = ($event: any) => (
          _ctx.resetFieldError($event, _ctx.AddressSubType.BUSINESS_ADDRESS)
        ))
        }, null, 8, ["process-business-address", "order-form-data", "field-errors", "field-errors-business", "address-check-result", "address-required", "country-options", "product-type", "business-address"]),
        (
          _ctx.businessAddressSelected && _ctx.deliveryAddressEnabled && _ctx.deliveryAddress
        )
          ? (_openBlock(), _createBlock(_component_DeviatingAddressForm, {
              key: 3,
              "field-id-slug": "delivery",
              "address-check-result": _ctx.addressCheckResult?.deliveryAddress,
              "field-errors-address": _ctx.fieldErrorsDeliveryAddress,
              value: _ctx.deliveryAddress,
              "country-options": _ctx.countryOptions,
              "form-label": 
          _ctx.commonTexts.userInterface.formFieldLabels.deliveryAddressFormLabel
        ,
              onUpdateDeviatingAddress: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('updateDeliveryAddress', $event))),
              onDeleteDeviatingAddress: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('deleteDeliveryAddress'))),
              onResetFieldError: _cache[9] || (_cache[9] = ($event: any) => (
          _ctx.resetFieldError($event, _ctx.AddressSubType.DELIVERY_ADDRESS)
        )),
              class: "mt-4"
            }, null, 8, ["address-check-result", "field-errors-address", "value", "country-options", "form-label"]))
          : _createCommentVNode("", true),
        (
          _ctx.businessAddressSelected && _ctx.invoiceAddressEnabled && _ctx.invoiceAddress
        )
          ? (_openBlock(), _createBlock(_component_DeviatingAddressForm, {
              key: 4,
              "field-id-slug": "invoice",
              "address-check-result": _ctx.addressCheckResult?.invoiceAddress,
              "field-errors-address": _ctx.fieldErrorsInvoiceAddress,
              value: _ctx.invoiceAddress,
              "country-options": _ctx.countryOptions,
              "form-label": 
          _ctx.commonTexts.userInterface.formFieldLabels.invoiceAddressFormLabel
        ,
              onUpdateDeviatingAddress: _cache[10] || (_cache[10] = ($event: any) => (_ctx.$emit('updateInvoiceAddress', $event))),
              onDeleteDeviatingAddress: _cache[11] || (_cache[11] = ($event: any) => (_ctx.$emit('deleteInvoiceAddress'))),
              onResetFieldError: _cache[12] || (_cache[12] = ($event: any) => (
          _ctx.resetFieldError($event, _ctx.AddressSubType.INVOICE_ADDRESS)
        ))
            }, null, 8, ["address-check-result", "field-errors-address", "value", "country-options", "form-label"]))
          : _createCommentVNode("", true),
        (_ctx.requestEmailPhoneNumber && _ctx.businessAddress)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.businessAddressSelected)
                ? (_openBlock(), _createBlock(_component_PersonalDataEmailPhoneForm, {
                    key: 0,
                    value: _ctx.businessAddress,
                    "field-errors": _ctx.fieldErrorsBusinessAddress,
                    "selected-country": _ctx.businessAddress.country,
                    onPersonalDataInput: _cache[13] || (_cache[13] = ($event: any) => (_ctx.updateAddressData($event))),
                    onResetFieldError: _cache[14] || (_cache[14] = ($event: any) => (
            _ctx.resetFieldError($event, _ctx.AddressSubType.BUSINESS_ADDRESS)
          ))
                  }, null, 8, ["value", "field-errors", "selected-country"]))
                : (_openBlock(), _createBlock(_component_PersonalDataEmailPhoneForm, {
                    key: 1,
                    value: _ctx.orderFormData,
                    "field-errors": _ctx.fieldErrorsPrivateAddress,
                    "selected-country": _ctx.orderFormData.country,
                    onPersonalDataInput: _cache[15] || (_cache[15] = ($event: any) => (_ctx.updateAddressData($event))),
                    onResetFieldError: _cache[16] || (_cache[16] = ($event: any) => (
            _ctx.resetFieldError($event, _ctx.AddressSubType.PRIVATE_ADDRESS)
          ))
                  }, null, 8, ["value", "field-errors", "selected-country"]))
            ]))
          : _createCommentVNode("", true),
        (_ctx.requestRegionSelect)
          ? (_openBlock(), _createBlock(_component_RegionSelectForm, {
              key: 6,
              value: _ctx.pva,
              "has-error": _ctx.pvaFieldHasError,
              onInput: _cache[17] || (_cache[17] = ($event: any) => (_ctx.updateDistrict($event))),
              class: "mt-4"
            }, null, 8, ["value", "has-error"]))
          : _createCommentVNode("", true),
        (_ctx.requestIban && _ctx.ibanData)
          ? (_openBlock(), _createBlock(_component_FormElementIbanField, {
              key: 7,
              "iban-data": _ctx.ibanData,
              "has-error": _ctx.ibanFieldHasError,
              onIbanUpdated: _cache[18] || (_cache[18] = ($event: any) => (_ctx.updateIbanData($event))),
              onIbanInvalid: _cache[19] || (_cache[19] = ($event: any) => (_ctx.ibanFieldHasError = true))
            }, null, 8, ["iban-data", "has-error"]))
          : _createCommentVNode("", true),
        (_ctx.requestMilesAndMoreId)
          ? (_openBlock(), _createBlock(_component_MilesAndMoreForm, {
              key: 8,
              value: _ctx.milesAndMoreId,
              onUpdateMilesAndMoreId: _cache[20] || (_cache[20] = ($event: any) => (_ctx.$emit('updateMilesAndMoreId', $event))),
              onHasError: _cache[21] || (_cache[21] = ($event: any) => (_ctx.milesAndMoreFieldHasError = $event))
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        (_ctx.showGiftSubscriptionLimitSelection)
          ? (_openBlock(), _createBlock(_component_GiftSubscriptionLimitSelectionForm, {
              key: 9,
              class: "margin-top-xl",
              onChangeRecruiterGiftSubscriptionLimit: _cache[22] || (_cache[22] = ($event: any) => (
          _ctx.$emit('changeRecruiterGiftSubscriptionLimit', $event)
        )),
              value: _ctx.selectedGiftSubscriptionLimit
            }, null, 8, ["value"]))
          : _createCommentVNode("", true),
        _createVNode(_component_TheOverlay, {
          class: "d-inline-block w-100",
          isFullPage: false,
          show: _ctx.isValidating || _ctx.formDataCurrentlyValidating,
          opacity: 0.3,
          "spiner-size": 20
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BasicButton, {
              disabled: _ctx.isValidating || _ctx.formDataCurrentlyValidating,
              variant: "primary",
              class: "main-content-button",
              onButtonClicked: _ctx.submit
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.commonTexts.userInterface.buttons.proceed), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onButtonClicked"])
          ]),
          _: 1
        }, 8, ["show"])
      ]),
      _: 1
    })
  ]))
}
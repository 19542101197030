import { InternalAdminConnectorInterface } from '@/services/connectors/interfaces';
import { AdminData, AdminUserData } from '@/types/AdminUserRole';
import { internalAPI } from '@/services/http-service';
import {
  AdminSalesCampaignApprovalListData,
  BasicSalesCampaignData,
} from '@/types/AdminSalesCampaignData';
import {
  SalesCampaignDetailsData,
  SingleSalesCampaignData,
  SingleSalesCampaignResponseData,
} from '@/models/SalesCampaigns';

export class InternalAdminConnector implements InternalAdminConnectorInterface {
  async getAdminData(): Promise<AdminData> {
    const response = await internalAPI.get('home');
    return response.data;
  }
  async getAdminUserData(url: string): Promise<AdminUserData> {
    const response = await internalAPI.get(url);
    return response.data;
  }
  async addNewUser(url: string, newUserMail: string): Promise<string> {
    const response = await internalAPI.post(url, newUserMail, {
      headers: { 'Content-Type': 'text/plain' },
    });
    return response.data;
  }
  async deleteUser(url: string, selectedUser: string): Promise<void> {
    return internalAPI.delete(url, { data: selectedUser });
  }
  getSalesCampaignsForTenant(): Promise<BasicSalesCampaignData[]> {
    return internalAPI.get('/tenant/salesCampaigns').then((response) => {
      return response.data;
    });
  }
  getSalesCampaignById(
    salesCampaignId: string,
  ): Promise<SingleSalesCampaignResponseData> {
    return internalAPI
      .get('/tenant/salesCampaign/' + salesCampaignId)
      .then((response) => {
        return response.data;
      });
  }
  saveSingleSalesCampaign(
    salesCampaignData: SingleSalesCampaignData | SalesCampaignDetailsData,
  ): Promise<void> {
    return internalAPI.post(
      '/tenant/salesCampaign',
      JSON.stringify(salesCampaignData),
      {
        headers: { 'Content-Type': 'application/json' },
      },
    );
  }
  saveApprovedSalesCampaigns(
    selectedTenant: string,
    selectedSalesCampaigns: string[],
  ): Promise<void> {
    return internalAPI.post(
      'admin/approvedSalesCampaigns/' + selectedTenant,
      selectedSalesCampaigns,
    );
  }
  async getSalesCampaignsApprovalList(
    selectedTenant: string,
  ): Promise<AdminSalesCampaignApprovalListData> {
    const response = await internalAPI.get(
      'admin/availableSalesCampaigns/' + selectedTenant,
    );
    return response.data;
  }
}

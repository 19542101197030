import { DataStoreConnectorInterface } from '@/services/connectors/interfaces';
import { FormDataToken } from '@/models/BaseTypes';
import {
  readDataFromClientStorage,
  removeStorageItemFromClientStorage,
  writeDataToClientStorage,
} from '@/composables/client-storage';

export class DataStoreConnectorStub implements DataStoreConnectorInterface {
  private static DATA_STORE_STUB_STORAGE_KEY = 'dummyDataStorage';
  retrieveData(formDataToken: FormDataToken | null): Promise<string> {
    return new Promise<string>((resolve) => {
      console.log('Retrieving Store from Session Storage');
      console.log('Token: ', formDataToken);

      const result = readDataFromClientStorage(
        DataStoreConnectorStub.DATA_STORE_STUB_STORAGE_KEY,
      );
      removeStorageItemFromClientStorage(
        DataStoreConnectorStub.DATA_STORE_STUB_STORAGE_KEY,
      );
      resolve(result ? result : '');
    });
  }

  storeData(data: string, formDataToken: FormDataToken | null): Promise<void> {
    console.log('Persisting Store in Session Storage');
    console.log('Token: ', formDataToken);

    return new Promise<void>((resolve) => {
      writeDataToClientStorage(
        DataStoreConnectorStub.DATA_STORE_STUB_STORAGE_KEY,
        data,
      );
      resolve();
    });
  }
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "navbar-progress" }
const _hoisted_2 = ["data-step"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(_ctx.commonText.userFrontend.orderBonusSelectionView.orderProgress), 1),
    _createElementVNode("div", {
      class: "dots",
      "data-step": _ctx.step
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (i) => {
        return (_openBlock(), _createElementBlock("span", {
          key: i,
          class: "dot"
        }))
      }), 128))
    ], 8, _hoisted_2)
  ]))
}
import { DateTime } from 'luxon';
import store from '@/store';

const LOGIN_REDIRECT_OFFSET_IN_MILLIS = 30000;

export function scrollTo(id: string) {
  const elementToScrollTo = document.getElementById(id);
  if (elementToScrollTo !== null) {
    elementToScrollTo.scrollIntoView({
      behavior: 'smooth',
    });
  }
}

export function setAlreadyRedirectedToLoginFlag() {
  const timeStamp = DateTime.now().toMillis().toString(10);
  window.sessionStorage.setItem('alreadyRedirectedToLogin', timeStamp);
}

export function removeAlreadyRedirectedToLoginFlag() {
  window.sessionStorage.removeItem('alreadyRedirectedToLogin');
}

export function getAlreadyRedirectedToLoginFlag() {
  return window.sessionStorage.getItem('alreadyRedirectedToLogin');
}

export function alreadyRedirectedToLoginBeforeOffset() {
  const timeFromFlag = getAlreadyRedirectedToLoginFlag();
  if (timeFromFlag !== null) {
    const redirectTime = DateTime.fromMillis(parseFloat(timeFromFlag));
    const now = DateTime.now();
    return (
      now.diff(redirectTime).milliseconds >= LOGIN_REDIRECT_OFFSET_IN_MILLIS
    );
  } else {
    return true;
  }
}

export function showFixedStartDate() {
  const salesCampaign = store.getters.formData.salesCampaign;
  return (
    (salesCampaign.subscriptionIssueStartNumber !== null &&
      salesCampaign.subscriptionIssueStartNumber !== '') ||
    (salesCampaign.subscriptionStartDate !== null &&
      salesCampaign.subscriptionStartDate !== '')
  );
}

export function getFixedStartDate() {
  const salesCampaign = store.getters.formData.salesCampaign;
  if (salesCampaign.subscriptionStartDate !== null) {
    return salesCampaign.subscriptionStartDate;
  }
  return undefined;
}
export function getFixedStartIssue() {
  const salesCampaign = store.getters.formData.salesCampaign;
  if (salesCampaign.subscriptionIssueStartNumber !== null) {
    return salesCampaign.subscriptionIssueStartNumber;
  }
  return undefined;
}

<template>
  <div class="osc-name-form">
    <div class="form-row form-group mb-4">
      <FormElementSelectField
        v-if="nameEditable"
        class="col-md-6"
        :label="commonTexts.userInterface.formFieldLabels.salutation"
        :selectOptions="salutationOptions"
        :value="value.salutation"
        @input="updatePersonalData(formFields.SALUTATION, $event)"
        :field-has-error="fieldErrorSalutation"
        @selectionChanged="resetFieldError('salutation')"
      />
      <FormElementSelectField
        v-if="nameEditable && !isDigitalProductType"
        class="col-md-6 mt-4 mt-md-0"
        :label="commonTexts.userInterface.formFieldLabels.title"
        :selectOptions="titleOptions"
        :value="value.title"
        @input="updatePersonalData(formFields.TITLE, $event)"
      />
    </div>
    <div class="form-row">
      <DisplayElementUserSalutation
        :address="value"
        v-if="!nameEditable"
        class="col-md-12 mb-4"
      />
      <FormElementInputField
        v-if="nameEditable"
        :label="commonTexts.userInterface.formFieldLabels.firstName"
        :value="value.firstName"
        @input="updatePersonalData(formFields.FIRST_NAME, $event)"
        :field-has-error="fieldErrorFirstname"
        @focus="resetFieldError('firstName')"
        class="col-md-12 mb-4"
        maxlength="35"
      />
      <FormElementInputField
        v-if="nameEditable"
        :label="commonTexts.userInterface.formFieldLabels.lastName"
        :value="value.lastName"
        @input="updatePersonalData(formFields.LAST_NAME, $event)"
        :field-has-error="fieldErrorLastName"
        @focus="resetFieldError('lastName')"
        class="col-md-12"
        maxlength="35"
      />
    </div>
  </div>
</template>

<script lang="ts">
  import FormElementSelectField from '@/components/FormElements/FormElementSelectField.vue';
  import FormElementInputField from '@/components/FormElements/FormElementInputField.vue';
  import {
    computed,
    ComputedRef,
    defineComponent,
    PropType,
    reactive,
    ref,
    watch,
  } from 'vue';
  import { salutationOptions, titleOptions } from '@/utils/selectOptions';
  import {
    FieldErrorsAddressData,
    ViolationFields,
  } from '@/validators/validatorTypes';
  import DisplayElementUserSalutation from '@/components/DisplayElements/DisplayElementUserSalutation.vue';
  import PrintProductTypeLiteral from '@/utils/printProductTypeLiteral';
  import commonTexts from '@/data/commonTexts';
  import { OrderFormData } from '@/types/OrderFormTypes';

  export default defineComponent({
    name: 'PersonalDataForm',
    components: {
      DisplayElementUserSalutation,
      FormElementInputField,
      FormElementSelectField,
    },
    props: {
      value: {
        type: Object as PropType<OrderFormData>,
        required: true,
      },
      fieldErrors: {
        type: Object as PropType<FieldErrorsAddressData>,
        required: true,
      },
      nameEditable: {
        type: Boolean,
        required: false,
        default: true,
      },
      productType: {
        type: String,
        required: true,
      },
    },
    emits: ['personalDataInput', 'resetFieldError'],
    setup(props, { emit }) {
      enum formFields {
        SALUTATION = 'salutation',
        TITLE = 'title',
        FIRST_NAME = 'firstName',
        LAST_NAME = 'lastName',
      }

      const fieldErrorFirstname = ref(false);
      const fieldErrorSalutation = ref(false);
      const fieldErrorLastName = ref(false);
      const isDigitalProductType: ComputedRef<boolean> = computed(
        () => props.productType === PrintProductTypeLiteral.DIGITAL,
      );

      const fieldErrors: FieldErrorsAddressData = reactive(props.fieldErrors);
      watch(fieldErrors, (newValue: FieldErrorsAddressData) => {
        fieldErrorFirstname.value = newValue.firstName;
        fieldErrorLastName.value = newValue.lastName;
        fieldErrorSalutation.value = newValue.salutation;
      });

      const updatePersonalData = function (
        field: formFields,
        newValue: string,
      ) {
        const data = Object.assign({}, props.value);
        switch (field) {
          case formFields.SALUTATION:
            data.salutation = newValue;
            break;
          case formFields.TITLE:
            data.title = newValue;
            break;
          case formFields.FIRST_NAME:
            data.firstName = newValue;
            break;
          case formFields.LAST_NAME:
            data.lastName = newValue;
            break;
        }

        emit('personalDataInput', data);
      };

      const resetFieldError = function (field: string) {
        switch (field) {
          case formFields.SALUTATION:
            emit('resetFieldError', ViolationFields.SALUTATION);
            break;
          case formFields.FIRST_NAME:
            emit('resetFieldError', ViolationFields.FIRST_NAME);
            break;
          case formFields.LAST_NAME:
            emit('resetFieldError', ViolationFields.LAST_NAME);
            break;
        }
      };

      return {
        salutationOptions,
        titleOptions,
        formFields,
        updatePersonalData,
        fieldErrorSalutation,
        fieldErrorFirstname,
        fieldErrorLastName,
        isDigitalProductType,
        resetFieldError,
        commonTexts,
      };
    },
  });
</script>

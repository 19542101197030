import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import OrderPage from '@/views/neubestellung/abo/Order.vue';

// Vue.use(VueRouter);

const routes: Array<RouteRecordRaw> = [
  {
    path: '/neubestellung/abo/',
    name: 'Start',
    component: OrderPage,
  },
  {
    path: '/neubestellung/abo/payment',
    name: 'PaymentPage',
    component: () =>
      import(
        /* webpackChunkName: "payment" */ '../views/neubestellung/abo/Payment.vue'
      ),
  },
  {
    path: '/neubestellung/abo/verification',
    name: 'VerificationPage',
    component: () =>
      import(
        /* webpackChunkName: "verification" */ '../views/neubestellung/abo/Verification.vue'
      ),
  },
  {
    path: '/neubestellung/abo/success',
    name: 'SuccessPage',
    component: () =>
      import(
        /* webpackChunkName: "success" */ '../views/neubestellung/abo/Success.vue'
      ),
  },
  {
    path: '/registrationSuccess',
    name: 'RegistrationSuccess',
    component: () =>
      import(
        /* webpackChunkName: "success" */ '../views/neubestellung/abo/RegistrationSuccess.vue'
      ),
  },
  {
    path: '/paypal/agreement/result',
    name: 'PayPalAgreementResult',
    component: () =>
      import(
        /* webpackChunkName: "PayPalAgreementResult" */ '../views/PayPalAgreementResult.vue'
      ),
  },
  {
    path: '/card/agreement/result',
    name: 'CardAgreementResult',
    component: () =>
      import(
        /* webpackChunkName: "CardAgreementResult" */ '../views/CardAgreementResult.vue'
      ),
  },
  {
    path: '/card/oneoffpay/result',
    name: 'CardOneOffPayResult',
    component: () =>
      import(
        /* webpackChunkName: "CardOneOffPayResult" */ '../views/CardOneOffPayResult.vue'
      ),
  },
  {
    path: '/neubestellung/abo/orderexists',
    name: 'OrderExists',
    component: () =>
      import(
        /* webpackChunkName: "orderexists" */ '../views/neubestellung/abo/OrderExists.vue'
      ),
  },
  {
    path: '/neubestellung/abo/FollowUpOffer',
    name: 'FollowUpOffer',
    component: () =>
      import(
        /* webpackChunkName: "FollowUpOrderConfirmation" */ '../views/error/FollowUpOffer.vue'
      ),
  },
  {
    path: '/neubestellung/abo/error/:type',
    name: 'ErrorPage',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "error" */ '../views/neubestellung/abo/Error.vue'
      ),
  },
  {
    path: '/accountlocked',
    name: 'GpLocked',
    component: () => import('../views/error/GpLocked.vue'),
  },
  {
    path: '/internal',
    name: 'Internal',
    component: () =>
      import(
        /* webpackChunkName: "internaloverview" */ '../views/internal/InternalOverview.vue'
      ),
  },
  {
    // path: '/internal/users',
    path: '/internal/userManagement/:userType',
    name: 'UserManagement',
    component: () =>
      import(
        /* webpackChunkName: "usermanagement" */ '../views/internal/UserManagement.vue'
      ),
  },
  {
    path: '/internal/SalesCampaignsApproval',
    name: 'SalesCampaignsApproval',
    component: () =>
      import(
        /* webpackChunkName: "salescampaignsapproval" */ '../views/internal/SalesCampaignsApproval.vue'
      ),
  },
  {
    path: '/internal/salesCampaignsOverview',
    name: 'SalesCampaignsOverview',
    component: () =>
      import(
        /* webpackChunkName: "salescampaignsoverview" */ '../views/internal/SalesCampaignsOverview.vue'
      ),
  },
  {
    path: '/internal/salesCampaignCustomizing/:salesCampaignId',
    name: 'SalesCampaignCustomizing',
    component: () =>
      import(
        /* webpackChunkName: "salescampaigncustomizing" */ '../views/internal/SalesCampaignCustomizing.vue'
      ),
  },
  {
    path: '/internal/orderBonusesOverview',
    name: 'OrderBonusesOverview',
    component: () => import('../views/internal/OrderBonusesOverview.vue'),
  },
  {
    path: '/internal/orderBonusCustomizing/:materialNumber',
    name: 'OrderBonusCustomizing',
    component: () => import('../views/internal/OrderBonusCustomizing.vue'),
  },
  {
    path: '/internal/complexOrderBonus/neuAnlage',
    name: 'ComplexOrderBonusCreation',
    component: () => import('../views/internal/ComplexOrderBonusCreation.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/neubestellung/abo/',
  },
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    const wrapperElement = document.querySelector('#wrapper');
    if (wrapperElement) {
      wrapperElement.scrollTop = 0;
    }
  },
  routes,
});

// navigation guard to ensure valid formData
router.beforeEach((to, from, next) => {
  const guardedRoutes = ['PaymentPage', 'VerificationPage'];
  if (guardedRoutes.includes(to.name as string) && !store.state.isFormValid) {
    router.push({ name: 'Start' });
  } else {
    next();
  }
});

export default router;

<template>
  <div
    id="order-collapse"
    :class="{
      'collapsable-box': true,
      'collapsable-box--open': open,
    }"
  >
    <div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'CollapsableBox',
    props: {
      open: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  });
</script>

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormElementInputField = _resolveComponent("FormElementInputField")!
  const _component_FormSection = _resolveComponent("FormSection")!

  return (_openBlock(), _createBlock(_component_FormSection, { class: "form-element-phone-number" }, {
    default: _withCtx(() => [
      _createVNode(_component_FormElementInputField, {
        "without-label": "",
        readonly: "",
        value: _ctx.countryCode,
        class: "col-md-3 col-sm-6 col-6 form-element-phone-number__field",
        "field-id": 
        _ctx.commonTexts.userInterface.formFieldLabels.phoneNumber.countryCode +
        _ctx.fieldIdSlug
      
      }, null, 8, ["value", "field-id"]),
      _createVNode(_component_FormElementInputField, {
        label: _ctx.commonTexts.userInterface.formFieldLabels.phoneNumber.areaCode,
        "field-id": 
        _ctx.commonTexts.userInterface.formFieldLabels.phoneNumber.areaCode +
        _ctx.fieldIdSlug
      ,
        value: _ctx.areaCode,
        "field-has-error": _ctx.fieldHasError,
        "input-type": "tel",
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateAreaCode($event))),
        onBlur: _ctx.submitPhoneNumber,
        class: "col-md-3 col-sm-6 col-6 form-element-phone-number__field"
      }, null, 8, ["label", "field-id", "value", "field-has-error", "onBlur"]),
      _createVNode(_component_FormElementInputField, {
        label: 
        _ctx.commonTexts.userInterface.formFieldLabels.phoneNumber.subscriberNumber
      ,
        value: _ctx.subscriberNumber,
        "field-has-error": _ctx.fieldHasError,
        "field-id": 
        _ctx.commonTexts.userInterface.formFieldLabels.phoneNumber.subscriberNumber +
        _ctx.fieldIdSlug
      ,
        "input-type": "tel",
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateSubscriberNumber($event))),
        onBlur: _ctx.submitPhoneNumber,
        class: "col-md-6 col-sm-12 col-12 form-element-phone-number__field"
      }, null, 8, ["label", "value", "field-has-error", "field-id", "onBlur"])
    ]),
    _: 1
  }))
}